import React, {useEffect, useState} from 'react';

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";

import InputField from "../../../components/input/new/InputField";
import SelectField from "../../../components/input/new/SelectField";
import InputPhoneField from "../../../components/input/new/InputPhoneField";
import CurrencyFormatField from "../../../components/input/new/CurrencyFormatField";
import SelectMultipleField from "../../../components/input/new/SelectMultipleField";
import RegTypeService from "../../../repository/RegTypeService";
import MiscService from "../../../repository/MiscService";
import {toast} from "react-toastify";
import RegistrationFooter from "../../../components/registration/RegistrationFooter";
import FileUploadField from "../../../components/input/new/FileUploadField";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import DocumentService from "../../../repository/DocumentService";
import TableBody from "@material-ui/core/TableBody";
import IssuerService from "../../../repository/IssuerService";
import CircularProgress from "@material-ui/core/CircularProgress";
import {formatBytes} from "../../../shared/utility";
import CheckBoxField from "../../../components/input/new/CheckBoxField";
import GooglePlaceAutocomplete from "../../../components/input/new/GooglePlaceAutocomplete";
import {useLocation, useParams} from "react-router";

const IssuerDirectPage = (props) => {

    const allowsubmit = useQuery()
    const [inProgress, setInProgress] = useState(false)
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [years, setYears] = useState([])
    const [regTypes, setRegTypes] = useState([])
    const [availableAddresses, setAvailableAddresses] = useState(new Map())
    const [errors, setErrors] = useState({})
    const [usaCountryId, setUsaCountryId] = useState('')
    const [documents, setDocuments] = useState([])
    const [verified, setVerified] = useState(false)
    const [profile, setProfile] = useState({
        firstName: '',
        lastName: '',
        pocIssuerEmail: '',
        phone: '',
        companyName: '',
        companyBusiness: '',
        companyCompetitors: '',
        companyFormedYear: '',
        capitalToRaise: '',
        capitalToDate: '',
        regTypes: [],
        startedRaise: '',
        launchingOffering: ''
    })
    const [address, setAddress] = useState({
        city: '',
        postalCode: '',
        streetAddress: '',
        country: '',
        state: '',
    })

    const apiKey = 'AIzaSyCaB_eJmQ8cOSg26Mx4u5MYEDHBzqtJ640'
    //let captcha = useRef()

    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    useEffect(() => {
        console.log(allowsubmit)
        initYears()
        fetchCountries()
        fetchStates()
        fetchRegTypes()
        //loadReCaptcha()

        // if (captcha.reset) {
        //     captcha.reset()
        // }
    }, [])

    const initYears = () => {
        let years = [];
        const currentYear = new Date().getFullYear()

        for (let i = 1900; i <= currentYear; i++) {
            years.unshift(i);
        }

        setYears(years)
    }

    const fetchCountries = () => {
        MiscService.fetchCountries()
            .then(data => {
                const countries = data.data.map(country => {
                    return {
                        id: country.id,
                        value: country.name
                    }
                })

                const usaCountry = countries.filter(country => country.value === 'United States')[0]
                const usaCountryId = usaCountry ? usaCountry.id : ''

                setCountries(countries)
                setUsaCountryId(usaCountryId)

                // set default value
                setProfile({
                    ...profile,
                    country: usaCountryId
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    const fetchStates = () => {
        MiscService.fetchStates()
            .then(data => {
                const states = data.data.map(state => {
                    return {
                        id: state.id,
                        value: state.name
                    }
                })

                setStates(states)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const fetchRegTypes = () => {
        RegTypeService.fetch()
            .then(data => {
                const regTypes = data.data.map(type => {
                    return {
                        id: type.id,
                        value: type.name,
                    }
                });

                setRegTypes(regTypes)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const submit = () => {
        if (handleValidation()) {
            setInProgress(true)

            const request = {
                ...profile,
                ...address
            }

            IssuerService.saveAttemptIssuer(request)
                .then(response => {
                    if (documents.length > 0) {
                        DocumentService.saveDocumentMultipleByEmail('other', documents, profile.pocIssuerEmail)
                            .then(response => {
                                response.data.forEach(item => {
                                    IssuerService.saveAttributeDataByEmail({
                                            value: item.filename,
                                            attributeCode: 'OTHER',
                                            documentId: item.id
                                        },
                                        profile.pocIssuerEmail)
                                        .then(response => {
                                            setInProgress(false)
                                            toast.success('All data submitted!')
                                        })
                                })
                            })
                            .catch((error) => {
                                console.log(error)
                                setInProgress(false)
                                toast.error('Error while submitting documents!')
                            })
                    } else {
                        setInProgress(false)
                        toast.success('All data submitted!')
                    }
                })
                .catch(error => {
                    console.log(error)
                    setInProgress(false)
                    toast.error('Error while submitting data!')
                })
        } else {
            toast.error('Please fill all the required fields!')
        }
    }

    const handleChangeFiles = (files) => {
        const _files = files.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        }))

        documents.push(..._files)
        setDocuments([...documents])
    }

    const handleValidation = () => {
        let errors = {}
        let valid = true

        if (!profile.firstName) {
            valid = false
            errors.firstName = 'Cannot be empty'
        }

        if (!profile.lastName) {
            valid = false
            errors.lastName = 'Cannot be empty'
        }

        if (!profile.pocIssuerEmail) {
            valid = false
            errors.pocIssuerEmail = 'Cannot be empty'
        }

        if (!profile.phone) {
            valid = false
            errors.phone = 'Cannot be empty'
        }

        if (!profile.companyName) {
            valid = false
            errors.companyName = 'Cannot be empty'
        }

        if (!address.streetAddress) {
            valid = false
            errors.streetAddress = 'Cannot be empty'
        }

        if (!address.postalCode) {
            valid = false
            errors.postalCode = 'Cannot be empty'
        }

        if (!address.country) {
            valid = false
            errors.country = 'Cannot be empty'
        } else {
            if (address.country === usaCountryId && !address.state) {
                valid = false
                errors.state = 'Cannot be empty'
            }
        }

        if (!address.city) {
            valid = false
            errors.city = 'Cannot be empty'
        }

        if (!profile.companyBusiness) {
            valid = false
            errors.companyBusiness = 'Cannot be empty'
        }

        if (!profile.capitalToRaise) {
            valid = false
            errors.capitalToRaise = 'Cannot be empty'
        }

        setErrors(errors)

        return valid
    }

    const handleChangeProfileField = (value, field) => {
        setProfile({
            ...profile,
            [field]: value.id ? value.id : value
        })

        validateValue(value, field)
    }

    const handleChangeAddressField = (value, field) => {
        setAddress({
            ...address,
            [field]: value.id ? value.id : value
        })

        validateValue(value, field)
    }

    const validateValue = (value, field) => {
        if (value || value === '') {
            errors[field] = ''
        }

        if (field === 'pocIssuerEmail' && value !== '') {
            !validateEmail(value) && (errors[field] = 'Wrong email format')
        }
    }

    const validateEmail = (email) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        return pattern.test(email);
    }

    const handleOnChangeAddress = (value) => {
        setAddress({
            ...address,
            streetAddress: value
        })
    }

    const handleSelectedAddress = (place) => {
        let state1 = ''
        let country1 = ''
        let city1 = ''
        let address1 = ''
        let postcode1 = ''

        for (const component of place.address_components) {
            const componentType = component.types[0];

            switch (componentType) {
                case "street_number": {
                    address1 = `${component.long_name} ${address1}`;
                    validateValue(address1, 'streetAddress')
                    break;
                }
                case "route": {
                    address1 += component.long_name;
                    validateValue(address1, 'streetAddress')
                    break;
                }
                case "postal_code": {
                    postcode1 = `${component.long_name}${postcode1}`;
                    validateValue(postcode1, 'postalCode')
                    break;
                }
                case "postal_code_suffix": {
                    postcode1 = `${postcode1}-${component.long_name}`;
                    validateValue(postcode1, 'postalCode')
                    break;
                }
                case "locality":
                    city1 = component.long_name;
                    validateValue(city1, 'city')
                    break;

                case "administrative_area_level_1": {
                    state1 = component.long_name;
                    break;
                }
                case "country":
                    country1 = component.long_name;
                    break;
                default:
            }
        }

        let country = countries.filter(_country => _country.value === country1)[0]

        let countryId = ''
        let stateId = ''

        if (country) {
            countryId = country.id
            validateValue(countryId, 'country')

            if (countryId === usaCountryId) {
                let state = states.filter(state => state.value === state1)[0]

                if (state) {
                    stateId = state.id
                    validateValue(stateId, 'state')
                }
            }
        }

        setAddress({
            country: countryId,
            state: stateId,
            city: city1,
            postalCode: postcode1,
            streetAddress: address1,
        })
    }

    const handleRemove = (file) => {
        const index = documents.indexOf(file)
        documents.splice(index, 1)
        setDocuments([...documents])
    }

    // const onLoadRecaptcha = () => {
    //     if (captcha.reset) {
    //         captcha.reset()
    //     }
    // }
    //
    // const verifyCallback = (recaptchaToken) => {
    //     if (recaptchaToken) {
    //         setVerified(true)
    //     }
    // }


    return (
        <>
            {inProgress ? (
                <Container maxWidth='lg'>
                    <Grid container
                          justify={"center"}
                          alignItems={"center"}
                          style={{height: '100vh'}}>
                        <Grid item>
                            <CircularProgress/>
                        </Grid>
                    </Grid>
                </Container>
            ) : (
                <>
                    <Grid container
                          spacing={3}
                          justify={"center"}>
                        <Grid item lg={6} xs={6}>
                            <Typography variant='h4'
                                        align={"center"}
                                        style={{marginTop: '30px'}}>
                                Register your raise
                            </Typography>
                        </Grid>
                    </Grid>

                    <Container
                        maxWidth='lg'
                        style={{margin: '48px auto'}}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} xs={12}>
                                <Card raised
                                      style={{overflow: 'unset'}}>
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item lg={12} xs={12}>
                                                <Typography variant='h6'>
                                                    Submitter Information
                                                </Typography>

                                                <Divider/>
                                            </Grid>

                                            <Grid item lg={12} xs={12}>
                                                <Grid container spacing={3}>
                                                    <Grid item lg={3} xs={6}>
                                                        <InputField
                                                            title={"First name"}
                                                            name={'firstName'}
                                                            required
                                                            value={profile.firstName}
                                                            onChange={handleChangeProfileField}
                                                            hasError={!!errors.firstName}
                                                            errorMessage={errors.firstName}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={3} xs={6}>
                                                        <InputField
                                                            title={"LastName"}
                                                            name={"lastName"}
                                                            required
                                                            value={profile.lastName}
                                                            onChange={handleChangeProfileField}
                                                            hasError={!!errors.lastName}
                                                            errorMessage={errors.lastName}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={3} xs={6}>
                                                        <InputField
                                                            title={"Email"}
                                                            name={"pocIssuerEmail"}
                                                            required
                                                            value={profile.pocIssuerEmail}
                                                            onChange={handleChangeProfileField}
                                                            hasError={!!errors.pocIssuerEmail}
                                                            errorMessage={errors.pocIssuerEmail}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={3} xs={6}>
                                                        <InputPhoneField
                                                            title={"Phone"}
                                                            name={"phone"}
                                                            required
                                                            value={profile.phone}
                                                            onChange={handleChangeProfileField}
                                                            hasError={!!errors.phone}
                                                            errorMessage={errors.phone}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                                <Card raised>
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item lg={12} xs={12}>
                                                <Typography variant='h6'>
                                                    Company Information
                                                </Typography>

                                                <Divider/>
                                            </Grid>

                                            <Grid item lg={12} xs={12}>
                                                <Grid container spacing={3}>
                                                    <Grid item lg={4} xs={6}>
                                                        <InputField
                                                            title={"Name"}
                                                            name={'companyName'}
                                                            required
                                                            value={profile.companyName}
                                                            onChange={handleChangeProfileField}
                                                            hasError={!!errors.companyName}
                                                            errorMessage={errors.companyName}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={4} xs={6}>
                                                        <GooglePlaceAutocomplete
                                                            apiKey={apiKey}
                                                            title={"Street Address"}
                                                            name={'streetAddress'}
                                                            defaultValue={address.streetAddress}
                                                            onChange={handleOnChangeAddress}
                                                            fillInAddress={handleSelectedAddress}
                                                            hasError={!!errors.streetAddress}
                                                            errorMessage={errors.streetAddress}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={4} xs={6}>
                                                        <InputField
                                                            title={"Postal/Zip code"}
                                                            name={'postalCode'}
                                                            required={address.country === usaCountryId}
                                                            value={address.postalCode}
                                                            onChange={handleChangeAddressField}
                                                            hasError={!!errors.postalCode}
                                                            errorMessage={errors.postalCode}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={4} xs={6}>
                                                        <SelectField
                                                            title={"Country"}
                                                            name={'country'}
                                                            required
                                                            value={address.country}
                                                            options={countries}
                                                            onChange={handleChangeAddressField}
                                                            hasError={!!errors.country}
                                                            errorMessage={errors.country}
                                                        />
                                                    </Grid>

                                                    {address.country === usaCountryId && (
                                                        <Grid item lg={4} xs={6}>
                                                            <SelectField
                                                                title={"State"}
                                                                name={'state'}
                                                                required
                                                                value={address.state}
                                                                options={states}
                                                                onChange={handleChangeAddressField}
                                                                hasError={!!errors.state}
                                                                errorMessage={errors.state}
                                                            />
                                                        </Grid>
                                                    )}

                                                    <Grid item lg={4} xs={6}>
                                                        <InputField
                                                            title={"City"}
                                                            name={"city"}
                                                            required
                                                            value={address.city}
                                                            onChange={handleChangeAddressField}
                                                            hasError={!!errors.city}
                                                            errorMessage={errors.city}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={4} xs={6}>
                                                        <InputField
                                                            title={"Industry"}
                                                            name={"companyBusiness"}
                                                            required
                                                            value={profile.companyBusiness}
                                                            onChange={handleChangeProfileField}
                                                            hasError={!!errors.companyBusiness}
                                                            errorMessage={errors.companyBusiness}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={4} xs={6}>
                                                        <InputField
                                                            title={"Competitors (if any)"}
                                                            name={"companyCompetitors"}
                                                            value={profile.companyCompetitors}
                                                            onChange={handleChangeProfileField}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={4} xs={6}>
                                                        <SelectField
                                                            title={"Year Company was formed"}
                                                            name={'companyFormedYear'}
                                                            value={profile.companyFormedYear}
                                                            options={years}
                                                            onChange={handleChangeProfileField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                                <Card raised>
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item lg={12} xs={12}>
                                                <Typography variant='h6'>
                                                    Additional Information
                                                </Typography>

                                                <Divider/>
                                            </Grid>

                                            <Grid item lg={12} xs={12}>
                                                <Grid container spacing={3}>
                                                    <Grid item lg={4} xs={6}>
                                                        <CurrencyFormatField
                                                            title={"How much capital are looking to raise?"}
                                                            name={"capitalToRaise"}
                                                            required
                                                            prefix={"$ "}
                                                            value={profile.capitalToRaise}
                                                            onChange={handleChangeProfileField}
                                                            hasError={!!errors.capitalToRaise}
                                                            errorMessage={errors.capitalToRaise}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={4} xs={6}>
                                                        <CurrencyFormatField
                                                            title={"Capital raised to date"}
                                                            name={"capitalToDate"}
                                                            prefix={"$ "}
                                                            value={profile.capitalToDate}
                                                            onChange={handleChangeProfileField}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={4} xs={6}>
                                                        <SelectMultipleField
                                                            title={"Type of Offering (Reg Types)"}
                                                            name={"regTypes"}
                                                            value={profile.regTypes}
                                                            options={regTypes}
                                                            onChange={handleChangeProfileField}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={6} xs={6}>
                                                        <InputField
                                                            title={"How close are you to launching the offering?"}
                                                            name={"launchingOffering"}
                                                            value={profile.launchingOffering}
                                                            onChange={handleChangeProfileField}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={6} xs={6}>
                                                        <CheckBoxField
                                                            title={"Have you started the raise?"}
                                                            name={"startedRaise"}
                                                            options={["Yes", "No"]}
                                                            value={profile.startedRaise}
                                                            onChange={handleChangeProfileField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                                <Card raised>
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item lg={12} xs={12}>
                                                <Typography variant='h6'>
                                                    Documentation
                                                </Typography>

                                                <Divider/>
                                            </Grid>

                                            <Grid item lg={6} xs={12}>
                                                <FileUploadField
                                                    title={"Whitepaper / OM or PPM / 2-pager / SAFE agreement"}
                                                    inputCaption={"Only JPG, PNG, DOCS or PDF files. File must be less than 20 Mb"}
                                                    multiple={true}
                                                    accept={'application/pdf,image/png,image/jpeg,application/vnd.openxmlformats-officedocument.wordprocessingml.document'}
                                                    onChange={handleChangeFiles}/>
                                            </Grid>

                                            <Grid item lg={6} xs={12}>
                                                {documents.length === 0 ? (
                                                    <Typography>
                                                        No Documents
                                                    </Typography>
                                                ) : (
                                                    <TableContainer>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Document Name</TableCell>
                                                                    <TableCell>Size</TableCell>
                                                                </TableRow>
                                                            </TableHead>

                                                            <TableBody>
                                                                {documents.map((document, index) => {
                                                                        return (
                                                                            <TableRow key={document.name + index}>
                                                                                <TableCell>{document.name}</TableCell>
                                                                                <TableCell>
                                                                                    {formatBytes(document.size)}</TableCell>
                                                                                <TableCell
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        justifyContent: 'flex-end'
                                                                                    }}>

                                                                                    <Button
                                                                                        style={{color: '#B71C1C'}}
                                                                                        name={props.fileNumber}
                                                                                        type={"button"}
                                                                                        onClick={() => handleRemove(document)}>
                                                                                        Remove
                                                                                    </Button>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    }
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item lg={12} xs={12}
                                  style={{textAlign: 'end'}}>
                                <Grid container spacing={3}>
                                    <Grid item lg={6} xs={6}>
                                        {/*<ReCaptcha*/}
                                        {/*    ref={(el) => {*/}
                                        {/*        captcha = el*/}
                                        {/*    }}*/}
                                        {/*    size="normal"*/}
                                        {/*    data-theme="light"*/}
                                        {/*    render="explicit"*/}
                                        {/*    sitekey="6LfPUfYUAAAAANV9y2TFUGhv9nnfOICbPvr2AFra"*/}
                                        {/*    onloadCallback={onLoadRecaptcha}*/}
                                        {/*    verifyCallback={verifyCallback}*/}
                                        {/*/>*/}
                                    </Grid>

                                    <Grid item lg={6} xs={6}>
                                        <Button
                                            type={"button"}
                                            color="primary"
                                            variant={"contained"}
                                            disabled={allowsubmit.get('allowsubmit') !== 'true'}
                                            onClick={submit}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>

                    <RegistrationFooter/>
                </>
            )}
        </>
    )
}

export default IssuerDirectPage;