import React, {Component} from 'react';

import './css/InboxIndividualPage.css'
import ConversationService from "../../repository/ConversationService";
import Moment from 'react-moment';
import {toast} from 'react-toastify';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import AddIcon from "@material-ui/icons/Add";
import MessageComposeModal from "./modals/MessageComposeModal";

class InboxIndividualPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            channels: [],
            userRole: '',
        }
    }

    componentDidMount() {
        document.body.setAttribute('class', 'dashboard-body');

        this.setState({
            userRole: JSON.parse(localStorage.getItem('userInfo')).userGroup.id
        });

        this.fetchMessageChannels();
        this.updateMessage();
        //setInterval(this.updateMessage, 4000)

    }

    updateMessage = () => {
        this.props.notRead.forEach(message => {
            ConversationService.updateMessage(message.id)
        })

    };

    fetchMessageChannels = () => {
        ConversationService.fetchSubscriptions().then(data => {
            this.setState({channels: data.data}, () => {
                setInterval(this.startCheckZendesk, 15000)
            });
        });
    };

    // showInvestModal = () => {
    //     this.setState({show: true})
    // };

    onMessageSend = (message) => {
        ConversationService.postMessage(this.state.channels[0].channelId, message, true).then(data => {
            toast.success('Thank you for sending your message. We will respond within 72 hours!');
        })
    };


    handleClose = () => {
        this.setState({show: false})
    };

    // onChannelChange = (e) => {
    //     this.fetchMessagesPerChannel(e.target.value)
    // };

    checkNewMessageFromZendesk = (channelId) => {
        ConversationService.checkNewMessageFromZendesk(channelId)
            .then(response => {
                    // if (response.data === true) {
                    //     this.fetchMessageChannels();
                    // }
                }
            )
    };

    startCheckZendesk = () => {
        this.checkNewMessageFromZendesk(this.state.channels[0].channelId);
    };

    handleFormSubmit = (e) => {
        // debugger
        // e.preventDefault();
        // this.onMessageSend({
        //     'channelId': this.state.channels[0].channelId,
        //     'content': e.target.content.value,
        //     'flag':true
        // });
        // e.target.reset();
        // // props.close();
    };

    showComposeModal = () => {
        this.setState({
            ...this.state,
            show: true
        })
    }

    // onMessageSend = (e) => {
    //     // ConversationService.postMessage(req.channelId, req.content).then(data => {
    //     //     this.loadConversation(this.props.user.id);
    //     // })
    //     debugger
    //     e.preventDefault();
    //     this.onMessageSend({
    //         'channelId': this.state.channels[0].channelId,
    //         'content': e.target.content.value,
    //         'flag':true
    //     });
    //     e.target.reset();
    // }


    render() {
        return (
            <>
                <MessageComposeModal
                    submit={this.onMessageSend}
                    show={this.state.show}
                    close={this.handleClose}>
                </MessageComposeModal>

                <Grid container spacing={3} style={{paddingTop: 80}}>
                    <Grid item lg={12} xs={12}>
                        <Typography variant='h6'>
                            Support
                        </Typography>

                        <Divider/>
                    </Grid>

                    <Grid
                        item lg={8} xs={12}
                        style={{display: "flex", justifyContent: "center", margin: 'auto'}}>
                        <Card raised>
                            <CardContent>
                                {(this.state.userRole === 'USER_ISSUER' && this.props.messages.length > 0) || this.state.userRole !== 'USER_ISSUER' ?
                                    <Grid container spacing={3}>
                                        <Grid item lg={6} xs={6}>
                                            <Typography variant="h6">Inbox Panel</Typography>
                                        </Grid>
                                        <Grid item lg={6} xs={6}
                                              style={{display: "flex", justifyContent: 'flex-end'}}>
                                            <Button variant='contained'
                                                    color='primary'
                                                    startIcon={<AddIcon/>}
                                                    onClick={this.showComposeModal}>
                                                Send a message to support
                                            </Button>
                                        </Grid>
                                        <Grid item lg={12} xs={12}>
                                            <Divider/>
                                        </Grid>
                                        <Grid item lg={12} xs={12}>
                                            {this.props.messages.length === 0 ?
                                                <Grid item lg={12} xs={12}> <Typography>No
                                                    Messages</Typography></Grid> :
                                                this.props.messages.map(v => {
                                                    return (
                                                        <Grid xs={12} lg={12} style={{paddingBottom: 10}}>
                                                            <Card>
                                                                <CardContent style={{backgroundColor: '#D9E9F3'}}>
                                                                    <Grid container spacing={1}>
                                                                        <Grid item lg={6} xs={6}>
                                                                            <Typography
                                                                                variant='subtitle2'>Date: <Moment
                                                                                format={'LLL'}>{v.dateTime}</Moment></Typography>
                                                                        </Grid>
                                                                        <Grid item lg={6} xs={6}>
                                                                            <Typography
                                                                                variant='subtitle2'>Author: {v.nameFrom + ' ' + v.surnameFrom}</Typography>
                                                                        </Grid>
                                                                        <Grid item lg={12} xs={12}>
                                                                            <Divider/>
                                                                        </Grid>
                                                                        <Grid item lg={12} xs={12}>
                                                                            <Typography
                                                                                variant='subtitle1'>{ConversationService.formatMessage(v.content)}</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid item lg={12} xs={12}>
                                        <Typography>Your inbox will be available as soon as the support initiates
                                            the communication.</Typography>
                                    </Grid>}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={12}/>
                </Grid>
            </>

        )
    }
}

export default InboxIndividualPage;