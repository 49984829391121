import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const SigningModal = ({documentUrl, open, close}) => {

    return (
        <Dialog
            onClose={close}
            open={open}
            fullWidth
            maxWidth={"md"}
        >
            <DialogTitle>Sign document</DialogTitle>

            <DialogContent style={{overflow: 'hidden'}}>
                <Grid container spacing={3}>
                    <Grid item lg={12} xs={12}>
                            <iframe
                                title={'sign'}
                                src={documentUrl}
                                style={{textAlign: 'center', width: '100%', minHeight: '500px'}}
                            />

                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button
                    type="button"
                    data-dismiss="modal"
                    onClick={close}>
                    close
                </Button>
            </DialogActions>
        </Dialog>

    )
}

export default SigningModal