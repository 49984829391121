import React from 'react';

import Aux from '../../hoc/AuxWrapper';
import {CardContent, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import DoneIcon from '@material-ui/icons/Done';
import styled from "styled-components";
import Button from "@material-ui/core/Button";

const IconSuccess = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #E3F6E4;
  margin-bottom: 10px;
`;

const WrapApproval = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
`

const WaitingForApprovalPage = (props) => {

    return (
        <Aux>
            <WrapApproval>
                <Card raised
                      style={{padding: '60px 0'}}>
                    <CardContent>
                        <Grid container>
                            <Box m="auto">
                                <Box display="flex" justifyContent="center">
                                    <IconSuccess>
                                        <DoneIcon
                                            color={"secondary"}/>
                                    </IconSuccess>
                                </Box>

                                <Typography
                                    variant="h6"
                                    style={{textAlign: 'center'}}>
                                    Thank you for completing the application process.
                                    <br/>
                                    You will receive an email once
                                    <br/>
                                    your account is approved.
                                </Typography>

                                <Box display="flex" justifyContent="center">
                                    <Button
                                        variant='contained'
                                        color={"primary"}
                                        style={{marginTop: 70}}
                                        onClick={() => window.location.href = process.env.REACT_APP_OFFERING_GATEWAY + '/offerings'}>
                                        Continue
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </CardContent>
                </Card>
            </WrapApproval>
        </Aux>
    )
};

export default WaitingForApprovalPage;