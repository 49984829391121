import axios from '../custom-axios/app';

const RegistrationService = {
    saveAttributeData: (attributeCode, value) => {
        axios.post('/example/path', {
            value: value,
            attributeCode: attributeCode,
        }).then(response => {
        }).catch(error => {
            console.log(error);
        });
    },
    setPendingState: () => {
        return axios.post('/rest/investors/finish_registration');
    }
};

export default RegistrationService;