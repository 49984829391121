import React, {useState} from "react";

import {Typography} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";

const CheckBoxField = ({
                           title, name, value, options, multiple = false, required, onChange, hasError = false,
                           errorMessage
                       }) => {

    const [values, setValues] = useState([])

    const handleChange = (checked, value) => {
        if (multiple) {
            const checkedValues = [...values]
            const index = values.indexOf(value)

            if (checked && (index === -1)) checkedValues.push(value)
            else if (!checked && (index !== -1)) checkedValues.splice(index, 1)

            setValues(checkedValues)
            value = checkedValues
        }

        onChange(value, name)
    }

    return (
        <>
            <Typography>
                {title}
                {required && <span className="required__circle"> *</span>}
            </Typography>
            <FormControl variant="outlined"
                         fullWidth
                         error={hasError}
            >
                <FormGroup row>
                    {options.map(option =>
                        <FormControlLabel
                            key={option.id ? option.id : option}
                            control={
                                <Checkbox
                                    name={option.id ? option.id : option}
                                    onChange={(event) => handleChange(event.target.checked, event.target.name)}
                                    color="primary"
                                    checked={value.some ? (value.some(value => option.id ? value === option.id : value === option))
                                        : (option.id ? value === option.id : value === option)}
                                />
                            }
                            label={option.value ? option.value : option}
                        />
                    )}
                </FormGroup>
                <FormHelperText>
                    {hasError && errorMessage}
                </FormHelperText>
            </FormControl>
        </>
    )
}

export default CheckBoxField