import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

class DobUS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            month: '',
            day: '',
            year: '',
            daysInMonth: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
            days: [],
            initialDatesSet: false,
            error: false
        }
    }

    componentDidUpdate(prevProps) {
        if ((this.props.defaultValue && this.props.defaultValue.chosenValue && !prevProps.defaultValue) || (prevProps.defaultValue && prevProps.defaultValue.chosenValue && !this.state.initialDatesSet)) {
            let date = new Date(this.props.defaultValue.chosenValue);
            this.setState({
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate(),
                initialDatesSet: true
            });

            this.updateDays(date.getMonth() + 1);
        }
    }

    leapYear(year) {
        return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
    }

    monthChanged(e) {
        this.setState({
            month: parseInt(e.target.value)
        }, () => this.dateChanged())
    }

    yearChanged(e) {
        this.setState({
            year: parseInt(e.target.value)
        }, () => {
            let newDaysInMonth = [...this.state.daysInMonth];

            if (this.leapYear(this.state.year)) {
                newDaysInMonth[1] = 29;
                this.setState({
                    daysInMonth: newDaysInMonth,
                }, () => this.dateChanged())
            } else if (this.state.daysInMonth[1] === 29) {
                newDaysInMonth[1] = 28;
                this.setState({
                    daysInMonth: newDaysInMonth,
                    day: this.state.day === 29 ? 1 : this.state.day,
                }, () => this.dateChanged())
            } else this.dateChanged();
        })
    }

    dayChanged(e) {
        this.setState({
            day: parseInt(e.target.value)
        }, () => this.submitDate())
    }

    dateChanged() {
        if (this.state.daysInMonth[this.state.month - 1] !== this.state.days.length) {
            this.updateDays(this.state.month);
            this.submitDate();
        } else this.submitDate();
    }

    updateDays(month) {
        let newDays = [];

        for (let i = 1; i <= this.state.daysInMonth[month - 1]; i++) {
            newDays.push(i);
        }

        this.setState({
            days: newDays,
        });

    }

    submitDate() {
        if (this.state.year !== ' ' && this.state.month !== ' ' && this.state.day !== ' ') {
            let dob = new Date(this.state.year, this.state.month - 1, this.state.day, 0, 0, 0, 0);
            this.props.onChangeHandler({
                attributeCode: this.props.attributeCode,
                value: dob
            });

            let now = new Date();
            if (dob.getFullYear() + 18 > now.getFullYear()) {
                this.setState({error: true});
            } else if (dob.getFullYear() + 18 === now.getFullYear()) {
                if (dob.getMonth() > now.getMonth()) {
                    this.setState({error: true});
                } else if (dob.getMonth() === now.getMonth()) {
                    this.setState({error: dob.getDate() > now.getDate()});
                } else this.setState({error: false})
            } else this.setState({error: false});
        }
    }

    render() {
        return (
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <FormControl variant="outlined"
                                 fullWidth
                                 size={"small"}>
                        <small>Month</small>
                        <Select
                            native
                            variant={"outlined"}
                            size={"small"}
                            value={this.state.month}
                            //  className={this.props.monthsClassName}
                            onChange={(e) => this.monthChanged(e)}>
                            <option value="" disabled defaultValue/>
                            {this.props.months.map(m => {
                                return (
                                    <option
                                        key={m}
                                        value={m}> {m} </option>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <FormControl variant="outlined"
                                 fullWidth
                                 size={"small"}>
                        <small>Day</small>
                        <Select
                            size={"small"}
                            variant={"outlined"}
                            value={this.state.day}
                            //  className={this.props.daysClassName}
                            onChange={(e) => this.dayChanged(e)}>
                            <option value="" disabled defaultValue/>
                            {
                                this.state.days.map(d => {
                                    return (
                                        <option key={d} value={d}> {d} </option>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <FormControl variant="outlined"
                                 fullWidth
                                 size={"small"}>
                        <small>Year</small>
                        <Select
                            size={"small"}
                            value={this.state.year}
                            variant={"outlined"}
                            // className={this.props.yearsClassName}
                            onChange={(e) => this.yearChanged(e)}>
                            <option value="" disabled defaultValue/>
                            {this.props.years.map(y => {
                                return (
                                    <option
                                        key={y}
                                        value={y}>
                                        {y}
                                    </option>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

        );
    }
}


export default DobUS;