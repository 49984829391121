import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import UserService from "../../repository/UserService";
import InvestorService from "../../repository/InvestorService";

const initialState = {
    currentUser: null,
    investorData: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
}

export const fetchUserInfo = createAsyncThunk(
    'users/fetchCurrentUser',
    async (_, thunkAPI) => {
        try {
            // thunkAPI.dispatch(setLoader())
            const response = await UserService.fetchCurrentUser()
            //  thunkAPI.dispatch(unsetLoader())

            return response.data
        } catch (error) {
            // thunkAPI.dispatch(unsetLoader())
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const fetchInvestorData = createAsyncThunk(
    'users/fetchInvestorData',
    async (_, thunkAPI) => {
        try {
            // thunkAPI.dispatch(setLoader())
            const response = await InvestorService.fetchInvestorStatus()
            //  thunkAPI.dispatch(unsetLoader())

            return response.data
        } catch (error) {
            // thunkAPI.dispatch(unsetLoader())
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const currentUserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        assignOffer: (state, action) => {
            state.currentUser = {
                ...state.currentUser,
                assignOffer: action.payload
            }

            return state;
        },
        clearState: (state) => {
            state.isError = false
            state.isSuccess = false
            state.isFetching = false
            state.currentUser = null
            state.investorData = null

            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserInfo.pending, (state) => {
                state.isFetching = true
            })
            .addCase(fetchUserInfo.fulfilled, (state, action) => {
                state.isFetching = false
                state.isSuccess = true
                state.currentUser = action.payload
            })
            .addCase(fetchUserInfo.rejected, (state, action) => {
                state.isFetching = false
                state.isError = true
                state.errorMessage = action.payload.message;
            })
            .addCase(fetchInvestorData.pending, (state) => {
                state.isFetching = true
            })
            .addCase(fetchInvestorData.fulfilled, (state, action) => {
                state.isFetching = false
                state.isSuccess = true
                state.investorData = action.payload
            })
            .addCase(fetchInvestorData.rejected, (state, action) => {
                state.isFetching = false
                state.isError = true
                state.errorMessage = action.payload.message;
            })

    },
})

export const {assignOffer} = currentUserSlice.actions
export const {clearState} = currentUserSlice.actions;

export const selectCurrentUser = (state) => state.user