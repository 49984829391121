import React, {Component} from 'react';

import IssuerService from "../../../repository/IssuerService";
import MiscService from "../../../repository/MiscService";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import InputField from "../../../components/input/new/InputField";
import SelectField from "../../../components/input/new/SelectField";
import {toast} from "react-toastify";
import InputPhoneField from "../../../components/input/new/InputPhoneField";
import GooglePlaceAutocomplete from "../../../components/input/new/GooglePlaceAutocomplete";

class IssuerProfilePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            profile: {
                pocFullName: '',
                pocIssuerEmail: '',
                phone: '',
                companyName: '',
                city: '',
                postalCode: '',
                streetAddress: '',
                country: '',
                state: '',
                companyBusiness: '',
                companyCompetitors: '',
                companyFormedYear: '',
                capitalToRaise: '',
                capitalToDate: '',
                regTypes: [],
                startedRaise: '',
                launchingOffering: ''
            },
            countries: [],
            states: [],
            availableAddresses: new Map(),
            usaCountryId: '',
            apiKey: 'AIzaSyCaB_eJmQ8cOSg26Mx4u5MYEDHBzqtJ640',
            years: [],
            errors: {}
        }
    }

    componentDidMount() {
        this.init()
        this.initYears()
    }

    init() {
        MiscService.fetchCountries()
            .then(data => {
                const countries = data.data.map(country => {
                    return {
                        id: country.id,
                        value: country.name
                    }
                })

                const usaCountry = countries.filter(country => country.value === 'United States')[0]
                const usaCountryId = usaCountry ? usaCountry.id : ''

                this.setState({
                    countries: countries,
                    usaCountryId: usaCountryId,
                    profile: {
                        ...this.state.profile,
                        country: usaCountryId
                    }
                })

                MiscService.fetchStates()
                    .then(data => {
                        const states = data.data.map(state => {
                            return {
                                id: state.id,
                                value: state.name
                            }
                        })

                        this.setState({
                            states: states
                        })
                    })

                IssuerService.loadProfile()
                    .then((data) => {
                        this.setState({
                            profile: {
                                ...this.state.profile,
                                pocFullName: !!data.data.pocFullName ? data.data.pocFullName : this.state.profile.pocFullName,
                                pocIssuerEmail: !!data.data.pocIssuerEmail ? data.data.pocIssuerEmail : this.state.profile.pocIssuerEmail,
                                phone: !!data.data.phone ? data.data.phone : this.state.profile.phone,
                                companyName: !!data.data.companyName ? data.data.companyName : this.state.profile.companyName,
                                country: !!data.data.country ? data.data.country : this.state.usaCountryId,
                                state: !!data.data.state ? data.data.state : this.state.profile.state,
                                city: !!data.data.city ? data.data.city : this.state.profile.city,
                                postalCode: !!data.data.postalCode ? data.data.postalCode : this.state.profile.postalCode,
                                streetAddress: !!data.data.streetAddress ? data.data.streetAddress : this.state.profile.streetAddress,
                                companyBusiness: !!data.data.companyBusiness ? data.data.companyBusiness : this.state.profile.companyBusiness,
                                companyCompetitors: !!data.data.companyCompetitors ? data.data.companyCompetitors : this.state.profile.companyCompetitors,
                                companyFormedYear: !!data.data.companyFormedYear ? data.data.companyFormedYear : this.state.profile.companyFormedYear,
                            },
                        })
                    })
            })
    }

    initYears = () => {
        let years = [];
        const currentYear = new Date().getFullYear()

        for (let i = 1900; i <= currentYear; i++) {
            years.unshift(i);
        }

        this.setState({
            years: years
        })
    }

    nextStep(e) {
        e.preventDefault();
    }

    onSubmit = () => {
        if (this.handleValidation()) {
            const request = {
                ...this.state.profile,
                registrationStatus: 'STEP_ISSUER_DOCUMENTATION'
            }

            IssuerService.saveProfile(request)
                .then(data => {
                    this.setState({
                        profile: data.data
                    }, () => {
                        this.props.setRegistrationStatus('STEP_ISSUER_DOCUMENTATION')
                            .then(res => {
                                localStorage.setItem('userInfo', JSON.stringify(res.data))
                                this.props.history.push('/issuer-documentation')
                            }).catch(err => {
                        })
                    })
                })
        } else {
            toast.error('Please fill all the required fields.')
        }
    }

    handleValidation = () => {
        let errors = {}
        let valid = true

        if (!this.state.profile.companyName) {
            valid = false
            errors.companyName = 'Cannot be empty'
        }

        if (!this.state.profile.streetAddress) {
            valid = false
            errors.streetAddress = 'Cannot be empty'
        }

        if (!this.state.profile.postalCode) {
            valid = false
            errors.postalCode = 'Cannot be empty'
        }

        if (!this.state.profile.country) {
            valid = false
            errors.country = 'Cannot be empty'
        }

        if ((this.state.profile.country === this.state.usaCountryId) && !this.state.profile.state) {
            valid = false
            errors.state = 'Cannot be empty'
        }

        if (!this.state.profile.city) {
            valid = false
            errors.city = 'Cannot be empty'
        }

        if (!this.state.profile.pocFullName) {
            valid = false
            errors.pocFullName = 'Cannot be empty'
        }

        if (!this.state.profile.phone) {
            valid = false
            errors.phone = 'Cannot be empty'
        }

        if (!this.state.profile.companyBusiness) {
            valid = false
            errors.companyBusiness = 'Cannot be empty'
        }

        this.setState({
            errors: errors
        })

        return valid
    }

    handleChangeProfileField = (value, prop) => {
        this.setState({
            profile: {
                ...this.state.profile,
                [prop]: value.id ? value.id : value
            }
        })

        this.validateValue(value, prop)
    }

    validateValue = (value, prop) => {
        if (value || value === '') {
            this.setState({
                errors: {
                    [prop]: ''
                }
            })
        }
    }

    handleOnChangeAddress = (address) => {
        this.setState({
            profile: {
                ...this.state.profile,
                streetAddress: address
            }
        })
    }

    handleSelectedAddress = (place) => {
        let state1 = ''
        let country1 = ''
        let city1 = ''
        let address1 = ''
        let postcode1 = ''

        for (const component of place.address_components) {
            const componentType = component.types[0];

            switch (componentType) {
                case "street_number": {
                    address1 = `${component.long_name} ${address1}`;
                    break;
                }
                case "route": {
                    address1 += component.long_name;
                    break;
                }
                case "postal_code": {
                    postcode1 = `${component.long_name}${postcode1}`;
                    break;
                }
                case "postal_code_suffix": {
                    postcode1 = `${postcode1}-${component.long_name}`;
                    break;
                }
                case "locality":
                    city1 = component.long_name;
                    break;

                case "administrative_area_level_1": {
                    state1 = component.long_name;
                    break;
                }
                case "country":
                    country1 = component.long_name;
                    break;
                default:
            }
        }

        let country = this.state.countries.filter(_country => _country.value === country1)[0]

        let countryId = ''
        let stateId = ''

        if (country) {
            countryId = country.id

            if (countryId === this.state.usaCountryId) {
                let state = this.state.states.filter(state => state.value === state1)[0]

                if (state) {
                    stateId = state.id
                }
            }
        }

        this.setState({
            profile: {
                ...this.state.profile,
                country: countryId,
                state: stateId,
                city: city1,
                postalCode: postcode1,
                streetAddress: address1
            }
        })
    }


    render() {
        return (
            <Container maxWidth='lg' style={{margin: '48px auto'}}>
                <Grid container spacing={3}>
                    <Grid item lg={12} xs={12}>
                        <Card raised>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item lg={12} xs={12}>
                                        <Typography variant='h6'>
                                            Company Information
                                        </Typography>

                                        <Divider/>
                                    </Grid>

                                    <Grid item lg={6} xs={12}>
                                        <Grid container spacing={3}>
                                            <Grid item lg={12} xs={12}>
                                                <Grid container spacing={3}>
                                                    <Grid item lg={6} xs={6}>
                                                        <Typography>Company name
                                                            <span className="required__circle"> *</span>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item lg={6} xs={6}>
                                                        <InputField
                                                            name={'companyName'}
                                                            value={this.state.profile.companyName}
                                                            onChange={this.handleChangeProfileField}
                                                            hasError={!!this.state.errors.companyName}
                                                            errorMessage={this.state.errors.companyName}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item lg={12} xs={12}>
                                                <Grid container spacing={3}>
                                                    <Grid item lg={6} xs={6}>
                                                        <Typography>
                                                            Country
                                                            <span className="required__circle"> *</span>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item lg={6} xs={6}>
                                                        <SelectField
                                                            name={'country'}
                                                            value={this.state.profile.country}
                                                            options={this.state.countries}
                                                            onChange={this.handleChangeProfileField}
                                                            hasError={!!this.state.errors.country}
                                                            errorMessage={this.state.errors.country}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            {this.state.usaCountryId !== '' && (this.state.profile.country === this.state.usaCountryId) ? (
                                                <Grid item lg={12} xs={12}>
                                                    <Grid container spacing={3}>
                                                        <Grid item lg={6} xs={6}>
                                                            <Typography>
                                                                State/Province
                                                                <span className="required__circle"> *</span>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item lg={6} xs={6}>
                                                            <SelectField
                                                                name={'state'}
                                                                value={this.state.profile.state}
                                                                options={this.state.states}
                                                                onChange={this.handleChangeProfileField}
                                                                hasError={!!this.state.errors.state}
                                                                errorMessage={this.state.errors.state}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ) : null}

                                            <Grid item lg={12} xs={12}>
                                                <Grid container spacing={3}>
                                                    <Grid item lg={6} xs={6}>
                                                        <Typography>
                                                            City
                                                            <span className="required__circle"> *</span>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item lg={6} xs={6}>
                                                        <InputField
                                                            name={'city'}
                                                            value={this.state.profile.city}
                                                            onChange={this.handleChangeProfileField}
                                                            hasError={!!this.state.errors.city}
                                                            errorMessage={this.state.errors.city}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item lg={12} xs={12}>
                                                <Grid container spacing={3}>
                                                    <Grid item lg={6} xs={6}>
                                                        <Typography>
                                                            Street address
                                                            <span className="required__circle"> *</span>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item lg={6} xs={6}>
                                                        <GooglePlaceAutocomplete
                                                            apiKey={this.state.apiKey}
                                                            defaultValue={this.state.profile.streetAddress}
                                                            onChange={this.handleOnChangeAddress}
                                                            fillInAddress={this.handleSelectedAddress}
                                                            hasError={!!this.state.errors.streetAddress}
                                                            errorMessage={this.state.errors.streetAddress}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item lg={12} xs={12}>
                                                <Grid container spacing={3}>
                                                    <Grid item lg={6} xs={6}>
                                                        <Typography>
                                                            Postal code
                                                            {this.state.profile.country === this.state.usaCountryId &&
                                                            <span className="required__circle"> *</span>}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item lg={6} xs={6}>
                                                        <InputField
                                                            name={'postalCode'}
                                                            value={this.state.profile.postalCode}
                                                            //type={'number'}
                                                            onChange={this.handleChangeProfileField}
                                                            hasError={!!this.state.errors.postalCode}
                                                            errorMessage={this.state.errors.postalCode}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item lg={6} xs={12}>
                                        <Grid container spacing={3}>
                                            <Grid item lg={12} xs={12}>
                                                <Grid container spacing={3}>
                                                    <Grid item lg={6} xs={6}>
                                                        <Typography>
                                                            Industry
                                                            <span className="required__circle"> *</span>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item lg={6} xs={6}>
                                                        <InputField
                                                            name={'companyBusiness'}
                                                            value={this.state.profile.companyBusiness}
                                                            onChange={this.handleChangeProfileField}
                                                            hasError={!!this.state.errors.companyBusiness}
                                                            errorMessage={this.state.errors.companyBusiness}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item lg={12} xs={12}>
                                                <Grid container spacing={3}>
                                                    <Grid item lg={6} xs={6}>
                                                        <Typography>
                                                            Competitors (if any)
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item lg={6} xs={6}>
                                                        <InputField
                                                            name={'companyCompetitors'}
                                                            value={this.state.profile.companyCompetitors}
                                                            onChange={this.handleChangeProfileField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item lg={12} xs={12}>
                                                <Grid container spacing={3}>
                                                    <Grid item lg={6} xs={6}>
                                                        <Typography>
                                                            Year Company was formed
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item lg={6} xs={6}>
                                                        <SelectField
                                                            name={'companyFormedYear'}
                                                            value={this.state.profile.companyFormedYear}
                                                            options={this.state.years}
                                                            onChange={this.handleChangeProfileField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                        <Card raised
                              style={{overflow: 'unset'}}>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item lg={12} xs={12}>
                                        <Typography variant='h6'>
                                            Contact Information
                                        </Typography>

                                        <Divider/>
                                    </Grid>

                                    <Grid item lg={6} xs={12}>
                                        <Grid container spacing={3}>
                                            <Grid item lg={6} xs={6}>
                                                <Typography>
                                                    Full name
                                                    <span className="required__circle"> *</span>
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} xs={6}>
                                                <InputField
                                                    name={"pocFullName"}
                                                    value={this.state.profile.pocFullName}
                                                    onChange={this.handleChangeProfileField}
                                                    hasError={!!this.state.errors.pocFullName}
                                                    errorMessage={this.state.errors.pocFullName}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item lg={6} xs={12}>
                                        <Grid container spacing={3}>
                                            <Grid item lg={6} xs={6}>
                                                <Typography>
                                                    Phone number
                                                    <span className="required__circle"> *</span>
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} xs={6}>
                                                <InputPhoneField
                                                    name={"phone"}
                                                    value={this.state.profile.phone}
                                                    onChange={this.handleChangeProfileField}
                                                    hasError={!!this.state.errors.phone}
                                                    errorMessage={this.state.errors.phone}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item lg={12} xs={12}
                          style={{textAlign: 'end'}}>
                        <Button
                            type={"button"}
                            color="primary"
                            //disabled={this.state.errors}
                            endIcon={<ArrowForwardIcon/>}
                            variant={"contained"}
                            onClick={this.onSubmit}
                        >
                            Continue
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}

export default IssuerProfilePage;