import React, {useEffect} from 'react';

import {Grid} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import DoneIcon from '@material-ui/icons/Done';

const RegistrationSteps = (props) => {

    const [step, setStep] = React.useState(0)

    useEffect(() => {
        const currentStep = props.steps.find(s => s.value === props.currentStep);
        setStep(currentStep.number)
    })

    // const navigate = (e, step, path) => {
    //     e.preventDefault();
    //
    //     const nextStep = props.steps.find(s => s.value === step);
    //     const currentStep = props.steps.find(s => s.value === props.currentStep);
    //
    //     if (nextStep.number && (nextStep.number < currentStep.number) && props.currentStep !== 'STEP_APPROVAL') {
    //         props.setRegistrationStatus(step)
    //             .then(res => {
    //                 localStorage.setItem('userInfo', JSON.stringify(res.data));
    //                 window.location.href = path;
    //                 //this.props.history.push(path);
    //             }).catch(err => {
    //         });
    //     }
    // }

    const steps = () => {

        return props.steps.map((step, index) => {
            const currentStep = props.steps.find(({value}) => value === props.currentStep);
            const activeStep = currentStep.value === step.value ? 'active' : '';
            const prevStep = currentStep.number > step.number ? 'done' : '';

            return (
                <li
                    key={step.value}

                    className={props.userGroup && props.userGroup.id === 'USER_ISSUER'
                        ? `${activeStep} ${prevStep} registration__steps--issuer`
                        : props.userGroup && props.userGroup.id === 'USER_INVESTOR_COMPANY'
                            ? `${activeStep} ${prevStep} registration__steps--company`
                            : `${activeStep} ${prevStep} registration__steps`}>

                    <div className="step__circle">
                        {prevStep ? <DoneIcon/> : step.number}
                        {props.steps.length === step.number ? null : props.userGroup && props.userGroup.id === 'USER_ISSUER'
                            ? <div className="linear__steps--issuer"/>
                            : props.userGroup && props.userGroup.id === 'USER_INVESTOR_COMPANY'
                                ? <div className='linear__steps--company'/>
                                : <div className="linear__steps"/>}

                    </div>
                </li>

            );
        })
    }

    return (
        <>
            {props.userGroup && props.userGroup.id === 'USER_INVESTOR_INDIVIDUAL' && step === 7 ? null :

                <Grid item lg={12} xs={12} style={{marginTop: '100px', marginBottom: '20px'}}>
                    <Card raised={true}>
                        <CardContent>
                            <ul className="registration__main--steps">
                                {steps()}
                            </ul>
                        </CardContent>
                    </Card>
                </Grid>

            }
        </>
    );
};


export default RegistrationSteps;