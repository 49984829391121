import {toast} from "react-toastify";

const validationAlert = (valid) => {
    let message = '';
    valid.forEach(element => {
        message = message + element + ', '
    });
    let s = 'Please fill in the following field(s): ' + message.slice(0, -2);
    toast.error(s);
}

export default validationAlert