import axios from '../custom-axios/app';

const InvestmentService = {

    fetchAllMyInvestments: (sort, page, pageSize, filter) => {
        let filterOpt = [];

        if (filter) {
            filterOpt = Object.keys(filter).map(function (key) {
                if (filter[key] !== undefined && filter[key] !== '') return "&filter%5B" + key + "%5D=" + filter[key] + ""

                return ''
            })
        }

        return axios.get(`/api/investments/paged?sort=${sort}&page=${page}&pageSize=${pageSize}` + filterOpt.join(''));
    },

    fetchInvestment: (id) => {
        return axios.get(`/api/investments/${id}`);
    },

    fetchInvestmentStatuses: () => {
        return axios.get("/api/investments/statuses");
    },

    fetchIssuerDealsInvestments: () => {
        return axios.get("/api/investments/issuer");
    },

    fetchIssuerDealsInvestmentsPerDeal: (dealId) => {
        return axios.get("/api/investments/issuer/" + dealId);
    },

    postNewInvestment: (data) => {
        return axios.post("/api/investments", data);
    },

    updateInvestment: (data) => {
        return axios.put("/api/investments", data);
    }
}

export default InvestmentService;