import React, {Component} from 'react';
import axios from '../custom-axios/app';
import {toast} from "react-toastify";

class ActivatePage extends Component {

    componentDidMount() {
        localStorage.clear()
        const email = this.props.match.params.email;
        axios.post('users/activation_token_verification', {
            email: email,
            activationString: this.props.match.params.code
        }).then(response => {
            localStorage.setItem('accountVerification', 'true');
            localStorage.setItem('email', email);
            this.props.history.push('/choose-password')
        }).catch(error => {
            toast.error('Your link had been expired. Please re-register.')
            this.props.history.push('/login');
        });
    }

    render() {
        return (
            <h1> Loading ... </h1>
        );
    }
}

export default ActivatePage;