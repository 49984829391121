import React, {useEffect, useState} from 'react';
import RegistrationProcess from "../RegistrationProcess";
import ValidationService from "../../repository/ValidationService";
import UserService from "../../repository/UserService";
import OfferService from "../../repository/OfferService";
import {useHistory, useParams, useRouteMatch} from "react-router";

const ChooseNextStep = (props) => {

    const steps = [
        {value: 'STEP_INSTITUTION_INFO', label: 'Institution Info', path: '/institution-info', number: 1},
        {value: 'STEP_BACKGROUND_INFO', label: 'Background Info', path: '/background-info', number: 2},

        {value: 'STEP_CONTACT_INFO', label: 'Contact Info & ID', path: '/contact-info', number: 1},
        {value: 'STEP_DOCUMENTATION', label: 'Documentation', path: '/documentation', number: 2},
        {value: 'STEP_SUITABILITY', label: 'Suitability', path: '/suitability', number: 3},
        {value: 'STEP_ACCREDITATION', label: 'Accreditation', path: '/accreditation', number: 4},
        {value: 'STEP_INVESTOR_STATUS', label: 'Investor Status', path: '/investor-status', number: 5},
        {value: 'STEP_FINAL_SUBMISSION', label: 'Disclosures & Final Submission', path: '/final-submission', number: 6},
        {value: 'STEP_APPROVAL', label: 'Waiting for approval', path: '/approval', number: 7}
    ];

    const [user, setUser] = useState({})
    const [url1, setUrl1] = useState('')
    const [offer, setOffer] = useState(null)
    const {offerId} = useParams()
    const history = useHistory()
    const {path, url} = useRouteMatch()
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    useEffect(() => {
        setUser(userInfo)
        setUrl1(url)

        if (offerId) {
            OfferService.fetchUserOfferById(offerId)
                .then(response => {
                    const allApproved = response.data
                        .filter(step => (step.step.id !== 'STEP_APPROVAL'))
                        .every(step => (step.status === 'APPROVED'))

                    if (response.data.length > 0 && allApproved) {
                        history.push('/invest/' + offerId)
                    } else {
                        const requiredSteps = steps.filter(value =>
                            response.data.filter(step => (step.status !== 'APPROVED')) //  && step.status !== 'PENDING_APPROVAL'
                                .flatMap(step => step.step.id)
                                .includes(value.value)
                        )

                        setOffer({
                            id: offerId,
                            steps: requiredSteps
                        })
                    }
                })
                .catch(error => {
                })
        }
    }, [])

    const selectNextAction = (action) => {
        if (action === 'continue') {
            UserService.assignOffer({offerId: offer.id})
                .then(response => {
                    history.push(`${url}${offer.steps[0].path}`)
                })
                .catch(error => console.error(error))
        } else {
            window.location.href = process.env.REACT_APP_OFFERING_GATEWAY + '/offerings/'
                + user.userGroup.id.toLowerCase()
        }
    }

    const setRegistrationStatus = (step, next) => {
        UserService.setRegistrationStatus(step)
            .then(response => {
                const currentStep = offer.steps.find(s => s.value === step);
                let index = offer.steps.indexOf(currentStep)
                let nextIndex = next ? index + 1 : index - 1
                const nextStep = offer.steps[nextIndex]

                history.push(`/offers/${offerId}${nextStep.path}`)
            })
            .catch(error => {
            })

        // let request = {
        //     step: status,
        //     status: ''
        // }
        // UserService.saveOfferStatus(request)
    }

    return (
        <RegistrationProcess
            {...props}
            validate={ValidationService.validate}
            userGroup={user.userGroup}
            // currentStep={props.currentStep}
            setCurrentRegistrationStep={setRegistrationStatus}
            setRegistrationStatus={setRegistrationStatus}
            offer={offer}
            nextStep={selectNextAction}
        />
    )
}


export default ChooseNextStep
