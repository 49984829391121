import * as actionTypes from '../actions/actionTypes';

const initialState = {
    investorAttributes: null,
    investorAttributeOptions: null,
    investor: null,
    investorData: null
};

const fetchInvestorAttributesSuccess = (state, action) => {
    return {
        ...state,
        investorAttributes: action.payload.data
    }
};

const fetchInvestorAttributesError = (state, action) => {
    return state;
};

const fetchInvestorAttributeOptionsSuccess = (state, action) => {
    return {
        ...state,
        investorAttributeOptions: action.payload.data
    }
};

const fetchInvestorAttributeOptionsError = (state, action) => {
    return state;
};

const fetchInvestorSuccess = (state, action) => {
    return {
        ...state,
        investor: action.payload.data
    }
};

const fetchInvestorError = (state, action) => {
    return state;
};

const fetchInvestorDataSuccess = (state, action) => {
    return {
        ...state,
        investorData: action.payload.data
    }
};

const fetchInvestorDataError = (state, action) => {
    return state;
};

const initialData = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.FETCH_INVESTOR_ATTRIBUTES_SUCCESS:
            return fetchInvestorAttributesSuccess(state, action);
        case actionTypes.FETCH_INVESTOR_ATTRIBUTES_ERROR:
            return fetchInvestorAttributesError(state, action);
        case actionTypes.FETCH_INVESTOR_ATTRIBUTE_OPTIONS_SUCCESS:
            return fetchInvestorAttributeOptionsSuccess(state, action);
        case actionTypes.FETCH_INVESTOR_ATTRIBUTE_OPTIONS_ERROR:
            return fetchInvestorAttributeOptionsError(state, action);
        case actionTypes.FETCH_INVESTOR_SUCCESS:
            return fetchInvestorSuccess(state, action);
        case actionTypes.FETCH_INVESTOR_ERROR:
            return fetchInvestorError(state, action);
        case actionTypes.FETCH_INVESTOR_DATA_SUCCESS:
            return fetchInvestorDataSuccess(state, action);
        case actionTypes.FETCH_INVESTOR_DATA_ERROR:
            return fetchInvestorDataError(state, action);
        default:
            return state;
    }
};

export default initialData;