import React, {Component} from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';

import Aux from './hoc/AuxWrapper';
import DefaultRoutes from './containers/routing/Default';
import AccountVerificationRoutes from './containers/routing/AccountVerification';
import Dashboard from './containers/Dashboard';
import RegistrationHeader from './components/registration/RegistrationHeader';
import RegistrationFooter from './components/registration/RegistrationFooter';
import Logout from './components/Logout';
import './App.css';
import InvestorService from "./repository/InvestorService";
import IssuerRegistration from './containers/routing/IssuerRegistration';
import axios from "./custom-axios/app";
import UserService from "./repository/UserService";
import ChooseUserType from "./containers/registration/ChooseUserType";
import DashboardIssuer from "./containers/DashboardIssuer";
import Login from "./containers/Login/Login";
import SignUp from "./containers/registration/SignUp";
import ChoosePasswordReset from "./containers/registration/ChoosePasswordReset";
import TimerLogoutModal from "./pages/dashboard/modals/TimerLogoutModal";
import ChooseNextAction from "./containers/registration/ChooseNextAction";
import ChooseNextStep from "./containers/registration/ChooseNextStep";
import RegistrationProcess from "./containers/RegistrationProcess";
import ValidationService from "./repository/ValidationService";


class App extends Component {

    constructor(props) {
        super(props);
        this.events = [
            "load",
            "mousedown",
            "click",
            "scroll",
            "keypress"
        ];
        this.warn = this.warn.bind(this);
        this.resetTimeout = this.resetTimeout.bind(this);

        for (let i in this.events) {
            window.addEventListener(this.events[i], this.resetTimeout);
        }

        if (window.location.pathname !== '/login') {
            this.setTimeout();
        }
    }

    clearTimeout() {
        if (this.warnTimeout) clearTimeout(this.warnTimeout);
    }

    setTimeout() {
        this.warnTimeout = setTimeout(this.warn, 780 * 1000);
    }

    resetTimeout() {
        this.clearTimeout();
        if (window.location.pathname !== '/login') {
            this.setTimeout();
        }
    }

    warn() {
        this.setState({
            showAlert: true
        })
    }

    destroy() {
        this.clearTimeout();

        for (let i in this.events) {
            window.removeEventListener(this.events[i], this.resetTimeout);
        }
    }

    state = {
        investorStatus: null,
        currentStep: '',
        user: {},
        showAlert: false,
        fetched: 0
    };

    scripts = [
        'assets/js/jquery-3.2.1.min.js',
        'assets/js/popper.min.js',
        'assets/js/dropzone.js',
        'assets/js/popper.min.js',
        'assets/js/bootstrap.min.js',
        'assets/js/owl.carousel.min.js',
        'assets/js/owl.carousel.custom.js',
        'assets/js/moment-with-locales.js',
        'assets/js/bootstrap-datetimepicker.js',
        'assets/js/custom-file-input.js',
        'assets/js/custom.js',
        'assets/js/bootstrap-select.min.js'
    ];

    componentDidMount() {
        this.fetchUserInfo();
        this.fetchInvestorBasicInfo();
        this.fetchInvestorStatus();

        setTimeout(function () {
            const ele = document.getElementById('loading')
            if (ele) {
                // fade out
                ele.classList.add('loading-finish-on-load')
                setTimeout(() => {
                    // remove from DOM
                    ele.outerHTML = ''
                }, 2000)
            }
        }, 500);
    }

    fetchUserInfo() {
        axios.get('/users/current-user')
            .then(res => {
                localStorage.setItem('userInfo', JSON.stringify(res.data));
                // if (localStorage.getItem('confirmed') === 'true') {
                //     // this.props.history.push('/');
                // } else localStorage.clear()
            })
            .catch(err => {
                console.log(err)
            })
    }

    fetchInvestorBasicInfo() {
        InvestorService.fetchInvestorBasicInfo()
            .then(response => {
                localStorage.setItem('investorInfo', JSON.stringify(response.data));
            }).catch(error => {
        })
    }

    fetchInvestorStatus() {
        InvestorService.fetchInvestorStatus()
            .then(response => {
                this.setState({
                    investorStatus: response.data
                });
            })
            .catch(error => {
            })

    }

    setCurrentRegistrationStep(step) {
        this.setState({
            currentStep: step
        });
    }

    reload = () => {
        UserService.fetchUser().then(data => {
            this.setState({
                user: data.data
            })
        })
    };

    investorStatusUpdated(attributeCode, value) {
        let status = JSON.parse(JSON.stringify(this.state.investorStatus));
        status[attributeCode].chosenValue = value;
        this.setState({
            investorStatus: status
        })
    }

    closeModal = () => {
        this.setState({
            showAlert: false
        })
    };

    render() {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));

        let routes = (
            <DefaultRoutes/>
        );

        if (localStorage.getItem('passwordReset')) {
            routes = (
                <Switch>
                    <Route path="/login" exact component={Login}/>
                    <Route path="/sign-up" exact component={SignUp}/>
                    <Route path="/choose-password" exact component={ChoosePasswordReset}/>
                    <Route path="/logout" exact component={Logout}/>
                    <Redirect to="/choose-password"/>
                </Switch>
            );
        }

        if (localStorage.getItem('accountVerification')) {
            routes = (
                <AccountVerificationRoutes/>
            );
        }

        if (userInfo) {
            if (userInfo.userGroup === null) {
                routes = (
                    <Switch>
                        <Route path="/logout" exact component={Logout}/>
                        <Route path="/choose-next-action" exact component={ChooseNextAction}/>
                        <Route path="/choose-user-type" exact component={ChooseUserType}/>
                        <Redirect to="/choose-user-type"/>
                    </Switch>
                );
            } else if (userInfo.userGroup && userInfo.userGroup.id === 'USER_INVESTOR_INDIVIDUAL' || userInfo.userGroup.id === 'USER_INVESTOR_COMPANY') {
                routes = (
                    <Dashboard/>
                )

                // if (userInfo.registrationStatus === 'STEP_FINISHED' || userInfo.registrationStatus === null) {
                //     routes = (
                //         <Dashboard/>
                //     )
                // } else {
                //     let infoComponents = (
                //         <RegistrationProcess
                //             {...this.props}
                //             validate={ValidationService.validate}
                //             userGroup={userInfo.userGroup}
                //             currentStep={this.state.currentStep}
                //             setCurrentRegistrationStep={(step) => this.setCurrentRegistrationStep(step)}
                //             setRegistrationStatus={UserService.setRegistrationStatus}
                //         />
                //     );
                //
                //     routes = (
                //         <Aux>
                //             <RegistrationHeader
                //                 saveForLater={userInfo.registrationStatus !== 'STEP_APPROVAL'}/>
                //             <Switch>
                //                 {/*<Route path="/offers/:offerId" children={*/}
                //                 {/*    <ChooseNextStep*/}
                //                 {/*        {...this.props}*/}
                //                 {/*        setCurrentRegistrationStep={this.setCurrentRegistrationStep}*/}
                //                 {/*        userInfo={userInfo}*/}
                //                 {/*        routes={infoComponents}*/}
                //                 {/*    />*/}
                //                 {/*}/>*/}
                //                 {/*<Route path="/logout" exact component={Logout}/>*/}
                //
                //                 <ChooseNextStep
                //                     {...this.props}
                //                     setCurrentRegistrationStep={this.setCurrentRegistrationStep}
                //                     userInfo={userInfo}
                //                     routes={infoComponents}
                //                 />
                //
                //                 {/*{infoComponents}*/}
                //             </Switch>
                //             <RegistrationFooter/>
                //         </Aux>
                //     );
                // }
            } else if (userInfo.userGroup.id === 'USER_ISSUER') {
                if (userInfo.registrationStatus === 'STEP_FINISHED') {
                    routes = (
                        <DashboardIssuer/>
                    );
                } else {
                    let infoComponents = (
                        <IssuerRegistration
                            userGroup={userInfo.userGroup}
                            reload={this.reload}
                            setCurrentRegistrationStep={(step) => this.setCurrentRegistrationStep(step)}
                            registrationStatus={userInfo.registrationStatus}
                            setRegistrationStatus={UserService.setRegistrationStatus}/>
                    );

                    routes = (
                        <Aux>
                            <RegistrationHeader
                                saveForLater={userInfo.registrationStatus !== 'STEP_APPROVAL'}/>
                            <Switch>
                                {infoComponents}
                            </Switch>
                            <RegistrationFooter/>
                        </Aux>
                    );
                }
            }
        }

        return (
            <Aux>
                {
                    this.state.showAlert
                        ? <TimerLogoutModal
                            className="modal"
                            close={this.closeModal}
                        />
                        : null
                }

                {routes}
            </Aux>
        )
    }
}


export default withRouter(App);
