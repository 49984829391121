import React from 'react';

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {NavLink} from "react-router-dom";
import Divider from "@material-ui/core/Divider";

const registrationFooter = (props) => {

    const footerYear = () => {
        return new Date().getFullYear()
    }

    return (
        <footer className="basic">
            <Container>
                <Grid container spacing={3}>
                    <Grid item lg={12} xs={12}>
                        <Divider/>
                    </Grid>

                    <Grid item md={8} xs={12} lg={8}>
                        <p>&copy; {footerYear()} Americas Executions, LLC. All right reserved.</p>
                        <p className="smaller">Check the background of this firm on FINRA’s BrokerCheck</p>
                        <NavLink to="https://brokercheck.finra.org/" target="_blank">
                            <img
                                src={require('../../assets/images/brokercheck.png')}
                                alt={""}
                            />
                        </NavLink>
                    </Grid>

                    <Grid item md={2} xs={12} lg={2}>
                        <ul className="list-unstyled">
                            <li>
                                <NavLink to="#">Home</NavLink>
                            </li>
                            <li>
                                <NavLink to="#">About</NavLink>
                            </li>
                            <li>
                                <NavLink to="#">Investment Banking</NavLink>
                            </li>
                            <li>
                                <NavLink to="#">Executions Services</NavLink>
                            </li>
                            <li>
                                <NavLink to="#">Careers</NavLink>
                            </li>
                        </ul>
                    </Grid>

                    <Grid item xs={12} md={2} lg={2}>
                        <ul className="list-unstyled margin-bottom-0">

                            <li>
                                <NavLink to="#">Privacy Policy</NavLink>
                            </li>

                            <li>
                                <NavLink to="#">Terms & Conditions</NavLink>
                            </li>

                            <li>
                                <NavLink to="#">606 Reports</NavLink>
                            </li>

                            <li>
                                <NavLink to="#">www.sipc.org</NavLink>
                            </li>

                            <li>
                                <ul className="list-inline social margin-bottom-0">

                                    <li>
                                        <NavLink to="#">
                                            <i className="fab fa-facebook"></i>
                                        </NavLink>
                                    </li>

                                    <li>
                                        <NavLink to="#">
                                            <i className="fab fa-linkedin"></i>
                                        </NavLink>
                                    </li>

                                </ul>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
            </Container>
        </footer>
    );
}

export default registrationFooter;