import React, {useEffect, useState} from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import InvestorService from "../../repository/InvestorService";
import DwollaService from "../../repository/DwollaService";
import Button from "@material-ui/core/Button";
import {toast} from "react-toastify";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import PaymentForm from "../../components/dwolla/PaymentForm";
import AddingBankModal from "../../components/dwolla/AddingBankModal";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import InvestmentService from "../../repository/InvestmentService";
import TaskService from "../../repository/TaskService";

const PaymentPage = (props) => {

    const [customerId, setCustomerId] = useState('')
    const [iavToken, setIavToken] = useState(null)
    const [fundingSources, setFundingSources] = useState([])
    const [investment, setInvestment] = useState(null)
    const [showAddingModal, setShowAddingModal] = useState(false)
    const [inProgress, setInProgress] = useState(false)
    const [dwollaErrors, setDwollaErrors] = useState([])

    useEffect(() => {
        fetchUser()
        fetchInvestment()
    }, [])

    const fetchUser = () => {
        setInProgress(true)

        InvestorService.fetchInvestor()
            .then(response => {
                const user = response.data.user

                if (user.dwollaId) {
                    setCustomerId(user.dwollaId)
                    fetchIavToken(user.dwollaId)
                    fetchFundingSources(user.dwollaId)
                } else {
                    DwollaService.createCustomer()
                        .then(response => {
                            setCustomerId(response.data.id)
                            fetchIavToken(response.data.id)
                            fetchFundingSources(response.data.id)
                        })
                }

                setInProgress(false)
            }).catch(error => {
            console.log(error);
            setInProgress(false)
        })
    }

    const fetchIavToken = (customerId) => {
        setInProgress(true)

        DwollaService.fetchIavToken(customerId)
            .then(res => {
                const token = res.data
                setIavToken(token)
                setInProgress(false)
            })
    }

    const fetchFundingSources = (customerId) => {
        setInProgress(true)

        DwollaService.fetchFundingSources(customerId)
            .then(response => {
                const data = response.data.map(source => {
                    return {
                        id: source.id,
                        value: source.name,
                        bankName: source.bankName,
                        status: source.status
                    }
                })

                setFundingSources(data)
                setInProgress(false)
            })
    }

    const fetchInvestment = () => {
        InvestmentService.fetchInvestment(Number.parseInt(props.match.params.id))
            .then(response => {
                setInvestment(response.data)
            })
            .catch(error => console.log(error))
    }

    const closeAddingModal = () => {
        fetchFundingSources(customerId)
        setShowAddingModal(false)
    }

    const onErrorHandler = (err) => {
        console.log(err)
    }

    const submitHandler = (fundingSource, amount) => {
        setInProgress(true)

        DwollaService.transfer({
            sourceFundingSourceId: fundingSource,
            amount: amount
        })
            .then(response => {
                // TaskService.deleteTask(Number.parseInt(props.location.state.taskId))
                //     .then(response => {})
                //     .catch(error => {})

                InvestmentService.updateInvestment({
                    investmentId: Number.parseInt(props.match.params.id),
                    amount: amount,
                    transferUrl: response.data._links.self.href
                })
                    .then(response => {
                        toast.success('Payment Successful $' + amount)
                        props.history.push('/my-investments');
                    })
                    .catch(error => {
                        toast.error(error)
                        setInProgress(false)
                    })
            })
            .catch(error => {
                let errors = {}
                errors.message = error.response.data._embedded.errors[0].message
                setInProgress(false)
                setDwollaErrors(errors)
            })
    }

    return (
        inProgress ? (
            <Container maxWidth='lg'>
                <Grid container
                      justify={"center"}
                      alignItems={"center"}
                      style={{height: '100vh'}}>
                    <Grid item>
                        <CircularProgress/>
                    </Grid>
                </Grid>
            </Container>
        ) : (
            <Grid container spacing={3}
                  style={{paddingTop: 80}}
                  justify={"center"}>
                <Grid item lg={12} xs={12}>
                    <Typography variant='h6'>
                        Payment
                    </Typography>

                    <Divider/>
                </Grid>

                <Grid item lg={8} xs={12}>
                    <Card raised>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item lg={12} xs={12}>
                                    <Typography variant='h6'>
                                        Attached Bank Accounts
                                    </Typography>

                                    <Divider/>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell align={"center"}>Bank name</TableCell>
                                                    <TableCell align={"center"}>Status</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {fundingSources.length > 0 ? fundingSources.map((source) => (
                                                    <TableRow key={source.value}>
                                                        <TableCell component="th" scope="row">
                                                            {source.value}
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            {source.bankName}
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            {source.status}
                                                        </TableCell>
                                                    </TableRow>
                                                )) : (
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            No accounts
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>

                                <Grid item lg={12} xs={12}
                                      style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button style={{margin: 5}}
                                            color="primary"
                                            variant="contained"
                                            onClick={() => setShowAddingModal(true)}>
                                        Add My Bank Account
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                {fundingSources.length > 0 && (
                    <Grid item lg={8} xs={12}>
                        <PaymentForm
                            fundingSources={fundingSources}
                            investment={investment}
                            dwollaErrors={dwollaErrors}
                            onSubmit={submitHandler}
                        />
                    </Grid>
                )}

                <Grid item lg={12} xs={12}>
                    <AddingBankModal
                        iavToken={iavToken}
                        open={showAddingModal}
                        close={closeAddingModal}
                        onErrorStep={onErrorHandler}
                    />
                </Grid>
            </Grid>
        )
    )
}

export default PaymentPage