import React, {Component} from 'react';
import Aux from '../../hoc/AuxWrapper';
import axios from '../../custom-axios/app';
import {connect} from 'react-redux';
import * as actions from "../../store/actions/actionTypes";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import {NavLink} from "react-router-dom";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";


const IconBlock = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  position: absolute;
  top: -25px;
  left: 30px;
  background: linear-gradient(0deg, rgba(102,187,106,1) 0%, rgba(67,160,71,1) 100%);;
`;


const HeaderTextLogin = styled.div`
  font-size: 24px;
  font-family: "Roboto";
  font-weight: 500;
`;

const CartComponent = styled.div`
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),
     0px 1px 1px 0px rgba(0,0,0,0.14),
      0px 1px 3px 0px rgba(0,0,0,0.12);
    padding: 60px 30px 0 30px;
    position: relative;
    background: white;
    max-width: 480px;
    min-width: 480px;
`;

const MainWrap = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const WrapBoxLogin = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0 75px 0;
`;

const WrapMainElement = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextErrorMessage = styled.div`
  color: #B71C1C;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  text-decoration: none;
`;


class ChoosePassword extends Component {

    state = {
        password: '',
        confirmPassword: '',
        loginError: null,
        validPasswords: false,
        passwordRegex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,30}$/
    };

    loginUser() {
        axios.post('/login', {
            email: localStorage.getItem("email"),
            password: this.state.password
        }).then(res => {
            this.props.authSuccess(res.data);
            axios.get('/users/current-user')
                .then(res2 => {
                    localStorage.setItem('userInfo', JSON.stringify(res2.data));
                    if (res2.data.userGroup.id === "USER_ISSUER") {
                        this.props.history.push('/issuer-profile');
                    } else {
                        this.props.history.push('/choose-next-action');
                    }
                }).catch(err => {
                console.log(err)
            })
        }).catch(err => {
            this.props.authError(err.response)
        })
    }

    passwordSubmitHandler(e) {
        e.preventDefault();

        axios.post('/users/register', {
            password: this.state.password,
            repeatPassword: this.state.confirmPassword,
            email: localStorage.getItem("email")
        }).then(response => {
            this.loginUser();
            this.props.history.push("/login");
        }).catch(error => {
            console.log(error);
        });
    }

    changePasswordHandler(e) {
        this.setState({
            password: e.target.value
        }, () => {
            this.checkPasswordsEquality();
        });
    }

    changeConfirmPasswordHandler(e) {
        this.setState({
            confirmPassword: e.target.value
        }, () => {
            this.checkPasswordsEquality();
        });
    }

    checkPasswordsEquality() {
        let loginError = null;

        if (this.state.password !== this.state.confirmPassword) {
            loginError = 'Passwords do not match';
            this.setState({
                validPasswords: false
            });
        } else if (this.checkPasswordValidity()) {
            this.setState({
                validPasswords: true
            });
        } else {
            loginError = 'Passwords do not meet the requirements';
            this.setState({
                validPasswords: false
            });
        }

        this.setState({
            loginError: loginError
        });
    }

    checkPasswordValidity() {
        return this.state.passwordRegex.test(this.state.password);
    }

    render() {
        return (
            <Aux>

                {/*<div className="signup-overlay-1"></div>*/}

                <MainWrap>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={3}>

                        <WrapMainElement>

                            <WrapBoxLogin>
                                <NavLink to="index.php">
                                    <img
                                        src={require('../../assets/images/logo-blue.png')}
                                        width="140"
                                        height="51"
                                        alt=""/>
                                </NavLink>
                            </WrapBoxLogin>

                            <CartComponent>

                                <IconBlock>
                                    <PermIdentityOutlinedIcon
                                        style={{color: 'white', fontSize: '30px', margin: '0 auto'}}/>
                                </IconBlock>

                                <form onSubmit={(e) => this.passwordSubmitHandler(e)}>

                                    <Box mb={1}>
                                        <HeaderTextLogin>Choose a Password</HeaderTextLogin>
                                    </Box>

                                    <div className="form-group">
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            type="password"
                                            placeholder="Enter Password"
                                            value={this.state.password}
                                            onChange={(e) => this.changePasswordHandler(e)}/>
                                    </div>

                                    <div className="form-group">
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            type="password"
                                            label="Confirm Password"
                                            value={this.state.confirmPassword}
                                            onChange={(e) => this.changeConfirmPasswordHandler(e)}/>
                                    </div>

                                    <div className="form-group">
                                        <TextErrorMessage> {this.state.loginError} </TextErrorMessage>
                                    </div>
                                </form>

                                <Typography variant="caption">
                                    Your password must be between 8 and 30 characters,
                                    must contain at least one uppercase letter, one number and
                                    one special character.</Typography>

                                <Box pt="15px" pb="15px">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={(e) => this.passwordSubmitHandler(e)}
                                        disabled={!this.state.validPasswords}>
                                        Next
                                    </Button>
                                </Box>

                                {/*<section className="sign-up-wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-10 col-sm-offset-1 col-lg-8 col-lg-offset-2">
                                <div className="card dark text-center padding-bottom-40">

                                    <a className="signup-logo" href="index.php">
                                        <img src={require('../../assets/images/logo.png')} width="140" height="51"
                                             alt=""/>
                                    </a>

                                    <h2 className="margin-bottom-30">Choose a Password</h2>

                                    <form className="" onSubmit={(e) => this.passwordSubmitHandler(e)}>
                                        <div className="form-group">
                                            <input type="password" className="form-control input-xl"
                                                   placeholder="Enter Password"
                                                   value={this.state.password}
                                                   onChange={(e) => this.changePasswordHandler(e)}/>
                                        </div>
                                        <div className="form-group">
                                            <input type="password" className="form-control input-xl"
                                                   placeholder="Confirm Password"
                                                   value={this.state.confirmPassword}
                                                   onChange={(e) => this.changeConfirmPasswordHandler(e)}/>
                                        </div>
                                        <div className="form-group">
                                            <span className="error-message"> {this.state.loginError} </span>
                                        </div>
                                        <p className="small">Your password must be between 8 and 30 characters, must
                                            contain at least one uppercase letter and one number.</p>

                                        <button formAction="signup-4-typeofuser.php" type="submit"
                                                className="btn btn-orange btn-xl margin-top-20"
                                                disabled={!this.state.validPasswords}>Next
                                        </button>
                                    </form>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>*/}
                            </CartComponent>
                        </WrapMainElement>
                    </Grid>
                </MainWrap>

            </Aux>

        );
    }
}


const mapStateToProps = state => {
    return {
        email: state.activateReducer.email
    }
};

const mapDispatchToProps = dispatch => {
    return {
        authSuccess: (token) => dispatch({type: actions.AUTH_SUCCESS, payload: token}),
        authError: (error) => dispatch({type: actions.AUTH_ERROR, error: error})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoosePassword);