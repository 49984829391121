import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputPhone from "../../components/input/InputPhone";
import {Box} from "@material-ui/core";

class ProfileIndividualPage extends Component {

    state = {
        updated: false,
        firstName: '',
        lastName: '',
        streetAddress: '',
        city: '',
        postalCode: '',
        mailStreetAddress: '',
        mailCity: '',
        mailPostalCode: '',
        stateId: '',
        countryId: '',
        mailStateId: '',
        mailCountryId: '',
        primaryEmail: '',
        alternativeMail: ' ',
        phoneNumber: '',
        alternativePhoneNumber: '',
        twoFactorAuthentication: false,
        errorAlternativeEmailValid: false,
        forCheckAlternativeEmailData: ' ',
        loader: true,
        'STATE_PROVINCE_ID': [],
        'COUNTRY_ID': [],
        'MAILING_COUNTRY_ID': [],
        'MAILING_STATE_PROVINCE_ID': []
    };

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(nextProps, nextState) {
        if (!this.state.updated && nextProps.investor) {
            this.setState({
                updated: true
            }, () => this.setStateFields());
        }

        if (nextProps.investorData && nextProps.investorData['SEC_MAIL'] && nextProps.investorData['SEC_MAIL'].chosenValue !== undefined
            && nextProps.investorData['SEC_MAIL'].chosenValue !== this.state.alternativeMail
            && this.state.alternativeMail === this.state.forCheckAlternativeEmailData && this.state.alternativeMail !== '') {
            this.setState({
                alternativeMail: nextProps.investorData['SEC_MAIL'].chosenValue,
                loader: false
            })
        }
    }

    init() {
        this.props.fetchAttributeOptions('STATE_PROVINCE_ID')
            .then((data) => {
                this.setState({
                    ...this.state,
                    'STATE_PROVINCE_ID': data.data
                });
            });

        this.props.fetchAttributeOptions('COUNTRY_ID').then((data) => {
            this.setState({
                ...this.state,
                'COUNTRY_ID': data.data
            });
        });

        this.props.fetchAttributeOptions('MAILING_COUNTRY_ID').then((data) => {
            this.setState({
                ...this.state,
                'MAILING_COUNTRY_ID': data.data
            });
        });

        this.props.fetchAttributeOptions('MAILING_STATE_PROVINCE_ID').then((data) => {
            this.setState({
                ...this.state,
                'MAILING_STATE_PROVINCE_ID': data.data
            });
        });

        this.setState({
            ...this.state,
            loader: false
        });

    }

    onChangeHandler = (stateField, value) => {
        this.setState({
            [stateField]: value
        });
    }

    submitChanges(e) {
        e.preventDefault();

        this.props.updateInvestorInfo({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            streetAddress: this.state.streetAddress,
            city: this.state.city,
            postalCode: this.state.postalCode,
            mailStreetAddress: this.state.mailStreetAddress,
            mailCity: this.state.mailCity,
            mailPostalCode: this.state.mailPostalCode,
            stateId: this.state.stateId,
            countryId: this.state.countryId,
            mailStateId: this.state.mailStateId,
            mailCountryId: this.state.mailCountryId,
            phoneNumber: this.state.phoneNumber,
            alternativePhoneNumber: this.state.alternativePhoneNumber,
            twoFactorAuthentication: this.state.twoFactorAuthentication
        });
    }

    setStateFields() {
        this.setState({
            firstName: this.props.investor.user.firstName,
            lastName: this.props.investor.user.lastName,
            streetAddress: this.props.investor.streetAddress,
            city: this.props.investor.city,
            postalCode: this.props.investor.postalCode,
            mailStreetAddress: this.props.investor.mailStreetAddress,
            mailCity: this.props.investor.mailCity,
            mailPostalCode: this.props.investor.mailPostalCode,
            stateId: this.props.investor.state ? this.props.investor.state.id : '',
            countryId: this.props.investor.country ? this.props.investor.country.id : '',
            mailStateId: this.props.investor.mailState ? this.props.investor.mailState.id : '',
            mailCountryId: this.props.investor.mailCountry ? this.props.investor.mailCountry.id : '',
            phoneNumber: this.props.investor.phoneNumber,
            alternativePhoneNumber: this.props.investor.alternativePhoneNumber,
            twoFactorAuthentication: this.props.investor.user.twoFactorAuthentication,

        });
    }

    validEmail = (email) => {
        let pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(email);
    }

    onChangeAlternativeEmail = (e) => {
        let email = e.target.value;
        let error = false;

        if (this.validEmail(email) || email === '') {
            let request = {
                "attributeCode": "SEC_MAIL",
                "value": email
            };

            this.props.pushInvestorChange(request);
        } else {
            error = true;
        }

        this.setState({
            errorAlternativeEmailValid: error,
            alternativeMail: email
        })
    }


    onTwoFactorAuthentication = () => {
        this.setState({
            twoFactorAuthentication: !this.state.twoFactorAuthentication
        })
    };


    render() {
        return (
            <>
                <Grid container spacing={3} style={{paddingTop: 80}}>
                    <Grid item lg={12} xs={12}>

                        <Box mb="'10px">
                            <Typography variant='h6'>
                                Profile
                            </Typography>
                        </Box>

                        <Divider/>
                    </Grid>

                    {this.state.loader ? (
                        <Grid item
                              lg={12}
                              xs={12}
                              style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  minHeight: '50vh'
                              }}>
                            <CircularProgress/>
                        </Grid>
                    ) : (
                        <>
                            <Grid item lg={6} md={6} xs={12}>
                                <Card raised>
                                    <CardContent>
                                        <Box mb="10px">
                                            <Typography variant='h6'>
                                                Contact information
                                            </Typography>
                                        </Box>

                                            <Divider/>


                                        <Grid item container className='app_form--group'>
                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                                <Typography>
                                                    First name
                                                </Typography>
                                            </Grid>

                                            <Grid item lg={7} xs={12}>
                                                <Box display='flex' alignItems='center'>
                                                    <input disabled={'true'}
                                                           type="text"
                                                           defaultValue={this.props.investorData['FIRST_NAME_ON_ID'] ? this.props.investorData['FIRST_NAME_ON_ID'].chosenValue : ''}
                                                           className='input__custom'
                                                           onChange={(e) => this.onChangeHandler('firstName', e.target.value)}/>
                                                </Box>
                                            </Grid>
                                        </Grid>


                                        <Grid item container className='app_form--group'>
                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                                <Typography>
                                                    Last name
                                                </Typography>
                                            </Grid>

                                            <Grid item lg={7} xs={12}>
                                                <input type="text"
                                                       disabled={'true'}
                                                       className="input__custom"
                                                       defaultValue={this.props.investorData['FIRST_NAME_ON_ID'] ? this.props.investorData['LAST_NAME_ON_ID'].chosenValue : ''}
                                                       onChange={(e) => this.onChangeHandler('lastName', e.target.value)}/>
                                            </Grid>
                                        </Grid>

                                        <Grid item container className='app_form--group'>
                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                                <Typography>Home country</Typography>
                                            </Grid>

                                            <Grid item lg={7} xs={12}>
                                                <input type="text"
                                                       disabled={'true'}
                                                       className="input__custom"
                                                       defaultValue={this.props.investorData['COUNTRY_ID'] ? this.props.investorData['COUNTRY_ID'].chosenValue : ''}
                                                       onChange={(e) => this.onChangeHandler('', e.target.value)}/>
                                            </Grid>
                                        </Grid>


                                        <Grid item container className='app_form--group'>
                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                                <Typography>Home street</Typography>
                                            </Grid>
                                            <Grid item lg={7} xs={12}>
                                                <input type="text"
                                                       disabled={'true'}
                                                       className="input__custom"
                                                       defaultValue={this.props.investorData['STREET_ADDRESS_ID'] ? this.props.investorData['STREET_ADDRESS_ID'].chosenValue : ''}
                                                       onChange={(e) => this.onChangeHandler('streetAddress', e.target.value)}/>
                                            </Grid>
                                        </Grid>


                                        <Grid item container className='app_form--group'>
                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                            <Typography>
                                                Home city
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={7} xs={12}>
                                            <input type="text"
                                                   disabled={'true'}
                                                   className="input__custom"
                                                   defaultValue={this.props.investorData['CITY_ID'] ? this.props.investorData['CITY_ID'].chosenValue : ''}
                                                   onChange={(e) => this.onChangeHandler('city', e.target.value)}/>
                                        </Grid>
                                        </Grid>

                                        <Grid item container className='app_form--group'>
                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                            <Typography>Home State/Province</Typography>
                                        </Grid>
                                        <Grid item lg={7} xs={12}>
                                            <input type="text"
                                                   disabled={'true'}
                                                   className="input__custom"
                                                   defaultValue={this.props.investorData['STATE_PROVINCE_ID'] ? this.props.investorData['STATE_PROVINCE_ID'].chosenValue : ''}
                                                   onChange={(e) => this.onChangeHandler('city', e.target.value)}/>
                                        </Grid>
                                        </Grid>

                                        <Grid item container className='app_form--group'>
                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                                <Typography>
                                                    Home postal code
                                                </Typography>
                                            </Grid>

                                            <Grid item lg={7} xs={12}>
                                                <input type="text" disabled={'true'}
                                                       className="input__custom"
                                                       defaultValue={this.props.investorData['POSTAL_CODE_ID'] ? this.props.investorData['POSTAL_CODE_ID'].chosenValue : ''}
                                                       onChange={(e) => this.onChangeHandler('postalCode', e.target.value)}/>
                                            </Grid>
                                        </Grid>


                                        <Grid item container className='app_form--group'>
                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                                <Typography>Phone number</Typography>
                                            </Grid>

                                            <Grid item lg={7} xs={12}>
                                                {this.props.investor ? (
                                                    <InputPhone
                                                        defaultValue={this.props.investor.user.phoneNumber}
                                                        disabled={true}
                                                    />

                                                    // <IntlTelInput
                                                    //     style={{"display": "block"}}
                                                    //     disabled={true}
                                                    //     defaultValue={this.props.investor.user.phoneNumber}
                                                    //     format="true"
                                                    //     autoHideDialCode="false"
                                                    //     containerClassName="intl-tel-input"
                                                    //     inputClassName="input__custom"
                                                    // />
                                                ) : null}
                                            </Grid>
                                        </Grid>


                                        <Grid item container className='app_form--group'>
                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                                <Typography>Alternative Number</Typography>
                                            </Grid>

                                            <Grid item lg={7} xs={12}>
                                                {this.props.investor ? (
                                                    <InputPhone
                                                        defaultValue={this.props.investor.alternativePhoneNumber ? this.props.investor.alternativePhoneNumber : ''}
                                                        attributeCode="WORK_PHONE_NUMBER"
                                                        onCnangeHandler={(value) => this.onChangeHandler('alternativePhoneNumber', value)}
                                                    />


                                                    // <IntlTelInput
                                                    //     style={{"display": "block"}}
                                                    //     defaultValue={this.props.investor.alternativePhoneNumber ? this.props.investor.alternativePhoneNumber : ''}
                                                    //     format="true"
                                                    //     autoHideDialCode="true"
                                                    //     containerClassName="intl-tel-input"
                                                    //     inputClassName="input__custom"
                                                    //     onPhoneNumberChange={(valid, value, obj, clearval) => this.onChangeHandler('alternativePhoneNumber', clearval)}
                                                    // />
                                                ) : null}
                                            </Grid>
                                        </Grid>

                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item lg={6} md={6} xs={12}>

                                <Card raised style={{marginBottom: '20px'}}>
                                    <CardContent>

                                        <Box mb="10px">
                                            <Typography variant='h6'>
                                                Mailing information
                                            </Typography>
                                        </Box>

                                        <Divider/>


                                        <Grid item container className='app_form--group'>

                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                                <Typography>
                                                    Primary email
                                                </Typography>
                                            </Grid>

                                            <Grid item lg={7} xs={12}>
                                                <input type="text"
                                                       className="input__custom"
                                                       defaultValue={this.props.investor ? this.props.investor.user.email : ''}
                                                       disabled
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item container  className='app_form--group'>
                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                                        <Typography>Alternative email</Typography>
                                                    </Grid>

                                                    <Grid item lg={7} xs={12}>
                                                        <FormControl variant="outlined"
                                                                     error={this.state.errorAlternativeEmailValid}
                                                                     fullWidth>
                                                            <TextField variant='outlined'
                                                                       error={this.state.errorAlternativeEmailValid}
                                                                       value={this.state.alternativeMail}
                                                                       InputProps={{
                                                                           style: {fontSize: 14}
                                                                       }}
                                                                       helperText={this.state.errorAlternativeEmailValid ? 'ERROR: Email is not valid' : ''}
                                                                       onChange={(e) => this.onChangeAlternativeEmail(e)}>
                                                            </TextField>
                                                        </FormControl>
                                                    </Grid>
                                        </Grid>

                                        <Grid item container className='app_form--group'>
                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                                        <Typography>Mailing country</Typography>
                                                    </Grid>
                                                    <Grid item lg={7} xs={12}>
                                                        <input type="text"
                                                               disabled={'true'}
                                                               className="input__custom"
                                                               defaultValue={this.props.investorData['MAILING_COUNTRY_ID'] ? this.props.investorData['MAILING_COUNTRY_ID'].chosenValue : ''}
                                                               onChange={(e) => this.onChangeHandler('mailCountryId', e.target.value)}/>
                                                    </Grid>
                                        </Grid>

                                        <Grid item container className='app_form--group'>
                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                                        <Typography>Mailing street address</Typography>
                                                    </Grid>

                                                    <Grid item lg={7} xs={12}>
                                                        <input type="text"
                                                               disabled={'true'}
                                                               className="input__custom"
                                                               defaultValue={this.props.investorData['MAILING_STREET_ADDRESSS_ID'] ? this.props.investorData['MAILING_STREET_ADDRESSS_ID'].chosenValue : ''}
                                                               onChange={(e) => this.onChangeHandler('mailStreetAddress', e.target.value)}/>
                                                    </Grid>

                                        </Grid>

                                        <Grid item container className='app_form--group'>
                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                                        <Typography>Mailing city</Typography>
                                                    </Grid>

                                                    <Grid item lg={7} xs={12}>
                                                        <input type="text"
                                                               disabled={'true'}
                                                               className="input__custom"
                                                               defaultValue={this.props.investorData['MAILING_CITY_ID'] ? this.props.investorData['MAILING_CITY_ID'].chosenValue : ''}
                                                               onChange={(e) => this.onChangeHandler('mailCity', e.target.value)}/>
                                                    </Grid>
                                        </Grid>

                                        <Grid item container className='app_form--group'>
                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                                        <Typography>Mailing state/province</Typography>
                                                    </Grid>

                                                    <Grid item lg={7} xs={12}>
                                                        <input type="text"
                                                               disabled={'true'}
                                                               className="input__custom"
                                                               defaultValue={this.props.investorData['MAILING_POSTAL_CODE_ID'] ? this.props.investorData['MAILING_POSTAL_CODE_ID'].chosenValue : ''}
                                                               onChange={(e) => this.onChangeHandler('mailPostalCode', e.target.value)}/>
                                                    </Grid>
                                        </Grid>

                                        <Grid item container className='app_form--group'>
                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                                        <Typography>Mailing Postal Code</Typography>
                                                    </Grid>
                                                    <Grid item lg={7} xs={12}>
                                                        <input type="text"
                                                               disabled={'true'}
                                                               className="input__custom"
                                                               defaultValue={this.props.investorData['MAILING_POSTAL_CODE_ID'] ? this.props.investorData['MAILING_POSTAL_CODE_ID'].chosenValue : ''}
                                                               onChange={(e) => this.onChangeHandler('mailPostalCode', e.target.value)}/>
                                                    </Grid>
                                        </Grid>



                                    </CardContent>
                                </Card>


                                <Card raised>
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item lg={12} xs={12}>
                                                <Typography variant='h6'>
                                                    My preferences
                                                </Typography>

                                                <Divider/>
                                            </Grid>

                                            <Grid item lg={12} xs={12}>
                                                <Grid container spacing={3}>
                                                    <Grid item lg={6} xs={6}>
                                                        <Typography>Enable two factor
                                                            authentication</Typography>
                                                    </Grid>
                                                    <Grid item lg={6} xs={6}>
                                                        <form action="#">
                                                            <div className="switch">
                                                                <input id="switch-1" type="checkbox"
                                                                       className="switch-input"
                                                                       checked={this.state.twoFactorAuthentication}
                                                                       onClick={this.onTwoFactorAuthentication}
                                                                />
                                                                <label htmlFor="switch-1"
                                                                       className="switch-label margin-bottom--1"/>
                                                            </div>
                                                        </form>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>

                                    </CardContent>
                                </Card>

                            </Grid>

                            <Grid item lg={12} xs={12}
                                  style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-end",
                                      paddingBottom: 32
                                  }}>
                                <Button variant='contained'
                                        color='primary'
                                        disabled={this.state.errorAlternativeEmailValid}
                                        onClick={(e) => this.submitChanges(e)}>
                                    Submit changes
                                </Button>
                            </Grid>
                        </>

                    )}
                </Grid>
            </>
        );
    }
}

export default ProfileIndividualPage;