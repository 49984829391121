import React, {Component} from 'react';
import OfferService from "../../repository/OfferService";
import NumberFormat from 'react-number-format';
import PayTypeService from "../../repository/PayTypeService";
import DealDataService from "../../repository/DealDataService";
import PdfPreviewModal from "./modals/PdfPreviewModal";
import {AUTH_TOKEN} from "../../shared/utility";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import Button from "@material-ui/core/Button";

export default class OfferingReviewPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deal: {},
            show: false,
            payTypes: [],
            documents: [],
            fileToPreview: null,
            showPreview: false,
        }
    }

    componentDidMount() {
        document.body.setAttribute('class', 'dashboard-body');
        this.loadDeal(this.props.match.params.code);
        this.fetchPayTypes();
    }

    loadDeal = (code) => {
        OfferService.fetchByDealCode(code)
            .then((response) => {
                this.setState({
                    deal: response.data
                }, () => {
                    this.fetchDealDocuments();
                });
            })
    }

    // onNewInvestment = (data) => {
    //     data.dealId = this.state.deal.id;
    //
    //     InvestmentService.postNewInvestment(data)
    //         .then((res) => {
    //             toast.success('Investment request is created!');
    //             this.props.history.push('/subscription');
    //         })
    // }

    showInvestModal = () => {
        this.props.history.push(`/invest/${this.state.deal.id}`);
    }

    onFilePreview = (item) => {
        this.setState({
            fileToPreview: item.chosenValue,
            showPreview: true
        });
    }

    handleClose = () => {
        this.setState({show: false})
    }

    fetchPayTypes() {
        PayTypeService.fetchAll()
            .then(response => {
                this.setState({
                    payTypes: response.data
                })
            }).catch(error => {
            console.log(error);
        })
    }

    fetchDealDocuments = () => {
        DealDataService.fetchByDealAndAttribute({
            dealId: this.state.deal.id,
            attributeCode: 'DOCUMENT'
        }).then(response => {
            this.setState({
                documents: response.data
            });
        }).catch(error => {

            console.log(error);
        })
    };

    handleClosePdf = () => {
        this.setState({
            fileToPreview: null,
            showPreview: false
        })
    }

    render() {
        return (
            <>
                <PdfPreviewModal
                    className="modal"
                    show={this.state.showPreview}
                    file={this.state.fileToPreview}
                    close={this.handleClosePdf}/>

                <section className="margin-top-50">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="info-wrapper">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-lg-8">
                                            <h3 className="color-grey text-upper weight700 small-title">
                                                Offering details</h3>
                                            <div className="row">
                                                <div className="col-xs-12">
                                                    <div className="card card-custom padded20">
                                                        <div className="row">
                                                            <div className="col-xs-12 col-lg-5">
                                                                <div className="card-custom-img">
                                                                    {
                                                                        this.state.deal.imageUrl
                                                                            ? <img alt="dealImg"
                                                                                   src={process.env.REACT_APP_DMS_GATEWAY + '/v1/api/document/download/'
                                                                                   + this.state.deal.imageUrl + '?access_token=' + localStorage.getItem(AUTH_TOKEN)}
                                                                                   className="img-responsive"/>
                                                                            : <LocalOfferIcon
                                                                                style={{fontSize: 90, margin: 16}}/>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-12 col-lg-7">
                                                                <div
                                                                    className="card-title text-left">
                                                                    {this.state.deal.name}
                                                                </div>
                                                                <p>
                                                                    {this.state.deal.description}
                                                                </p>
                                                            </div>
                                                            <div className="col-xs-12">
                                                                <div className="card-footer margin-top-40">
                                                                    <div className="row">
                                                                        <div className="col-xs-12 col-lg-6">
                                                                            <div className="text-left-mobile-center ">
                                                                                Raise Target
                                                                                <span
                                                                                    className="card-footer_price">
                                                                                    <NumberFormat
                                                                                        value={this.state.deal.raiseTarget}
                                                                                        displayType={'text'}
                                                                                        thousandSeparator={true}
                                                                                        prefix={'$'}/>
                                                                                    </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xs-12 col-lg-6">
                                                                            <div className="text-right-mobile-center">
                                                                                <Button
                                                                                    fullWidth
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    onClick={this.showInvestModal}>
                                                                                    Click here to invest
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xs-12 col-sm-12 col-lg-4">
                                            <h3
                                                className="color-grey text-upper weight700 small-title">
                                                Deal documents
                                            </h3>
                                            <div className="card card-custom padded20">
                                                <ol className="document-list">
                                                    {this.state.documents.map(document => {
                                                        return (
                                                            <li key={document.id}>
                                                                {document.documentOriginalName}
                                                                <Button
                                                                    onClick={() => this.onFilePreview(document)}>
                                                                    (preview)
                                                                </Button>
                                                            </li>
                                                        )
                                                    })
                                                    }
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*<OfferingInvestModal*/}
                {/*    className="modal"*/}
                {/*    submit={this.onNewInvestment}*/}
                {/*    show={this.state.show}*/}
                {/*    close={this.handleClose}*/}
                {/*    payTypes={this.state.payTypes}>*/}
                {/*</OfferingInvestModal>*/}
            </>
        )
    }

}