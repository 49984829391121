// AUTH
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const UNAUTH_SUCCESS = "UNAUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";

// SIGN UP
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_ERROR = "SIGN_UP_ERROR";

// ACTIVATION
export const ACTIVATION_SUCCESS = "ACTIVATION_SUCCESS";

// USER INFO
export const SAVE_USER_INFO = "SAVE_USER_INFO";
export const FETCH_USER_INFO_SUCCESS = "FETCH_USER_INFO_SUCCESS";
export const FETCH_USER_INFO_ERROR = "FETCH_USER_INFO_SUCCESS";

// INITIAL DATA

export const FETCH_INVESTOR_ATTRIBUTES_SUCCESS = "FETCH_INVESTOR_ATTRIBUTES_SUCCESS";
export const FETCH_INVESTOR_ATTRIBUTES_ERROR = "FETCH_INVESTOR_ATTRIBUTES_ERROR";
export const FETCH_INVESTOR_ATTRIBUTE_OPTIONS_SUCCESS = "FETCH_INVESTOR_ATTRIBUTE_OPTIONS_SUCCESS";
export const FETCH_INVESTOR_ATTRIBUTE_OPTIONS_ERROR = "FETCH_INVESTOR_ATTRIBUTE_OPTIONS_ERROR";
export const FETCH_INVESTOR_SUCCESS = "FETCH_INVESTOR_SUCCESS";
export const FETCH_INVESTOR_ERROR = "FETCH_INVESTOR_ERROR";
export const FETCH_INVESTOR_DATA_SUCCESS = "FETCH_INVESTOR_DATA_SUCCESS";
export const FETCH_INVESTOR_DATA_ERROR = "FETCH_INVESTOR_DATA_ERROR";

// DOCUMENTS

export const SAVE_STATE_ID_FRONT = "SAVE_STATE_ID_FRONT";
export const SAVE_STATE_ID_BACK = "SAVE_STATE_ID_BACK";
export const SAVE_PASSPORT = "SAVE_PASSPORT";

// REGISTRATION STEPS

export const SET_REGISTRATION_STEP = "SET_REGISTRATION_STEP";