const ValidationService = {
    validate: (data, attributes, nonMandatory) => {
        let valid = [];
        attributes.forEach(attribute => {
            if (!nonMandatory.includes(attribute.code)) {
                if (Array.isArray(data[attribute.code])) {
                    if (data[attribute.code].length === 0){
                        valid.push(attribute.name)
                    } else {
                        if (data[attribute.code][0].chosenValue == null){
                            valid.push(attribute.name)
                        }
                    }
                } else if (!data[attribute.code]) {
                    valid.push(attribute.name);
                } else if (Object.entries(data[attribute.code]).length === 0 && data[attribute.code].constructor === Object) {
                    valid.push(attribute.name);
                } else if ((data[attribute.code].chosenValue === null || data[attribute.code].chosenValue.trim().length === 0)) {
                    valid.push(attribute.name);
                } else if (data[attribute.code].chosenValue === 'false'){
                    valid.push(attribute.name);
                }
            }
        });

        return valid;
    },
};

export default ValidationService;