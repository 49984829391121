import axios from '../custom-axios/app';

const DealDataService = {
    save: function (body) {
        return axios.post('/rest/deal_data', body);
    },
    fetchByDealAndAttribute: function (params) {
        return axios.get(`/rest/deal_data/deal/${params.dealId}/${params.attributeCode}`);
    }

};

export default DealDataService;