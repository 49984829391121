import axios from '../custom-axios/app';

const RegTypeReportService = {
    getByIssuerId: () => {
        return axios.get("/rest/regtype_report/issuer/");
    },
    getByDealId: (dealId) => {
        return axios.get("/rest/regtype_report/deal/"+dealId);
    }
}

export default RegTypeReportService;