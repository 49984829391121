import React, {Component} from 'react';
import RadioInput from '../../components/input/RadioInput';
import FileUpload from '../../components/input/FileUploadComponent';
import Aux from '../../hoc/AuxWrapper';
import validationAlert from "../../components/ValidationAlert";
import {Divider, Grid, Typography} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import DoneIcon from '@material-ui/icons/Done';
import styled from "styled-components";

const IconSuccess = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #E3F6E4;
  margin-bottom: 40px;
`;

const IconOrr = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #F3F3F3;
`;


class AccreditationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            MEET_EITHER_OF_THESE: [],
            SPOUSE_TOG_MEET: [],
            UNSURE_INDIVIDUAL_JOINT: [],
            nonMandatoryFields: ['SPOUSE_TOG_MEET', 'LETTER_ACCRD', 'UNSURE_INDIVIDUAL_JOINT']
        };
    }

    componentDidMount() {
        this.props.setCurrentRegistrationStep('Accreditation');
        this.init();
        window.scrollTo(0, 0)
    }

    init() {
        this.props.fetchAttributeOptions('MEET_EITHER_OF_THESE').then((data) => {
            this.setState({
                ...this.state,
                'MEET_EITHER_OF_THESE': data.data
            });
        });

        this.props.fetchAttributeOptions('SPOUSE_TOG_MEET').then((data) => {
            this.setState({
                ...this.state,
                'SPOUSE_TOG_MEET': data.data
            });
        });

        this.props.fetchAttributeOptions('UNSURE_INDIVIDUAL_JOINT').then((data) => {
            this.setState({
                ...this.state,
                'UNSURE_INDIVIDUAL_JOINT': data.data
            });
        });
    }

    previousStep(e) {
        e.preventDefault();

        this.navigate(e, false);
    }

    changeStep(e, step, path) {
        e.preventDefault();
        this.props.fetchInvestorStatusByStep('STEP_ACCREDITATION')
            .then(response => {
                let newMandatoryFields = [...this.state.nonMandatoryFields];

                if (this.props.investorData['MEET_EITHER_OF_THESE'] && this.props.investorData['MEET_EITHER_OF_THESE'].chosenValue === 'no') {
                    newMandatoryFields = newMandatoryFields.filter(f => f !== 'SPOUSE_TOG_MEET');

                    if (this.props.investorData['SPOUSE_TOG_MEET'] && this.props.investorData['SPOUSE_TOG_MEET'].chosenValue === 'no') {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'UNSURE_INDIVIDUAL_JOINT');
                    }
                }
                /* if (this.props.investorData['COUNTRY_CITIZEN'].chosenValue == 'United States' || this.props.investorData['COUNTRY_ID'].chosenValue == 'United States') {
                     newMandatoryFields = newMandatoryFields.filter(f => f !== 'LETTER_ACCRD');

                 }*/

                let valid = this.props.validate(this.props.investorData, response.data, newMandatoryFields);
                if (valid.length === 0) {
                    this.navigate(e,true);
                } else validationAlert(valid)
            }).catch(err => {
            console.log(err);
        });
    }

    navigate(e, next) {
        e.preventDefault();
        this.props.setRegistrationStatus('STEP_ACCREDITATION', next)
    }

    saveProgress = () => {
        this.props.history.push("/logout")
    }

    render() {
        return (
            <>
                <Grid item lg={12} xs={12}>
                    <Box mb="20px">
                        <Card raised>
                            <CardContent>
                                <form>
                                    <Box>
                                        <Box pb={3.5}>
                                            <Typography variant="h6">
                                                To be accredited as an Individual, you must satisfy
                                                one of these two conditions:
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Grid container>
                                                <Grid item lg={5}>
                                                    <IconSuccess>
                                                        <DoneIcon style={{fontSize: '35px', color: '#4CAF50'}}/>
                                                    </IconSuccess>

                                                    <Typography variant="subtitle2">
                                                        My personal Liquid Net Worth (excluding the value of my primary
                                                        residence)
                                                        is over $1,000,000 USD.
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={2} xs={12}>
                                                    <div className="or_registration">
                                                        <IconOrr>
                                                            <Typography variant="subtitle2">or</Typography>
                                                        </IconOrr>
                                                    </div>
                                                </Grid>

                                                <Grid item lg={5}>
                                                    <IconSuccess>
                                                        <DoneIcon style={{fontSize: '35px', color: '#4CAF50'}}/>
                                                    </IconSuccess>

                                                    <Typography variant="subtitle2">
                                                        My income was in excess of $200,000 USD in each of the the past
                                                        2
                                                        calendar years and I reasonably expect to reach the same income
                                                        level
                                                        in the current year.
                                                    </Typography>
                                                </Grid>

                                            </Grid>
                                        </Box>

                                        <Box mt={4} mb={1}>
                                            <Typography variant="subtitle2">Do you meet either of these conditions?
                                                <span className="required__circle"> *</span>
                                            </Typography>
                                        </Box>

                                        <RadioInput
                                            name="MEET_EITHER_OF_THESE"
                                            attributeCode="MEET_EITHER_OF_THESE"
                                            defaultValue={this.props.investorData['MEET_EITHER_OF_THESE']}
                                            options={this.state['MEET_EITHER_OF_THESE']}
                                            deleteInvestorData={this.props.deleteInvestorData}
                                            onChange={this.props.pushInvestorChange}/>

                                        <Box py={5}>
                                            <Divider/>
                                        </Box>
                                    </Box>

                                    {(this.props.investorData['MEET_EITHER_OF_THESE'] && this.props.investorData['MEET_EITHER_OF_THESE'].chosenValue === 'no') ?
                                        <Box>
                                            <Box pb={3.5}>
                                                <Typography variant="h6">
                                                    To be accredited as a Joint investor with your Spouse, you and your
                                                    Spouse together must satisfy one of these two conditions:
                                                </Typography>
                                            </Box>

                                            <Box>
                                                <Grid container>
                                                    <Grid item lg={5}>

                                                        <IconSuccess>
                                                            <DoneIcon style={{fontSize: '35px', color: '#4CAF50'}}/>
                                                        </IconSuccess>

                                                        <Typography variant="subtitle2">
                                                            My Joint Liquid Net Worth with my Spouse
                                                            (excluding the value of our primary residence) is over
                                                            $1,000,000 USD.
                                                        </Typography>

                                                    </Grid>

                                                    <Grid item lg={2}>
                                                        <Box display="flex" justifyContent="center">
                                                            <IconOrr>
                                                                <Typography variant="subtitle2">or</Typography>
                                                            </IconOrr>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item lg={5}>
                                                        <IconSuccess>
                                                            <DoneIcon style={{fontSize: '35px', color: '#4CAF50'}}/>
                                                        </IconSuccess>

                                                        <Typography variant="subtitle2">
                                                            My Joint Income with my Spouse was in excess of $300,000
                                                            USD in each of the past 2 calendar years and we reasonably
                                                            expect
                                                            to reach the same income level in the current year
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                            </Box>


                                            <Box mt={4} mb={1}>
                                                <Typography variant="subtitle2">
                                                    Do you and your Spouse together meet either of these conditions?
                                                    (answer is mandatory only if you answered "No" on the previous
                                                    question)
                                                    <span className="required__circle"> *</span>
                                                </Typography>
                                            </Box>

                                            <RadioInput
                                                name="SPOUSE_TOG_MEET"
                                                attributeCode="SPOUSE_TOG_MEET"
                                                defaultValue={this.props.investorData['SPOUSE_TOG_MEET']}
                                                options={this.state['SPOUSE_TOG_MEET']}
                                                deleteInvestorData={this.props.deleteInvestorData}
                                                onChange={this.props.pushInvestorChange}/>

                                            <Box py={5}>
                                                <Divider/>
                                            </Box>
                                        </Box>
                                        : null}

                                    {(this.props.investorData['MEET_EITHER_OF_THESE'] && this.props.investorData['MEET_EITHER_OF_THESE'].chosenValue === 'no') &&
                                    (this.props.investorData['SPOUSE_TOG_MEET'] && this.props.investorData['SPOUSE_TOG_MEET'].chosenValue === 'no') ?
                                        <Aux>
                                            <Box>
                                                <Box mb={1}>
                                                    <Typography variant="subtitle2">
                                                        Are you unsure of your Individual or Joint accreditation
                                                        status and would prefer someone on our team
                                                        reach out to you?
                                                    </Typography>
                                                </Box>

                                                <RadioInput
                                                    name="UNSURE_INDIVIDUAL_JOINT"
                                                    attributeCode="UNSURE_INDIVIDUAL_JOINT"
                                                    defaultValue={this.props.investorData['UNSURE_INDIVIDUAL_JOINT']}
                                                    options={this.state['UNSURE_INDIVIDUAL_JOINT']}
                                                    onChange={this.props.pushInvestorChange}/>
                                            </Box>

                                            {this.props.investorData['UNSURE_INDIVIDUAL_JOINT'] && this.props.investorData['UNSURE_INDIVIDUAL_JOINT'].chosenValue && this.props.investorData['UNSURE_INDIVIDUAL_JOINT'].chosenValue === "yes" ?

                                                <Box mt={4}>
                                                    <Typography variant="subtitle2">
                                                        Our Accreditation Team will reach out to you within the next 3
                                                        business days.
                                                    </Typography>
                                                </Box>

                                                : null}

                                            <Box py={5}>
                                                <Divider/>
                                            </Box>

                                        </Aux> : null}

                                    <Box>
                                        <Box mb={1}>
                                            <Typography variant="subtitle2">
                                                Please upload your most recent (dated withing the last 90 days)
                                                Letter of Accreditation from a CPA, Attorney or Broker/Dealer
                                                if you have one:
                                            </Typography>

                                            <Typography variant={"subtitle2"}>
                                                (Only JPG, PNG or PDF files. File
                                                must be less than 20 Mb)
                                            </Typography>
                                        </Box>

                                        <Grid lg={8}>
                                            <FileUpload
                                                onFileSelected={this.props.saveDocument}
                                                fileNumber="file-1"
                                                accept={'.jpg .png .pdf'}
                                                currentValue={this.props.investorData['LETTER_ACCRD']}
                                                onDocumentDelete={this.props.onDocumentDelete}
                                                documentGroupCode="letter-of-accreditation"
                                                attributeCode="LETTER_ACCRD"
                                                wrapperClass=""/>
                                        </Grid>
                                    </Box>
                                </form>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>

                <Grid item lg={12} xs={12}
                      style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '20px'}}>
                    <Button
                        variant='outlined'
                        style={{
                            background: '#FAFAFA',
                            marginRight: '5px'
                        }}
                        onClick={(e, step, path) => this.previousStep(e)}>
                        Previous
                    </Button>

                    <Button variant='outlined'
                            style={{
                                marginRight: '5px'
                            }}
                            color='primary'
                            onClick={() => this.saveProgress()}>
                        Save Progress & Come Back Later
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        // disabled={this.state.errorEmailValid ||
                        // (this.props.investorData['UNSURE_INDIVIDUAL_JOINT']
                        //     && this.props.investorData['UNSURE_INDIVIDUAL_JOINT'].chosenValue
                        //     && this.props.investorData['UNSURE_INDIVIDUAL_JOINT'].chosenValue === "no")}
                        onClick={(e) => this.changeStep(e, 'STEP_INVESTOR_STATUS', '/investor-status')}>
                        Next
                    </Button>
                </Grid>

            </>
        );
    }
}

export default AccreditationPage;