import React, {useEffect, useState} from 'react';
import {Route, Switch} from 'react-router-dom';
import Aux from '../../hoc/AuxWrapper';

import ProfileIndividualPage from '../../pages/dashboard/ProfileIndividualPage';
import DocumentationIndividualPage from '../../pages/dashboard/DocumentationIndividualPage';
import OfferingsIndividualPage from '../../pages/dashboard/OfferingsIndividualPage';
import MyInvestmentsIndividualPage from '../../pages/dashboard/MyInvestmentsIndividual';
import Logout from "../../components/Logout";
import OfferingReviewPage from "../../pages/dashboard/OfferingReviewPage";
import InboxIndividualPage from '../../pages/dashboard/InboxIndividualPage'
import ConversationService from "../../repository/ConversationService";
import {makeStyles} from "@material-ui/core/styles";
import SidebarIndividualNew from "../../components/dashboard/SidebarIndividualNew";
import clsx from "clsx";
import CssBaseline from "@material-ui/core/CssBaseline";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ChooseAction from "../registration/ChooseAction";
import PaymentPage from "../../pages/dashboard/PaymentPage";
import IndividualSignaturePage from "../../pages/dashboard/IndividualSignaturePage";
import Grid from "@material-ui/core/Grid";
import IndividualDashboardPage from "../../pages/dashboard/IndividualDashboardPage";
import ChooseNextStep from "../registration/ChooseNextStep";
import {Redirect} from "react-router";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),

        [theme.breakpoints.down("md")]: {
            zIndex: 9999,
            position: 'fixed',
            marginLeft: 0,
            width: '100%'
        },

    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },

    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),

        [theme.breakpoints.down("md")]: {
            zIndex: 9999,
            position: 'fixed',
            width: '250'
        },
    },

    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,

        [theme.breakpoints.down("md")]: {
            width: 0,
        },

    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 0, 0, 4),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,

        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(0, 0, 0, 1),
        },

    },
    content: {
        flex: '1 0 auto'
    },

    contentOverflow: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#F4F6F8',
        minHeight: '100vh',
        width: '100%',
        padding: 0
    }
}));


const IndividualDashboard = (prop) => {

    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [messages, setMessages] = useState([]);
    const [notRead, setNotRead] = useState([]);

    useEffect(() => {
        fetchMessages();
        setInterval(fetchMessages, 5000)
    }, []);

    const fetchMessages = () => {
        ConversationService.fetchSubscriptions().then(data => {
            if (data.data[0]) {
                ConversationService.fetchMessagesPerChannel(data.data[0].channelId)
                    .then(data => {
                        setMessages(data.data);
                        let notRead = [];
                        data.data.forEach(message => {
                            if (message.read) {
                                notRead.push(message)
                            }
                        });
                        setNotRead(notRead);
                    })
            }
        });
    };

    useEffect(() => {
        const screenWidth = window.screen.width;

        if (screenWidth < 1280) {
            setOpen(false)
        }
    }, []);


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);

    };

    return (
        <Aux>
            <div className={classes.root}>
                <CssBaseline/>

                <Header
                    investorData={prop.investorData}
                    headerClass={clsx(classes.appBar, {[classes.appBarShift]: open})}
                    classTest={clsx(classes.menuButton, {[classes.hide]: open})}
                    onClick={handleDrawerOpen}/>

                <SidebarIndividualNew
                    path={prop.path}
                    investorData={prop.investorData}
                    investor={prop.investor}
                    messages={notRead}
                    classToolbar={classes.toolbar}
                    drawerClose={handleDrawerClose}
                    classesDrawer={{paper: clsx({[classes.drawerOpen]: open, [classes.drawerClose]: !open})}}
                    classDrawer={clsx(classes.drawer, {[classes.drawerOpen]: open, [classes.drawerClose]: !open})}
                />

                <main className={classes.contentOverflow}>

                    <div className={classes.content}>
                        <div style={{padding: '15px'}}>
                            <Switch>
                                <Route path="/dashboard" exact
                                       render={(props) =>
                                           <IndividualDashboardPage
                                               {...props}/>}
                                />

                                <Route path="/profile" exact
                                       render={(props) =>
                                           <ProfileIndividualPage
                                               {...props}
                                               updateInvestorInfo={prop.updateInvestorInfo}
                                               pushInvestorChange={prop.pushInvestorChange}
                                               deleteInvestorData={prop.deleteInvestorData}
                                               investorData={prop.investorData}
                                               investor={prop.investor}
                                               fetchAttributeOptions={prop.fetchAttributeOptions}
                                               investorAttributeOptions={prop.investorAttributeOptions}
                                               validate={prop.validate}
                                           />}
                                />

                                <Route path="/documentation" exact
                                       render={(props) =>
                                           <DocumentationIndividualPage
                                               {...props}/>}
                                />

                                <Route path="/offerings" exact
                                       render={(props) =>
                                           <OfferingsIndividualPage
                                               {...props}/>}
                                />

                                <Route path="/offerings/:code" exact
                                       render={(props) =>
                                           <OfferingReviewPage
                                               {...props}/>}
                                />

                                <Route path="/offers/:offerId/:actionId?" exact
                                       render={(props) =>
                                           <ChooseNextStep
                                               {...props}
                                           />
                                       }
                                />

                                <Route path="/inbox" exact
                                       render={(props) =>
                                           <InboxIndividualPage
                                               {...props}
                                               notRead={notRead}
                                               messages={messages}/>}
                                />

                                <Route path="/my-investments" exact
                                       render={(props) =>
                                           <MyInvestmentsIndividualPage
                                               {...props}/>}/>

                                <Route path="/invest/:id" exact component={IndividualSignaturePage}/>

                                <Route path="/investment/:id/payment" exact component={PaymentPage}/>

                                <Route path="/logout" exact component={Logout}/>

                                <Route path="/choose-action" exact component={ChooseAction}/>

                                <Redirect to="/dashboard"/>
                            </Switch>
                        </div>
                    </div>

                    <Grid className='footer_backend--app' item container lg={12} xs={12}>
                        <Footer/>
                    </Grid>
                </main>
            </div>
        </Aux>
    );
};

export default IndividualDashboard;