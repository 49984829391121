import React, {Component} from 'react';
import Aux from '../../hoc/AuxWrapper';
import axios from '../../custom-axios/app';
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import {NavLink} from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccountBoxIcon from '@material-ui/icons/AccountBox';


const CartComponent = styled.div`
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),
     0px 1px 1px 0px rgba(0,0,0,0.14),
      0px 1px 3px 0px rgba(0,0,0,0.12);
    padding: 30px 30px 30px 30px;
    position: relative;
    background: white;
    max-width: 480px;
    min-width: 480px;
    
    @media (max-width: 600px) {
        width: 100%;
        min-width: 100%;
  } 
`;

const MainWrap = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const WrapBoxLogin = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0 35px 0;
`;

const WrapMainElement = styled.div`
  display: flex;
  flex-direction: column;
`;

const BoxBLock = styled.div`
    width: 48%;
    border: 1px solid #eee;
    padding: 25px;
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 15px;
    
    &:hover {
        border: 1px solid #032F66;
    }
`;


class ChooseAction extends Component {

    selectNextAction(action) {
        axios.get('/users/current-user')
            .then(res => {
                localStorage.setItem('userInfo', JSON.stringify(res.data));
                if (action === 'account') {
                    this.props.history.push('/profile');
                } else {
                    window.location.href = process.env.REACT_APP_OFFERING_GATEWAY + '/offerings/' + res.data.userGroup.id.toLowerCase();
                }
            }).catch(err => {

        });
    }


    render() {
        return (
            <Aux>
                {/*<div className="signup-overlay-1"></div>*/}
                <MainWrap>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={3}>
                        <WrapMainElement>
                            <WrapBoxLogin>
                                <NavLink to="index.php">
                                    <img
                                        src={require('../../assets/images/logo-blue.png')}
                                        width="140"
                                        height="51"
                                        alt=""/>
                                </NavLink>
                            </WrapBoxLogin>

                            <CartComponent>
                                {/*<h3 className="margin-bottom-60 color-grey">*/}
                                {/*    Thank you! Your registration is complete. You can preview investments or you can*/}
                                {/*    finish registration.</h3>*/}

                                <Box display="flex">
                                    <BoxBLock onClick={() => this.selectNextAction('preview')}>
                                        <Box pb="15px">
                                            <AttachMoneyIcon style={{fontSize: '45px', color: '#032F66'}}/>
                                        </Box>

                                        <Typography variant="subtitle2">Preview offerings</Typography>

                                    </BoxBLock>

                                    <BoxBLock onClick={() => this.selectNextAction('account')}>
                                        <Box pb="15px">
                                            <AccountBoxIcon style={{fontSize: '45px', color: '#032F66'}}/>
                                        </Box>

                                        <Typography variant="subtitle2">Go to your personal account</Typography>
                                    </BoxBLock>
                                </Box>
                            </CartComponent>
                        </WrapMainElement>
                    </Grid>
                </MainWrap>
            </Aux>
        );
    }
}

export default ChooseAction;
