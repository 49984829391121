import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import SelectField from "../../components/input/new/SelectField";
import InvestmentCard from "../../components/dashboard/InvestmentCard";
import InvestmentService from "../../repository/InvestmentService";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {toast} from 'react-toastify';
import TextField from "@material-ui/core/TextField";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LastPageIcon from "@material-ui/icons/LastPage";

class MyInvestmentsIndividual extends Component {

    constructor(props) {
        super(props);
        this.state = {
            investmentsUrl: `/rest/investment`,
            investments: [],
            investmentStatuses: [],
            sizes: [10, 20, 50, 100, 200],
            pageSize: 10,
            sort: '',
            filter: {
                investmentStatus: ''
            },
            page: 1,
            pagePagination: 1,
            totalPages: 0,
            pageList: [],
            maxPageListSize: 5,
            loader: true
        }
    }

    componentDidMount() {
        document.body.setAttribute('class', 'dashboard-body');
        this.fetchInvestments();
        this.fetchInvestmentStatuses()
    }

    fetchInvestmentStatuses() {
        InvestmentService.fetchInvestmentStatuses()
            .then(response => {
                this.setState({
                    investmentStatuses: response.data
                })
            })
            .catch(error => {
                console.log(error);
            })
    }

    fetchInvestments = () => {
        this.setState({
            loader: true
        })

        InvestmentService.fetchAllMyInvestments(this.state.sort ? this.state.sort : '',
            this.state.page - 1, this.state.pageSize, this.state.filter)
            .then(response => {
                let data = response.data.content

                this.setState({
                    investments: data,
                    totalPages: response.data.totalPages,
                    loader: false
                }, () => this.generatePageList())

            })
            .catch(error => {
                console.log(error)
            })
    }

    onChangeSearchField = (value, field) => {
        this.setState({
            filter: {
                ...this.state.filter,
                [field]: value.id ? value.id : value
            }
        }, () => this.fetchInvestments())
    }

    firstPage() {
        this.setState(() => ({
                page: 1,
            }), () => this.fetchInvestments()
        )
    }

    lastPage() {
        this.setState(() => ({
                page: this.state.totalPages,
            }), () => this.fetchInvestments()
        )
    }

    currentPaginationPage = () => {
        let paginationPageChecked = this.state.pagePagination;

        if (paginationPageChecked <= this.state.totalPages && paginationPageChecked > 0) {
            this.setState((prevState) => ({
                page: paginationPageChecked,
            }), () => {
                let newPageList = [];
                for (let i = this.state.page; i <= Math.min(this.state.totalPages, this.state.pagePagination + this.state.maxPageListSize - 1); i++) {
                    newPageList.push(i);
                    this.setState({
                        pageList: newPageList
                    }, () => {
                    })
                }
                this.fetchInvestments();
            });
        } else {
            toast.error('This page does not exist');
        }
    };

    nextPage() {
        if (this.state.page < this.state.totalPages) {
            this.setState((prevState) => ({
                page: prevState.page + 1,

            }), () => {
                if (this.state.page > this.state.pageList[this.state.pageList.length - 1]) {
                    let newPageList = [...this.state.pageList];
                    newPageList.push(this.state.page)
                    newPageList.shift()

                    this.setState({
                        pageList: newPageList
                    })
                }

                this.fetchInvestments()
            })
        }
    }

    previousPage() {
        if (this.state.page > 1) {
            this.setState((prevState) => ({
                page: prevState.page - 1,
            }), () => {
                if (this.state.page < this.state.pageList[0]) {
                    let newPageList = [...this.state.pageList];
                    newPageList.unshift(this.state.page)
                    newPageList.pop()

                    this.setState({
                        pageList: newPageList
                    })
                }

                this.fetchInvestments()
            })
        }
    }

    setPage(page) {
        this.setState({
            page: page,
        }, () => {
            this.fetchInvestments();
        });

    }

    onChangeGetSpecifiedPage = (e) => {
        this.setState({
            pagePagination: +e.target.value
        })
    }

    generatePageList() {
        let initialPageList = [];

        for (let i = 1; i <= Math.min(this.state.totalPages, this.state.maxPageListSize); i++) {
            initialPageList.push(i);
        }

        this.setState({
            pageList: initialPageList
        })
    }


    render() {
        return (
            <Grid container spacing={3}
                  style={{paddingTop: 80}}>
                <Grid item lg={12} xs={12}>
                    <Typography variant='h6'>
                        My investments
                    </Typography>

                    <Divider/>
                </Grid>

                <Grid item lg={2} xs={3}>
                    <SelectField
                        name={"investmentStatus"}
                        value={this.state.filter.investmentStatus}
                        emptyLabel={'All'}
                        size={'small'}
                        options={this.state.investmentStatuses}
                        onChange={this.onChangeSearchField}
                    />
                </Grid>

                {this.state.loader ? (
                    <Grid item lg={12} xs={12}
                          style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              minHeight: '50vh'
                          }}>
                        <CircularProgress/>
                    </Grid>
                ) : this.state.investments.length === 0 ? (
                    <Grid item lg={12} xs={12}>
                        <Typography>
                            You have not made any Investments yet. Once you do, they will appear here
                            along with pertinent information.
                        </Typography>
                    </Grid>
                ) : (
                    <>
                        <Grid item lg={12} xs={12}>
                            <Grid container spacing={3}>
                                {this.state.investments.map((item) => (
                                    <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
                                        <InvestmentCard
                                            investment={item}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>

                        <Grid item lg={12} xs={12}
                              style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Box mt={2} mb={4} display="flex" alignItems="center">
                                <Button
                                    size="small"
                                    onClick={() => this.firstPage()}
                                >
                                    <FirstPageIcon/>
                                </Button>

                                <Button
                                    size="small"
                                    onClick={() => this.previousPage()}
                                >
                                    <ChevronLeftIcon/>
                                </Button>

                                {this.state.pageList.map(item => {
                                    return (
                                        <Button
                                            key={item}
                                            size="small"
                                            variant={item === this.state.page ? 'contained' : ''}
                                            color={item === this.state.page ? 'primary' : ''}
                                            onClick={() => this.setPage(item)}
                                        >
                                            {item}
                                        </Button>
                                    )
                                })}

                                <Button
                                    size="small"
                                    onClick={() => this.nextPage()}
                                >
                                    <ChevronRightIcon/>
                                </Button>

                                <Button
                                    size="small"
                                    onClick={() => this.lastPage()}
                                >
                                    <LastPageIcon/>
                                </Button>

                                <TextField
                                    size="small"
                                    variant="outlined"
                                    onChange={this.onChangeGetSpecifiedPage}
                                    type={"number"}
                                    style={{width: 80, margin: 'auto'}}
                                    min={0}/>

                                <Box mx={1}>
                                    <Button
                                        color="primary"
                                        variant="ou¶tlined"
                                        onClick={this.currentPaginationPage}
                                    >
                                        Go
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </>
                )}
            </Grid>
        )
    }
}

export default MyInvestmentsIndividual;