import React from 'react';
import ConversationService from "../../repository/ConversationService";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const RadioInput = (props) => {

  const onChangeHandler = (value) => {
    const option = props.options.find(o => o.value === value);
    props.onChange({
      attributeCode: props.attributeCode,
      value: value,
      investorAttributeOptionId: option.id
    });

    if (props.fieldToClear) {
      let request = {
        attributeCode: props.fieldToClear,
        value: ''
      };
      if (request) {
        props.onChange(request);
      }
    }
    if (value === 'yes' && props.attributeCode === 'UNSURE_INDIVIDUAL_JOINT') {
      ConversationService.createZendeskTicket()
    }

    if (value === 'yes' && props.onDocumentDelete) {
      props.onDocumentDelete(props.documentGroupCodeStateIdFront, props.attributeCodeStateIdFront);
      props.onDocumentDelete(props.documentGroupCodeStateIdBack, props.attributeCodeStateIdBack);
    }

    if (value === 'yes' && props.attributeCode === 'MEET_EITHER_OF_THESE') {
      props.deleteInvestorData({attributeCode: 'SPOUSE_TOG_MEET'});
      props.deleteInvestorData({attributeCode: 'UNSURE_INDIVIDUAL_JOINT'});
    }

    if (value === 'yes' && props.attributeCode === 'SPOUSE_TOG_MEET') {
      props.deleteInvestorData({attributeCode: 'UNSURE_INDIVIDUAL_JOINT'});
    }

    if (value === 'no' && props.onDocumentDelete) {
      props.onDocumentDelete(props.documentGroupCodePassport, props.attributeCodePassport);
    }

    if (props.attributes) {
      if (value === 'yes') {
        props.attributes.forEach(attr => {
          let request;
          if (attr.data && attr.data.attributeType === 'TEXT_INPUT') {
            request = {
              attributeCode: attr.code,
              value: attr.data.chosenValue
            };
          } else if (attr && attr.data && attr.data.chosenValue) {
            const option = attr.chosenData.find(option => option.description === attr.data.chosenValue);

            if (option) {
              request = {
                attributeCode: attr.code,
                investorAttributeOptionId: option.id,
                value: option.description
              };
            }
          }
          if (request) {
            props.pushInvestorChange(request);
          }
        })
      } else {
        props.attributes.forEach(attr => {
          props.deleteInvestorData({attributeCode: attr.code})
        });
      }

    }
  };

  const yesId = props.name + 'yes';
  const noId = props.name + 'no';

  return (
    <div style={props.noDisplay?{display:'none'}:{}}>

      <FormControlLabel
        control={<Radio
          type="radio"
          name={props.name}
          id={yesId}
          value="yes"
          color="primary"
          checked={props.alwaysChecked?true:props.defaultValue && props.defaultValue.chosenValue ? props.defaultValue.chosenValue === "yes" : false}
          onChange={(e) => onChangeHandler(e.currentTarget.value)}/>}
        label="Yes"/>


      <FormControlLabel
        label="No"
        control={
          <Radio
            color="primary"
            type="radio"
            name={props.name}
            id={noId}
            value="no"
            checked={props.alwaysChecked?false:props.defaultValue && props.defaultValue.chosenValue ? props.defaultValue.chosenValue === "no" : false}
            onChange={(e) => onChangeHandler(e.currentTarget.value)}/>
        }/>
    </div>
  );
};

export default RadioInput;