import * as React from 'react';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {Grid} from "@material-ui/core";

const HorizontalStepperWithError = (props) => {

    // const [step, setStep] = useState(0)

    const currentStep = props.steps?.find(s => s.path.replace('/', '') === props.currentStep);
    let index = props.steps?.indexOf(currentStep)

    if (currentStep < 0) {
        index = 0
    }

    // useEffect(() => {
    //     const currentStep = props.steps.find(s => s.value === props.currentStep);
    //     const index = props.steps.indexOf(currentStep)
    //
    //     if (currentStep >= 0) {
    //         setStep(index)
    //     }
    // }, [])


    const isStepFailed = (step) => {
        return step === 1;
    };

    return (
        <Grid item lg={12} xs={12} style={{marginTop: '100px', marginBottom: '20px'}}>
            <Card raised={true}>
                <CardContent>
                    <Stepper activeStep={index}>
                        {props.steps && props.steps.map((step1, index) => {
                            const labelProps = {};

                            // if (isStepFailed(index)) {
                            //     labelProps.optional = (
                            //         <Typography variant="caption" color="error">
                            //             Alert message
                            //         </Typography>
                            //     );
                            //
                            //     labelProps.error = true;
                            // }

                            return (
                                <Step key={step1.path}>
                                    <StepLabel {...labelProps}></StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </CardContent>
            </Card>
        </Grid>
    );
}

export default HorizontalStepperWithError