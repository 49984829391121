import * as actionTypes from '../actions/actionTypes';

const initialState = {
  email: ''
};

const activationSuccess = (state, action) => {
    return {
        ...state,
        email: action.payload
    }
};

const activate = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ACTIVATION_SUCCESS:
            return activationSuccess(state, action);
        default:
            return state
    }
};

export default activate;