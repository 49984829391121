import React from 'react';
import NumberFormat from 'react-number-format';
import {AUTH_TOKEN} from "../../shared/utility";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import LocalMallIcon from '@material-ui/icons/LocalMall';
import Button from "@material-ui/core/Button";

const InvestmentCard = (props) => {

    return (
        <Card
            raised
            style={{minWidth: '100%', minHeight: 310}}>
            <CardContent>
                <Grid container spacing={2}
                      justify='center'
                      alignItems='center'
                >
                    {props.investment.dealImageUrl ? (
                        <img alt="dealImg"
                            // src={require('../../assets/images/background.jpg')}
                             src={process.env.REACT_APP_DMS_GATEWAY + '/v1/api/document/download/' +
                             props.investment.dealImageUrl + '?access_token=' + localStorage.getItem(AUTH_TOKEN)}
                             className="img-responsive"/>
                    ) : (
                        <LocalMallIcon style={{fontSize: 90, margin: '24px'}}/>
                    )}

                    <Grid item lg={12} xs={12}
                          style={{display: 'flex', justifyContent: 'center'}}>
                        <Typography variant='subtitle2'>
                            {props.investment.dealName}
                        </Typography>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                        <Divider/>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                        <Grid container spacing={2}
                              justify={"space-between"} alignItems={"center"}>
                            <Grid item lg={3} xs={6}>
                                <Typography variant="caption">
                                    Status:
                                </Typography>
                            </Grid>

                            <Grid item lg={9} xs={6}
                                  style={{display: "flex", justifyContent: 'flex-end'}}>
                                {props.investment.investmentStatus.id === 'MAKE_PAYMENT' ? (
                                    <Button style={{margin: 5}}
                                            color="primary"
                                            size={"small"}
                                            variant="contained"
                                            onClick={() => window.location.href = `/investment/${props.investment.id}/payment/`}>
                                        {props.investment.investmentStatus.value}
                                    </Button>
                                ) : (
                                    <Typography variant="caption">
                                        {props.investment.investmentStatus.value}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item lg={4} xs={6}>
                                <Typography variant="caption">
                                    I invested:
                                </Typography>
                            </Grid>

                            <Grid item lg={8} xs={6}
                                  style={{display: "flex", justifyContent: 'flex-end'}}>
                                <Typography variant="caption">
                                    <NumberFormat value={props.investment.amount}
                                                  displayType={'text'}
                                                  thousandSeparator={true}
                                                  prefix={'$'}/>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default InvestmentCard;