import React, {Component} from 'react';

import Aux from '../../hoc/AuxWrapper';
import axios from '../../custom-axios/app';
import {connect} from 'react-redux';
import * as actions from "../../store/actions/actionTypes";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {toast} from "react-toastify";

class ChoosePasswordReset extends Component {

    state = {
        password: '',
        confirmPassword: '',
        loginError: null,
        validPasswords: false,
        passwordRegex: /(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&]){8,30}/
    };

    passwordSubmitHandler(e) {
        e.preventDefault();

        axios.post('/users/reset-password-success', {
            password: this.state.password,
            repeatPassword: this.state.confirmPassword,
            email: localStorage.getItem("email"),
            passwordResetString: localStorage.getItem('passwordResetString')
        }).then(response => {
            toast.success('Password successfully changed');
            this.props.history.push("/logout");
        }).catch(error => {
            toast.error('Error');
        });
    }

    changePasswordHandler(e) {
        this.setState({
            password: e.target.value
        }, () => {
            this.checkPasswordsEquality();
        });
    }

    changeConfirmPasswordHandler(e) {
        this.setState({
            confirmPassword: e.target.value
        }, () => {
            this.checkPasswordsEquality();
        });
    }

    checkPasswordsEquality() {
        var loginError = null;

        if (this.state.password !== this.state.confirmPassword) {
            loginError = 'Passwords do not match';
            this.setState({
                validPasswords: false
            })
        } else if (this.checkPasswordValidity()) {
            this.setState({
                validPasswords: true
            })
        } else {
            loginError = 'Passwords do not meet the requirements';
            this.setState({
                validPasswords: false
            });
        }

        this.setState({
            loginError: loginError
        });
    }

    checkPasswordValidity() {
        return this.state.passwordRegex.test(this.state.password);
    }

    render() {
        return (
            <Aux>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{height: '100vh'}}
                >
                    <Grid item lg={3}>
                        <Card raised>
                            <CardContent>
                                <form onSubmit={(e) => this.passwordSubmitHandler(e)}>
                                    <Grid
                                        container
                                        spacing={3}
                                    >
                                        <Grid
                                            item lg={12}
                                            style={{display: 'flex', justifyContent: 'center'}}
                                        >
                                            <img
                                                src={require('../../assets/images/logo-blue.png')}
                                                width="140"
                                                height="51"
                                                alt=""
                                            />
                                        </Grid>

                                        <Grid item lg={12}>
                                            <Typography
                                                variant={"h5"}
                                                align={"center"}
                                            >
                                                Choose a Password
                                            </Typography>
                                        </Grid>

                                        <Grid item lg={12}>
                                            <TextField
                                                fullWidth
                                                variant={"outlined"}
                                                type="password"
                                                placeholder="Enter Password"
                                                value={this.state.password}
                                                onChange={(e) => this.changePasswordHandler(e)}/>
                                        </Grid>

                                        <Grid item lg={12}>
                                            <TextField
                                                fullWidth
                                                variant={"outlined"}
                                                type="password"
                                                placeholder="Confirm Password"
                                                value={this.state.confirmPassword}
                                                onChange={(e) => this.changeConfirmPasswordHandler(e)}/>

                                            <Typography className="error-message">
                                                {this.state.loginError}
                                            </Typography>
                                        </Grid>

                                        <Grid item lg={12}>
                                            <p className="small color-black">Your password must be between 8 and 30
                                                characters,
                                                must contain at least one uppercase letter, one number and one special
                                                character.</p>
                                        </Grid>

                                        <Grid item lg={12}>
                                            <Button
                                                fullWidth
                                                variant={"contained"}
                                                color={"primary"}
                                                onClick={(e) => this.passwordSubmitHandler(e)}
                                                disabled={!this.state.validPasswords}>
                                                Next
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Aux>
        )
    }
}


const mapStateToProps = state => {
    return {
        email: state.activateReducer.email
    }
};

const mapDispatchToProps = dispatch => {
    return {
        authSuccess: (token) => dispatch({type: actions.AUTH_SUCCESS, payload: token}),
        authError: (error) => dispatch({type: actions.AUTH_ERROR, error: error})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoosePasswordReset);