import axios from '../custom-axios/app';

const DealDetailsService = {
    fetch: () => {
        return axios.get("/rest/deal_details");
    },
    fetchByDealCode: (code) => {
        return axios.get("/rest/deal/"+code);
    }

}

export default DealDetailsService;