import axios from '../custom-axios/app';

const SettingService = {
    transform: (requests) => {
        return requests.map(request => {
            return {
                id: request.id ? request.id : '',
                name: request.name ? request.name : '',
                settingGroup: request.settingGroup ? request.settingGroup : ''
            }
        })
    },

    createSetting: (request) => {
        return axios.post('/api/settings', request)
    },

    fetchSettingByName: (request) => {
        return axios.get(`/api/settings?name=${request}`);
    },
}

export default SettingService