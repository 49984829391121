import React, {Component} from 'react';
import {Link, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

class Footer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Grid container spacing={3}
                      className='footer_backend--app'>
                    <Grid item lg={4} xs={12}>
                        <Grid container spacing={3}>
                            <Grid item lg={6} xs={12}>
                                <Typography variant='subtitle2'>
                                    <Link color='inherit'
                                          href="https://www.amerx.com/">
                                        Home
                                    </Link>
                                </Typography>

                                <Typography variant='subtitle2'>
                                    <Link color='inherit'
                                          href="https://www.amerx.com/privacy-policy"
                                          target="_blank">
                                        Privacy Policy
                                    </Link>
                                </Typography>

                                <Typography variant='subtitle2'>
                                    <Link color='inherit'
                                          href="https://www.amerx.com/">
                                        About
                                    </Link>
                                </Typography>

                                <Typography variant='subtitle2'>
                                    <Link color='inherit'
                                          href="https://www.amerx.com/terms-conditions">
                                        Terms & Conditions </Link>
                                </Typography>
                            </Grid>

                            <Grid item lg={6} xs={12}>
                                <Typography variant='subtitle2'>
                                    <Link color='inherit'
                                          href="https://www.amerx.com/investment-banking">
                                        Investment Banking
                                    </Link>
                                </Typography>

                                <Typography variant='subtitle2'>
                                    <Link color='inherit'
                                          href="https://www.optimalapplications.com/606/AMERX/"
                                          target="_blank">
                                        606 Reports
                                    </Link>
                                </Typography>


                                <Typography variant='subtitle2'>
                                    <Link color='inherit'
                                          href="https://www.amerx.com/execution-services">Executions Services
                                    </Link>
                                </Typography>

                                <Typography variant='subtitle2'>
                                    <Link color='inherit'
                                          href="https://www.sipc.org/"
                                          target="_blank">www.sipc.org
                                    </Link>
                                </Typography>

                                <Typography variant='subtitle2'>
                                    <Link color='inherit'
                                          href="https://www.amerx.com/careers">
                                        Careers
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={8} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item lg={5} xs={12}>
                                <Typography variant='subtitle2'>  &copy; Americas Executions. LLC. All rights
                                    reserved.
                                </Typography>
                                <img
                                    src={require('../assets/images/dashboard_icons/broker-check-logo-footer.png')}
                                    alt="" className="logo-footer"/>
                            </Grid>

                            <Grid item lg={7} xs={12}>
                                <Typography variant='subtitle2'>
                                    If you need to change fields not modifiable by you in this profile or for support,
                                    please email <a href="mailto:support@amerx.com">support@amerx.com</a>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default Footer;