import React from "react";

import {Typography} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import FormHelperText from "@material-ui/core/FormHelperText";

const SelectMultipleField = ({
                                 title, name, value, emptyLabel, options, required = false, onChange,
                                 hasError = false, errorMessage
                             }) => {

    const handleChange = (values) => {
        onChange(values, name)
    }

    return (
        <>
            <Typography>
                {title}
                {required && <span className="required__circle"> *</span>}
            </Typography>
            <FormControl variant="outlined"
                         fullWidth
                         error={hasError}
            >
                <Select
                    multiple
                    value={value}
                    onChange={event => handleChange(event.target.value)}
                    renderValue={(selected) => options
                        .filter(value => selected.includes(value.id ? value.id : value))
                        .map(value => value.value
                            ? value.value
                            : value).join(', ')}
                >
                    {
                        emptyLabel && <MenuItem key={emptyLabel} value="">
                            {emptyLabel}
                        </MenuItem>
                    }

                    {
                        options.map(option =>
                            <MenuItem key={option.id ? option.id : option}
                                      value={option.id ? option.id : option}>
                                <Checkbox
                                    checked={value.some(value => option.id
                                        ? value === option.id
                                        : value === option)}
                                />
                                <ListItemText primary={option.value ? option.value : option}/>
                            </MenuItem>
                        )
                    }
                </Select>
                <FormHelperText>
                    {hasError && errorMessage}
                </FormHelperText>
            </FormControl>
        </>
    )
}

export default SelectMultipleField;