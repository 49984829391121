import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Aux from '../../hoc/AuxWrapper';
import Logout from "../../components/Logout";
import OfferingReviewPage from "../../pages/dashboard/OfferingReviewPage";
import InboxIndividualPage from '../../pages/dashboard/InboxIndividualPage'
import ProfileIssuerPage from "../../pages/dashboard/ProfileIssuerPage";
import OfferingsIssuerPage from "../../pages/dashboard/OfferingIssuerPage";
import InvestorsIssuerPage from "../../pages/dashboard/InvestorsIssuerPage";
import {makeStyles} from "@material-ui/core/styles";
import ConversationService from "../../repository/ConversationService";
import CssBaseline from "@material-ui/core/CssBaseline";
import Header from "../../components/Header";
import clsx from "clsx";
import Footer from "../../components/Footer";
import SidebarIssuerNew from "../../components/dashboard/SidebarIssuerNew";
import IssuerDashboardPage from "../../pages/dashboard/IssuerDashboardPage";
import Grid from "@material-ui/core/Grid";
import IssuerSignaturePage from "../../pages/dashboard/IssuerSignaturePage";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),

        [theme.breakpoints.down("md")]: {
            zIndex: 9999,
            position: 'fixed',
            marginLeft: 0,
            width: '100%'
        },

    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },

    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),

        [theme.breakpoints.down("md")]: {
            zIndex: 9999,
            position: 'fixed',
            width: '250'
        },
    },

    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,

        [theme.breakpoints.down("md")]: {
            width: 0,
        },

    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 0, 0, 4),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,

        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(0, 0, 0, 1),
        },

    },
    content: {
        flex: '1 0 auto'
    },

    contentOverflow: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#F4F6F8',
        minHeight: '100vh',
        width: '100%',
        padding: 0
    }
}));


const IssuerDashboard = (prop) => {

    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const [messages, setMessages] = useState([]);
    const [notRead, setNotRead] = useState([]);

    useEffect(() => {
        fetchMessages();
        setInterval(fetchMessages, 3000)
    }, []);

    const fetchMessages = () => {
        ConversationService.fetchSubscriptions()
            .then(data => {
                if (data.data.length !== 0) {
                    ConversationService.fetchMessagesPerChannel(data.data[0].channelId)
                        .then(data => {
                            setMessages(data.data);
                            let notRead = [];

                            data.data.forEach(message => {
                                if (message.read) {
                                    notRead.push(message)
                                }
                            });

                            setNotRead(notRead);
                        })
                }
            })
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);

    };
    return (
        <Aux>
            <div className={classes.root}>
                <CssBaseline/>

                <Header
                    headerClass={clsx(classes.appBar, {[classes.appBarShift]: open})}
                    classTest={clsx(classes.menuButton, {[classes.hide]: open})} onClick={handleDrawerOpen}/>

                <SidebarIssuerNew
                    path={prop.path}
                    messages={notRead}
                    classToolbar={classes.toolbar}
                    drawerClose={handleDrawerClose}
                    classesDrawer={{paper: clsx({[classes.drawerOpen]: open, [classes.drawerClose]: !open})}}
                    classDrawer={clsx(classes.drawer, {[classes.drawerOpen]: open, [classes.drawerClose]: !open})}
                />

                {/*<SidebarIssuer*/}
                {/*    issuer={prop.issuer}*/}
                {/*/>*/}

                <main className={classes.contentOverflow}>
                    <div className={classes.content}>
                        <div style={{padding: '15px'}}>
                            <Switch>
                                <Route path="/dashboard" exact
                                       render={(props) =>
                                           <IssuerDashboardPage
                                               {...props}/>}
                                />

                                <Route path="/profile" exact
                                       render={(props) =>
                                           <ProfileIssuerPage
                                               {...props}
                                               updateIssuerInfo={prop.updateIssuerInfo}
                                               saveIssuerPOC={prop.saveIssuerPOC}
                                               pushInvestorChange={prop.pushInvestorChange}
                                               deleteInvestorData={prop.deleteInvestorData}
                                               issuerData={prop.issuerData}
                                               issuer={prop.issuer}
                                               fetchAttributeOptions={prop.fetchAttributeOptions}
                                               investorAttributeOptions={prop.investorAttributeOptions}
                                               validate={prop.validate}
                                           />}/>


                                {/*<Route path="/documentation" exact*/}
                                {/*       render={(props) =>*/}
                                {/*           <DocumentationIndividualPage*/}
                                {/*               investorData={prop.issuerData}*/}
                                {/*               deleteInvestorData={prop.deleteInvestorData}*/}
                                {/*               pushInvestorChange={prop.pushInvestorChange}*/}
                                {/*               fetchAttributeOptions={prop.fetchAttributeOptions}*/}
                                {/*               investorAttributeOptions={prop.investorAttributeOptions}*/}
                                {/*               {...props}/>}/>*/}

                                <Route path="/offerings" exact
                                       render={(props) =>
                                           <OfferingsIssuerPage
                                               {...props}/>}/>

                                <Route path="/offerings/:code" exact
                                       render={(props) =>
                                           <OfferingReviewPage
                                               {...props}/>}/>

                                <Route path="/inbox" exact
                                       render={(props) =>
                                           <InboxIndividualPage
                                               notRead={notRead}
                                               messages={messages}
                                               {...props}/>}/>

                                <Route path="/investors" exact
                                       render={(props) =>
                                           <InvestorsIssuerPage
                                               {...props}/>}/>

                                <Route path="/investors/:code" exact
                                       render={(props) =>
                                           <InvestorsIssuerPage
                                               {...props}/>}/>

                                <Route path="/investment/:id/signature" exact
                                       component={IssuerSignaturePage}/>

                                <Route path="/logout" reload={prop.reload} exact
                                       render={(props) =>
                                           <Logout
                                               reload={props.reload}/>}
                                />

                                <Redirect to="/dashboard"/>
                            </Switch>
                        </div>
                    </div>

                    <Grid className='footer_backend--app' item container lg={12} xs={12}>
                        <Footer/>
                    </Grid>
                </main>
            </div>
        </Aux>
    );
};

export default IssuerDashboard;