import React from "react";

import Dwolla from "react-dwolla-iav";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const AddingBankModal = ({iavToken, open, close, onErrorStep}) => {

    const onSuccess = (data) => {

    }

    const onError = (err) => {
        onErrorStep(err)
    }

    const dwollaConfig = {
        backButton: true,
        customerToken: iavToken,
        environment: 'sandbox',
        fallbackToMicroDeposits: false,
        microDeposits: false,
        stylesheets: [],
        subscriber: () => {
        },
    }

    return (
        <Dialog
            onClose={close}
            open={open}
            fullWidth
            maxWidth={"md"}
        >
            <DialogTitle>Adding Source</DialogTitle>

            <DialogContent style={{overflow: 'hidden'}}>
                <Grid container spacing={3}>
                    <Grid item lg={12} xs={12}>
                        <Dwolla
                            onSuccess={onSuccess}
                            onError={onError}
                            dwollaConfig={dwollaConfig}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button
                    type="button"
                    data-dismiss="modal"
                    onClick={close}>
                    close
                </Button>
            </DialogActions>
        </Dialog>

    )
}

export default AddingBankModal