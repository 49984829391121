import React, {Component} from 'react';
import Aux from '../../hoc/AuxWrapper';
import axios from '../../custom-axios/app';
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import {NavLink} from "react-router-dom";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LeakAddIcon from '@material-ui/icons/LeakAdd';
import FlagIcon from '@material-ui/icons/Flag';


const CartComponent = styled.div`
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),
     0px 1px 1px 0px rgba(0,0,0,0.14),
      0px 1px 3px 0px rgba(0,0,0,0.12);
    padding: 30px 30px 30px 30px;
    position: relative;
    background: white;
    max-width: 480px;
    min-width: 480px;
`;

const MainWrap = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const WrapBoxLogin = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0 35px 0;
`;

const WrapMainElement = styled.div`
  display: flex;
  flex-direction: column;
`;

const BoxBLock = styled.div`
    width: 48%;
    border: 1px solid #eee;
    padding: 25px;
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 15px;
    
    &:hover {
        border: 1px solid #032F66;
    }
    
`;

class ChooseNextAction extends Component {

    state = {
        user: {}
    };

    componentDidMount() {
        this.fetchUser();
    }

    fetchUser = () => {
        axios.get('/users/current-user').then(
            res => {
                localStorage.setItem('userInfo', JSON.stringify(res.data));
                this.setState({
                    user: res.data
                })
            }
        )
    }

    selectNextAction(action) {
        if (action === 'finish') {
            this.props.history.push('/choose-user-type');
        } else {
            window.location.href = process.env.REACT_APP_OFFERING_GATEWAY + '/offerings/' + this.state.user.userGroup.id
        }
    }

    render() {
        return (
            <Aux>
                {/*<div className="signup-overlay-1"></div>*/}

                <MainWrap>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={3}>

                        <WrapMainElement>
                            <WrapBoxLogin>
                                <NavLink to="index.php">
                                    <img
                                        src={require('../../assets/images/logo-blue.png')}
                                        width="140"
                                        height="51"
                                        alt=""/>
                                </NavLink>
                            </WrapBoxLogin>

                            <CartComponent>
                                <Box pb={3}>
                                    <Typography
                                        variant="h6">
                                        Welcome {this.state.user ? this.state.user.firstName + " " + this.state.user.lastName : ''}!
                                        <p/>
                                        You can preview offerings or you can finish registration.
                                    </Typography>
                                </Box>

                                <Box display="flex">
                                    <BoxBLock
                                        onClick={() => this.selectNextAction('preview')}>
                                        <Box pb="15px">
                                            <LeakAddIcon style={{fontSize: '45px', color: '#032F66'}}/>
                                        </Box>

                                        <Typography variant="subtitle2">Preview Investments</Typography>
                                    </BoxBLock>

                                    <BoxBLock onClick={() => this.selectNextAction('finish')}>
                                        <Box pb="15px">
                                            <FlagIcon style={{fontSize: '45px', color: '#032F66'}}/>
                                        </Box>
                                        <Typography variant="subtitle2">Finish registration </Typography>
                                    </BoxBLock>
                                </Box>
                            </CartComponent>
                        </WrapMainElement>
                    </Grid>
                </MainWrap>
            </Aux>
        );
    }
}


export default ChooseNextAction;
