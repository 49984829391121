import React, {useEffect, useState} from 'react';

import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";

const InputEmail = (props) => {
    const [value, setValue] = useState('')
    const [errorWorkEmailValid, setErrorWorkEmailValid] = useState(false)

    useEffect(() => {
        if (props.defaultValue && props.defaultValue.chosenValue) {
            setValue(props.defaultValue.chosenValue)
        }
    }, [props.defaultValue])

    const handleOnChange = (e) => {
        const email = e.target.value;
        const error = !(validEmail(email) || email === '');

        setErrorWorkEmailValid(error)
        setValue(e.target.value)
    }

    const validEmail = (email) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return pattern.test(email);
    }

    const handleOnBlur = () => {
        if (!errorWorkEmailValid) {
            props.onBlurHandler({
                attributeCode: props.attributeCode,
                value: value
            });
        }
    }

    return (
        <FormControl variant="outlined"
                     fullWidth>
            <TextField variant='outlined'
                       value={value}
                       error={errorWorkEmailValid}
                       helperText={errorWorkEmailValid ? 'Email is not valid' : ''}
                       autoComplete={'0'}
                       type={props.type ? props.type : 'text'}
                       onBlur={handleOnBlur}
                       onChange={handleOnChange}>
            </TextField>
        </FormControl>
    )
};

export default InputEmail;