import * as actionTypes from '../actions/actionTypes';

const initialState = {
    accountVerification: false,
    error: null
};

const signUpSuccess = (state, action) => {
    localStorage.setItem('accountVerification', 'true');
    return {
        ...state,
        accountVerification: true,
        error: null
    }
};

const signUpError = (state, action) => {
    localStorage.removeItem('accountVerification');
    return {
        accountVerification: false,
        error: null
    }
};


const signUp = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SIGN_UP_SUCCESS:
            return signUpSuccess(state, action);
        case actionTypes.SIGN_UP_ERROR:
            return signUpError(state, action);
        default:
            return state;
    }
};

export default signUp;