import React from 'react';

import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import {ThemeProvider} from '@material-ui/styles';
import {store} from "./store/store";
import {theme} from "./theme/theme";
import {ToastContainer} from "react-toastify";
import CssBaseline from "@material-ui/core/CssBaseline";
import App from "./App";

const rootElement = document.getElementById('root');

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <CssBaseline/>
            <ToastContainer autoClose={3000}/>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>
    </ThemeProvider>,
    rootElement
)

// ReactDOM.render(
//     <ThemeProvider theme={theme}>
//         <Suspense fallback={<Loader/>}>
//             <Provider store={store}>
//                 <ToastContainer autoClose={3000}/>
//
//                 <BrowserRouter>
//                     <ConnectedRouter history={BrowserHistory}>
//                         <UserProvider>
//                             <BasicContainer/>
//                         </UserProvider>
//                     </ConnectedRouter>
//                 </BrowserRouter>
//             </Provider>
//         </Suspense>
//     </ThemeProvider>,
//     rootElement
// )