import React, {Component} from 'react';
import DocumentService from "../../../repository/DocumentService";
import IssuerService from "../../../repository/IssuerService";
import PdfPreviewModal from "../../dashboard/modals/PdfPreviewModal";
import ImagePreviewModal from "../../dashboard/modals/ImagePreviewModal";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Box from "@material-ui/core/Box";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import Dropzone from "react-dropzone";
import PublishIcon from "@material-ui/icons/Publish";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Moment from "react-moment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TableBody from "@material-ui/core/TableBody";

class IssuerDocumentationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'documents': [],
            showPreview: false,
            showPreviewImage: false,
            fileToPreview: null,
            documentsToSave: [],
            loader: true
        };
    }

    componentDidMount() {
        this.fetchOtherDocuments();
        window.scrollTo(0, 0)
    }

    pushIssuerChange = (request) => {
        return IssuerService.saveAttributeData(request);

    };

    onSaveDocuments = (e) => {
        let files = [...e.target.files];
        this.saveDocuments('other', files, 'OTHER');
    }

    fetchOtherDocuments = () => {
        this.setState({
            loader: true
        })
        IssuerService.fetchByAttributeCode('OTHER').then(data => {
            this.setState({
                documents: data.data,
                loader: false
            });
        })
    }

    submitDocuments = () => {
        this.state.documentsToSave.forEach(item => {
            this.pushIssuerChange({
                value: item.filename,
                attributeCode: 'OTHER',
                documentId: item.id
            }).then(response => {
                this.fetchOtherDocuments();
            });
        });
        this.setState({
            isLoading: false,
            documentsToSave: []
        });
    }

    saveDocument = (groupId, files, attrCode) => {
        this.setState({isLoading: true});
        DocumentService.saveDocumentMultiple(groupId, files)
            .then(response => {
                let files = response.data.map(file => {
                    return {
                        'id': file.id,
                        'filename': file.filename,
                        'originalFilename': file.originalFilename
                    }
                });
                this.setState(
                    {
                        'documentsToSave':
                            [...this.state.documentsToSave,
                                ...files
                            ]
                    });
                // this.setState({isLoading: false});
            }).catch(error => {
            this.setState({isLoading: false});
        });
    };


    onSubmit = (e) => {
        e.preventDefault();
        IssuerService.completeProfile().then(data => {
            this.props.setCurrentRegistrationStep(data.data.registrationStatus);
            this.navigate(e, 'STEP_APPROVAL', '/issuer-approval')
        })
    }

    navigate(e, step, path) {
        e.preventDefault();

        this.props.setRegistrationStatus(step)
            .then(res => {
                localStorage.setItem('userInfo', JSON.stringify(res.data));
                this.props.history.push(path);
            }).catch(err => {
        });
    }

    onFilePreview = (item) => {
        let fileToPreview;

        if (item.chosenValue !== undefined) {
            fileToPreview = item.chosenValue.split('.');
        } else fileToPreview = item.filename.split('.');
        if (fileToPreview[fileToPreview.length - 1] === 'pdf') {
            if (item.chosenValue !== undefined) {
                this.setState({
                    fileToPreview: item.chosenValue,
                    showPreview: true
                });
            } else {

                this.setState({
                    fileToPreview: item.filename,
                    showPreview: true
                });
            }
        } else {
            if (item.chosenValue !== undefined) {
                this.setState({
                    fileToPreview: item.chosenValue,
                    showPreviewImage: true
                });
            } else {
                this.setState({
                    fileToPreview: item.filename,
                    showPreviewImage: true
                });
            }
        }

    }

    remove(document) {
        let documents = [...this.state.documentsToSave];
        let newDocuments = documents.filter(d => {
            return (d.id !== document.id);
        });
        this.setState({documentsToSave: newDocuments});
    }


    handleClose = () => {
        this.setState({
            fileToPreview: null,
            showPreview: false
        })
    }

    handleCloseImage = () => {
        this.setState({
            showPreviewImage: false
        })
    }

    startLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading
        })
    }


    render() {
        return (
            <>
                {this.state.showPreview && (
                    <PdfPreviewModal
                        className="modal"
                        show={this.state.showPreview}
                        file={this.state.fileToPreview}
                        close={this.handleClose}
                    />
                )}

                {this.state.showPreviewImage && (
                    <ImagePreviewModal
                        className="modal"
                        show={this.state.showPreviewImage}
                        file={this.state.fileToPreview}
                        close={this.handleCloseImage}
                    />
                )}

                <Container maxWidth='lg' style={{margin: '48px auto'}}>
                    <Grid container spacing={3}>
                        <Grid item lg={6} xs={12}>
                            <Card raised>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item lg={12} xs={12}>
                                            <Typography variant='h6'>
                                                Documentation
                                            </Typography>

                                            <Divider/>
                                        </Grid>
                                        <Grid item lg={12} xs={12}>
                                            <Typography>
                                                Please submit any documentation relevant to the raise. List below is
                                                what our
                                                team will ask for if they are applicable, please provide now if
                                                possible:
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={12} xs={12}
                                              style={{padding: 10}}>
                                            <Typography>
                                                <li>Names and photo IDs of all Officers and Advisors</li>
                                                <li>Articles of Incorporation</li>
                                                <li>Operating Agreement</li>
                                                <li>Investor Deck</li>
                                                <li>Executive Summary</li>
                                                <li>SAFE(s) & SAFT(s)</li>
                                                <li>OM/PPM</li>
                                                <li>Whitepaper</li>
                                                <li>Advisor Agreements</li>
                                                <li>NDA</li>
                                                <li>All Sales & Marketing Material</li>
                                                <li>Financials, including Tax Returns, Projections & Expenses,
                                                    Audited
                                                    Financials
                                                </li>
                                                <li>Org Chart</li>
                                                <li>Intellectual Property (patents), Technology, Licensing or any
                                                    Technology
                                                    Agreements
                                                </li>

                                                <li>SIgned AmerX Investment Banking Agreement</li>
                                                <li>Company Talking Points</li>
                                                <li>Escrow Agreements or Digital Wallets owned by the Issuer</li>
                                                <li>Material Contracts such as Lease Agreements, Vendor Agreements,
                                                    Expense
                                                    Sharing
                                                    Agreements
                                                </li>
                                                <li>Stock Option Agreements or Previous Investor Sales</li>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item lg={6} xs={12}>
                            <Card raised>
                                <CardContent>


                                    <Box mb="20px">
                                        <Typography variant='h6'>Uploaded documents</Typography>
                                        <Divider/>
                                    </Box>

                                    <Box className="upload__block--title"
                                         onClick={this.startLoading}>
                                        {this.state.isLoading ? <RemoveIcon/> : <AddIcon/>}

                                        <Box ml="8px" component="span">Add document</Box>
                                    </Box>

                                    <Dropzone multiple={true}
                                              accept={'application/pdf,image/png,image/jpeg'}
                                              onDrop={acceptedFiles => this.saveDocument('other', acceptedFiles, 'OTHER')}>
                                        {({getRootProps, getInputProps}) => (
                                            <Box
                                                mt="15px"
                                                mb="15px">

                                                <Box
                                                    mb="15px"
                                                    p="25px"
                                                    className={this.state.isLoading ? 'upload__view' : 'upload__hidden'}
                                                    style={{background: '#FCFCFC'}}>
                                                    <Box>
                                                        <Box>
                                                            <Typography
                                                                variant="subtitle2"
                                                                gutterBottom>Only JPG, PNG or PDF files. File
                                                                must be less than 20 Mb <span
                                                                    className="required__circle">*</span>
                                                            </Typography>
                                                        </Box>
                                                        {
                                                            this.state.documentsToSave.map(document => {
                                                                    return (
                                                                        <Box
                                                                            pt="20px"
                                                                            pb="5px"
                                                                            style={{borderColor: '#DFDFDF'}}
                                                                            borderBottom={1}
                                                                            display="flex"
                                                                            key={document.id}>

                                                                            <Box flexGrow={1}>
                                                                                {document.originalFilename}
                                                                            </Box>
                                                                            <Box>
                                                                                <Button
                                                                                    color="primary"
                                                                                    name={'file-01'}
                                                                                    type={"button"}
                                                                                    onClick={() => this.onFilePreview(document)}>
                                                                                    Preview
                                                                                </Button>

                                                                                <Button
                                                                                    style={{color: '#B71C1C'}}
                                                                                    name={'file-01'}
                                                                                    type={"button"}
                                                                                    onClick={(e) => this.remove(document)}>
                                                                                    Delete
                                                                                </Button>
                                                                            </Box>
                                                                        </Box>
                                                                    )
                                                                }
                                                            )
                                                        }
                                                        {this.state.documentsToSave.length !== 0 ?
                                                            <Box style={{
                                                                display: 'flex',
                                                                justifyContent: 'flex-end',
                                                                paddingTop: 10
                                                            }}>

                                                                <Button variant='contained'
                                                                        color='primary'
                                                                        onClick={this.submitDocuments}>
                                                                    Submit Documents
                                                                </Button>

                                                            </Box> : null}
                                                    </Box>
                                                    <Box mt="25px" display="flex">

                                                        <Button onClick={this.startLoading}>
                                                            Cancel
                                                        </Button>

                                                        <Box ml="20px">
                                                            <input
                                                                id={'file-01'}
                                                                type={"file"} {...getInputProps()}
                                                                className="custom-file-input"
                                                                accept={'application/pdf,image/png,image/jpeg'}
                                                                required>
                                                            </input>

                                                            <label htmlFor={'file-01'}>
                                                                <Button
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    endIcon={<PublishIcon/>}
                                                                    component="span">Upload document
                                                                </Button>
                                                            </label>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )}
                                    </Dropzone>

                                    {this.state.loader ?
                                        <Grid item
                                              lg={12}
                                              xs={12}
                                              style={{display: "flex", justifyContent: "center"}}>
                                            <CircularProgress/>
                                        </Grid> :
                                        this.state.documents.length === 0 ?
                                            <Grid item lg={12} xs={12}>
                                                <Typography>No Documents</Typography></Grid> :
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Document Name</TableCell>
                                                            <TableCell>Date Uploaded</TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        {this.state.documents.map((document, index) => {
                                                                return (
                                                                    <TableRow key={document.documentOriginalName + index}>
                                                                        {/*<TableCell>{document.chosenValue}</TableCell>*/}
                                                                        <TableCell>{document.documentOriginalName}</TableCell>
                                                                        <TableCell>{<Moment
                                                                            format={'LLL'}>{document.dateCreated}</Moment>}</TableCell>
                                                                        <TableCell
                                                                            style={{
                                                                                display: 'flex',
                                                                                justifyContent: 'flex-end'
                                                                            }}>
                                                                            <Button
                                                                                onClick={() => this.onFilePreview(document)}
                                                                                variant='contained'
                                                                                color='primary'
                                                                                size='small'
                                                                                startIcon={<VisibilityIcon/>}>
                                                                                Preview
                                                                            </Button>
                                                                        </TableCell>
                                                                    </TableRow>)
                                                            }
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                    }
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item lg={12} xs={12} style={{textAlign: 'end'}}>
                            <Button color="primary"
                                    startIcon={<ArrowBackIcon/>}
                                    onClick={(e, step, path) => this.navigate(e, 'STEP_ISSUER_PROFILE', '/issuer-profile')}
                                    variant="contained">
                                Previous page
                            </Button>

                            <Button color="primary"
                                    style={{marginLeft: 10}}
                                    endIcon={<ArrowForwardIcon/>}
                                    variant="contained"
                                    onClick={this.onSubmit}>
                                Complete initial profile
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </>
        )
    }
}

export default IssuerDocumentationPage;