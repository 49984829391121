import React, {Component} from 'react';
import DocumentService from "../../repository/DocumentService";
import FileUploadComponent from "./FileUploadComponent";

class ImageFileUploadComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            imageUrl: {chosenValue: this.props.currentValue}
        }
    }

    onFileUpload = (groupId, file, attrCode) => {
        this.startLoadingProgress(true);
        DocumentService.saveDocument(groupId, file)
            .then(response => {
                this.setState({
                    ...this.state,
                    imageUrl: {chosenValue: response.data.filename}
                })
                this.props.onChange(process.env.REACT_APP_DMS_GATEWAY + '/v1/api/document/download/' + this.state.imageUrl.chosenValue);
                this.startLoadingProgress(false);
            }).catch(error => {
            this.startLoadingProgress(false);
        });
    }

    startLoadingProgress = (val) => {
        this.setState({
            isLoading: val
        })
    }


    onDocumentDelete = (e) => {
        this.setState({
                ...this.state,
                imageUrl: {}
            }
        )

    }

    render() {
        let output = '';
        if (this.state.isLoading) {
            output = (
                <span>Uploading...</span>
            )
        } else {
            output = (
                <FileUploadComponent attributeCode={this.props.attributeCode}
                                     currentValue={this.state.imageUrl.chosenValue}
                                     accept={this.props.accept}
                                     documentGroupCode={this.props.documentGroupCode}
                                     onFileSelected={this.onFileUpload}
                                     className={this.props.className}
                                     fileNumber={this.props.fileNumber}
                                     onDocumentDelete={this.onDocumentDelete}
                                     wrapperClass={this.props.wrapperClass}/>
            )
        }
        return (
            output
        )

    }
}

export default ImageFileUploadComponent;