import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import SignUp from "../registration/SignUp";
import Login from "../Login/Login";
import ActivatePage from "../ActivatePage";
import Verification from "../../components/registration/Verification";
import ResetPassword from "../../containers/ResetPassword";
import PasswordResetValidation from "../../containers/PasswordResetValidation";
import ChooseUserType from "../registration/ChooseUserType";
import Logout from "../../components/Logout";
import IssuerDirectPage from "../../pages/registration/issuers/IssuerDirectPage";
import NodleWaitListPage from "../../pages/registration/NodleWaitListPage";

const defaultRoutes = (props) => {
    return (
        <Switch>
            <Route path="/login" exact component={Login}/>
            <Route path="/logout" exact component={Logout}/>
            <Route path="/sign-up" exact component={SignUp}/>
            <Route path="/verification" exact component={Verification}/>
            <Route path="/activate/:email/:code" component={ActivatePage}/>
            <Route path="/reset-password" component={ResetPassword}/>
            <Route path="/forgotten_password" component={PasswordResetValidation}/>
            <Route path="/choose-user-type" exact component={ChooseUserType}/>
            <Route path="/for-issuers" exact component={IssuerDirectPage}/>
            <Route path="/nodlewaitlist" exact component={NodleWaitListPage}/>
            <Redirect to="/login"/>
        </Switch>
    )
}

export default defaultRoutes;