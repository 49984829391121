import React, {Component} from 'react';
import Aux from '../../hoc/AuxWrapper';
import {NavLink} from 'react-router-dom';
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

const items = [
    {
        label: 'Dashboard',
        icon: <DashboardIcon/>,
        imgSrc: 'icon-dashboard.png',
        path: '/dashboard'
    },
    {
        label: 'Profile',
        icon: <AccountCircleIcon/>,
        imgSrc: '../../assets/images/dashboard_icons/icon-point-contact.png',
        path: "/profile"
    },
    // {
    //     label: 'Documents',
    //     icon: <DescriptionIcon/>,
    //     imgSrc: '../../assets/images/dashboard_icons/icon-profile.png',
    //     path: "/documentation"
    // },
    {
        label: 'Offerings',
        icon: <LocalOfferIcon/>,
        imgSrc: '../../assets/images/dashboard_icons/icon-documentation.png',
        path: "/offerings"
    },

    {
        label: 'Investors',
        icon: <PeopleAltIcon/>,
        imgSrc: '../../assets/images/dashboard_icons/icon-inbox.png',
        path: "/investors"
    },
    {
        label: 'Support',
        icon: <MailIcon/>,
        imgSrc: 'icon-audit.png',
        path: "/inbox"
    },
];


class SidebarIssuerNew extends Component {

    constructor(props) {
        super(props);

        this.state = {
            buttonCollapsedFlag: true,
            buttonAreaExpandedFlag: false,
            navbarCollapsedFlag: true,
            navbarAreaExpandedFlag: false,
            pathOfActiveSection: ''
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.path !== undefined) {
            return {
                pathOfActiveSection: nextProps.path
            }
        }
    };

    toggleSidebar = () => {
        this.setState((prevState, props) => {
            return {
                buttonCollapsedFlag: !prevState.buttonCollapsedFlag,
                buttonAreaExpandedFlag: !prevState.buttonAreaExpandedFlag,
                navbarCollapsedFlag: !prevState.navbarCollapsedFlag,
                navbarAreaExpandedFlag: !prevState.navbarAreaExpandedFlag
            };
        }, () => {
            let newButtonClass = '';
            let newButtonAreaExpanded = '';
            let newNavbarClass = '';
            let newNavbarAreaExpanded = '';

            if (this.state.buttonCollapsedFlag) {
                newButtonClass = 'navbar-toggle collapsed';
            } else {
                newButtonClass = 'navbar-toggle';
            }

            if (this.state.buttonAreaExpanded) {
                newButtonAreaExpanded = 'true';
            } else {
                newButtonAreaExpanded = 'false';
            }

            if (this.state.navbarCollapsedFlag) {
                newNavbarClass = 'collapse navbar-collapse';
            } else {
                newNavbarClass = 'navbar-collapse';
            }

            if (this.state.navbarAreaExpandedFlag) {
                newNavbarAreaExpanded = 'true';
            } else {
                newNavbarAreaExpanded = 'false';
            }

            document.getElementById('navbar').setAttribute('class', newNavbarClass);
            document.getElementById('sidebar-toggle-button').setAttribute('class', newButtonClass);
            document.getElementById('navbar').setAttribute('aria-expanded', newNavbarAreaExpanded);
            document.getElementById('sidebar-toggle-button').setAttribute('aria-expanded', newButtonAreaExpanded);

        });

    };

    render() {
        return (
            <Aux>
                <Drawer
                    variant="permanent"
                    classes={this.props.classesDrawer}
                    className={this.props.classDrawer}>

                    <div className={this.props.classToolbar}>

                        <div>
                            <img alt={''}
                                 style={{display: 'block', width: '100%'}}
                                 src={require('../../assets/images/logo-blue.png')}/>
                        </div>

                        <IconButton onClick={this.props.drawerClose}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </div>

                    <Divider/>
                    {/*<img src={require('../assets/images/logo.png')} id="logo_amerx"/>*/}

                    <Box pt="70px">
                        <List>
                            {
                                items.map(item => {
                                    return (
                                        <ListItem
                                            style={{
                                                borderBottom: '1px solid #ddd',
                                                paddingTop: '16px',
                                                paddingBottom: '16px',
                                                textDecoration: 'none'
                                            }}
                                            className={this.state.pathOfActiveSection === item.path ? 'activeSection__sidebar' : 'active__sidebar'}
                                            key={item.path} to={item.path} component={NavLink}>

                                            <ListItemIcon
                                                style={{color: '#263238'}}>{item.label === 'Support' && this.props.messages.length !== 0 ?
                                                <Badge badgeContent={this.props.messages.length}
                                                       color="primary">{item.icon}</Badge> : item.icon}</ListItemIcon>
                                            <ListItemText style={{color: '#263238', textDecoration: 'none'}}
                                                          primary={item.label}/>
                                        </ListItem>
                                    );
                                })
                            }
                        </List>
                    </Box>
                </Drawer>
            </Aux>
        );
    }
};

export default SidebarIssuerNew;