import React, {Component} from 'react';
import axios from '../custom-axios/app';
import queryString from 'query-string'

class PasswordResetValidation extends Component {

    componentDidMount() {
        let parsed = queryString.parse(this.props.location.search);
        const email = parsed['email'];
        const code = parsed['code'];

        axios.post('users/reset-password-verification', {
            email: email,
            passwordResetString: code
        }).then(response => {
            localStorage.setItem('passwordReset', 'true');
            localStorage.setItem('passwordResetString', code);
            localStorage.setItem('email', email);
            this.props.history.push('/choose-password')
        }).catch(error => {
            alert('Error while processing your password change request. Either your email or code are invalid');
            this.props.history.push('/');
        });
    }

    render() {
        return (
            <h1> Loading ... </h1>
        );
    }
}

export default PasswordResetValidation;