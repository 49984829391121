import React, {useEffect, useState} from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const AlertDialog = (props) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    const handleYesClose = () => {
        props.closeYesHandler()
    };

    const handleNoClose = () => {
        props.closeNoHandler()
    };

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={handleNoClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleNoClose}
                        color="primary">
                    No
                </Button>
                <Button onClick={handleYesClose}
                        color="primary"
                        autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AlertDialog