import React, {Component} from "react";

import InvestorService from "../repository/InvestorService";
import IndividualRegistrationRoutes from '../containers/routing/IndividualRegistration';
import InstitutionRegistrationRoutes from '../containers/routing/InstitutionRegistration';
import Aux from '../hoc/AuxWrapper';
import InvestorAttributeService from '../repository/InvestorAttributeService';
import DocumentService from '../repository/DocumentService';
import axios from "../custom-axios/app";
import Container from "@material-ui/core/Container";


class RegistrationProcess extends Component {

    userInfo = JSON.parse(localStorage.getItem('userInfo'));

    constructor(props) {
        super(props);

        this.state = {
            investorData: {},
            investorAttributeOptions: [],
            currentStep: '',
        };
    }

    componentDidMount() {
        document.body.setAttribute('class', 'stReg');
        this.fetchInvestorStatus();
        this.createDummyInvestor();
    }

    createDummyInvestor() {
        axios.post('/rest/investors/dummy', {
            stateId: '3',
            countryId: '3',
            mailStateId: '3',
            mailCountryId: '3'

        }).then(r => {
        });
    }

    pushInvestorChange = (request) => {
        InvestorService.saveAttributeData(request)
            .then((data) => {
                let investorData = this.state.investorData;
                investorData[data.data.attributeCode] = data.data;
                this.setState({
                    ...this.state,
                    investorData: investorData
                }, () => this.fetchInvestorStatus())
            });
    };

    pushInvestorChangeForDocument = (request) => {
        InvestorService.saveAttributeData(request)
            .then((data) => {
                let investorData = this.state.investorData;
                let investorDocuments = [];
                if (investorData[data.data.attributeCode] && Array.isArray(investorData[data.data.attributeCode])) {
                    investorData[data.data.attributeCode].forEach(document => {
                        investorDocuments.push(document);
                    })
                }
                investorDocuments.push(data.data);
                investorData[data.data.attributeCode] = investorDocuments;
                this.setState({
                    ...this.state,
                    investorData: investorData
                }, () => {
                    this.fetchInvestorStatus();
                });
            })

    };

    deleteInvestorData = (request) => {
        InvestorService.deleteAttributeData(request)
            .then(response => {
                let investorData = this.state.investorData;
                investorData[request.attributeCode] = {};
                this.setState({
                    ...this.state,
                    investorData: investorData
                });
            }).catch(error => {
            console.log(error);
        });
    };

    deleteDocument = (request) => {
        InvestorService.deleteAttributeDataByDocument(request)
            .then(response => {
                let investorData = this.state.investorData;
                let investorDocuments = investorData[request.attributeCode];
                let investorDoc = [];

                investorDocuments.forEach(doc => {
                    if (doc.chosenValue === request.document.chosenValue) {
                        investorDoc = investorDocuments.filter(n => n.chosenValue !== request.document.chosenValue)
                    }
                });
                investorData[request.attributeCode] = investorDoc;
                this.setState({
                    ...this.state,
                    investorData: investorData
                }, () => {
                    this.fetchInvestorStatus();
                });
            }).catch(error => {
            console.log(error);
        });
    };

    deleteMultiInvestorData = (request) => {
        InvestorService.deleteMultiAttributeData(request)
            .then(response => {
                this.fetchInvestorStatus();
            }).catch(error => {
            console.log(error);
        });
    };

    onDocumentDelete = (groupId, attrCode, document) => {
        if (document) {
            this.deleteDocument({
                attributeCode: attrCode,
                document: document,
                uuid: document.chosenValue.split(".")[0]
            })
        } else {
            this.deleteInvestorData({
                attributeCode: attrCode
            })
        }
    };

    saveDocument = (groupId, file, attrCode) => {
        DocumentService.saveDocument(groupId, file)
            .then(response => {
                this.pushInvestorChangeForDocument({
                    value: response.data.filename,
                    attributeCode: attrCode,
                    documentId: response.data.id
                });
            }).catch(error => {
            console.log(error);
        });
    };

    fetchInvestorStatus = () => {
        InvestorService.fetchInvestorStatus()
            .then(response => {
                this.setState({
                    investorData: response.data,
                    currentStep: this.userInfo.registrationStatus,
                });
            })
            .catch(error => {
                console.log(error);
            })
    };

    updateInvestorInfo = (request) => {
        InvestorService.updateInvestorInfo(request)
            .then(response => {
                this.setState({
                    investor: response.data
                });
            }).catch(error => {
            console.log(error);
        });
    };

    fetchAttributeOptions = (code) => {
        return InvestorAttributeService.getAttributeOptions(code);
    };

    setCurrentRegistrationStep(step) {
        this.setState({
            currentStep: step
        });
    }

    // changeStep(e, step, path) {
    //     e.preventDefault();
    //     this.props.setRegistrationStatus(step)
    //         .then(res => {
    //             localStorage.setItem('userInfo', JSON.stringify(res.data));
    //             this.props.history.push(path);
    //         }).catch(err => {
    //     });
    // }

    /*
        fetchInvestorAttributeOptions = () => {
            InvestorService.fetchInvestorAttributeOptions()
                .then(response => {
                    this.setState({
                        investorAttributeOptions: response.data
                    });
                })
                .catch(error => {
                    console.log(error);
                })
        };
    */

    render() {
        let routes = (
            <IndividualRegistrationRoutes
                investorData={this.state.investorData}
                onDocumentDelete={this.onDocumentDelete}
                investorAttributeOptions={this.state.investorAttributeOptions}
                fetchAttributeOptions={this.fetchAttributeOptions}
                pushInvestorChange={this.pushInvestorChange}
                updateInvestorInfo={this.updateInvestorInfo}
                deleteInvestorData={this.deleteInvestorData}
                deleteMultiInvestorData={this.deleteMultiInvestorData}
                saveDocument={this.saveDocument}
                validate={this.props.validate}
                fetchInvestorStatusByStep={(step) => InvestorService.fetchInvestorStatusByStep(step)}
                setRegistrationStatus={this.props.setRegistrationStatus}
                setCurrentRegistrationStep={(step) => this.setCurrentRegistrationStep(step)}
                currentStep={this.state.currentStep}
                nextStep={this.props.nextStep}
                offer={this.props.offer}
            />
        );

        if (this.userInfo.userGroup.id === 'USER_INVESTOR_COMPANY') {
            routes = (
                <InstitutionRegistrationRoutes
                    userGroup={this.props.userGroup}
                    investorData={this.state.investorData}
                    investorAttributeOptions={this.state.investorAttributeOptions}
                    fetchAttributeOptions={this.fetchAttributeOptions}
                    pushInvestorChange={this.pushInvestorChange}
                    updateInvestorInfo={this.updateInvestorInfo}
                    onDocumentDelete={this.onDocumentDelete}
                    deleteInvestorData={this.deleteInvestorData}
                    saveDocument={this.saveDocument}
                    validate={this.props.validate}
                    fetchInvestorStatusByStep={(step) => InvestorService.fetchInvestorStatusByStep(step)}
                    setRegistrationStatus={this.props.setRegistrationStatus}
                    setCurrentRegistrationStep={(step) => this.props.setCurrentRegistrationStep(step)}
                    currentStep={this.state.currentStep}
                    nextStep={this.props.nextStep}
                    offer={this.props.offer}
                />
            );
        }
        return (
            <Container maxWidth="lg">
                <Aux>
                    {routes}
                </Aux>
            </Container>
        );
    }

}

export default RegistrationProcess;