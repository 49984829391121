import React, {useState} from "react";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import SelectField from "../input/new/SelectField";
import CurrencyFormatField from "../input/new/CurrencyFormatField";
import Button from "@material-ui/core/Button";
import {toast} from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

const PaymentForm = ({fundingSources, investment, onSubmit, dwollaErrors}) => {

    const [fundingSource, setFundingSource] = useState('')
    const [amount, setAmount] = useState('')
    const [errors, setErrors] = useState([])

    const submit = () => {
        if (handleValidation()) {
            onSubmit(fundingSource, amount)
        } else {
            toast.error('Please fill all the required fields!')
        }
    }

    const handleOnChange = (name, value) => {
        switch (name) {
            case 'amount':
                setAmount(value)
                setErrors([])
                break
            case 'fundingSource':
                setFundingSource(value)
                setErrors([])
                break
            default:
        }
    }

    const handleValidation = () => {
        let errors = {}
        let valid = true

        if (!fundingSource) {
            valid = false
            errors.fundingSource = 'Cannot be empty'
        }

        if (!amount) {
            valid = false
            errors.amount = 'Cannot be empty'
        } else if (amount > investment.dealRaiseTarget) {
            valid = false
            errors.amount = 'Value is greater than maximum'
        }

        setErrors(errors)

        return valid
    }

    return (
        <Grid container spacing={3}
              justify={"center"}>
            <Grid item lg={12} xs={12}>
                <Card raised>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item lg={12} xs={12}>
                                <Typography variant='h6'>
                                    Enter your investment amount
                                </Typography>

                                <Divider/>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item lg={6} xs={6}>
                                        <SelectField
                                            title={"Funding source"}
                                            name={'fundingSource'}
                                            required
                                            value={fundingSource}
                                            options={fundingSources}
                                            onChange={(value, name) => handleOnChange(name, value)}
                                            hasError={!!errors.fundingSource}
                                            errorMessage={errors.fundingSource}
                                        />
                                    </Grid>

                                    <Grid item lg={6} xs={6}>
                                        <CurrencyFormatField
                                            title={"Amount"}
                                            name={"amount"}
                                            required
                                            prefix={"$ "}
                                            value={amount}
                                            onChange={(value, name) => handleOnChange(name, value)}
                                            helperText={'Max value: ' + investment.dealRaiseTarget}
                                            hasError={!!errors.amount}
                                            errorMessage={errors.amount}
                                        />
                                    </Grid>

                                    <FormControl variant="outlined"
                                                 fullWidth
                                                 error={!!dwollaErrors.message}
                                    >
                                        <FormHelperText>
                                            <Typography align={"center"}>
                                                {!!dwollaErrors.message && dwollaErrors.message}
                                            </Typography>
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                                <Grid container spacing={3}
                                      style={{display: 'flex', justifyContent: 'center'}}>
                                    <Grid item lg={12} xs={12}>
                                        <LinearProgress
                                            variant="determinate"/>
                                    </Grid>

                                    <Grid item lg={8} xs={12}>
                                        <Typography
                                            variant="body2"
                                            gutterBottom align={"center"}
                                        >
                                            12345, LLC, an affiliate of Amerx, and a registered broker-dealer and member
                                            FINRA/SIPC, has
                                            been appointed as a placement agent for this offering and will receive
                                            transaction
                                            fees from the issuer of up to 7.00% based on the value of the equity and/or
                                            debt
                                            securities placed for the Issuer.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} lg={12}
                                  style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <Button style={{margin: 5}}
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}>
                                    Make Payment
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default PaymentForm