import React from "react";

import {Typography} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";

const SelectField = ({title, name, value, size, emptyLabel, options, required, onChange, hasError = false, errorMessage}) => {

    const handleChange = (value) => {
        onChange(value, name)
    }

    return (
        <>
            <Typography>
                {title}
                {required && <span className="required__circle"> *</span>}
            </Typography>
            <FormControl variant="outlined"
                         fullWidth
                         size={size}
                         error={hasError}
            >
                <Select
                    value={value}
                    displayEmpty
                    onChange={event => handleChange(event.target.value)}
                >
                    {emptyLabel && (
                        <MenuItem key={emptyLabel} value=''>
                            {emptyLabel}
                        </MenuItem>
                    )}

                    {options.map(option => (
                        <MenuItem key={option.id ? option.id : option}
                                  value={option.id ? option.id : option}>
                            {option.value ? option.value : option}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                    {hasError && errorMessage}
                </FormHelperText>
            </FormControl>
        </>
    )
}

export default SelectField