import React from 'react';
import NumberFormat from 'react-number-format';
import {NavLink} from 'react-router-dom'
import {AUTH_TOKEN} from '../../shared/utility';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

const DealComponent = (props) => {

    return (
        <>
            <Card
                raised
                style={{minWidth: '100%'}}>
                <CardContent>
                    <Grid container spacing={2}
                          justify='center'
                          alignItems='center'>
                        {
                            props.deal.imageUrl
                                ? <img alt="dealImg"
                                    // src={require('../../assets/images/background.jpg')}
                                       src={process.env.REACT_APP_DMS_GATEWAY + '/v1/api/document/download/' + props.deal.imageUrl
                                       + '?access_token=' + localStorage.getItem(AUTH_TOKEN) + '&embedded=true'}
                                    // src={props.deal.imageUrl + '?access_token=' + localStorage.getItem(AUTH_TOKEN)}
                                       className="img-responsive"/>
                                : <LocalOfferIcon style={{fontSize: 90, margin: 16}}/>
                        }

                        <Grid item lg={12} xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                            <Typography variant='h6'>{props.deal.name}</Typography>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <Typography variant='caption'>{props.deal.description}</Typography>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <Divider/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item lg={6} xs={6}>
                            <Typography variant="caption">Amount to be raised</Typography>
                        </Grid>
                        <Grid item lg={6} xs={6}>
                            <Typography variant="caption">
                                <NumberFormat value={props.deal.securityTokenOffering}
                                              displayType={'text'}
                                              thousandSeparator={true}
                                              prefix={'$'}/>
                            </Typography>
                        </Grid>

                        <Grid item lg={12} xs={12}>
                            <NavLink to={'/offerings/' + props.deal.id}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size='small'>
                                    Read more
                                </Button>
                            </NavLink>
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}
export default DealComponent;