import React, {useState} from 'react';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import ImageFileUploadComponent from "../../../components/input/ImageFileUploadComponent";


const OfferingComposeModal = (props) => {

    const [imageUrl,setImageUrl] = useState(0);
    const [regTypes,setRegTypes] = useState([]);
    const options = props.regTypes.map((regType) => {
        return {
            label: regType.name,
            value: regType.id,
        }
    })

    const customStyle = {width: '100%'}
    let handleFormSubmit = (e) => {
        e.preventDefault();
        props.submit({
            code: e.target.code.value,
            name: e.target.name.value,
            fullName: e.target.fullName.value,
            shortDescription: e.target.shortDescription.value,
            description: e.target.description.value,
            securityTokenOffering: e.target.securityTokenOffering.value,
            imageUrl: imageUrl,
            regTypes: regTypes
        })
        e.target.reset();
        props.close();
    }

    let close = (e) => {
        e.target.form.reset()
        props.close();
    }
    let onChange = (values) => {
        setRegTypes(values.map(o => {
            return {
                id: o.value,
                name: o.label
            }
        }));
    }

    let onImageChange = (value) => {
        setImageUrl(value);
    }


    return (
        <>
            <div id="composeMessageModal" className="modal modal-open" role="dialog" style={{
                transform: props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                display: props.show ? 'block' : 'none'
            }}>
                <div className="modal-dialog modal-lg">

                    <form onSubmit={handleFormSubmit}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Compose Offering</h4>
                            </div>
                            <div className="modal-body">

                                <div className="row">
                                    <div className="col-xs-12 col-md-6">
                                        <label className="weight400 text-upper control-label control-label-lg">
                                            Code</label>
                                        <input type="text" name={'code'} className="form-control input-lg"
                                               required={true}/>
                                    </div>
                                    <div className="col-xs-12 col-md-6">
                                        <label className="weight400 text-upper control-label control-label-lg">Short
                                            Name</label>
                                        <input type="text" name={'name'} className="form-control input-lg"
                                               required= {true}/>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-12 col-md-6 margin-top-10">
                                        <label className="weight400 text-upper control-label control-label-lg">Full
                                            Name</label>
                                        <input type="text" name={'fullName'} className="form-control input-lg"
                                               required={true}/>
                                    </div>
                                    <div className="col-xs-12 col-md-6  margin-top-10">
                                        <label className="weight400 text-upper control-label control-label-lg">Short
                                            Description</label>
                                        <textarea type="text" name={'shortDescription'} maxLength={500}
                                                  className="form-control input-xl" required={true}/>
                                    </div>

                                </div>


                                <div className="row">
                                    <div className="col-xs-12 col-md-6  margin-top-10">

                                        <label className="weight400 text-upper control-label control-label-lg">Full
                                            Description</label>
                                        <textarea type="text" name={'description'} maxLength={2000}
                                                  className="form-control input-xl" required={true}/>
                                    </div>
                                    <div className="col-xs-12 col-md-6  margin-top-10">

                                        <label className="weight400 text-upper control-label control-label-lg">Security
                                            Token Offering</label>
                                        <input type="number" name={'securityTokenOffering'}
                                               className="form-control input-lg" required={true}/>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-xs-12 col-md-6  margin-top-10">

                                        <label className="weight400 text-upper control-label control-label-xl">Register
                                            Type</label>
                                        <ReactMultiSelectCheckboxes onChange={onChange} customStyle={customStyle}
                                                                    options={options}/>

                                    </div>
                                    <div className="col-xs-12 col-md-6  margin-top-10">

                                        <label className="weight400 text-upper control-label control-label-xl">Image
                                            URL</label>
                                        <ImageFileUploadComponent attributeCode={'FINANCIAL_STATEMENT'}
                                                                  accept={'.jpg .png'}
                                                                  documentGroupCode={'offering_photo'}
                                                                  className="state-id front btn-block"
                                                                  fileNumber="file-4"
                                                                  onChange={onImageChange}
                                                                  />
                                    </div>
                                </div>




                            </div>
                            <div className="modal-footer text-center">
                                <button type="button" className="btn btn-primary btn-xl" data-dismiss="modal"
                                        onClick={close}>close
                                </button>
                                <button type="submit" className="btn btn-orange btn-xl">Send
                                </button>

                            </div>

                        </div>
                    </form>

                </div>
            </div>

        </>
    )
}

export default OfferingComposeModal;