import * as actionTypes from '../actions/actionTypes';
import {AUTH_TOKEN} from "../../shared/utility";

const initialState = {
    authenticated: false,
    error: null,
    userInfo: null
};

const authSuccess = (state, action) => {
    localStorage.setItem(AUTH_TOKEN, action.payload);
    localStorage.setItem('isAuthenticated', action.payload);
    return {
        ...state,
        authenticated: true,
        error: null
    }
};

const authError = (state, action) => {
    localStorage.removeItem(AUTH_TOKEN);
    return {
        ...state,
        authenticated: false,
        error: action.error
    }
};

const unAuthSuccess = (state, action) => {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem('isAuthenticated');
    return {
        ...state,
        authenticated: false,
        error: null
    }
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);
        case actionTypes.AUTH_ERROR:
            return authError(state, action);
        case actionTypes.UNAUTH_SUCCESS:
            return unAuthSuccess(state, action);
        default:
            return state;
    }
};

export default auth;