import React, {useEffect, useState} from 'react';

import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';
import FormHelperText from "@material-ui/core/FormHelperText";

const SelectCountry = (props) => {
    const [countryId, setCountryId] = useState('')

    useEffect(() => {
        if (props.selectedValue) {
            setCountryId(props.selectedValue)
        }
    }, [props.selectedValue])

    const handleNewValue = (_countryId, value) => {
        if (props.attributeCode === 'COUNTRY_CITIZEN' && value === 'United States') {
            props.deleteInvestorData({attributeCode: 'HAVE_PASSPORT'});
            props.deleteInvestorData({attributeCode: 'HAVE_SSN'});
        }

        if (props.attributeCode === 'COUNTRY_CITIZEN_POINT_OF_CONTACT' && value === 'United States') {
            props.deleteInvestorData({attributeCode: 'HAVE_PASSPORT_COMPANY'});
            props.deleteInvestorData({attributeCode: 'HAVE_SSN_COMPANY'});
        }

        props.onChangeHandler({
            attributeCode: props.attributeCode,
            investorAttributeOptionId: _countryId,
            value: value
        });
    }

    const handleOnChange = (e) => {
        const options = e.target.options
        let value;

        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                value = options[i].innerHTML.trim();
                break;
            }
        }

        setCountryId(e.target.value)
        handleNewValue(e.target.value, value)
    }

    return (
        <FormControl variant="outlined"
                     fullWidth
                     size={"small"}
                     error={props.hasError}
                     disabled={props.values.length === 0}>
            <Select
                native
                value={countryId}
                label={props.label}
                onChange={handleOnChange}>

                {props.values.map(value => {
                    return (
                        <option
                            key={value.id}
                            value={value.id}>

                            {value.value}
                        </option>
                    );
                })}
            </Select>

            <FormHelperText>
                {props.hasError && props.errorMessage}
            </FormHelperText>
        </FormControl>
    );
};

export default SelectCountry;