import React, {Component} from 'react';
import InputNumber from "./InputNumber";

export default class HiddenInputDouble extends Component {
    constructor(props) {
        super(props);

        this.state = {
            password: {
                chosenValue: ""
            },
            confirmPassword: "",
            errorConfirm: false
        };
    }

    componentDidMount() {
        if (this.props.defaultValue && this.props.defaultValue.chosenValue) {
            this.setState({
                password: this.props.defaultValue,
                confirmPassword: this.props.defaultValue
            })
        }
    }

    handleOnBlur = ({value}) => {
        if (value === this.state.confirmPassword.chosenValue) {
            let request = {
                attributeCode: this.props.attributeCode,
                value: value
            }

            this.props.onBlurHandler(request);

            this.setState({
                errorConfirm: false,
            })
        } else {
            this.setState({
                ...this.state,
                errorConfirm: true,
                password: {
                    chosenValue: value
                },
            })
        }
    }

    handleOnBlurConfirm = ({value}) => {
        if (this.state.password.chosenValue === value) {
            let request = {
                attributeCode: this.props.attributeCode,
                value: value
            }

            this.props.onBlurHandler(request);

            this.setState({
                errorConfirm: false
            })
        } else {
            this.setState({
                errorConfirm: true,
                confirmPassword: {
                    chosenValue: value
                },
            })
        }
    }

    render() {
        return (
            <>
                <div style={{marginBottom: '20px'}}>
                    <InputNumber
                        defaultValue={this.state.password}
                        attributeCode={this.props.attributeCode}
                        onBlurHandler={this.handleOnBlur}
                        minLength={6}
                        maxLength={this.props.maxLength}
                        hidden={true}
                        hasError={this.props.hasError}
                        errorMessage={this.props.hasError && this.props.errorMessage}
                    />
                </div>

                <InputNumber
                    defaultValue={this.state.confirmPassword}
                    attributeCode={this.props.attributeCode}
                    onBlurHandler={this.handleOnBlurConfirm}
                    minLength={6}
                    maxLength={this.props.maxLength}
                    hidden={true}
                    hasError={this.state.errorConfirm}
                    errorMessage={this.props.errorConfirm}
                />


                {/*    <TextField*/}
                {/*        fullWidth*/}
                {/*        onBlur={() => this.handleOnBlur()}*/}
                {/*        autoComplete="0"*/}
                {/*        onChange={this.onPasswordChange}*/}
                {/*        value={this.state.password}*/}
                {/*        error={this.props.hasError}*/}
                {/*        helperText={this.props.hasError && this.props.errorMessage}*/}
                {/*        inputProps={{*/}
                {/*            maxLength: this.props.maxLength*/}
                {/*        }}*/}
                {/*        type="text"*/}
                {/*        variant="outlined"*/}
                {/*        style={{marginBottom: 2}}/>*/}

                {/*    {this.state.error*/}
                {/*        ? <p style={{color: 'red', paddingTop: 10}}>*/}
                {/*            {this.props.error}*/}
                {/*        </p>*/}
                {/*        : null*/}
                {/*    }*/}
                {/*</div>*/}


                {/*<div style={{marginBottom: '20px'}}>*/}
                {/*    <TextField*/}
                {/*        fullWidth*/}
                {/*        onBlur={() => this.handleOnBlurConfirm()}*/}
                {/*        placeholder={this.props.placeholder ? this.props.placeholder : ''}*/}
                {/*        autoComplete="0"*/}
                {/*        onChange={this.onPasswordConfirmChange}*/}
                {/*        inputProps={{*/}
                {/*            maxLength: this.props.maxLength*/}
                {/*        }}*/}
                {/*        value={this.state.confirmPassword}*/}
                {/*        type="text"*/}
                {/*        variant="outlined"*/}
                {/*    />*/}
                {/*    {this.state.errorConfirm ?*/}
                {/*        <p style={{color: 'red', paddingTop: 10}}>{this.props.errorConfirm}</p> : null}*/}
                {/*</div>*/}

            </>
        );
    }
}