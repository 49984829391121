import React, {Component} from 'react';
import MiscService from "../../repository/MiscService";
import IssuerService from "../../repository/IssuerService";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

class ProfileIssuerPage extends Component {

    state = {
        updated: false,
        'STATE_PROVINCE_ID': [],
        'COUNTRY_ID': [],
        'MAILING_COUNTRY_ID': [],
        'MAILING_STATE_PROVINCE_ID': [],
        numPages: null,
        pageNumber: 1,
        'profile': {},
        'countries': [],
        'states': [],
        country: null,
        state: 'state1',
        pocFullName: '',
        phone: '',
        pocIssuerEmail: ' ',
        postalCode: '',
        streetAddress: '',
        city: '',
        revenueGeneration: '',
        capitalToDate: '',
        capitalToRaise: '',
        companyName: '',
        dealStructure: '',
        companyBusiness: '',
        companyCompetitors: '',
        registrationStatus: '',
        loader: true,
        errorPocIssuerEmailValid: false,
        forCheckPocIssuerEmail: ' '
    };

    componentDidMount() {
        this.init();
    }

    // onDocumentLoadSuccess = ({numPages}) => {
    //     this.setState({numPages});
    // }

    componentWillUpdate(nextProps, nextState) {
        if (!this.state.updated && nextProps.issuer) {
            this.setState({
                updated: true,
            }, () => this.setStateFields());
        }
        if (nextProps.issuer && nextProps.issuer && nextProps.issuer.pocIssuerEmail !== undefined
            && nextProps.issuer.pocIssuerEmail !== this.state.pocIssuerEmail
            && this.state.pocIssuerEmail === this.state.forCheckPocIssuerEmail && this.state.pocIssuerEmail !== '') {

            this.setState({
                pocIssuerEmail: nextProps.issuer.pocIssuerEmail,
                loader: false
            })
        }

    }

    init() {
        this.props.fetchAttributeOptions('STATE_PROVINCE_ID').then((data) => {
            this.setState({
                ...this.state,
                'STATE_PROVINCE_ID': data.data,
            });
        });

        this.props.fetchAttributeOptions('COUNTRY_ID').then((data) => {
            this.setState({
                ...this.state,
                'COUNTRY_ID': data.data
            });
        });

        this.props.fetchAttributeOptions('MAILING_COUNTRY_ID').then((data) => {
            this.setState({
                ...this.state,
                'MAILING_COUNTRY_ID': data.data
            });
        });

        this.props.fetchAttributeOptions('MAILING_STATE_PROVINCE_ID').then((data) => {
            this.setState({
                ...this.state,
                'MAILING_STATE_PROVINCE_ID': data.data
            });
        });

        MiscService.fetchCountries().then(data => {
            this.setState({
                'countries': data.data
            });
        })

        MiscService.fetchStates().then(data => {
            this.setState({
                'states': data.data
            });
        })

        IssuerService.loadProfile().then((data) => {
            this.setState({
                profile: data.data,
                loader: false
            });
        });

    }

    onChangeHandler(stateField, value) {
        this.setState({
            [stateField]: value
        });
    }

    submitChanges(e) {
        e.preventDefault();
        this.props.saveIssuerPOC({
            country: this.state.country,
            state: this.state.state,
            pocFullName: this.state.pocFullName,
            phone: this.state.phone,
            pocIssuerEmail: this.state.pocIssuerEmail,
            postalCode: this.state.postalCode,
            streetAddress: this.state.streetAddress,
            city: this.state.city,
            companyName: this.state.companyName,
            registrationStatus: this.state.registrationStatus
        });
    }

    setStateFields() {
        this.setState({
            country: this.props.issuer.country.code,
            state: this.props.issuer.state ? this.props.issuer.state.code : '',
            pocFullName: this.props.issuer.pocFullName,
            phone: this.props.issuer.phone,
            postalCode: this.props.issuer.postalCode,
            streetAddress: this.props.issuer.streetAddress,
            city: this.props.issuer.city,
            companyName: this.props.issuer.companyName,
            registrationStatus: this.props.issuer.user.registrationStatus,
        });
    }

    validEmail = (email) => {
        let pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(email);
    }

    onChangeAlternativeEmail = (e) => {
        let email = e.target.value;
        let error;
        error = !(this.validEmail(email) || email === '');

        this.setState({
            errorPocIssuerEmailValid: error,
            pocIssuerEmail: email
        })
    }

    setCountry(e) {
        this.setState({
            country: e.target.value
        })
    }

    render() {
        return (
            <>
                <Grid container spacing={3}
                      style={{paddingTop: 80}}>
                    <Grid item lg={12} xs={12}>
                        <Typography variant='h6'>
                            Profile
                        </Typography>

                        <Divider/>
                    </Grid>

                    {this.state.loader ?
                        <Grid item
                              lg={12}
                              xs={12}
                              style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  minHeight: '50vh'
                              }}>
                            <CircularProgress/>
                        </Grid>
                        :

                        <>

                            {/*<Grid item lg={12}>*/}
                            {/*    <Typography variant='h6'>Contact information</Typography>*/}
                            {/*</Grid>*/}



                            <Grid item lg={6} md={6} xs={12}>
                                <Card>
                                    <CardContent>

                                        <Grid item container className='app_form--group'>

                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                                <Typography>POC's full name</Typography>
                                            </Grid>

                                            <Grid item lg={7} xs={12}>
                                                <input name='pocFullName'
                                                       defaultValue={this.state.profile.pocFullName}
                                                       type="text"
                                                       onChange={(e) => this.onChangeHandler('pocFullName', e.target.value)}
                                                       className="input__custom"/>
                                            </Grid>
                                        </Grid>


                                        <Grid item container className='app_form--group'>
                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                                <Typography>Primary Email</Typography>
                                            </Grid>

                                            <Grid item lg={7} xs={12}>
                                                <input defaultValue={this.state.profile.primaryEmail}
                                                       type="email"
                                                       disabled={true}
                                                       className="input__custom"/>
                                            </Grid>
                                        </Grid>


                                        <Grid item container className='app_form--group'>
                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                                <Typography>POC's alternative email</Typography>
                                            </Grid>

                                            <Grid item lg={7} xs={12}>
                                                {/*<input name='pocIssuerEmail'*/}
                                                {/*       defaultValue={this.state.profile.pocIssuerEmail}*/}
                                                {/*       type="email"*/}
                                                {/*       onChange={(e) => this.onChangeHandler('pocIssuerEmail', e.target.value)}*/}
                                                {/*       className="input__custom"/>*/}
                                                <FormControl variant="outlined"
                                                             error={this.state.errorPocIssuerEmailValid}
                                                             fullWidth>

                                                    <TextField variant='outlined'
                                                               error={this.state.errorPocIssuerEmailValid}
                                                               value={this.state.pocIssuerEmail}
                                                               InputProps={{
                                                                   style: {fontSize: 14}
                                                               }}
                                                               helperText={this.state.errorPocIssuerEmailValid ? 'ERROR: Email is not valid' : ''}
                                                               onChange={(e) => this.onChangeAlternativeEmail(e)}>
                                                    </TextField>
                                                </FormControl>

                                            </Grid>
                                        </Grid>

                                        <Grid item container className='app_form--group'>

                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                                <Typography>POC’s work number</Typography>
                                            </Grid>

                                            <Grid item lg={7} xs={12}>
                                                <input defaultValue={this.state.profile.phone}
                                                       name='phone'
                                                       onChange={(e) => this.onChangeHandler('phone', e.target.value)}
                                                       type="text"
                                                       className="input__custom"/>
                                            </Grid>
                                        </Grid>


                                        <Grid item container className='app_form--group'>
                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                                <Typography>Company Name</Typography>
                                            </Grid>

                                            <Grid item lg={7} xs={12}>
                                                <input name={"companyName"}
                                                       onChange={(e) => this.onChangeHandler('companyName', e.target.value)}
                                                       defaultValue={this.state.profile.companyName}
                                                       type="text"
                                                       className="input__custom"/>
                                            </Grid>
                                        </Grid>

                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item lg={6} md={6} xs={12}>
                                <Card>
                                    <CardContent>

                                        <Grid item container className='app_form--group'>
                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                                <Typography>Country</Typography>
                                            </Grid>

                                            <Grid item lg={7} xs={12}>
                                                <select className='select__custom'
                                                        name={'country'}
                                                        onChange={(e) => this.setCountry(e)}>
                                                    <option/>
                                                    {
                                                        this.state.countries.map(ch => {
                                                            return (
                                                                <option key={ch.code}
                                                                        selected={ch.code === this.state.country}
                                                                        value={ch.code}>{ch.name}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </Grid>
                                        </Grid>


                                        <Grid item container  className='app_form--group'>

                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                                <Typography>State/Province</Typography>
                                            </Grid>

                                            <Grid item lg={7} xs={12}>
                                                <select disabled={this.state.country !== 'USA'}
                                                        className="select__custom"
                                                        name={'state'}
                                                        onChange={(e) => this.onChangeHandler('state', e.target.value)}>

                                                    <option/>
                                                    {this.state.country === 'USA'
                                                    || (!this.state.country
                                                        && this.state.profile.country === 'USA') ?
                                                        this.state.states.forEach(ch => {
                                                            if (ch.code !== 'state1' && ch.code !== 'state2')
                                                                return (
                                                                    <option key={ch.code}
                                                                            selected={ch.code === this.state.profile.state}
                                                                            value={ch.code}>{ch.name}
                                                                    </option>
                                                                )
                                                        }) : null}
                                                </select>
                                            </Grid>
                                        </Grid>


                                        <Grid item container  className='app_form--group'>
                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                                <Typography>City</Typography>
                                            </Grid>

                                            <Grid item lg={7} xs={12}>
                                                <input name={"city"}
                                                       type="text"
                                                       onChange={(e) => this.onChangeHandler('city', e.target.value)}
                                                       defaultValue={this.state.profile.city}
                                                       className="input__custom"/>
                                            </Grid>
                                        </Grid>


                                        <Grid item container  className='app_form--group'>
                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                                <Typography>Street Address</Typography>
                                            </Grid>

                                            <Grid item lg={7} xs={12}>
                                                <input name={"streetAddress"}
                                                       onChange={(e) => this.onChangeHandler('streetAddress', e.target.value)}
                                                       defaultValue={this.state.profile.streetAddress}
                                                       type="text"
                                                       className="input__custom"/>
                                            </Grid>
                                        </Grid>


                                        <Grid item container  className='app_form--group'>
                                            <Grid item lg={5} xs={12} className='app_form--group-label'>
                                                <Typography>Postal Code</Typography>
                                            </Grid>

                                            <Grid item lg={7} xs={12}>
                                                <input name={"postalCode"}
                                                       onChange={(e) => this.onChangeHandler('postalCode', e.target.value)}
                                                       defaultValue={this.state.profile.postalCode}
                                                       type="text"
                                                       className="input__custom"/>
                                            </Grid>
                                        </Grid>

                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    disabled={this.state.errorPocIssuerEmailValid}
                                    onClick={(e) => this.submitChanges(e)}>Submit changes
                                </Button>
                            </Grid>
                        </>


                    }
                </Grid>
            </>

        );
    }
}

export default ProfileIssuerPage;