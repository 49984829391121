import axios from '../custom-axios/app';

const AuthService = {

    signIn: (request) => {
        return axios.post('/login', request)
    },

    logout: () => {
        localStorage.clear();
    },

    register: (request) => {
        return axios.post('/users/sign-up', request);
    }
}

export default AuthService