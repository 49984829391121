import axios from '../custom-axios/app';

const IssuerService = {
    loadProfile: () => {
        return axios.get('/rest/registration/issuer/profile');
    },

    saveProfile: (request) => {
        return axios.post('/rest/registration/issuer/profile', request);
    },

    saveAttemptIssuer: (request) => {
        return axios.post('/rest/issuer/sign-up', request);
    },

    completeProfile: () => {
        return axios.post('/rest/registration/issuer/complete');
    },

    saveAttributeData: (request) => {
        return axios.post('/rest/issuer_data', request);
    },

    saveAttributeDataByEmail: (request, email) => {
        return axios.post('/rest/issuer_data/' + email, request);
    },

    fetchByAttributeCode: (code) => {
        return axios.get('/rest/issuer_data/attribute_code/'+code);
    },

    fetchIssuer:(code) => {
        return axios.get('/rest/issuer');
    },

    updateIssuerInfo: (request) => {
        return axios.post('/rest/issuer', request);
    }
}

export default IssuerService;