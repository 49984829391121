import React from 'react';

import {Typography} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

const InputField = ({
                        title, name, value, multiline, placeholder, required, disabled, onChange,
                        hasError = false, errorMessage, type
                    }) => {

    const defaultValue = {
        type: 'text'
    }

    return (
        <>
            <Typography>
                {title}
                {required && <span className="required__circle"> *</span>}
            </Typography>
            <TextField
                fullWidth
                multiline={multiline}
                value={value}
                disabled={disabled}
                error={hasError}
                placeholder={placeholder}
                helperText={hasError && errorMessage}
                onChange={event => onChange(event.target.value, name)}
                type={type ? type : defaultValue.type}
                variant="outlined"
            />
        </>
    )
}

export default InputField;