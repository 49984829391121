import * as actionTypes from '../actions/actionTypes';

const initialState = {
  stateIdFront: null,
  stateIdBack: null,
  passport: null
};

const saveIdFront = (state, action) => {
  return {
      ...state,
      stateIdFront: action.payload
  }
};

const saveIdBack = (state, action) => {
    return {
        ...state,
        stateIdBack: action.payload
    }
};

const savePassport = (state, action) => {
    return {
        ...state,
        passport: action.payload
    }
};

const document = (state = initialState, action) => {

    switch(action.type) {
        case actionTypes.SAVE_STATE_ID_FRONT:
            return saveIdFront(state, action);
        case actionTypes.SAVE_STATE_ID_BACK:
            return saveIdBack(state, action);
        case actionTypes.SAVE_PASSPORT:
            return savePassport(state, action);
        default:
            return state;
    }
};

export default document;