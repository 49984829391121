import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import DocumentService from "../../repository/DocumentService";
import InvestorService from "../../repository/InvestorService";
import {toast} from 'react-toastify';
import PdfPreviewModal from "./modals/PdfPreviewModal";
import ImagePreviewModal from './modals/ImagePreviewModal';
import DealDetailsService from "../../repository/DealDetailsService";
import IssuerService from "../../repository/IssuerService";
import DealDataService from "../../repository/DealDataService";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from "@material-ui/core/CircularProgress";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import VisibilityIcon from '@material-ui/icons/Visibility';
import PublishIcon from '@material-ui/icons/Publish';
import TableCell from "@material-ui/core/TableCell";
import Moment from "react-moment";

class DocumentationIndividualPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            documents: [],
            documentsToSave: [],
            isLoading: false,
            fileToPreview: null,
            showPreview: false,
            showPreviewImage: false,
            deals: [],
            documentEntity: 'user',
            noDocuments: false,
            loader: true
        };
        this.remove = this.remove.bind(this);
    }

    componentDidMount() {
        document.body.setAttribute('class', 'dashboard-body');
        this.fetchOtherDocuments();
        this.loadDeals();

    }

    pushInvestorChange = (request) => {
        IssuerService.saveAttributeData(request).then((data) => {
            this.fetchOtherDocuments();
            toast.success("Updated!")
        }).catch(error => {
            InvestorService.saveAttributeData(request).then((data) => {
                this.fetchOtherDocuments();
                toast.success("Updated!")
            })
        })

    };

    fetchOtherDocuments = () => {
        this.setState({
            loader: true
        })
        IssuerService.fetchByAttributeCode('OTHER')
            .then(data => {
                this.setState({
                    'documents': data.data,
                    noDocuments: data.data.length === 0,
                    loader: false,
                });
            }).catch(error => {
            InvestorService.fetchByAttributeCode('OTHER').then(data => {
                this.setState({
                    documents: data.data,
                    noDocuments: data.data.length === 0,
                    loader: false,
                }, () => {
                    InvestorService.fetchByAttributeCode('LETTER_ACCRD')
                        .then(res => {
                            let docs = [...this.state.documents];
                            docs.push(res.data[0]);
                            this.setState({
                                documents: docs,
                                noDocuments: data.data.length === 0,
                                loader: false,

                            });
                        }).catch(err => console.log(err));
                });
            })
        })
    };

    fetchDealDocuments = () => {
        DealDataService.fetchByDealAndAttribute({
            dealId: this.state.documentEntity,
            attributeCode: 'DOCUMENT'
        }).then(response => {
            this.setState({
                documents: response.data
            });
        }).catch(error => {
            console.log(error);
        })
    };

    saveDocument = (groupId, files, attrCode) => {
        this.setState({isLoading: true});
        DocumentService.saveDocumentMultiple(groupId, files)
            .then(response => {
                let files = response.data.map(file => {
                    return {
                        'id': file.id,
                        'filename': file.filename,
                        'originalFilename': file.originalFilename
                    }
                });
                this.setState(
                    {
                        'documentsToSave':
                            [...this.state.documentsToSave,
                                ...files
                            ]
                    });
                // this.setState({isLoading: false});
            }).catch(error => {
            this.setState({isLoading: false});
        });
    };

    remove(document) {
        let documents = [...this.state.documentsToSave];
        let newDocuments = documents.filter(d => {
            return (d.id !== document.id);
        });
        this.setState({documentsToSave: newDocuments});
    }

    onFilePreview = (item) => {
        let fileToPreview;

        if (item.chosenValue !== undefined) {
            fileToPreview = item.chosenValue.split('.');
        } else fileToPreview = item.filename.split('.');

        if (fileToPreview[fileToPreview.length - 1] === 'pdf') {
            if (item.chosenValue !== undefined) {
                this.setState({
                    fileToPreview: item.chosenValue,
                    showPreview: true
                });
            } else {

                this.setState({
                    fileToPreview: item.filename,
                    showPreview: true
                });
            }
        } else {
            if (item.chosenValue !== undefined) {
                //let a = item.filename

                this.setState({
                    fileToPreview: item.chosenValue,
                    showPreviewImage: true
                });
            } else {
                this.setState({
                    fileToPreview: item.filename,
                    showPreviewImage: true
                });
            }
        }

    };


    handleClose = () => {
        this.setState({
            fileToPreview: null,
            showPreview: false
        })
    };
    submit = () => {
        if (this.state.documentEntity === 'user') {
            this.state.documentsToSave.forEach(item => {
                this.pushInvestorChange({
                    value: item.filename,
                    attributeCode: 'OTHER',
                    documentId: item.id
                });
            });
        } else {
            this.state.documentsToSave.forEach(item => {
                this.pushDealChange({
                    name: item.originalFilename,
                    value: item.filename,
                    attributeCode: 'DOCUMENT',
                    dealId: this.state.documentEntity
                });
            });

        }

        if (this.state.documentEntity === 'user') {
            this.fetchOtherDocuments();
        } else {
            this.fetchDealDocuments();
        }
        this.setState({documentsToSave: []})
    };

    pushDealChange(body) {
        DealDataService.save(body)
            .then(response => {
                this.fetchDealDocuments();
            }).catch(error => {
            console.log(error);
        })
    };

    loadDeals = () => {
        DealDetailsService.fetch().then((response) => {
            this.setState({deals: response.data});
        })
    };

    // onError = (e) => {
    //     console.log(e);
    // };

    documentEntityChanged(e) {
        this.setState({
            documentEntity: e.target.value
        }, () => {
            if (this.state.documentEntity === 'user') {
                this.fetchOtherDocuments();
            } else {
                this.fetchDealDocuments();
            }
        })
    };

    handleCloseImage = () => {
        this.setState({
            showPreviewImage: false
        })
    };
    startLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading
        })
    }


    render() {
        return (
            <>
                <PdfPreviewModal
                    className="modal"
                    show={this.state.showPreview}
                    file={this.state.fileToPreview}
                    close={this.handleClose}/>
                <ImagePreviewModal
                    className="modal"
                    show={this.state.showPreviewImage}
                    file={this.state.fileToPreview}
                    close={this.handleCloseImage}
                />

                <Grid container spacing={3} style={{paddingTop: 80}}>
                    <Grid item lg={12} xs={12}>
                        <Typography variant='h6'>
                            DOCUMENTS
                        </Typography>

                        <Divider/>
                    </Grid>

                    <Grid item lg={6} xs={12}>
                        <Card raised>
                            <CardContent>

                                <Box mb='15px'>
                                    <Typography variant='h6'>Upload new documents</Typography>
                                    <Divider/>
                                </Box>

                                <Box className="upload__block--title"
                                     onClick={this.startLoading}>
                                    {this.state.isLoading ? <RemoveIcon/> : <AddIcon/>}

                                    <Box ml="8px" component="span">Add document</Box>
                                </Box>

                                <Dropzone multiple={true}
                                          accept={'application/pdf,image/png,image/jpeg'}
                                          onDrop={acceptedFiles => this.state.documentEntity === 'user' ? this.saveDocument('other', acceptedFiles, 'OTHER') : this.saveDocument('deal-document', acceptedFiles, 'DOCUMENT')}>
                                    {({getRootProps, getInputProps}) => (
                                        <Box
                                            mt="15px"
                                            mb="15px">

                                            <Box
                                                mb="15px"
                                                p="25px"
                                                className={this.state.isLoading ? 'upload__view' : 'upload__hidden'}
                                                style={{background: '#FCFCFC'}}>
                                                <Box>
                                                    <Box>
                                                        <Typography
                                                            variant="subtitle2"
                                                            gutterBottom>Only JPG, PNG or PDF files. File
                                                            must be less than 20 Mb <span
                                                                className="required__circle">*</span>
                                                        </Typography>
                                                    </Box>
                                                    {
                                                        this.state.documentsToSave.map(document => {
                                                                return (
                                                                    <Box
                                                                        pt="20px"
                                                                        pb="5px"
                                                                        style={{borderColor: '#DFDFDF'}}
                                                                        borderBottom={1}
                                                                        display="flex"
                                                                        key={document.id}>

                                                                        <Box flexGrow={1}>
                                                                            {document.originalFilename}
                                                                        </Box>
                                                                        <Box>
                                                                            <Button
                                                                                color="primary"
                                                                                name={'file-01'}
                                                                                type={"button"}
                                                                                onClick={() => this.onFilePreview(document)}>
                                                                                Preview
                                                                            </Button>

                                                                            <Button
                                                                                style={{color: '#B71C1C'}}
                                                                                name={'file-01'}
                                                                                type={"button"}
                                                                                onClick={(e) => this.remove(document)}>
                                                                                Delete
                                                                            </Button>
                                                                        </Box>
                                                                    </Box>
                                                                )
                                                            }
                                                        )
                                                    }
                                                    {this.state.documentsToSave.length !== 0 ?
                                                        <Box style={{
                                                            display: 'flex',
                                                            justifyContent: 'flex-end',
                                                            paddingTop: 10
                                                        }}>

                                                            <Button variant='contained'
                                                                    color='primary'
                                                                    onClick={this.submit}>
                                                                Submit Documents
                                                            </Button>

                                                        </Box> : null}
                                                </Box>
                                                <Box mt="25px" display="flex">

                                                    <Button onClick={this.startLoading}>
                                                        Cancel
                                                    </Button>

                                                    <Box ml="20px">
                                                        <input
                                                            id={'file-01'}
                                                            type={"file"} {...getInputProps()}
                                                            className="custom-file-input"
                                                            accept={'application/pdf,image/png,image/jpeg'}
                                                            required>
                                                        </input>

                                                        <label htmlFor={'file-01'}>
                                                            <Button
                                                                variant="outlined"
                                                                color="primary"
                                                                endIcon={<PublishIcon/>}
                                                                component="span">Upload document
                                                            </Button>
                                                        </label>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                </Dropzone>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item lg={6} xs={12}>
                        <Card raised>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item lg={12} xs={12}>
                                        <Typography variant='h6'>Uploaded documents</Typography>
                                        <Divider/>
                                    </Grid>

                                    {this.state.loader ?
                                        <Grid item
                                              lg={12}
                                              xs={12}
                                              style={{display: "flex", justifyContent: "center"}}>
                                            <CircularProgress/>
                                        </Grid>
                                        : this.state.noDocuments ?
                                            <Grid item lg={12} xs={12}>
                                                <Typography>No Documents</Typography></Grid> :
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            {/*<TableCell>Document Description</TableCell>*/}
                                                            <TableCell>Document Name</TableCell>
                                                            <TableCell>Date Uploaded</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    {this.state.documents.map(document => {
                                                            return (
                                                                <TableRow>
                                                                    {/*<TableCell>{document.chosenValue}</TableCell>*/}
                                                                    <TableCell>{document.documentOriginalName}</TableCell>
                                                                    <TableCell>{<Moment
                                                                        format={'LLL'}>{document.dateCreated}</Moment>}</TableCell>
                                                                    <TableCell
                                                                        style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'flex-end'
                                                                        }}>
                                                                        <Button onClick={() => this.onFilePreview(document)}
                                                                                variant='contained'
                                                                                color='primary'
                                                                                size='small'
                                                                                startIcon={<VisibilityIcon/>}>
                                                                            Preview
                                                                        </Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        }
                                                    )}
                                                </Table>
                                            </TableContainer>
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                        {this.state.deals.length > 0 ?
                            <div>
                                <Typography> Document type </Typography>
                                <select className="form-control input-lg"
                                        onChange={(e) => this.documentEntityChanged(e)}>
                                    <option key="user" value="user"> User</option>
                                    {this.state.deals.map(d => {
                                        return (
                                            <option key={d.deal.id} value={d.deal.id}> {d.deal.name} </option>)
                                    })}
                                </select>
                            </div>
                            : null}
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default DocumentationIndividualPage;