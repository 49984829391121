import React, {useEffect, useState} from 'react';

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import InputPhoneField from "../../components/input/new/InputPhoneField";
import InputField from "../../components/input/new/InputField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import MiscService from "../../repository/MiscService";
import CheckBoxField from "../../components/input/new/CheckBoxField";
import CurrencyFormatField from "../../components/input/new/CurrencyFormatField";
import Button from "@material-ui/core/Button";
import {toast} from "react-toastify";
import SelectField from "../../components/input/new/SelectField";
import UserService from "../../repository/UserService";
import InaccessibleErrorPage from "../InaccessibleErrorPage";
import SettingService from "../../repository/SettingService";

const NodleWaitListPage = (props) => {

    const [accessible, setAccessible] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [loader, setLoader] = useState(false)
    const [countries, setCountries] = useState([])
    const [errors, setErrors] = useState({})
    const [profile, setProfile] = useState({
        firstName: '',
        lastName: '',
        userGroup: '',
        institutionName: '',
        amount: '',
        paymentMethod: '',
        email: '',
        phone: '',
        country: '',
    })

    useEffect(() => {
        checkPermissions()
        fetchCountries()

        // if (captcha.reset) {
        //     captcha.reset()
        // }
    }, [])

    const checkPermissions = () => {
        fetch("https://geolocation-db.com/json/7733a990-ebd4-11ea-b9a6-2955706ddbf3")
            .then(response => response.json())
            .then(response => {
                if (response.country_name === 'United States') {
                    SettingService.fetchSettingByName('/nodlewaitlist')
                        .then(response1 => {
                            if (response1.data) {
                                const ips = response1.data.settingData.filter(data => data.attribute.code === 'ALLOWED_IP_ADDRESSES')[0]

                                if (ips && ips.value.split(',').map(value => value.trim()).includes(response.IPv4)) {
                                    setAccessible(true)
                                }
                            }
                        })
                        .catch(error => {
                        })
                } else {
                    setAccessible(true)
                }
            })
            .catch(error => {
            })
    }

    const handleFormDisplay = () => {
        setShowForm(!showForm)
    }

    const fetchCountries = () => {
        MiscService.fetchCountries()
            .then(data => {
                const countries = data.data.map(country => {
                    return {
                        id: country.id,
                        value: country.name
                    }
                })

                const usaCountry = countries.filter(country => country.value === 'United States')[0]
                const usaCountryId = usaCountry ? usaCountry.id : ''

                setCountries(countries)
                //setUsaCountryId(usaCountryId)

                // set default value
                setProfile({
                    ...profile,
                    country: usaCountryId
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    const handleValidation = () => {
        let errors = {}
        let valid = true

        if (!profile.firstName) {
            valid = false
            errors.firstName = 'Cannot be empty'
        }

        if (!profile.lastName) {
            valid = false
            errors.lastName = 'Cannot be empty'
        }

        if (!profile.email) {
            valid = false
            errors.email = 'Cannot be empty'
        }

        if (!profile.phone) {
            valid = false
            errors.phone = 'Cannot be empty'
        }

        if (!profile.userGroup) {
            valid = false
            errors.userGroup = 'Cannot be empty'
        } else if (profile.userGroup === 'USER_INVESTOR_COMPANY' && !profile.institutionName) {
            valid = false
            errors.institutionName = 'Cannot be empty'
        }

        if (!profile.amount) {
            valid = false
            errors.amount = 'Cannot be empty'
        }

        if (!profile.paymentMethod) {
            valid = false
            errors.paymentMethod = 'Cannot be empty'
        }

        if (!profile.country) {
            valid = false
            errors.country = 'Cannot be empty'
        }

        setErrors(errors)

        return valid
    }

    const handleChangeProfileField = (value, field) => {
        setProfile({
            ...profile,
            [field]: value.id ? value.id : value
        })

        validateValue(value, field)
    }

    const validateValue = (value, field) => {
        if (value || value === '') {
            errors[field] = ''
        }

        if (field === 'email' && value !== '') {
            !validateEmail(value) && (errors[field] = 'Wrong email format')
        }

        if (field === 'amount' && value !== '') {
            (value < 50000) && (errors[field] = 'Amount can\'t be less then 50,000 USD')
        }
    }

    const validateEmail = (email) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        return pattern.test(email);
    }

    const submit = () => {
        if (handleValidation()) {
            setLoader(true)

            UserService.saveInvestorRequest(profile)
                .then(response => {
                    setLoader(false)
                    toast.success('Thank you for submitting your information, we will be in touch regarding next steps.')
                })
                .catch(error => {
                    setLoader(false)
                    toast.error('Error while saving data! Try another email!')
                })
        } else {
            toast.error('Please fill all the required fields!')
        }
    }


    return (
        accessible ? (
            loader ? (
                <Container maxWidth='lg'>
                    <Grid container
                          justify={"center"}
                          alignItems={"center"}
                          style={{height: '100vh'}}>
                        <Grid item>
                            <CircularProgress/>
                        </Grid>
                    </Grid>
                </Container>
            ) : (
                <Container maxWidth='lg'>
                    <Grid
                        container
                        spacing={3}
                        justify={"center"}
                    >
                        {showForm && (
                            <>
                                <Grid item lg={9} xs={12}>
                                    <Typography
                                        variant='h5'
                                        align={"center"}
                                        style={{marginTop: '30px'}}
                                    >
                                        Please add your name to the Nodle sale waitlist. This sale is for NON U.S.
                                        entities
                                        and individuals ONLY. Minimum purchase amount of 50,000 USD or equivalent.
                                    </Typography>
                                </Grid>

                                <Grid item lg={10} xs={12}>
                                    <Card raised
                                          style={{overflow: 'unset'}}>
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                <Grid item lg={12} xs={12}>
                                                    <Grid container spacing={3}>
                                                        <Grid item lg={6} xs={12}>
                                                            <InputField
                                                                title={"First name"}
                                                                name={'firstName'}
                                                                required
                                                                value={profile.firstName}
                                                                onChange={handleChangeProfileField}
                                                                hasError={!!errors.firstName}
                                                                errorMessage={errors.firstName}
                                                            />
                                                        </Grid>

                                                        <Grid item lg={6} xs={12}>
                                                            <InputField
                                                                title={"Last name"}
                                                                name={"lastName"}
                                                                required
                                                                value={profile.lastName}
                                                                onChange={handleChangeProfileField}
                                                                hasError={!!errors.lastName}
                                                                errorMessage={errors.lastName}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item lg={12} xs={12}>
                                                    <Grid container spacing={3}>
                                                        <Grid item lg={6} xs={12}>
                                                            <CheckBoxField
                                                                title={"Investing as"}
                                                                name={"userGroup"}
                                                                required
                                                                options={[
                                                                    {
                                                                        id: 'USER_INVESTOR_INDIVIDUAL',
                                                                        value: "Individual"
                                                                    },
                                                                    {
                                                                        id: 'USER_INVESTOR_COMPANY',
                                                                        value: "Institution"
                                                                    }
                                                                ]}
                                                                value={profile.userGroup}
                                                                onChange={handleChangeProfileField}
                                                                hasError={!!errors.userGroup}
                                                                errorMessage={errors.userGroup}
                                                            />
                                                        </Grid>

                                                        {profile.userGroup === 'USER_INVESTOR_COMPANY' && (
                                                            <Grid item lg={6} xs={12}>
                                                                <InputField
                                                                    title={"Institution name"}
                                                                    name={"institutionName"}
                                                                    required
                                                                    value={profile.institutionName}
                                                                    onChange={handleChangeProfileField}
                                                                    hasError={!!errors.institutionName}
                                                                    errorMessage={errors.institutionName}
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>

                                                <Grid item lg={12} xs={12}>
                                                    <Grid container spacing={3}>
                                                        <Grid item lg={6} xs={12}>
                                                            <CurrencyFormatField
                                                                title={"Indication of interest/Amount"}
                                                                name={"amount"}
                                                                required
                                                                prefix={"$ "}
                                                                value={profile.amount}
                                                                onChange={handleChangeProfileField}
                                                                hasError={!!errors.amount}
                                                                errorMessage={errors.amount}
                                                            />
                                                        </Grid>

                                                        <Grid item lg={6} xs={12}>
                                                            <CheckBoxField
                                                                title={"Payment method"}
                                                                name={"paymentMethod"}
                                                                required
                                                                options={["US dollar (Wire)", "Crypto (BTC, ETH, DOT, USDT, USDC)"]}
                                                                value={profile.paymentMethod}
                                                                onChange={handleChangeProfileField}
                                                                hasError={!!errors.paymentMethod}
                                                                errorMessage={errors.paymentMethod}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item lg={12} xs={12}>
                                                    <Grid container spacing={3}>
                                                        <Grid item lg={4} xs={12}>
                                                            <SelectField
                                                                title={"Country"}
                                                                name={'country'}
                                                                required
                                                                value={profile.country}
                                                                options={countries}
                                                                onChange={handleChangeProfileField}
                                                                hasError={!!errors.country}
                                                                errorMessage={errors.country}
                                                            />
                                                        </Grid>

                                                        <Grid item lg={4} xs={12}>
                                                            <InputField
                                                                title={"Email"}
                                                                name={"email"}
                                                                required
                                                                value={profile.email}
                                                                onChange={handleChangeProfileField}
                                                                hasError={!!errors.email}
                                                                errorMessage={errors.email}
                                                            />
                                                        </Grid>

                                                        <Grid item lg={4} xs={12}>
                                                            <InputPhoneField
                                                                title={"Phone"}
                                                                name={"phone"}
                                                                required
                                                                value={profile.phone}
                                                                onChange={handleChangeProfileField}
                                                                hasError={!!errors.phone}
                                                                errorMessage={errors.phone}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item lg={9} xs={12}
                                      style={{textAlign: 'end'}}>
                                    <Grid container spacing={3}>
                                        <Grid item lg={6} xs={6}>
                                            {/*<ReCaptcha*/}
                                            {/*    ref={(el) => {*/}
                                            {/*        captcha = el*/}
                                            {/*    }}*/}
                                            {/*    size="normal"*/}
                                            {/*    data-theme="light"*/}
                                            {/*    render="explicit"*/}
                                            {/*    sitekey="6LfPUfYUAAAAANV9y2TFUGhv9nnfOICbPvr2AFra"*/}
                                            {/*    onloadCallback={onLoadRecaptcha}*/}
                                            {/*    verifyCallback={verifyCallback}*/}
                                            {/*/>*/}
                                        </Grid>

                                        <Grid item lg={6} xs={6}>
                                            <Button
                                                type={"button"}
                                                color="primary"
                                                variant={"contained"}
                                                //disabled={!verified}
                                                onClick={submit}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}

                        {!showForm && (
                            <>
                                <Grid item lg={12} xs={12}
                                      style={{textAlign: 'center', marginTop: '30px'}}>
                                    <img
                                        src={require('../../assets/images/logo-blue.png')}
                                        width="120"
                                        height="41"
                                        alt=""/>
                                </Grid>

                                <Grid item lg={12} xs={12}
                                      style={{
                                          textAlign: 'center',
                                          marginTop: '30px',
                                          background: '#39516e',
                                          padding: '0',
                                          position: 'relative'
                                      }}>
                                    <img
                                        src={require('../../assets/images/background_nodle.jpg')}
                                        width={'100%'}
                                        height={'300px'}
                                        alt=""/>

                                    <div className={'qDqoW'}>
                                        <Typography
                                            variant={"h5"}
                                            align={"center"}
                                            style={{marginTop: '10px', marginBottom: '10px', color: '#fff'}}
                                        >
                                            A boutique bank with a global network
                                        </Typography>

                                        <Typography
                                            //variant={"h6"}
                                            align={"center"}
                                            style={{marginTop: '10px', marginBottom: '10px', color: '#fff'}}
                                        >
                                            A Leading Investment Bank in the Blockchain Space
                                        </Typography>
                                    </div>

                                    <Typography
                                        variant={"h5"}
                                        align={"center"}
                                        style={{marginTop: '10px', marginBottom: '10px', color: '#fff'}}
                                    >
                                        Lead IB and Broker-Dealer for tZERO's Security Token Offering
                                        $100+ Million Raised
                                    </Typography>
                                </Grid>

                                <Grid item lg={9} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item lg={12} xs={12}
                                              style={{textAlign: 'end', marginTop: '30px'}}>
                                            <Button
                                                type={"button"}
                                                color="primary"
                                                variant={"contained"}
                                                //disabled={!verified}
                                                onClick={handleFormDisplay}
                                            >
                                                Add me to waitlist
                                            </Button>
                                        </Grid>

                                        <Grid item lg={12} xs={12}>
                                            <Typography
                                                variant={"h6"}
                                                align={"center"}
                                                style={{marginTop: '20px'}}
                                            >
                                                NODLE SELECTS AMERICAS EXECUTIONS AS EXCLUSIVE INVESTMENT BANK FOR
                                                INSTITUTIONAL
                                                TOKEN
                                                SALE
                                            </Typography>

                                            <Typography
                                                variant={"subtitle2"}
                                                align={"center"}
                                                style={{fontStyle: 'italic'}}
                                            >
                                                AmerX Joins the Nodle Team, Poised to Provide Secure, Low-Cost
                                                Connectivity
                                                for
                                                the
                                                Internet of Things
                                            </Typography>

                                            <Typography
                                                align={"left"}
                                                style={{marginTop: '20px'}}
                                            >
                                                Nodle, a global blockchain network that provides secure, low-cost
                                                connectivity
                                                for the
                                                Internet of Things (IoT), has selected Americas Executions “AmerX” as
                                                the
                                                exclusive
                                                investment bank for its
                                                private token sale.
                                            </Typography>

                                            <Typography
                                                align={"left"}
                                                style={{marginTop: '20px'}}
                                            >
                                                Nodle, a Caymans company, has a waitlist of non-U.S.-based institutional
                                                investors that
                                                will have the first look at the pre-sale which is set to kick off
                                                Tuesday,
                                                October 12th,
                                                2021. Interested non-U.S.-based investors can join the waitlist:
                                                <a href="https://portal.amerx.com/nodlewaitlist/"> portal.amerx.com/nodlewaitlist</a>.
                                            </Typography>

                                            <Typography
                                                align={"left"}
                                                style={{marginTop: '20px'}}
                                            >
                                                The engagement with AmerX (<a href="https://amerx.com/">amerx.com</a>),
                                                a
                                                leading
                                                blockchain investment bank, is the
                                                first major announcement from AmerX since the completion of its first
                                                security
                                                token
                                                offering
                                                in 2018.
                                            </Typography>

                                            <Typography
                                                align={"left"}
                                                style={{marginTop: '20px'}}
                                            >
                                                “Our emphasis on a private sale with non-U.S. based institutional
                                                investors
                                                is a
                                                testament to both our global market traction, partner growth, and
                                                commitment
                                                to
                                                maintaining a
                                                level of compliance that strives to be the gold standard for blockchain
                                                offerings,” said
                                                Micha
                                                Benoliel, CEO and Co-Founder of Nodle.
                                            </Typography>

                                            <Typography
                                                align={"left"}
                                                style={{marginTop: '20px'}}
                                            >
                                                Fady Tanios, CEO of Americas Executions, said: “We are pleased to lead
                                                the
                                                Nodle
                                                offering. AmerX is committed to raising awareness for token projects
                                                with
                                                meaningful
                                                benefits to
                                                both its users and institutional investors.”
                                            </Typography>

                                            <Typography
                                                align={"left"}
                                                style={{marginTop: '20px', fontWeight: "bold"}}
                                            >
                                                The Opportunity
                                            </Typography>

                                            <Typography
                                                align={"left"}
                                                style={{marginTop: '20px'}}
                                            >
                                                With Forbes predicting 3.5 billion IoT connections in the next two years
                                                and
                                                McKinsey
                                                reporting 127 new devices per second connecting to the web, the world is
                                                entering an era
                                                of hyperconnectivity where access to data can drive improvements from
                                                increased
                                                economic
                                                productivity to granular climate data for protecting the environment.
                                                These
                                                advances
                                                require a scalable, global network.
                                            </Typography>

                                            <Typography
                                                align={"left"}
                                                style={{marginTop: '20px'}}
                                            >
                                                In less than three years, Nodle has built one of the largest wireless
                                                networks
                                                in the
                                                world,
                                                entirely through software that runs on over 5 million daily active
                                                smartphones.
                                                Nodle
                                                achieved this in partnership with numerous mobile app developers and
                                                with
                                                its
                                                own <a
                                                href="https://nodle.io/cash/">Nodle
                                                Cash</a> app, their consumer app which enables anyone with a smartphone
                                                to
                                                easily mine
                                                cryptocurrency, without having to purchase hardware.
                                            </Typography>

                                            <Typography
                                                align={"left"}
                                                style={{marginTop: '20px'}}
                                            >
                                                Building on the blockchain provided Nodle with its ideal technical means
                                                to
                                                achieve a privacy-first network that is both decentralized and scalable.
                                                Through the use of the Nodle Chain, the company can deliver secure
                                                communications today in over 100 countries.
                                                Nodle uses blockchain and crypto to scale the number of objects that can
                                                connect to the Internet and secure data traffic and identity.
                                            </Typography>

                                            <Typography
                                                align={"left"}
                                                style={{marginTop: '20px'}}
                                            >
                                                Nodle Cash, Nodle’s native cryptocurrency, the incentive mechanism for
                                                the
                                                flow
                                                of data
                                                in the network, is mined remotely utilizing software that can run on
                                                almost
                                                any
                                                communications device including any smartphone. Using Nodle’s “Proof of
                                                Connectivity”
                                                algorithm anyone
                                                with a smartphone can “mine” Nodle Cash.
                                            </Typography>

                                            <Typography
                                                align={"left"}
                                                style={{marginTop: '20px'}}
                                            >
                                                Nodle believes its unique economic model can grow the network globally
                                                to
                                                bring
                                                crypto
                                                to its first billion users while also serving the communications needs
                                                of
                                                the
                                                enterprise.
                                            </Typography>

                                            <Typography
                                                align={"left"}
                                                style={{marginTop: '20px', fontWeight: "bold"}}
                                            >
                                                About Nodle | The Citizen Network™:
                                            </Typography>

                                            <Typography align={"left"}>
                                                <a href="https://nodle.io/">Nodle</a> is building the world’s largest
                                                decentralized
                                                wireless network to connect and
                                                secure the next trillion objects and sensors to the Internet at low
                                                cost.
                                                The
                                                Nodle
                                                network is
                                                powered by millions of Bluetooth-enabled smartphones that earn Nodle
                                                Cash
                                                for
                                                providing
                                                connectivity to nearby Bluetooth-enabled IoT devices and sensors. App
                                                developers
                                                and
                                                publishers can monetize their applications via the Nodle networking
                                                library
                                                which
                                                enables them to mine Nodle Cash for helping the network grow. Nodle’s
                                                powerful
                                                privacy-first IoT
                                                stack allows for various use cases including connecting and securing
                                                physical
                                                assets,
                                                tracking lost or valuable items, capturing sensor data, security
                                                certificates,
                                                providing
                                                decision-making insights for consumer electronics manufacturers,
                                                enterprises,
                                                smart
                                                cities, and the finance industry, and much more.
                                            </Typography>

                                            <Typography
                                                align={"left"}
                                                style={{marginTop: '20px'}}
                                            >
                                                The Nodle Cash app is available for <a
                                                href="https://nodle.io/cash/">iOS</a> or <a
                                                href="https://nodle.io/cash/">Android</a>.
                                            </Typography>

                                            <Typography
                                                align={"left"}
                                                style={{marginTop: '20px'}}
                                            >
                                                <a href="http://twitter.com/nodlenetwork">Twitter</a> | <a
                                                href="https://t.me/nodlecommunity">Telegram</a> | <a
                                                href="https://discord.gg/N5nTUt8RWJ">Discord</a> | <a
                                                href="https://www.youtube.com/c/nodle">YouTube</a> | <a
                                                href="https://nodle.medium.com/">Medium</a> | <a
                                                href="https://github.com/NodleCode">GitHub</a> | <a
                                                href="https://nodle.io/">Website</a>
                                            </Typography>

                                            <Typography
                                                align={"left"}
                                                style={{marginTop: '20px', fontWeight: "bold"}}
                                            >
                                                About Americas Executions, LLC “AmerX”
                                            </Typography>

                                            <Typography align={"left"}>
                                                Americas Executions, LLC is a broker-dealer registered with the SEC and
                                                a
                                                member
                                                of
                                                FINRA and the NYSE. AmerX is a boutique investment bank specializing in
                                                private
                                                placements and
                                                M&A across a range of sectors. AmerX is registered to do business in the
                                                U.S.
                                                and serves
                                                clients in over 50 countries. AmerX, one of the earliest investment
                                                banks in
                                                the
                                                blockchain
                                                sector, maintains a large global client base of both institutional and
                                                accredited
                                                investors and
                                                services some of the largest institutions in the world. More information
                                                can
                                                be
                                                found
                                                at <a
                                                href="https://amerx.com/">www.amerx.com</a>.
                                            </Typography>

                                            <Typography
                                                align={"left"}
                                                style={{marginTop: '20px'}}
                                            >
                                                Disclaimer:
                                            </Typography>

                                            <Typography
                                                align={"left"}
                                                style={{marginTop: '20px'}}
                                            >
                                                Certain information contained in this press release constitutes
                                                forward-looking
                                                statements, which can be identified by the use of forward-looking
                                                terminology
                                                such as
                                                “may,” “will,”
                                                “should,” expect,” “anticipate,” “target,” “project,” “projections,”
                                                “estimate,”
                                                “intend,” “continue,” or “believe,” or the negatives thereof or other
                                                variations
                                                thereon
                                                or
                                                comparable terminology. Such statements are not guarantees of future
                                                performance
                                                or
                                                activities.
                                                This is not an offer to sell, a solicitation of an offer to buy, or a
                                                recommendation of
                                                a product or service.
                                            </Typography>

                                            <Typography
                                                align={"justify"}
                                                style={{marginTop: '20px'}}
                                            >
                                                Investments in private placements and early stage companies are very
                                                speculative
                                                and
                                                involve a high degree of risk.
                                                Those investors who cannot afford to lose their entire investment should
                                                not
                                                invest in
                                                private placements and start-ups.
                                                Companies seeking startup investments tend to be in earlier stages of
                                                development and
                                                their business model, products and services may not yet be fully
                                                developed,
                                                operational
                                                or tested in the public
                                                marketplace. There is no guarantee that the
                                                terms are accurate or in agreement with the market or industry
                                                valuations.
                                                Additionally,
                                                investors may receive restricted stock or tokens that may be subject to
                                                holding
                                                period
                                                requirements. You may lose some or
                                                all of your investment.
                                            </Typography>
                                        </Grid>

                                        <Grid item lg={12} xs={12}>
                                            <Typography style={{marginTop: '20px'}}>
                                                © 2021 Americas Executions, LLC. All rights reserved
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    {/*<Grid item lg={12} xs={12}>*/}
                                    {/*    <Footer/>*/}
                                    {/*</Grid>*/}
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Container>
            )
        ) : (
            <InaccessibleErrorPage/>
        )
    )
}

export default NodleWaitListPage