import React from 'react';

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const InaccessibleErrorPage = (props) => {


    return (
        <Container maxWidth='lg'>
            <Grid container
                  justify={"center"}
                  alignItems={"center"}
                  style={{height: '100vh'}}>
                <Grid item>
                    <Typography
                        variant='h5'
                        align={"center"}
                        style={{marginTop: '30px'}}
                    >
                        Sorry, but this page is not available to you.
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    )
}

export default InaccessibleErrorPage