import React, {Component} from 'react';
import Aux from '../../hoc/AuxWrapper';
import axios from '../../custom-axios/app';
import Grid from "@material-ui/core/Grid";
import {NavLink} from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import HomeWorkIcon from '@material-ui/icons/HomeWork';

const CartComponent = styled.div`
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),
     0px 1px 1px 0px rgba(0,0,0,0.14),
      0px 1px 3px 0px rgba(0,0,0,0.12);
    padding: 30px 30px 30px 30px;
    position: relative;
    background: white;
    max-width: 480px;
    min-width: 480px;
`;

const MainWrap = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const WrapBoxLogin = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0 35px 0;
`;

const WrapMainElement = styled.div`
  display: flex;
  flex-direction: column;
`;

const BoxBLock = styled.div`
    width: 48%;
    border: 1px solid #eee;
    padding: 25px;
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 15px;
    
    &:hover {
        border: 1px solid #032F66;
    }
`;

class ChooseUserType extends Component {

    state = {
        userType: ''
    };


    // saveUserInfo(userInfo) {
    //     this.props.saveUser(userInfo);
    // }


    selectTypeHandler(type) {
        axios.post('/users/user_type', {
            userGroup: type
        }).then(
            response => {
                //this.saveUserInfo(response.data);
                //window.location.replace("/");
                axios.get('/users/current-user')
                    .then(res => {
                        localStorage.setItem('userInfo', JSON.stringify(res.data));
                        this.props.history.push('/');
                    }).catch(err => {

                });
            });
    }


    render() {
        return (
            <Aux>
                {/*<div className="signup-overlay-1"></div>*/}

                <MainWrap>

                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={3}>

                        <WrapMainElement>

                            <WrapBoxLogin>
                                <NavLink to="index.php">
                                    <img
                                        src={require('../../assets/images/logo-blue.png')}
                                        width="140"
                                        height="51"
                                        alt=""/>
                                </NavLink>
                            </WrapBoxLogin>

                            <CartComponent>

                                <Box pb={3}>
                                    <Typography
                                        variant="h6">
                                        Would you like to sign up as an Individual or
                                        Institution?
                                    </Typography>
                                </Box>

                                <Box display="flex">
                                    <BoxBLock onClick={() => this.selectTypeHandler('USER_INVESTOR_INDIVIDUAL')}>
                                        <Box pb="15px">
                                            <FingerprintIcon style={{fontSize: '45px', color: '#032F66'}}/>
                                        </Box>
                                        <Typography variant="subtitle2">Individual</Typography>
                                    </BoxBLock>

                                    <BoxBLock onClick={() => this.selectTypeHandler('USER_INVESTOR_COMPANY')}>
                                        <Box pb="15px">
                                            <HomeWorkIcon style={{fontSize: '45px', color: '#032F66'}}/>
                                        </Box>
                                        <Typography variant="subtitle2">Institution</Typography>
                                    </BoxBLock>
                                </Box>

                            </CartComponent>
                        </WrapMainElement>
                    </Grid>
                </MainWrap>
            </Aux>
        );
    }
}

// const mapDispatchToProps = dispatch => {
//     return {
//         saveUser: (userInfo) => dispatch({type: actions.SAVE_USER_INFO, payload: userInfo})
//     }
// };

export default ChooseUserType;
// export default connect(null, mapDispatchToProps)(ChooseUserType);