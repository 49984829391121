import React from 'react';
import Aux from '../../hoc/AuxWrapper';
import {NavLink} from 'react-router-dom';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import {makeStyles} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

const RegistrationHeader = (props) => {
    const classes = useStyles();
    return (
        <Aux>
            <div className={classes.root}>
                <AppBar position="fixed">
                    <Toolbar>

                        <Box flexGrow={1}>
                            <img alt="AmerX" src={require('../../assets/images/logo-white.png')}
                                 className="img-responsive"/>
                        </Box>

                        <Box>
                            {props.saveForLater === false ? null :
                                <NavLink to="/logout">
                                    <Button
                                        style={{color: 'white'}}
                                        variant="contained"
                                        color="secondary"
                                        size='small'
                                        component={"span"}>Save for Later
                                    </Button>
                                </NavLink>}

                            <NavLink to="/logout">
                                <Button
                                    startIcon={<ExitToAppIcon/>}
                                    style={{color: 'white', marginLeft: '15px'}}
                                    component={"span"}>Logout
                                </Button>
                            </NavLink>
                        </Box>

                    </Toolbar>
                </AppBar>
            </div>
        </Aux>
    );
};

export default RegistrationHeader;