import React, {Component} from 'react';

import Checkbox from '../../components/input/Checkbox';
import RegistrationService from '../../repository/RegistrationService'
import DocumentScroll from '../../components/DocumentScroll';
import InputText from '../../components/input/InputText';
import '../dashboard/css/Final-Submission.css';
import InvestorService from "../../repository/InvestorService";
import {CardContent, Divider} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import validationAlert from "../../components/ValidationAlert";
import {toast} from "react-toastify";


class FinalSubmissionPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            investor: null,
            captcha: true,
            'PRIVACY_DISCLOSURE': [],
            'TERMS_CONDITIONS': [],
            'RISK_DICLOSURE': [],
            'INFORMATION_IS_CORRECT': [],
            'INFORMATION_IS_CORRECT_COMPANY': [],
            userInfo: JSON.parse(localStorage.getItem('userInfo'))
        };
        // this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        // this.verifyCallback = this.verifyCallback.bind(this);
    }

    componentDidMount() {
        this.props.setCurrentRegistrationStep('Disclosures & Final Submission');
        this.init();
        window.scrollTo(0, 0)
        if (this.captchaDemo) {
            console.log("started, just a second...")
            this.captchaDemo.reset();
        }
    }

    // onLoadRecaptcha() {
    //     if (this.captchaDemo) {
    //         this.captchaDemo.reset();
    //     }
    // }
    //
    // verifyCallback(recaptchaToken) {
    //     if (recaptchaToken) {
    //         this.setState({captcha: true})
    //     }
    // }

    init() {
        InvestorService.fetchInvestor()
            .then(response => {
                this.setState({
                    investor: response.data
                })
            }).catch(error => console.log(error));

        this.props.fetchAttributeOptions('PRIVACY_DISCLOSURE').then((data) => {
            this.setState({
                ...this.state,
                'PRIVACY_DISCLOSURE': data.data
            });
        });

        this.props.fetchAttributeOptions('TERMS_CONDITIONS').then((data) => {
            this.setState({
                ...this.state,
                'TERMS_CONDITIONS': data.data
            });
        });

        this.props.fetchAttributeOptions('RISK_DICLOSURE').then((data) => {
            this.setState({
                ...this.state,
                'RISK_DICLOSURE': data.data
            });
        });

        this.props.fetchAttributeOptions('INFORMATION_IS_CORRECT').then((data) => {
            this.setState({
                ...this.state,
                'INFORMATION_IS_CORRECT': data.data
            });
        });

        this.props.fetchAttributeOptions('INFORMATION_IS_CORRECT_COMPANY').then((data) => {
            this.setState({
                ...this.state,
                'INFORMATION_IS_CORRECT_COMPANY': data.data
            });
        });

    }

    checkSignature() {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));

        if (userInfo.userGroup.id === 'USER_INVESTOR_INDIVIDUAL') {
            return userInfo.firstName.toLowerCase() === this.props.investorData['FIRST_NAME_FINAL'].chosenValue.toLowerCase()
                && userInfo.lastName.toLowerCase() === this.props.investorData['LAST_NAME_FINAL'].chosenValue.toLowerCase()
        } else {
            return userInfo.firstName.toLowerCase() === this.props.investorData['FIRST_NAME_FINAL_COMPANY'].chosenValue.toLowerCase()
                && userInfo.lastName.toLowerCase() === this.props.investorData['LAST_NAME_FINAL_COMPANY'].chosenValue.toLowerCase();
        }
    }

    nextStep(e) {
        e.preventDefault();

        this.props.fetchInvestorStatusByStep('STEP_FINAL_SUBMISSION')
            .then(response => {
                let nonMandatoryFields;

                if (this.state.userInfo.userGroup.id === 'USER_INVESTOR_COMPANY') {
                    nonMandatoryFields = ['RISK_DISCLOSURE_READ_INDIVIDUAL', 'FIRST_NAME_FINAL', 'LAST_NAME_FINAL', 'INFORMATION_IS_CORRECT'];
                } else {
                    nonMandatoryFields = ['RISK_DISCLOSURE_READ_COMPANY', 'FIRST_NAME_FINAL_COMPANY', 'LAST_NAME_FINAL_COMPANY', 'INFORMATION_IS_CORRECT_COMPANY'];
                }

                if ((this.props.investorData['RISK_DISCLOSURE_READ_INDIVIDUAL']
                    && this.props.investorData['RISK_DISCLOSURE_READ_INDIVIDUAL'].chosenValue === 'yes')
                    || (this.props.investorData['RISK_DISCLOSURE_READ_COMPANY']
                        && this.props.investorData['RISK_DISCLOSURE_READ_COMPANY'].chosenValue === 'yes')) {
                    let valid = this.props.validate(this.props.investorData, response.data, nonMandatoryFields)

                    if (valid.length === 0) {
                        if (this.checkSignature()) {
                            this.navigate(e, true);

                            // RegistrationService.setPendingState()
                            //     .then(res => {
                            //         this.navigate(e, true);
                            //
                            //         // localStorage.setItem('userInfo', JSON.stringify(res.data));
                            //         // this.props.history.push('/approval')
                            //     })
                            //     .catch(err => toast.error(err))
                        } else {
                            toast.error('First Name and Last Name must match those entered during registration.')
                        }
                    } else {
                        validationAlert(valid)
                    }
                } else
                    toast.error('Please read the whole disclosure');
            })
            .catch(err => {
                toast.error(err)
            })
    }

    previousStep(e) {
        this.navigate(e, false);
    }

    navigate(e, next) {
        e.preventDefault();

        this.props.setRegistrationStatus('STEP_FINAL_SUBMISSION', next)
    }

    render() {
        return (
            <>
                <Grid item md={12} lg={12}>
                    <Box mb="20px">
                        <Card raised>
                            <CardContent>
                                <Box pb="20px">
                                    <Typography variant="h6">
                                        Disclosures & Final Submission
                                    </Typography>

                                    <Divider/>
                                </Box>

                                <Box>
                                    <DocumentScroll
                                        pushInvestorChange={this.props.pushInvestorChange}
                                        attributeCode={this.state.userInfo.userGroup.id === 'USER_INVESTOR_INDIVIDUAL' ? 'RISK_DISCLOSURE_READ_INDIVIDUAL' : 'RISK_DISCLOSURE_READ_COMPANY'}/>
                                </Box>

                                <form>

                                    <Box display="flex" alignItems="center" mt="25px" mb="25px">
                                        <Typography>
                                            Read status:
                                        </Typography>

                                        <Box ml="10px">
                                            <Typography variant="h6">  {
                                                this.state.userInfo.userGroup.id === 'USER_INVESTOR_INDIVIDUAL'
                                                    ? (this.props.investorData['RISK_DISCLOSURE_READ_INDIVIDUAL']
                                                    && this.props.investorData['RISK_DISCLOSURE_READ_INDIVIDUAL'].chosenValue === 'yes') ? 'YES' : 'NO'
                                                    : (this.props.investorData['RISK_DISCLOSURE_READ_COMPANY']
                                                    && this.props.investorData['RISK_DISCLOSURE_READ_COMPANY'].chosenValue === 'yes') ? 'YES' : 'NO'

                                            } </Typography>
                                        </Box>

                                    </Box>

                                    <Box mt="35px" mb="25px">
                                        <div className="checkbox">
                                            <Checkbox
                                                label={
                                                    <Typography>
                                                        Information is Correct to the Best of my Knowledge
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                }
                                                attributeCode={this.state.userInfo.userGroup.id === 'USER_INVESTOR_INDIVIDUAL' ? 'INFORMATION_IS_CORRECT' : 'INFORMATION_IS_CORRECT_COMPANY'}
                                                pushInvestorChange={this.props.pushInvestorChange}
                                                defaultValue={this.state.userInfo.userGroup.id === 'USER_INVESTOR_INDIVIDUAL' ? this.props.investorData['INFORMATION_IS_CORRECT'] : this.props.investorData['INFORMATION_IS_CORRECT_COMPANY']}
                                                options={this.state.userInfo.userGroup.id === 'USER_INVESTOR_INDIVIDUAL' ? this.state['INFORMATION_IS_CORRECT'] : this.state['INFORMATION_IS_CORRECT_COMPANY']}
                                                attributes={[]}/>
                                        </div>
                                    </Box>

                                    <Grid container>
                                        <Grid item lg={12} xs={12}>
                                            <Grid item lg={6} xs={12}>
                                                <Box>
                                                    <Typography>
                                                        First Name
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Box>

                                                <Box pb={3}>
                                                    <InputText
                                                        attributeCode={this.state.userInfo.userGroup.id === 'USER_INVESTOR_INDIVIDUAL' ? 'FIRST_NAME_FINAL' : 'FIRST_NAME_FINAL_COMPANY'}
                                                        defaultValue={this.state.userInfo.userGroup.id === 'USER_INVESTOR_INDIVIDUAL' ? this.props.investorData['FIRST_NAME_FINAL'] : this.props.investorData['FIRST_NAME_FINAL_COMPANY']}
                                                        onBlurHandler={this.props.pushInvestorChange}
                                                        className="input__custom"/>
                                                </Box>

                                            </Grid>
                                        </Grid>

                                        <Grid item lg={12} xs={12}>
                                            <Grid item lg={6} xs={12}>
                                                <Box>
                                                    <Typography>
                                                        Last Name
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Box>

                                                <InputText
                                                    defaultValue={this.state.userInfo.userGroup.id === 'USER_INVESTOR_INDIVIDUAL' ? this.props.investorData['LAST_NAME_FINAL'] : this.props.investorData['LAST_NAME_FINAL_COMPANY']}
                                                    attributeCode={this.state.userInfo.userGroup.id === 'USER_INVESTOR_INDIVIDUAL' ? 'LAST_NAME_FINAL' : 'LAST_NAME_FINAL_COMPANY'}
                                                    onBlurHandler={this.props.pushInvestorChange}
                                                    className="input__custom"/>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/*<Box pt="25px" pb="20px">*/}
                                    {/*    <Divider/>*/}

                                    {/*    <Box pt="20px">*/}
                                    {/*        <ReCaptcha*/}
                                    {/*            ref={(el) => {*/}
                                    {/*                this.captchaDemo = el;*/}
                                    {/*            }}*/}
                                    {/*            size="normal"*/}
                                    {/*            data-theme="light"*/}
                                    {/*            render="explicit"*/}
                                    {/*            sitekey="6LfPUfYUAAAAANV9y2TFUGhv9nnfOICbPvr2AFra"*/}
                                    {/*            onloadCallback={this.onLoadRecaptcha}*/}
                                    {/*            verifyCallback={this.verifyCallback}/>*/}
                                    {/*    </Box>*/}

                                    {/*</Box>*/}
                                </form>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>

                <Grid item lg={12} xs={12}
                      style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '20px'}}>
                    <Button
                        variant='outlined'
                        style={{
                            background: '#FAFAFA',
                            marginRight: '5px'
                        }}
                        onClick={(e, step, path) => this.navigate(e, false)}>
                        Previous
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => this.nextStep(e)}
                        //disabled={!this.state.captcha}
                    >
                        Complete Initial Profile
                    </Button>
                </Grid>
            </>
        );
    }
}

export default FinalSubmissionPage;