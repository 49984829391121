import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import {Typography} from "@material-ui/core";
import UserService from "../repository/UserService";

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: null
    }
  }

  componentDidMount() {
    this.fetchUser();
  }

  fetchUser() {
    UserService.fetchUser()
      .then(response => {
        this.setState({
          user: response.data
        })

      }).catch(error => {
      console.log(error);
    })
  }

  render() {
    const name = this.state.user ? `${this.state.user.firstName} ${this.state.user.lastName}` : '';
    return (
        <AppBar position="fixed" className={this.props.headerClass}>
          {/*<Box flexGrow={1}>Welcome</Box>*/}
          <Box display="flex">
            <Box flexGrow={1}>

              <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={this.props.onClick}
                    className={this.props.classTest}
                    edge="start">
                  <MenuIcon style={{fontSize: '30px'}}/>

                </IconButton>
              </Toolbar>
            </Box>

            <Box pr="15px" display="flex" alignItems="center">

              <PermIdentityIcon style={{fontSize: '18px', color: '#fff'}}/>

              <Box
                  ml="10px"
                  mr={"45px"}>
                {/*{*/}
                {/*  this.props.investorData && this.props.investorData['FIRST_NAME_ON_ID'] && this.props.investorData['LAST_NAME_ON_ID']&&*/}
                {/*  (*/}
                {/*      <Typography>{this.props.investorData['FIRST_NAME_ON_ID'].chosenValue + ' ' + this.props.investorData['LAST_NAME_ON_ID'].chosenValue}</Typography>)}*/}


                      <Typography>{name}</Typography>
              </Box>

              <NavLink
                  style={{color: 'white'}}
                  to="/logout">

                <Box
                    display="flex"
                    justifyContent="center"
                    component="span">
                  <ExitToAppIcon style={{fontSize: '18px', color: '#fff'}}/>
                  <Box pl="10px" component="span">Logout</Box>

                </Box>
              </NavLink>
            </Box>
          </Box>
        </AppBar>
    );
  }
}

export default Header;