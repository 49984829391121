import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    loading: false,
}

export const loaderSlice = createSlice({
    name: "loader",
    initialState,
    reducers: {
        setLoader(state, action) {
            state.loading = true
        },
        unsetLoader(state, action) {
            state.loading = false
        },
    },
})

export const {setLoader, unsetLoader} = loaderSlice.actions

export const selectLoader = state => state.loader