import React, {Component} from 'react';
import DealDetailsService from "../../repository/DealDetailsService";
import "./css/IssuersDashboard.css"
import OfferingComposeModal from "./modals/OfferingComposeModal";
import OfferService from "../../repository/OfferService";
import {toast} from "react-toastify/index";
import RegTypeService from '../../repository/RegTypeService'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import LinearProgress from "@material-ui/core/LinearProgress";
import TableCell from "@material-ui/core/TableCell";
import Moment from "react-moment";
import Divider from "@material-ui/core/Divider";

class OfferingsIssuerPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deals: [],
            show: false,
            regTypes: [],
            loader: true
        }
    }

    componentDidMount() {
        document.body.setAttribute('class', 'dashboard-body');
        this.loadDeals();
        this.loadRegTypes();
    }

    loadDeals = () => {
        DealDetailsService.fetch().then((response) => {
            this.setState({
                deals: response.data,
                loader: false
            });
        })
    }

    loadRegTypes = () => {
        RegTypeService.fetch().then(data => {
            this.setState({
                regTypes: data.data
            })
        })
    }

    toggleNewOfferingModal = () => {
        this.setState({
            show: true
        })
    }

    handleClose = () => {
        this.setState({
            show: false
        })
    }

    createNewOffering = (offer) => {
        OfferService.save(offer).then(resp => {
            toast.success('Offer request is created!');
            this.loadDeals();
        })
    }

    onError = (e) => {
        console.log("ERROR:", e);
    }

    render() {
        return (
            <>
                <OfferingComposeModal
                    className="modal"
                    submit={this.createNewOffering}
                    show={this.state.show}
                    regTypes={this.state.regTypes}
                    close={this.handleClose}>
                </OfferingComposeModal>


                <Grid container spacing={3} style={{paddingTop: 80}}>
                    <Grid item lg={12} xs={12}>
                        <Typography variant='h6'>
                            Offerings
                        </Typography>

                        <Divider/>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item lg={12} xs={12}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Name</TableCell>
                                                        <TableCell>Raise goal</TableCell>
                                                        <TableCell>Share Price</TableCell>
                                                        <TableCell>Reg Types</TableCell>
                                                        <TableCell>% of goal</TableCell>
                                                        <TableCell># of investors</TableCell>
                                                        <TableCell>Date created</TableCell>
                                                        <TableCell>Status</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {this.state.loader ? (
                                                    <LinearProgress
                                                        variant="indeterminate"
                                                        color="primary"
                                                        style={{display: 'table-caption'}}
                                                    />
                                                ) : (
                                                    <TableBody>
                                                        {this.state.deals.map((item) => (
                                                                <TableRow>
                                                                    <TableCell>{item.deal.name} </TableCell>
                                                                    <TableCell>{item.deal.raiseTarget} </TableCell>
                                                                    <TableCell>{item.deal.sharePrice}</TableCell>
                                                                    <TableCell>
                                                                        {item.deal.regTypes.map(value => value.name).join(', ')}
                                                                    </TableCell>
                                                                    <TableCell>{item.amtRaised / item.deal.raiseTarget * 100}</TableCell>
                                                                    <TableCell>{item.numOfInvestors}</TableCell>
                                                                    <TableCell>
                                                                        <Moment
                                                                            format={'L LT'}>{item.deal.dateCreated}
                                                                        </Moment>
                                                                    </TableCell>
                                                                    <TableCell>{item.deal.dealStatus}</TableCell>
                                                                </TableRow>
                                                            )
                                                        )
                                                        }
                                                    </TableBody>
                                                )}
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default OfferingsIssuerPage;