import React, {Component} from 'react';
import "./css/IssuersDashboard.css"
import InvestmentService from "../../repository/InvestmentService";
import RegTypeReportService from "../../repository/RegTypeReportService";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import LinearProgress from "@material-ui/core/LinearProgress";
import TableBody from "@material-ui/core/TableBody";
import Moment from "react-moment";
import Divider from "@material-ui/core/Divider";

class InvestorsIssuerPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            investments: [],
            regTypeReport: [],
            loader: true
        }
    }

    componentDidMount() {
        document.body.setAttribute('class', 'dashboard-body');
        if (!this.props.match.params.code) {
            this.loadInvestments();
            this.loadRegTypeReport();
        } else {
            this.loadRegTypeReportPerDeal(this.props.match.params.code);
            this.loadInvestmentsPerDeal(this.props.match.params.code);
        }
        window.scrollTo(0, 0)
    }

    loadInvestments = () => {
        InvestmentService.fetchIssuerDealsInvestments()
            .then((response) => {
                this.setState({
                    investments: response.data,
                    loader: false
                });
            })
    }

    loadRegTypeReport = () => {
        RegTypeReportService.getByIssuerId().then(response => {
            let res = new Map();
            response.data.forEach(item => {
                if (res.has(item.name)) {
                    res.get(item.name).push(item);
                } else {
                    res.set(item.name, [item]);
                }
            });
            this.setState({regTypeReport: res})
        })
    }

    loadRegTypeReportPerDeal = (dealId) => {
        RegTypeReportService.getByDealId(dealId).then(response => {
            let res = new Map();
            response.data.forEach(item => {
                if (res.has(item.name)) {
                    res.get(item.name).push(item);
                } else {
                    res.set(item.name, [item]);
                }
            });
            this.setState({regTypeReport: res})
        })
    }

    loadInvestmentsPerDeal = (dealId) => {
        InvestmentService.fetchIssuerDealsInvestmentsPerDeal(dealId).then((response) => {

            this.setState({investments: response.data});
        })
    }

    render() {
        return (
            <>
                <Grid container spacing={3}
                      style={{paddingTop: 80}}>
                    <Grid item lg={12} xs={12}>
                        <Typography variant='h6'>
                            Investors
                        </Typography>

                        <Divider/>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item lg={12} xs={12}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Investor</TableCell>
                                                        <TableCell>Email</TableCell>
                                                        <TableCell>Amount</TableCell>
                                                        <TableCell>Created</TableCell>
                                                        <TableCell>Approved</TableCell>
                                                        <TableCell>Status</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {this.state.loader ? (
                                                    <LinearProgress
                                                        variant="indeterminate"
                                                        color="primary"
                                                        style={{display: 'table-caption'}}
                                                    />
                                                ) : (
                                                    <TableBody>
                                                        {this.state.investments.map((item) => (
                                                                <TableRow>
                                                                    <TableCell>{item.investorFirstName} {item.investorLastName}</TableCell>
                                                                    <TableCell>{item.investorEmail}</TableCell>
                                                                    <TableCell>{item.amount}</TableCell>
                                                                    <TableCell>
                                                                        <Moment format={'LLL'}>
                                                                            {item.dateCreated}
                                                                        </Moment>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.dateApproved ? (
                                                                            <Moment format={'LLL'}>
                                                                                {item.dateApproved}
                                                                            </Moment>
                                                                        ) : ''}
                                                                    </TableCell>
                                                                    <TableCell>{item.investmentStatus.value}</TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                    </TableBody>
                                                )}
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default InvestorsIssuerPage;