import React from 'react';

const PasswordInput = (props) => {
    let defaultValue = {
        chosenValue: ''
    };

    const handleOnBlur = (e) => {
        let value = e.target.value;
        if(props.capitalize) {
            value = value.charAt(0).toUpperCase() + value.slice(1);
        }
        let request = {
            "attributeCode": props.attributeCode,
            "value": value
        };
        props.onBlurHandler(request);
    };

    return (
        <input
            onBlur={(e) => handleOnBlur(e)}
            autoComplete="0"
            defaultValue={props.defaultValue ? props.defaultValue.chosenValue : defaultValue.chosenValue}
            type="password"
            className={props.className}/>
    );
};

export default PasswordInput;