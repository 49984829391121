import React, {Component} from 'react';
import IndividualDashboardRoutes from '../containers/routing/IndividualDashboard';
import CompanyDashboardRoutes from '../containers/routing/CompanyDashboard';
import IssuerDashboardRoutes from '../containers/routing/IssuerDashboard';
import Aux from '../hoc/AuxWrapper';
import InvestorService from "../repository/InvestorService";
import InvestorAttributeService from "../repository/InvestorAttributeService";
import DocumentService from "../repository/DocumentService";
import {toast} from "react-toastify";


class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            investorData: {},
            investorAttributeOptions: [],
            investor: null,
            currentStep: '',
            channels: [],
            messages: [],
        };
    }

    componentDidMount() {
        document.body.setAttribute('class', 'dashboard-body');
        this.fetchInvestorStatus();
        this.fetchInvestor();
    }

    pushInvestorChange = (request) => {
        return InvestorService.saveAttributeData(request).then((data) => {
            let investorData = this.state.investorData;
            investorData[data.data.attributeCode] = data.data;
            this.setState({
                ...this.state,
                investorData: investorData
            })
        })

    };

    fetchInvestor = () => {
        InvestorService.fetchInvestor()
            .then(response => {
                this.setState({
                        investor: response.data
                    }
                    // , () => {
                    //     this.fetchMessageChannels();
                    // }
                );
            }).catch(error => {
            console.log(error);
        });
    };

    // fetchMessageChannels = () => {
    //     ConversationService.fetchSubscriptions().then(data => {
    //         this.setState({channels: data.data});
    //         this.fetchMessagesPerChannel(data.data[0].channelId);
    //     });
    // };

    // fetchMessagesPerChannel = (channelId) => {
    //     ConversationService.fetchMessagesPerChannel(channelId)
    //         .then(data => {
    //             this.setState({messages: data.data});
    //         })
    // };

    updateInvestorInfo = (request) => {
        InvestorService.updateInvestorInfo(request)
            .then(response => {
                this.setState({
                    investor: response.data
                });
                toast.success('Saved!')
            }).catch(error => {
            toast.error(error)
        });
    };

    deleteInvestorData = (request) => {
        InvestorService.deleteAttributeData(request)
            .then(response => {
                let investorData = this.state.investorData;
                investorData[request.attributeCode] = {};
                this.setState({
                    ...this.state,
                    investorData: investorData
                });
            }).catch(error => {
            console.log(error);
        });
    };

    onDocumentDelete = (groupId, attrCode) => {
        this.deleteInvestorData({
            attributeCode: attrCode
        });
    };

    saveDocument = (groupId, file, attrCode) => {
        DocumentService.saveDocument(groupId, file)
            .then(response => {
                this.pushInvestorChange({
                    value: response.data.filename,
                    attributeCode: attrCode
                });
            }).catch(error => {
            console.log(error);
        });
    };

    fetchInvestorStatus = () => {
        InvestorService.fetchInvestorStatus()
            .then(response => {
                this.setState({
                    investorData: response.data
                });
            })
            .catch(error => {
                console.log(error);
            })

    };

    fetchAttributeOptions = (code) => {
        return InvestorAttributeService.getAttributeOptions(code);
    };

    render() {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        let routes = (
            <div/>
        );

        if (userInfo.userGroup.id === 'USER_INVESTOR_INDIVIDUAL') {
            routes = (
                <IndividualDashboardRoutes
                    path={this.props.path}
                    investor={this.state.investor}
                    updateInvestorInfo={this.updateInvestorInfo}
                    investorData={this.state.investorData}
                    onDocumentDelete={this.onDocumentDelete}
                    investorAttributeOptions={this.state.investorAttributeOptions}
                    fetchAttributeOptions={this.fetchAttributeOptions}
                    pushInvestorChange={this.pushInvestorChange}
                    deleteInvestorData={this.deleteInvestorData}
                    saveDocument={this.saveDocument}
                    validate={this.props.validate}
                    messages={this.state.messages}/>
            )
        } else if (userInfo.userGroup.id === 'USER_INVESTOR_COMPANY') {
            routes = (
                <CompanyDashboardRoutes
                    investor={this.state.investor}
                    updateInvestorInfo={this.updateInvestorInfo}
                    investorData={this.state.investorData}
                    onDocumentDelete={this.onDocumentDelete}
                    investorAttributeOptions={this.state.investorAttributeOptions}
                    fetchAttributeOptions={this.fetchAttributeOptions}
                    pushInvestorChange={this.pushInvestorChange}
                    deleteInvestorData={this.deleteInvestorData}
                    saveDocument={this.saveDocument}
                    validate={this.props.validate}/>
            )
        } else if (userInfo.userGroup.id === 'USER_ISSUER') {
            routes = (
                <IssuerDashboardRoutes
                    investor={this.state.investor}
                    updateInvestorInfo={this.updateInvestorInfo}
                    investorData={this.state.investorData}
                    onDocumentDelete={this.onDocumentDelete}
                    investorAttributeOptions={this.state.investorAttributeOptions}
                    fetchAttributeOptions={this.fetchAttributeOptions}
                    pushInvestorChange={this.pushInvestorChange}
                    deleteInvestorData={this.deleteInvestorData}
                    saveDocument={this.saveDocument}
                    validate={this.props.validate}/>
            )
        }

        return (
            <Aux>
                {routes}
            </Aux>
        );
    }
}

export default Dashboard;