import React, {Component} from 'react';

import Textarea from '../../components/input/Textarea';
import validationAlert from "../../components/ValidationAlert";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import DropZoneComponent from "../../components/input/DropZoneComponent";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {AUTH_TOKEN} from "../../shared/utility";

class BackgroundInfoPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nonMandatoryFields: ['COPY_OF_ARTICLES_OR_INC', 'OPERATING_AGREEMENT', 'COPY_OF_SS4', 'COPIES_OF_DRIVER_LICENSE', 'INSITUTIONAL_ACC_LETTER', 'FINCEN_CSS_fORM']
        }
    }

    componentDidMount() {
        this.props.setCurrentRegistrationStep('Background Info');
        window.scrollTo(0, 0)
    }


    changeStep(e) {
        e.preventDefault();
        this.props.fetchInvestorStatusByStep('STEP_BACKGROUND_INFO')
            .then(response => {
                let newMandatoryFields = [...this.state.nonMandatoryFields];

                let valid = this.props.validate(this.props.investorData, response.data, newMandatoryFields)
                if (valid.length === 0) {
                    this.navigate(e, true);
                } else validationAlert(valid);
            }).catch(err => {
            console.log(err);
        });
    }

    previousStep(e) {
        e.preventDefault();

        this.navigate(e, false);
    }

    navigate(e, next) {
        e.preventDefault();
        this.props.setRegistrationStatus('STEP_BACKGROUND_INFO', next)
        //     .then(res => {
        //         localStorage.setItem('userInfo', JSON.stringify(res.data));
        //         this.props.history.push(path);
        //     }).catch(err => {
        // });
    }

    saveProgress = () => {
        this.props.history.push("/logout")
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                    <Card raised>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item lg={12} xs={12}>
                                    <Typography
                                        variant="h6">
                                        Background Information
                                    </Typography>

                                    <Divider/>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <Typography>
                                        Source of funds:
                                        <span className="required__circle"> *</span>
                                    </Typography>
                                    <Textarea
                                        defaultValue={this.props.investorData['STATEMENT_OWN_WORDS']}
                                        attributeCode="STATEMENT_OWN_WORDS"
                                        onBlurHandler={this.props.pushInvestorChange}
                                        className="custom__textAria"
                                        rows="10"/>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={6} xs={12}>
                    <Card raised>
                        <CardContent>
                            <Grid container spacing={3}>

                                <Grid item lg={12} xs={12}>
                                    <Typography variant="h6">
                                        Documentation
                                    </Typography>

                                    <Divider/>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <Typography>
                                        Please upload all the documents below that
                                        apply to your Institution:
                                    </Typography>
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    <DropZoneComponent attributeCode={'COPY_OF_ARTICLES_OR_INC'}
                                                       headText={'Copy of Articles of Incorporation Certificate of Domicile (Date of Formation)'}
                                                       innerText={'Please upload a Copy of Articles of Incorporation Certificate of Domicile (Date of Formation).'}
                                                       currentValue={this.props.investorData['COPY_OF_ARTICLES_OR_INC']}
                                                       accept={'.jpg, .png, .pdf'}
                                                       documentGroupCode={'articles-of-incorporation-certificate-of-domicile'}
                                                       onFileSelected={this.props.saveDocument}
                                                       onDocumentDelete={this.props.onDocumentDelete}
                                                       fileNumber="file-5"/>
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    <DropZoneComponent attributeCode={'OPERATING_AGREEMENT'}
                                                       headText={'Operating Agreement'}
                                                       innerText={'Please upload a Operating Agreement.'}
                                                       currentValue={this.props.investorData['OPERATING_AGREEMENT']}
                                                       accept={'.jpg, .png, .pdf'}
                                                       documentGroupCode={'operating-agreement'}
                                                       onFileSelected={this.props.saveDocument}
                                                       onDocumentDelete={this.props.onDocumentDelete}
                                                       fileNumber="file-6"/>
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    <DropZoneComponent attributeCode={'COPY_OF_SS4'}
                                                       headText={'Copy of SS4/tax doc that reflects companies EIN'}
                                                       innerText={'Please upload a Copy of SS4/tax doc that reflects companies EIN.'}
                                                       currentValue={this.props.investorData['COPY_OF_SS4']}
                                                       accept={'.jpg, .png, .pdf'}
                                                       documentGroupCode={'ss4-tax-doc'}
                                                       onDocumentDelete={this.props.onDocumentDelete}
                                                       onFileSelected={this.props.saveDocument}
                                                       fileNumber="file-7"/>
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    <DropZoneComponent attributeCode={'COPIES_OF_DRIVER_LICENSE'}
                                                       headText={'Copies of the driver\'s license/passport etc for POC & Officers'}
                                                       innerText={'Please upload a Copies of the driver\'s license/passport etc for POC & Officers.'}
                                                       currentValue={this.props.investorData['COPIES_OF_DRIVER_LICENSE']}
                                                       accept={'.jpg, .png, .pdf'}
                                                       documentGroupCode={'drivers-licence-passport'}
                                                       onFileSelected={this.props.saveDocument}
                                                       onDocumentDelete={this.props.onDocumentDelete}
                                                       fileNumber="file-8"/>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <DropZoneComponent attributeCode={'INSITUTIONAL_ACC_LETTER'}
                                                       headText={'Institutional accreditation letter'}
                                                       innerText={'Please upload a Institutional accreditation letter.'}
                                                       currentValue={this.props.investorData['INSITUTIONAL_ACC_LETTER']}
                                                       accept={'.jpg, .png, .pdf'}
                                                       documentGroupCode={'insitutional-accreditation-letter'}
                                                       onFileSelected={this.props.saveDocument}
                                                       onDocumentDelete={this.props.onDocumentDelete}
                                                       fileNumber="file-9"/>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <DropZoneComponent attributeCode={'UTILITY_BILL'}
                                                       headText={'Utility bill or lease agreement'}
                                                       innerText={'Please upload a Utility bill or lease agreement for proof of address.'}
                                                       currentValue={this.props.investorData['UTILITY_BILL']}
                                                       accept={'.jpg, .png, .pdf'}
                                                       documentGroupCode={'utility-bill'}
                                                       onFileSelected={this.props.saveDocument}
                                                       onDocumentDelete={this.props.onDocumentDelete}
                                                       fileNumber="file-11"/>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <DropZoneComponent attributeCode={'REGISTER_MANAGERS'}
                                                       headText={'"Register of Managers" document, Memorandum of Association and BOD meeting'}
                                                       innerText={'Please upload a "Register of Managers" document, Memorandum of Association and BOD meeting if available.'}
                                                       currentValue={this.props.investorData['REGISTER_MANAGERS']}
                                                       accept={'.jpg, .png, .pdf'}
                                                       documentGroupCode={'register-managers'}
                                                       onFileSelected={this.props.saveDocument}
                                                       onDocumentDelete={this.props.onDocumentDelete}
                                                       fileNumber="file-12"/>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <DropZoneComponent attributeCode={'FINCEN_CSS_fORM'}
                                                       headText={
                                                           <>
                                                               {/*<a href="https://www.fincen.gov/sites/default/files/shared/CDD_Rev6.7_Sept_2017_Certificate.pdf"*/}
                                                               {/*   target="_blank"*/}
                                                               {/*   rel="noopener noreferrer"> */}
                                                               {/*    FinCen CDD Form */}
                                                               {/*</a>*/}

                                                               <a href={process.env.REACT_APP_DMS_GATEWAY +
                                                               '/v1/api/document/download/fincen-cdd-form.pdf?access_token=' +
                                                               localStorage.getItem(AUTH_TOKEN) + '&embedded=true'}
                                                                  target="_blank"
                                                                  rel="noopener noreferrer">
                                                                   FinCen CDD Form </a>
                                                               (Please fill out, save to computer & upload)
                                                           </>
                                                       }
                                                       innerText={
                                                           <>Please upload a
                                                               <a href={process.env.REACT_APP_DMS_GATEWAY +
                                                               '/v1/api/document/download/fincen-cdd-form.pdf?access_token=' +
                                                               localStorage.getItem(AUTH_TOKEN) + '&embedded=true'}
                                                                  target="_blank"
                                                                  rel="noopener noreferrer"
                                                               > FinCen CDD Form </a>
                                                               (fill out, save to computer & upload.)
                                                           </>}
                                                       currentValue={this.props.investorData['FINCEN_CSS_fORM']}
                                                       accept={'.jpg, .png, .pdf'}
                                                       documentGroupCode={'fincen-cdd-form'}
                                                       onFileSelected={this.props.saveDocument}
                                                       onDocumentDelete={this.props.onDocumentDelete}
                                                       fileNumber="file-10"/>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={12} xs={12} style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '30px'}}>
                    <Button variant='outlined'
                            style={{
                                background: '#FAFAFA',
                                marginRight: '5px'
                            }}
                            onClick={(e) => this.previousStep(e)}>
                        Previous
                    </Button>

                    <Button variant='outlined'
                            color='primary'
                            style={{
                                marginRight: '5px '
                            }}
                            onClick={() => this.saveProgress()}>
                        Save Progress & Come Back Later
                    </Button>

                    <Button variant='contained'
                            color='primary'
                            onClick={(e) => this.changeStep(e)}>
                        Next
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

export default BackgroundInfoPage;