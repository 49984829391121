import * as actionTypes from '../actions/actionTypes';

const initialState = {
    currentStep: ''
};

const setRegistrationStep = (state, action) => {
  return {
      ...state,
      currentStep: action.payload
  }
};

const registrationSteps = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_REGISTRATION_STEP:
            return setRegistrationStep(state, action);
        default:
            return state;
    }
};

export default registrationSteps;