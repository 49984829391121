import React, {Component} from 'react';

import Checkbox from '../../components/input/Checkbox';
import Textarea from '../../components/input/Textarea';
import validationAlert from "../../components/ValidationAlert";
import {CardContent, Grid, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";

class InvestorStatusPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'A_MEMBER_OR_EMP': [],
            'EMPL_BROKER': [],
            'SENIOR_OFFICER_BANK': [],
            'EMPLOYEE_INVESTMENT': [],
            'EMPLOYEE_STATE': [],
            'PERSON_SEC': [],
            'PERSON_CONTR': [],
            'RELATIONSHIP_INST': [],
            'PEP': [],
            nonMandatoryFields: ['A_MEMBER_OR_EMP', 'EMPL_BROKER', 'SENIOR_OFFICER_BANK', 'EMPLOYEE_INVESTMENT', 'EMPLOYEE_STATE', 'PERSON_SEC', 'PERSON_CONTR', 'IF_CHECKED_ANY', 'PEP']
        };
    }

    componentDidMount() {
        this.props.setCurrentRegistrationStep('Investor Status');
        this.init();
        window.scrollTo(0, 0)
    }

    init() {
        this.props.fetchAttributeOptions('A_MEMBER_OR_EMP').then((data) => {
            this.setState({
                ...this.state,
                'A_MEMBER_OR_EMP': data.data
            });
        });

        this.props.fetchAttributeOptions('EMPL_BROKER').then((data) => {
            this.setState({
                ...this.state,
                'EMPL_BROKER': data.data
            });
        });

        this.props.fetchAttributeOptions('SENIOR_OFFICER_BANK').then((data) => {
            this.setState({
                ...this.state,
                'SENIOR_OFFICER_BANK': data.data
            });
        });

        this.props.fetchAttributeOptions('EMPLOYEE_INVESTMENT').then((data) => {
            this.setState({
                ...this.state,
                'EMPLOYEE_INVESTMENT': data.data
            });
        });

        this.props.fetchAttributeOptions('EMPLOYEE_STATE').then((data) => {
            this.setState({
                ...this.state,
                'EMPLOYEE_STATE': data.data
            });
        });

        this.props.fetchAttributeOptions('PERSON_SEC').then((data) => {
            this.setState({
                ...this.state,
                'PERSON_SEC': data.data
            });
        });

        this.props.fetchAttributeOptions('PERSON_CONTR').then((data) => {
            this.setState({
                ...this.state,
                'PERSON_CONTR': data.data
            });
        });

        this.props.fetchAttributeOptions('PEP').then((data) => {
            this.setState({
                ...this.state,
                'PEP': data.data
            });
        });

        this.props.fetchAttributeOptions('RELATIONSHIP_INST').then((data) => {
            this.setState({
                ...this.state,
                'RELATIONSHIP_INST': data.data
            });
        });
    }

    previousStep(e) {
        e.preventDefault();

        this.navigate(e, false);
    }

    changeStep(e, step, path) {

        e.preventDefault();
        this.props.fetchInvestorStatusByStep('STEP_INVESTOR_STATUS')
            .then(response => {

                let newNonMandatoryFields = [...this.state.nonMandatoryFields];
                this.state.nonMandatoryFields.forEach(f => {
                    if (f !== undefined && f !== '' && f !== 'IF_CHECKED_ANY') {
                        if (this.props.investorData[f] !== undefined && this.props.investorData[f].chosenValue === 'true') {
                            newNonMandatoryFields = newNonMandatoryFields.filter(f => f !== 'IF_CHECKED_ANY');
                        }
                    }
                });
                let valid = this.props.validate(this.props.investorData, response.data, newNonMandatoryFields);
                if (valid.length === 0) {
                    this.navigate(e, true);
                } else validationAlert(valid);
            }).catch(err => {
            alert(err);
        });
    }

    navigate(e, next) {
        e.preventDefault();

        this.props.setRegistrationStatus('STEP_INVESTOR_STATUS', next)
    }

    saveProgress = () => {
        this.props.history.push("/logout")
    }

    render() {
        return (
            <>
                <Grid item md={12} lg={12}>
                    <Box mb="20px">
                        <Card raised>
                            <CardContent>
                                <Box pb="40px" pt="20px">
                                    <Typography variant="h6">
                                        Check all applicable boxes and provide an explanation in the
                                        space provided if you or
                                        if an immediate family member (spouse, brother, sister, parent, child,
                                        mother-in-law, father-in-law, sister-in-law, brother-in-law, daughter-in-law,
                                        son-in-law), or any other person who supports you or who you support to a
                                        material
                                        extent, or an additional account holder, is a described person below:
                                    </Typography>
                                </Box>

                                <Grid container spacing={3}>
                                    <Grid item lg={8}>
                                        <form>
                                            <div className="checkbox">
                                                <Checkbox
                                                    label={
                                                        'A member or employee of any securities, options or commodities\n' +
                                                        'Exchange or other Self-Regulatory Organization including a\n' +
                                                        'registered securities association, registered clearing\n' +
                                                        'organization or the Municipal Securities Rulemaking Board.'
                                                    }
                                                    attributeCode="A_MEMBER_OR_EMP"
                                                    pushInvestorChange={this.props.pushInvestorChange}
                                                    defaultValue={this.props.investorData['A_MEMBER_OR_EMP']}
                                                    options={this.state['A_MEMBER_OR_EMP']}
                                                    attributes={[]}/>
                                            </div>

                                            <div className="checkbox">
                                                <Checkbox
                                                    label={' An employee of a broker/dealer or other member of the Financial\n' +
                                                    '                                                    Industry Regulatory Authority (FINRA).'}
                                                    attributeCode="EMPL_BROKER"
                                                    pushInvestorChange={this.props.pushInvestorChange}
                                                    defaultValue={this.props.investorData['EMPL_BROKER']}
                                                    options={this.state['EMPL_BROKER']}
                                                    attributes={[]}/>
                                            </div>

                                            <div className="checkbox">
                                                <Checkbox
                                                    label={'A senior officer of a bank, savings and loan institution,\n' +
                                                    '                                                    insurance company, investment company, or investment advisory\n' +
                                                    '                                                    firm, or other financial institution.'}
                                                    attributeCode="SENIOR_OFFICER_BANK"
                                                    pushInvestorChange={this.props.pushInvestorChange}
                                                    defaultValue={this.props.investorData['SENIOR_OFFICER_BANK']}
                                                    options={this.state['SENIOR_OFFICER_BANK']}
                                                    attributes={[]}/>
                                            </div>

                                            <div className="checkbox">
                                                <Checkbox
                                                    label={'An employee of an investment advisor.'}
                                                    attributeCode="EMPLOYEE_INVESTMENT"
                                                    pushInvestorChange={this.props.pushInvestorChange}
                                                    defaultValue={this.props.investorData['EMPLOYEE_INVESTMENT']}
                                                    options={this.state['EMPLOYEE_INVESTMENT']}
                                                    attributes={[]}/>
                                            </div>

                                            <div className="checkbox">
                                                <Checkbox
                                                    label={'An employee of a state or federal securities regulator.'}
                                                    attributeCode="EMPLOYEE_STATE"
                                                    pushInvestorChange={this.props.pushInvestorChange}
                                                    defaultValue={this.props.investorData['EMPLOYEE_STATE']}
                                                    options={this.state['EMPLOYEE_STATE']}
                                                    attributes={[]}/>
                                            </div>

                                            <div className="checkbox">
                                                <Checkbox
                                                    label={'A person in the securities department of any of the entities\n' +
                                                    '                                                    listed above, or a person in a position to influence, or whose\n' +
                                                    '                                                    activities directly or indirectly involve, or are related to, the\n' +
                                                    '                                                    function of buying or selling securities for any such entites.'}
                                                    attributeCode="PERSON_SEC"
                                                    pushInvestorChange={this.props.pushInvestorChange}
                                                    defaultValue={this.props.investorData['PERSON_SEC']}
                                                    options={this.state['PERSON_SEC']}
                                                    attributes={[]}/>
                                            </div>

                                            <div className="checkbox">
                                                <Checkbox
                                                    label={'A person who has contributed to the equity or capital of a\n' +
                                                    '                                                    broker/dealer, directly or indirectly, in an amount that exceeds\n' +
                                                    '                                                    10% of the broker/dealer\'s equity or capital.'}
                                                    attributeCode="PERSON_CONTR"
                                                    pushInvestorChange={this.props.pushInvestorChange}
                                                    defaultValue={this.props.investorData['PERSON_CONTR']}
                                                    options={this.state['PERSON_CONTR']}
                                                    attributes={[]}/>
                                            </div>

                                            <div className="checkbox">
                                                <Checkbox
                                                    label={'A PEP - Politically Exposed Person \n' +
                                                    '(You or any of your immediate family member is a senior political figure, ' +
                                                    'defined as a current or former senior official in the executive, \n' +
                                                    'legislative, administrative, military or judicial branches of a government (whether elected or not), ' +
                                                    'a senior official of a political party, a senior \n' +
                                                    'executive of a government-owned enterprise, corporation, business ' +
                                                    'or entity formed by or for the benefit of such individual)'}
                                                    attributeCode="PEP"
                                                    pushInvestorChange={this.props.pushInvestorChange}
                                                    defaultValue={this.props.investorData['PEP']}
                                                    options={this.state['PEP']}
                                                    attributes={[]}/>
                                            </div>

                                            {
                                                (this.props.investorData['A_MEMBER_OR_EMP']
                                                    && this.props.investorData['A_MEMBER_OR_EMP'].chosenValue === 'true')
                                                || (this.props.investorData['EMPL_BROKER']
                                                    && this.props.investorData['EMPL_BROKER'].chosenValue === 'true')
                                                || (this.props.investorData['SENIOR_OFFICER_BANK']
                                                    && this.props.investorData['SENIOR_OFFICER_BANK'].chosenValue === 'true')
                                                || (this.props.investorData['EMPLOYEE_INVESTMENT']
                                                    && this.props.investorData['EMPLOYEE_INVESTMENT'].chosenValue === 'true')
                                                || (this.props.investorData['EMPLOYEE_STATE']
                                                    && this.props.investorData['EMPLOYEE_STATE'].chosenValue === 'true')
                                                || (this.props.investorData['PERSON_SEC']
                                                    && this.props.investorData['PERSON_SEC'].chosenValue === 'true')
                                                || (this.props.investorData['PERSON_CONTR']
                                                    && this.props.investorData['PERSON_CONTR'].chosenValue === 'true')
                                                    ? <Box mt="40px" mb="25px">
                                                        <Typography>
                                                            If you checked any of the above, please explain in detail:
                                                            <span className="required__circle"> *</span>
                                                        </Typography>

                                                        <Textarea
                                                            defaultValue={this.props.investorData['IF_CHECKED_ANY']}
                                                            attributeCode="IF_CHECKED_ANY"
                                                            onBlurHandler={this.props.pushInvestorChange}
                                                            className="input__custom"
                                                            rows="3"/>
                                                    </Box>
                                                    : ''

                                            }
                                        </form>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>

                <Grid item lg={12} xs={12}
                      style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '20px'}}>
                    <Button
                        variant='outlined'
                        style={{
                            background: '#FAFAFA',
                            marginRight: '5px'
                        }}
                        onClick={(e, step, path) => this.previousStep(e)}>
                        Previous
                    </Button>

                    <Button variant='outlined'
                            color='primary'
                            style={{
                                marginRight: '5px'
                            }}
                            onClick={() => this.saveProgress()}>
                        Save Progress & Come Back Later
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        disabled={this.state.errorEmailValid}
                        onClick={(e) => this.changeStep(e, 'STEP_FINAL_SUBMISSION', '/final-submission')}>
                        Next
                    </Button>
                </Grid>
            </>
        );
    }
}

export default InvestorStatusPage;