import React, {Component} from 'react';
import Aux from '../../hoc/AuxWrapper';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

class MultiCheckbox extends Component {

    onChangeHandler = (checked) => {
        if (checked) {
            this.props.pushInvestorChange({
                attributeCode: this.props.attributeCode,
                value: this.props.optionValue,
                investorAttributeOptionId: this.props.optionId
            });

            // added for sectors state management because of multiple select input
            if (this.props.stateChange) {
                this.props.stateChange(this.props.optionValue, 'ADD')
            }
        } else {
            this.props.deleteInvestorData({
                attributeCode: this.props.attributeCode,
                investorAttributeOptionId: this.props.optionId
            });

            if (this.props.stateChange) {
                this.props.stateChange(this.props.optionValue, 'REMOVE')
            }

            this.forceUpdate();
        }
    };

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.defaultValue && Array.isArray(nextProps.defaultValue);

    }

    render() {
        return (
            <Aux>
                <FormControlLabel
                    label={this.props.label}
                    control={
                        <Checkbox
                            id={this.props.label}
                            className=""
                            color="primary"
                            onChange={(e) => this.onChangeHandler(e.target.checked)}
                            checked={this.props.defaultValue && Array.isArray(this.props.defaultValue)
                            && this.props.defaultValue.find(v => v.chosenValue === this.props.optionValue)}
                            type="checkbox"/>
                    }/>
            </Aux>
        );
    }
}

export default MultiCheckbox;