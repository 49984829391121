import axios from "../custom-axios/app"

const SignatureService = {

    fetchSignatureDocuments: (offeringId) => {
        return axios.get('/api/signatures/documents/' + offeringId)
    },

    fetchSignatureStatus: (id) => {
        return axios.get(`/api/signatures/${id}/status`)
    },

    fetchSignature: (id) => {
        return axios.get(`/api/signatures/${id}`)
    }

}

export default SignatureService