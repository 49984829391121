import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import SignUp from "../registration/SignUp";
import Logout from "../../components/Logout";
import ChoosePassword from "../registration/ChoosePassword";
import Login from "../Login/Login";
import ChooseUserType from "../registration/ChooseUserType";
import ChooseNextAction from "../registration/ChooseNextAction";

const accountVerification = (props) => {
  return (
      <Switch>
          <Route path="/login" exact component={Login}/>
          <Route path="/sign-up" exact component={SignUp}/>
          <Route path="/choose-password" exact component={ChoosePassword}/>
          <Route path="/choose-user-type" exact component={ChooseUserType}/>
          <Route path="/choose-next-action" exact component={ChooseNextAction}/>
          <Route path="/logout" exact component={Logout}/>
          <Redirect to="/choose-password"/>
      </Switch>
  );
};

export default accountVerification;