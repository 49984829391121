import React, {Component} from 'react';
import Select from '../../components/input/Select1';
import InputText from "../../components/input/InputText";
import MultiCheckbox from '../../components/input/MultiCheckbox';
import validationAlert from "../../components/ValidationAlert";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {CardContent, Divider} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";

class SuitabilityPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'ANNUAL_INCOME': [],
            'TAX_RATE_ID': [],
            'TOTAL_NET_WORTH': [],
            'LIQUIDITY_NEEDS': [],
            'TOTAL_NET_WORTH_2': [],
            'EMPLOYMENT_STATUS_2': [],
            'INDUSTRY_2': [],
            'Stocks': [],
            'ANNUITIES': [],
            'ALT': [],
            'Options': [],
            'MARGIN': [],
            'Bonds': [],
            'MUTUAL_FUNDS': [],
            'FUTURES': [],
            'PRIVATE_PLACEMENTS': [],
            'INVEST_OBJ': [],
            'TIME_H': [],
            'RISK_TOL': [],
            'AVERAGE_INVESTMENT_SIZE': [],
            'SECTORS': [],
            'DIGITAL_ASSETS': [],
            nonMandatoryFields: ['OTHER_SECTOR', 'SHORT_SALES'],
            selectedSectors: []
        };
    }

    componentDidMount() {
        // this.props.setCurrentRegistrationStep('Suitability');
        this.init();
        window.scrollTo(0, 0)
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (Array.isArray(nextProps.investorData['SECTORS']) && this.state.selectedSectors.length === 0) {
            this.setState({
                selectedSectors: nextProps.investorData['SECTORS'].map(s => s.chosenValue)
            }, () => {
                return false;
            })
        }
        return true;
    }

    onSectorChange(sector, action) {
        let newSelectedSectors = [...this.state.selectedSectors];


        if (action === 'ADD')
            newSelectedSectors.push(sector);
        else if (action === 'REMOVE')
            newSelectedSectors = newSelectedSectors.filter(s => s !== sector);


        this.setState({
            selectedSectors: newSelectedSectors
        })
    }

    init() {
        this.props.fetchAttributeOptions('SECTORS').then((data) => {
            this.setState({
                ...this.state,
                'SECTORS': data.data
            });

        });

        this.props.fetchAttributeOptions('AVERAGE_INVESTMENT_SIZE').then((data) => {
            this.setState({
                ...this.state,
                'AVERAGE_INVESTMENT_SIZE': data.data
            });
        });

        this.props.fetchAttributeOptions('RISK_TOL').then((data) => {
            this.setState({
                ...this.state,
                'RISK_TOL': data.data
            });
        });

        this.props.fetchAttributeOptions('TIME_H').then((data) => {
            this.setState({
                ...this.state,
                'TIME_H': data.data
            });
        });

        this.props.fetchAttributeOptions('INVEST_OBJ').then((data) => {
            this.setState({
                ...this.state,
                'INVEST_OBJ': data.data
            });
        });

        this.props.fetchAttributeOptions('PRIVATE_PLACEMENTS').then((data) => {
            this.setState({
                ...this.state,
                'PRIVATE_PLACEMENTS': data.data
            });
        });

        this.props.fetchAttributeOptions('DIGITAL_ASSETS').then((data) => {
            this.setState({
                ...this.state,
                'DIGITAL_ASSETS': data.data
            });
        });

        this.props.fetchAttributeOptions('FUTURES').then((data) => {
            this.setState({
                ...this.state,
                'FUTURES': data.data
            });
        });

        this.props.fetchAttributeOptions('MUTUAL_FUNDS').then((data) => {
            this.setState({
                ...this.state,
                'MUTUAL_FUNDS': data.data
            });
        });

        this.props.fetchAttributeOptions('Bonds').then((data) => {
            this.setState({
                ...this.state,
                'Bonds': data.data
            });
        });

        this.props.fetchAttributeOptions('MARGIN').then((data) => {
            this.setState({
                ...this.state,
                'MARGIN': data.data
            });
        });

        this.props.fetchAttributeOptions('ANNUAL_INCOME').then((data) => {
            this.setState({
                ...this.state,
                'ANNUAL_INCOME': data.data
            });
        });

        this.props.fetchAttributeOptions('TAX_RATE_ID').then((data) => {
            this.setState({
                ...this.state,
                'TAX_RATE_ID': data.data
            });
        });

        this.props.fetchAttributeOptions('TOTAL_NET_WORTH').then((data) => {
            this.setState({
                ...this.state,
                'TOTAL_NET_WORTH': data.data
            });
        });

        this.props.fetchAttributeOptions('LIQUIDITY_NEEDS').then((data) => {
            this.setState({
                ...this.state,
                'LIQUIDITY_NEEDS': data.data
            });
        });

        this.props.fetchAttributeOptions('TOTAL_NET_WORTH_2').then((data) => {
            this.setState({
                ...this.state,
                'TOTAL_NET_WORTH_2': data.data
            });
        });

        this.props.fetchAttributeOptions('EMPLOYMENT_STATUS_2').then((data) => {
            this.setState({
                ...this.state,
                'EMPLOYMENT_STATUS_2': data.data
            });
        });

        this.props.fetchAttributeOptions('INDUSTRY_2').then((data) => {
            this.setState({
                ...this.state,
                'INDUSTRY_2': data.data
            });
        });

        this.props.fetchAttributeOptions('Stocks').then((data) => {
            this.setState({
                ...this.state,
                'Stocks': data.data
            });
        });

        this.props.fetchAttributeOptions('ANNUITIES').then((data) => {
            this.setState({
                ...this.state,
                'ANNUITIES': data.data
            });
        });

        this.props.fetchAttributeOptions('ALT').then((data) => {
            this.setState({
                ...this.state,
                'ALT': data.data
            });
        });

        this.props.fetchAttributeOptions('Options').then((data) => {
            this.setState({
                ...this.state,
                'Options': data.data
            });
        });
    }

    // nextStep(e) {
    //     e.preventDefault();
    //
    //     this.navigate(e);
    //
    //     // this.props.setRegistrationStatus('STEP_INVESTOR_STATUS')
    //     //     .then(res => {
    //     //         localStorage.setItem('userInfo', JSON.stringify(res.data));
    //     //         this.props.history.push('/investor-status');
    //     //     }).catch(err => {
    //     //     alert(err)
    //     // });
    // }

    changeStep(e, step, path) {
        e.preventDefault();

        this.props.fetchInvestorStatusByStep('STEP_SUITABILITY')
            .then(response => {
                let newNonMandatoryFields = [...this.state.nonMandatoryFields];

                if (this.props.investorData['SECTORS'] && Array.isArray(this.props.investorData['SECTORS'])
                    && this.props.investorData['SECTORS'].find(s => s.chosenValue === 'Other')) {
                    newNonMandatoryFields = newNonMandatoryFields.filter(f => f !== 'OTHER_SECTOR');
                }

                let valid = this.props.validate(this.props.investorData, response.data, newNonMandatoryFields);

                if (valid.length === 0) {
                    this.navigate(e, false);
                } else validationAlert(valid)
            }).catch(err => {
            alert(err);
        });
    }

    navigate(e, next) {
        e.preventDefault();

        this.props.setRegistrationStatus('STEP_SUITABILITY', next)
    }

    saveProgress = () => {
        this.props.history.push("/logout")
    }

    render() {
        return (

            <>

                <Grid item md={12} lg={12}>
                    <Box mb="20px">
                        <Card raised>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item lg={12}>
                                        <Typography variant="h6">
                                            Financials
                                        </Typography>

                                        <Divider/>
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <Grid container spacing={3}>
                                            <Grid item md={8} lg={4} xs={12}>
                                                <Grid item lg={12} xs={12}>
                                                    <Typography
                                                        gutterBottom>
                                                        Annual Income from all sources in US $
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={12} xs={12}>
                                                    <Box>
                                                        <Select
                                                            className="select__custom"
                                                            dataLiveSearch="true"
                                                            dataStyle="select__custom"
                                                            dataWidth="100%"
                                                            attributeCode="ANNUAL_INCOME"
                                                            selectedValue={this.props.investorData['ANNUAL_INCOME']}
                                                            onChange={this.props.pushInvestorChange}
                                                            options={this.state['ANNUAL_INCOME']}/>
                                                    </Box>
                                                </Grid>
                                            </Grid>

                                            <Grid item md={8} lg={4} xs={12}>
                                                <Grid item lg={12} xs={12}>
                                                    <Typography
                                                        gutterBottom>
                                                        Total Net Worth in US $
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={12} xs={12}>
                                                    <Select
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="TOTAL_NET_WORTH"
                                                        selectedValue={this.props.investorData['TOTAL_NET_WORTH']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['TOTAL_NET_WORTH']}/>
                                                </Grid>
                                            </Grid>

                                            <Grid item md={8} lg={4} xs={12}>
                                                <Grid item lg={12} xs={12}>
                                                    <Typography
                                                        gutterBottom>
                                                        Employment Status
                                                        <span className="required__circle"> *</span>
                                                    </Typography>

                                                </Grid>

                                                <Grid item lg={12} xs={12}>
                                                    <Select
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="EMPLOYMENT_STATUS_2"
                                                        selectedValue={this.props.investorData['EMPLOYMENT_STATUS_2']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['EMPLOYMENT_STATUS_2']}/>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item lg={12}>
                                        <Grid style={{marginBottom: '20px'}} container spacing={3}>
                                            <Grid item md={8} lg={4} xs={12}>
                                                <Grid item lg={12} xs={12}>
                                                    <Typography
                                                        gutterBottom>
                                                        Tax Rate
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={12} xs={12}>
                                                    <Grid container spacing={3}>
                                                        <Grid item lg={12} xs={12}>
                                                            <Select
                                                                className="input__custom"
                                                                dataLiveSearch="true"
                                                                dataStyle="input__custom"
                                                                dataWidth="100%"
                                                                attributeCode="TAX_RATE_ID"
                                                                selectedValue={this.props.investorData['TAX_RATE_ID']}
                                                                onChange={this.props.pushInvestorChange}
                                                                options={this.state['TAX_RATE_ID']}/>

                                                            <Box pt="5px">
                                                                <Typography
                                                                    variant="caption"
                                                                    display="block"
                                                                    gutterBottom>
                                                                    Your highest marginal rate (rate on last dollar of
                                                                    taxable
                                                                    income earned)
                                                                    <span className="required__circle"> *</span>
                                                                </Typography>
                                                            </Box>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item md={8} lg={4} xs={12}>

                                                <Grid item lg={12} xs={12}>
                                                    <Typography
                                                        gutterBottom>
                                                        Average Investment Size
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={12} xs={12}>
                                                    <Select
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="AVERAGE_INVESTMENT_SIZE"
                                                        selectedValue={this.props.investorData['AVERAGE_INVESTMENT_SIZE']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['AVERAGE_INVESTMENT_SIZE']}/>

                                                </Grid>
                                            </Grid>
                                            <Grid item md={8} lg={4} xs={12}>
                                                <Grid item lg={12} xs={12}>
                                                    <Typography
                                                        gutterBottom>
                                                        Job Title
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={12} xs={12}>
                                                    <InputText
                                                        defaultValue={this.props.investorData['JOB_TITLE']}
                                                        attributeCode="JOB_TITLE"
                                                        onBlurHandler={this.props.pushInvestorChange}
                                                        className="input__custom"/>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                    <Grid item lg={12}>
                                        <Typography>
                                            Sectors
                                            <span className="required__circle"> *</span>
                                        </Typography>

                                        <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom>Select Sectors that Interest you. </Typography>


                                        <Grid container>
                                            <Grid item md={6} lg={6}>
                                                {
                                                    this.state['SECTORS']
                                                        ? this.state['SECTORS'].slice(0, this.state['SECTORS'].length / 2)
                                                            .map(s => {
                                                                return (
                                                                    <div className="checkbox">
                                                                        <MultiCheckbox
                                                                            label={s.value}
                                                                            attributeCode="SECTORS"
                                                                            optionValue={s.value}
                                                                            optionId={s.id}
                                                                            deleteInvestorData={this.props.deleteMultiInvestorData}
                                                                            pushInvestorChange={this.props.pushInvestorChange}
                                                                            stateChange={(sector, action) => this.onSectorChange(sector, action)}
                                                                            defaultValue={this.props.investorData['SECTORS']}
                                                                            attributes={[]}/>
                                                                    </div>
                                                                )
                                                            })
                                                        : null
                                                }
                                            </Grid>

                                            <Grid item md={6} lg={6}>
                                                {
                                                    this.state['SECTORS']
                                                        ? this.state['SECTORS'].slice(this.state['SECTORS'].length / 2)
                                                            .map(s => {
                                                                return (
                                                                    <div className="checkbox">
                                                                        <MultiCheckbox
                                                                            label={s.value}
                                                                            attributeCode="SECTORS"
                                                                            optionValue={s.value}
                                                                            optionId={s.id}
                                                                            deleteInvestorData={this.props.deleteMultiInvestorData}
                                                                            pushInvestorChange={this.props.pushInvestorChange}
                                                                            stateChange={(sector, action) => this.onSectorChange(sector, action)}
                                                                            defaultValue={this.props.investorData['SECTORS']}
                                                                            attributes={[]}/>
                                                                    </div>
                                                                )
                                                            })
                                                        : null
                                                }
                                            </Grid>
                                        </Grid>

                                        {
                                            this.state.selectedSectors.find(s => s === 'Other')
                                                ? <Grid item lg={8} xs={12} md={6}>
                                                    <Box pt="30px">
                                                        <Divider/>

                                                        <Box pt="30px">

                                                            <Typography>
                                                                Other sector
                                                            </Typography>

                                                            <InputText
                                                                defaultValue={this.props.investorData['OTHER_SECTOR']}
                                                                attributeCode="OTHER_SECTOR"
                                                                onBlurHandler={this.props.pushInvestorChange}
                                                                className="input__custom"/>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                : null
                                        }

                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>

                <Grid item md={12} lg={12}>
                    <Box mb="20px">
                        <Card raised>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item lg={12}>
                                        <Typography variant="h6">
                                            Investment Experience
                                        </Typography>

                                        <Divider/>
                                    </Grid>

                                    <Grid item lg={12}>
                                        <Typography>
                                            How many years experience do you have investing
                                            in the following areas?
                                            <span className="required__circle"> *</span>
                                        </Typography>
                                    </Grid>

                                    <Grid item lg={12}>
                                        <Grid container spacing={3}>
                                            <Grid item md={8} xs={12} lg={4}>
                                                <Grid item lg={12}>
                                                    <Typography>
                                                        Stocks
                                                        <span className="required__circle"> *</span>
                                                    </Typography>

                                                </Grid>

                                                <Grid item lg={12}>
                                                    <Select
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="Stocks"
                                                        selectedValue={this.props.investorData['Stocks']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['Stocks']}/>
                                                </Grid>
                                            </Grid>

                                            <Grid item md={8} xs={12} lg={4}>
                                                <Grid item lg={12}>
                                                    <Typography>
                                                        Annuities
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <Select
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="ANNUITIES"
                                                        selectedValue={this.props.investorData['ANNUITIES']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['ANNUITIES']}/>
                                                </Grid>
                                            </Grid>

                                            <Grid item md={8} xs={12} lg={4}>
                                                <Grid item lg={12}>
                                                    <Typography>
                                                        Alternative
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <Select
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="ALT"
                                                        selectedValue={this.props.investorData['ALT']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['ALT']}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item lg={12}>
                                        <Grid container spacing={3}>
                                            <Grid item md={8} xs={12} lg={4}>
                                                <Grid item lg={12}>
                                                    <Typography>
                                                        Options
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <Select
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="Options"
                                                        selectedValue={this.props.investorData['Options']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['Options']}/>
                                                </Grid>

                                            </Grid>

                                            <Grid item md={8} xs={12} lg={4}>
                                                <Grid item lg={12}>
                                                    <Typography>
                                                        Margin
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <Select
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="MARGIN"
                                                        selectedValue={this.props.investorData['MARGIN']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['MARGIN']}/>
                                                </Grid>
                                            </Grid>

                                            <Grid item md={8} xs={12} lg={4}>
                                                <Grid item lg={12}>
                                                    <Typography>
                                                        Bonds
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <Select
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="Bonds"
                                                        selectedValue={this.props.investorData['Bonds']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['Bonds']}/>
                                                </Grid>
                                            </Grid>

                                            <Grid item md={8} xs={12} lg={4}>
                                                <Grid item lg={12}>
                                                    <Typography>
                                                        Mutual Funds
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <Select
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="MUTUAL_FUNDS"
                                                        selectedValue={this.props.investorData['MUTUAL_FUNDS']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['MUTUAL_FUNDS']}/>
                                                </Grid>
                                            </Grid>

                                            <Grid item md={8} xs={12} lg={4}>
                                                <Grid item lg={12}>
                                                    <Typography>
                                                        Futures
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <Select
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="FUTURES"
                                                        selectedValue={this.props.investorData['FUTURES']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['FUTURES']}/>
                                                </Grid>
                                            </Grid>

                                            <Grid item md={8} xs={12} lg={4}>
                                                <Grid item lg={12}>
                                                    <Typography>
                                                        Private Placements
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <Select
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="PRIVATE_PLACEMENTS"
                                                        selectedValue={this.props.investorData['PRIVATE_PLACEMENTS']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['PRIVATE_PLACEMENTS']}/>
                                                </Grid>
                                            </Grid>

                                            <Grid item md={8} xs={12} lg={4}>
                                                <Grid item lg={12}>
                                                    <Typography>
                                                        Digital Assets
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <Select
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="DIGITAL_ASSETS"
                                                        selectedValue={this.props.investorData['DIGITAL_ASSETS']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['DIGITAL_ASSETS']}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>

                <Grid item md={12} lg={12}>
                    <Box mb="20px">
                        <Card raised>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item lg={12}>
                                        <Typography variant="h6">
                                            Investment profile
                                        </Typography>

                                        <Divider/>
                                    </Grid>

                                    <Grid item lg={12}>
                                        <Grid container spacing={3}>
                                            <Grid item md={8} xs={12} lg={4}>
                                                <Grid item lg={12}>
                                                    <Typography>
                                                        Investment Objectives
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <Select
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="INVEST_OBJ"
                                                        selectedValue={this.props.investorData['INVEST_OBJ']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['INVEST_OBJ']}/>
                                                </Grid>
                                            </Grid>

                                            <Grid item md={8} xs={12} lg={4}>
                                                <Grid item lg={12}>
                                                    <Typography>
                                                        Time Horizon
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <Select
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="TIME_H"
                                                        selectedValue={this.props.investorData['TIME_H']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['TIME_H']}/>
                                                </Grid>
                                            </Grid>

                                            <Grid item md={8} xs={12} lg={4}>
                                                <Grid item lg={12}>
                                                    <Typography>
                                                        Risk Tolerance
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <Select
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="RISK_TOL"
                                                        selectedValue={this.props.investorData['RISK_TOL']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['RISK_TOL']}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>

                <Grid item md={12} lg={12}
                      style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '20px'}}>
                    <Button
                        variant='outlined'
                        style={{
                            background: '#FAFAFA',
                            marginRight: '5px'

                        }}
                        onClick={(e, step, path) => this.navigate(e, false)}>
                        Previous
                    </Button>

                    <Button variant='outlined'
                            color='primary'
                            style={{
                                marginRight: '5px'
                            }}
                            onClick={() => this.saveProgress()}>
                        Save Progress & Come Back Later
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        disabled={this.state.errorEmailValid}
                        onClick={(e) => this.changeStep(e, 'STEP_ACCREDITATION', '/accreditation')}>
                        Next
                    </Button>
                </Grid>

            </>
        );
    }
}

export default SuitabilityPage;