import React, {Component} from 'react';
import OfferService from "../../repository/OfferService";
import DealComponent from "../../components/dashboard/Deal";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import axios from "../../custom-axios/app";

class OfferingsIndividualPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deals: [],
            loader: false
        }
    }

    componentDidMount() {
        // document.body.setAttribute('class', 'dashboard-body');
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.setState({
            deals: userInfo.offers,
        });
        // this.loadDeals();
    }

    loadDeals = () => {
        this.setState({
            loader: true
        })
        OfferService.fetchActiveInvDeals()
            .then((response) => {
                // let a = []
                // for (let i = 0; i < 20; i++) {
                //     a.push(response.data[0])
                // }
                this.setState({
                    deals: response.data,
                    loader: false
                });
            })
    }

    redirectToOfferings = () => {
        axios.get('/users/current-user')
            .then(res => {
                window.location.href = process.env.REACT_APP_OFFERING_GATEWAY + '/offerings/'
                    + res.data.userGroup.id.toLowerCase()
            })
            .catch(error => console.log(error))
    }

    render() {
        return (
            <>
                <Grid container spacing={3} style={{paddingTop: 80}}>
                    <Grid item lg={12} xs={12}>
                        <Typography variant='h6'>
                            My offerings
                        </Typography>

                        <Divider/>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            size='small'
                            onClick={this.redirectToOfferings}>
                            View All
                        </Button>
                    </Grid>

                    {this.state.loader ? (
                        <Grid item
                              lg={12}
                              xs={12}
                              style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  minHeight: '50vh'
                              }}>
                            <CircularProgress/>
                        </Grid>
                    ) : this.state.deals ? (
                        this.state.deals.map((item) => {
                            return (
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <DealComponent key={item.id} deal={item}/>
                                </Grid>
                            )
                        })
                    ) : null}
                </Grid>
            </>
        );
    }
}

export default OfferingsIndividualPage;