import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import axios from '../../custom-axios/dms-app';
import ImagePreviewModal from "../../pages/dashboard/modals/ImagePreviewModal";
import PdfPreviewModal from "../../pages/dashboard/modals/PdfPreviewModal";
import {Box} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import PublishIcon from '@material-ui/icons/Publish';
import Typography from "@material-ui/core/Typography";
import RemoveIcon from '@material-ui/icons/Remove';
import AlertDialog from "../AlertDialog";


class DropZoneComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPreview: false,
            showPdfPreview: false,
            openBlock: false,
            openAlertDialog: false,
            document: null
        }
    }

    openFile = (e) => {
        e.stopPropagation();
        axios.get("/v1/api/document/download/" + this.props.currentValue.chosenValue, {responseType: 'blob'})
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', this.props.currentValue.chosenValue);
                document.body.appendChild(link);
                link.click();
            });
    };

    onDocumentDelete = () => {
        this.setState({
            openAlertDialog: false
        })

        if (this.state.document !== null) {
            this.props.onDocumentDelete(this.props.documentGroupCode, this.props.attributeCode, this.state.document)

            if (this.props.attributeCode === 'STATE_ID_4' && this.props.currentValue.length === 1
                && this.props.currentValue[this.props.currentValue.length - 1].chosenValue) {
                this.deleteGovernmentId();
            }

            if (this.props.attributeCode === 'STATE_ID_BACK' && this.props.currentValue.length === 1
                && this.props.currentValue[this.props.currentValue.length - 1].chosenValue) {
                this.deleteGovernmentId()
            }

            if (this.props.attributeCode === 'PASSPORT' && this.props.currentValue.length === 1
                && this.props.currentValue[this.props.currentValue.length - 1].chosenValue) {
                let request = {
                    attributeCode: "PASSPORT_NUMBER",
                    value: ''
                }

                this.props.pushInvestorChange(request)
            }

            if (this.props.attributeCode === 'PASSPORT_2' && this.props.currentValue.length === 1
                && this.props.currentValue[this.props.currentValue.length - 1].chosenValue) {
                let request = {
                    attributeCode: "PASSPORT_NUMBER_COMPANY",
                    value: ''
                }

                this.props.pushInvestorChange(request)
            }

            if (this.props.attributeCode === 'PASSPORT_2_COMPANY' && this.props.currentValue.length === 1
                && this.props.currentValue[this.props.currentValue.length - 1].chosenValue) {
                let request = {
                    attributeCode: "PASSPORT_2_NUMBER_COMPANY",
                    value: ''
                }

                this.props.pushInvestorChange(request)
            }

            this.setState({
                document: null
            })
        }
    }

    deleteGovernmentId = () => {
        // this.props.attributeCode==='STATE_ID_BACK'||this.props.attributeCode==='PASSPORT'
        if (!this.props.reversSideOfDocument[this.props.reversSideOfDocument.length - 1].chosenValue) {
            let request = {
                attributeCode: "ID_NUMBER",
                value: ''
            };
            this.props.pushInvestorChange(request)
        }

    }

    handleClose = (e) => {
        this.setState({
            showPreview: false
        })
    };

    handlePdfClose = (e) => {
        this.setState({
            showPdfPreview: false
        })
    };

    onFilePreview = (e, item) => {
        /*this.setState({
            fileToPreview: item.chosenValue,
            showPreview: true
        });*/

        let fileToPreview = item.chosenValue.split('.');
        if (fileToPreview[fileToPreview.length - 1] === 'pdf') {
            this.setState({
                fileToPreview: item.chosenValue,
                showPdfPreview: true
            });
        } else {
            this.setState({
                fileToPreview: item.chosenValue,
                showPreview: true
            });
        }

    };

    openBlock = () => {
        this.setState({
            openBlock: !this.state.openBlock
        })
    };

    openAlertDialog = (e, document) => {
        this.setState({
            openAlertDialog: true,
            document: document
        })
    }

    render() {
        return (
            <>
                {this.state.showPreview && (
                    <ImagePreviewModal
                        show={this.state.showPreview}
                        file={this.state.fileToPreview}
                        close={this.handleClose}/>
                )}

                {this.state.showPdfPreview && (
                    <PdfPreviewModal
                        show={this.state.showPdfPreview}
                        file={this.state.fileToPreview}
                        close={this.handlePdfClose}/>
                )}

                <Box className="upload__block--title" onClick={this.openBlock}>
                    {this.state.openBlock ? <RemoveIcon/> : <AddIcon/>}

                    <Box ml="8px" component="span">{this.props.headText}</Box>
                </Box>

                {/*<span>{this.props.labelText}</span>*/}

                <Dropzone
                    onDrop={
                        acceptedFiles => this.props.onFileSelected(this.props.documentGroupCode, acceptedFiles,
                            this.props.attributeCode)
                    }>
                    {({getRootProps, getInputProps}) => (

                        <Box
                            // mt="15px"
                            // mb="15px" className={this.props.className}
                        >

                            {/*<div {...getRootProps()} className="wrap">*/}
                            {/*  <Box className="upload__block--title">*/}
                            {/*    <AddIcon/>*/}
                            {/*    <Box ml="8px" component="span">{this.props.headText}</Box>*/}
                            {/*  </Box>*/}

                            {/*  /!*<span>{this.props.labelText}</span>*!/*/}
                            {/*</div>*/}

                            <Box
                                mb="15px"
                                p="25px"
                                className={this.state.openBlock ? 'upload__view' : 'upload__hidden'}
                                style={{background: '#FCFCFC'}}>

                                <Box>
                                    <Box>
                                        <Typography variant="subtitle2">{this.props.innerText}</Typography>
                                        <Typography
                                            variant="subtitle2"
                                            gutterBottom>Only JPG, PNG or PDF files. File
                                            must be less than 20 Mb <span className="required__circle">*</span>
                                        </Typography>
                                    </Box>

                                    {
                                        this.props.currentValue && Array.isArray(this.props.currentValue)
                                            ? this.props.currentValue.map(document => {
                                                if (document.chosenValue) {
                                                    return (
                                                        <Box
                                                            pt="20px"
                                                            pb="5px"
                                                            style={{borderColor: '#DFDFDF'}}
                                                            borderBottom={1}
                                                            display="flex"
                                                            key={document.chosenValue}>

                                                            <Box flexGrow={1}>
                                                                {document.documentOriginalName}
                                                            </Box>
                                                            {/*<label*/}
                                                            {/*    className="color-black"> {this.props.labelText ? this.props.headText + ' ' + this.props.labelText : this.props.headText} </label>*/}

                                                            <Box>
                                                                <Button
                                                                    color="primary"
                                                                    name={this.props.fileNumber}
                                                                    type={"button"}
                                                                    onClick={(e) => this.onFilePreview(e, document)}>
                                                                    Preview
                                                                </Button>

                                                                <Button
                                                                    style={{color: '#B71C1C'}}
                                                                    name={this.props.fileNumber}
                                                                    type={"button"}
                                                                    onClick={(e) => this.openAlertDialog(e, document)}>
                                                                    Remove
                                                                </Button>

                                                                <AlertDialog
                                                                    title={'Remove the document'}
                                                                    content={'Are you sure?'}
                                                                    open={this.state.openAlertDialog}
                                                                    closeYesHandler={this.onDocumentDelete}
                                                                    closeNoHandler={() => {
                                                                        this.setState({
                                                                            openAlertDialog: false
                                                                        })
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    )
                                                }

                                                return null
                                            })
                                            : null
                                    }
                                </Box>

                                <Box mt="25px" display="flex">

                                    <Button onClick={this.openBlock}>
                                        Cancel
                                    </Button>

                                    <Box ml="20px">
                                        <input
                                            id={this.props.fileNumber}
                                            type={"file"} {...getInputProps()}
                                            className="custom-file-input"
                                            accept={this.props.accept}
                                            required>
                                        </input>

                                        <label htmlFor={this.props.fileNumber}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                endIcon={<PublishIcon/>}
                                                component="span">
                                                Upload document
                                            </Button>
                                        </label>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Dropzone>
            </>
        )

    }

}

export default DropZoneComponent;