import axios from "../custom-axios/app"

const DwollaService = {

    fetchCustomer: (customerId) => {
        return axios.get(`/api/payment/customers/${customerId}`)
    },

    fetchFundingSources: (customerId) => {
        return axios.get(`/api/payment/customers/${customerId}/funding-sources`)
    },

    fetchIavToken: (customerId) => {
        return axios.get(`/api/payment/customers/${customerId}/iav-token`)
    },

    createCustomer: () => {
        return axios.post('/api/payment/customers')
    },

    transfer: (request) => {
        return axios.post('/api/payment/transfers', request)
    }

}

export default DwollaService