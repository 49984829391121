import React, {useEffect} from 'react';
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";

const Select1 = (props) => {
    useEffect(() => {
        if (props.attributeCode === 'COUNTRY_CITIZEN'
            || props.attributeCode === 'COUNTRY_CITIZEN_POINT_OF_CONTACT') {
            onChangeHandler(props.attributeCode, '2085', 'United States');
        }
    }, [])

    const onChangeHandler = (attributeCode, investorAttributeOptionId, options) => {
        let value;

        if (options === 'United States') {
            value = options;
        } else {
            for (let i = 0; i < options.length; i++) {
                if (options[i].selected) {
                    value = options[i].innerHTML.trim();
                    break;
                }
            }
        }

        if (attributeCode === 'COUNTRY_CITIZEN' && value === 'United States') {
            props.deleteInvestorData({attributeCode: 'HAVE_PASSPORT'});
            props.deleteInvestorData({attributeCode: 'HAVE_SSN'});
        }

        if (attributeCode === 'COUNTRY_CITIZEN_POINT_OF_CONTACT' && value === 'United States') {
            props.deleteInvestorData({attributeCode: 'HAVE_PASSPORT_COMPANY'});
            props.deleteInvestorData({attributeCode: 'HAVE_SSN_COMPANY'});
        }

        props.onChange({
            attributeCode: attributeCode,
            investorAttributeOptionId: investorAttributeOptionId,
            value: value
        });
    };

    return (
        <FormControl variant="outlined"
                     fullWidth
                     size={"small"}
                     error={props.hasError}
        >
            <Select
                native
                value={props.selectedValue
                    ? props.selectedValue.chosenOptionId
                    : props.geolocation
                        ? props.geolocation
                        : ''
                }
                disabled={props.options.length === 0}
                onChange={(e) => {
                    onChangeHandler(props.attributeCode, e.target.value, e.target.options)
                }}
                // className='select__custom'
                data-live-search={props.dataLiveSearch}
                data-style={props.dataStyle}
                data-width={props.dataWidth}>

                <option value="" disabled selected/>

                {props.options.map(option => {
                    return (
                        <option
                            key={option.id}
                            value={option.id}>
                            {option.value}
                        </option>
                    );
                })}
            </Select>

            <FormHelperText>
                {props.hasError && props.errorMessage}
            </FormHelperText>
        </FormControl>
    );
};

export default Select1;