import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Aux from '../../hoc/AuxWrapper';
import FinalSubmissionPage from '../../pages/registration/FinalSubmissionPage';
import InstitutionInfoPage from '../../pages/registration/InstitutionInfoPage';
import BackgroundInfoPage from '../../pages/registration/BackgroundInfoPage';
import WaitingForApproval from "../../pages/registration/WaitingForApprovalPage";
import {useParams, useRouteMatch} from "react-router";
import HorizontalStepperWithError from "../../components/registration/HorizontalStepperWithError";
import NextStep from "../registration/NextStep";


const InstitutionRegistration = (prop) => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let {path, url} = useRouteMatch()
    const {offerId, actionId} = useParams()

    const steps = [
        {value: 'STEP_INSTITUTION_INFO', label: 'Institution Info', path: '/institution-info', number: 1},
        {value: 'STEP_BACKGROUND_INFO', label: 'Background Info', path: '/background-info', number: 2},
        {value: 'STEP_FINAL_SUBMISSION', label: 'Disclosures & Final Submission', path: '/final-submission', number: 3},
        {value: 'STEP_APPROVAL', label: 'Waiting for approval', path: '/approval', number: 4}
    ];

    return (
        <Aux>
            {actionId && (
                <HorizontalStepperWithError
                    steps={prop.offer?.steps}
                    currentStep={actionId}
                />
            )}

            {/*<RegistrationSteps*/}
            {/*    userGroup={prop.userGroup}*/}
            {/*    steps={steps}*/}
            {/*    currentStep={userInfo.registrationStatus}*/}
            {/*    setRegistrationStatus={prop.setRegistrationStatus}/>*/}
            <Switch>
                <Route path={`${path}/institution-info`} exact
                       render={(props) =>
                           <InstitutionInfoPage
                               {...props}
                               fetchInvestorStatusByStep={(step) => prop.fetchInvestorStatusByStep(step)}
                               validate={prop.validate}
                               pushInvestorChange={prop.pushInvestorChange}
                               investorData={prop.investorData}
                               fetchAttributeOptions={prop.fetchAttributeOptions}
                               investorAttributeOptions={prop.investorAttributeOptions}
                               saveDocument={prop.saveDocument}
                               deleteInvestorData={prop.deleteInvestorData}
                               onDocumentDelete={prop.onDocumentDelete}
                               setRegistrationStatus={prop.setRegistrationStatus}
                               setCurrentRegistrationStep={(step) => prop.setCurrentRegistrationStep(step)}
                               currentStep={userInfo.registrationStatus}
                               steps={prop.offer}
                           />
                       }
                />

                <Route path={`${path}/background-info`} exact
                       render={(props) =>
                           <BackgroundInfoPage
                               {...props}
                               fetchInvestorStatusByStep={(step) => prop.fetchInvestorStatusByStep(step)}
                               validate={prop.validate}
                               pushInvestorChange={prop.pushInvestorChange}
                               onDocumentDelete={prop.onDocumentDelete}
                               investorData={prop.investorData}
                               fetchAttributeOptions={prop.fetchAttributeOptions}
                               investorAttributeOptions={prop.investorAttributeOptions}
                               saveDocument={prop.saveDocument}
                               setRegistrationStatus={prop.setRegistrationStatus}
                               setCurrentRegistrationStep={(step) => prop.setCurrentRegistrationStep(step)}
                               currentStep={userInfo.registrationStatus}
                               steps={prop.offer}
                           />
                       }
                />

                <Route path={`${path}/final-submission`} exact
                       render={(props) =>
                           <FinalSubmissionPage
                               {...props}
                               fetchInvestorStatusByStep={(step) => prop.fetchInvestorStatusByStep(step)}
                               validate={prop.validate}
                               pushInvestorChange={prop.pushInvestorChange}
                               onDocumentDelete={prop.onDocumentDelete}
                               investorData={prop.investorData}
                               fetchAttributeOptions={prop.fetchAttributeOptions}
                               investorAttributeOptions={prop.investorAttributeOptions}
                               saveDocument={prop.saveDocument}
                               setRegistrationStatus={prop.setRegistrationStatus}
                               setCurrentRegistrationStep={(step) => prop.setCurrentRegistrationStep(step)}
                               currentStep={userInfo.registrationStatus}
                               steps={prop.offer}
                           />
                       }
                />

                <Route path={`${path}/approval`} exact component={WaitingForApproval}/>
                <Route path={path} exact
                       render={(props) =>
                           <NextStep
                               {...props}
                               nextStep={prop.nextStep}
                           />
                       }/>
                {/*<Route path="/logout" exact component={Logout}/>*/}

                {/*<Redirect to={steps.filter(s => s.value === userInfo.registrationStatus)[0].path}/>*/}
            </Switch>
        </Aux>
    );
};

export default InstitutionRegistration;