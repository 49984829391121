import React, {useEffect, useState} from 'react';

import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import DropZoneComponent from "./input/DropZoneComponent";
import HiddenInputDouble from "./input/HiddenInputDouble";
import Divider from "@material-ui/core/Divider";

const DocumentationArea = (props) => {
    const [idFrontAttributeCode, setIdFrontAttributeCode] = useState('')
    const [idBackAttributeCode, setIdBackAttributeCode] = useState('')
    const [idNumberAttributeCode, setIdNumberAttributeCode] = useState('')
    const [passwordAttributeCode, setPasswordAttributeCode] = useState('')
    const [password2AttributeCode, setPassword2AttributeCode] = useState('')
    const [passwordNumberAttributeCode, setPasswordNumberAttributeCode] = useState('')
    const [password2NumberAttributeCode, setPassword2NumberAttributeCode] = useState('')

    useEffect(() => {
        if (props.userType && props.userType === 'USER_INVESTOR_INDIVIDUAL') {
            setIdFrontAttributeCode('STATE_ID_4')
            setIdBackAttributeCode('STATE_ID_BACK')
            setIdNumberAttributeCode('ID_NUMBER')
            setPasswordAttributeCode('PASSPORT')
            setPassword2AttributeCode('PASSPORT_2_INDIVIDUAL')
            setPasswordNumberAttributeCode('PASSPORT_NUMBER')
            setPassword2NumberAttributeCode('PASSPORT_2_NUMBER_INDIVIDUAL')
        } else if (props.userType && props.userType === 'USER_INVESTOR_COMPANY') {
            setIdFrontAttributeCode('STATE_ID_FRONT')
            setIdBackAttributeCode('STATE_ID_3')
            setIdNumberAttributeCode('ID_NUMBER_COMPANY')
            setPasswordAttributeCode('PASSPORT_2')
            setPassword2AttributeCode('PASSPORT_2_COMPANY')
            setPasswordNumberAttributeCode('PASSPORT_NUMBER_COMPANY')
            setPassword2NumberAttributeCode('PASSPORT_2_NUMBER_COMPANY')
        }
    }, [props.userType])

    return (
        <Grid container spacing={3}>
            <Grid item lg={12} xs={12}>
                <Typography
                    variant="h6">
                    Documentation
                </Typography>

                <Divider/>
            </Grid>

            {
                props.isUSA
                    ? <Grid item lg={6} xs={12}>

                        <div style={{marginBottom: '20px'}}>
                            <DropZoneComponent
                                attributeCode={idFrontAttributeCode}
                                labelText={'Front'}
                                innerText={'Please upload a picture or scan of the front of your valid (Not Expired) government identification.'}
                                headText={'US Driver’s License or Government Issued ID (Front)'}
                                currentValue={props.investorData[idFrontAttributeCode]}
                                reversSideOfDocument={props.investorData[idBackAttributeCode]}
                                accept={'.jpg, .png, .pdf'}
                                documentGroupCode={'state-id-front'}
                                onFileSelected={props.saveDocumentHandler}
                                onDocumentDelete={props.deleteDocumentHandler}
                                pushInvestorChange={props.changeInvestorHandler}
                                fileNumber="file-1"/>
                        </div>


                        <div style={{marginBottom: '20px'}}>
                            <DropZoneComponent
                                attributeCode={idBackAttributeCode}
                                labelText={'Back'}
                                innerText={'Please upload a picture or scan of the back of your valid (Not Expired) government identification.'}
                                headText={'US Driver’s License or Government Issued ID (Back)'}
                                currentValue={props.investorData[idBackAttributeCode]}
                                reversSideOfDocument={props.investorData[idFrontAttributeCode]}
                                accept={'.jpg, .png, .pdf'}
                                documentGroupCode={'state-id-back'}
                                onFileSelected={props.saveDocumentHandler}
                                onDocumentDelete={props.deleteDocumentHandler}
                                pushInvestorChange={props.changeInvestorHandler}
                                className="state-id back"
                                fileNumber="file-2"/>
                        </div>


                        {
                            (props.investorData[idFrontAttributeCode]
                                && (props.investorData[idFrontAttributeCode][0]
                                        ? props.investorData[idFrontAttributeCode][0].chosenValue
                                        : false
                                ))
                            || (props.investorData[idBackAttributeCode]
                                && (props.investorData[idBackAttributeCode][0].chosenValue
                                    ? props.investorData[idBackAttributeCode][0].chosenValue
                                    : false))
                                ? <Grid item lg={12} xs={12}>


                                    <Typography
                                        gutterBottom>
                                        Government ID number
                                        <span className="required__circle"> *</span>
                                    </Typography>


                                    <HiddenInputDouble
                                        defaultValue={props.investorData[idNumberAttributeCode]}
                                        attributeCode={idNumberAttributeCode}
                                        onBlurHandler={props.changeInvestorHandler}
                                        placeholder={"confirm id number"}
                                        errorConfirm={"Government ID numbers must match"}
                                        hasError={!!props.errors.governmentId}
                                        errorMessage={props.errors.governmentId}
                                        maxLength={24}
                                    />


                                </Grid>
                                : ''
                        }


                        <Grid item lg={12} xs={12}>
                            <Typography
                                style={{
                                    width: '40%',
                                    margin: 'auto',
                                    paddingTop: 20,
                                    textAlign: 'center'
                                }}
                            >
                                OR/AND
                            </Typography>
                        </Grid>
                    </Grid>
                    : ''
            }

            <Grid item lg={12} xs={12}>
                <Grid container spacing={2}>
                    {/*Pasport 1*/}
                    <Grid item lg={6} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item lg={12} xs={12}>
                                <DropZoneComponent
                                    attributeCode={passwordAttributeCode}
                                    innerText={'Please upload a picture or scan of your valid (Not Expired) passport.'}
                                    headText={'Passport'}
                                    currentValue={props.investorData[passwordAttributeCode]}
                                    accept={'.jpg, .png, .pdf'}
                                    documentGroupCode={'passport'}
                                    onFileSelected={props.saveDocumentHandler}
                                    onDocumentDelete={props.deleteDocumentHandler}
                                    pushInvestorChange={props.changeInvestorHandler}
                                    className="passport"
                                    fileNumber="file-3"/>
                            </Grid>

                            {(props.investorData[passwordAttributeCode] && props.investorData[passwordAttributeCode][0]
                                    ? props.investorData[passwordAttributeCode][0].chosenValue
                                    : false)
                                ? <Grid item lg={12} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item lg={5} xs={12}>
                                            <Typography
                                                gutterBottom>
                                                Passport Number
                                                <span className="required__circle"> *</span>
                                            </Typography>

                                            {
                                                props.isUSA
                                                    ? <Typography variant='caption'>
                                                        (min characters 6, max 9)
                                                    </Typography>
                                                    : ''
                                            }
                                        </Grid>

                                        <Grid item lg={12} xs={12}>
                                            <HiddenInputDouble
                                                defaultValue={props.investorData[passwordNumberAttributeCode]}
                                                attributeCode={passwordNumberAttributeCode}
                                                onBlurHandler={props.changeInvestorHandler}
                                                placeholder={"confirm passport number"}
                                                error={"min characters 6, max 9"}
                                                hasError={!!props.errors.passportNumber}
                                                errorMessage={props.errors.passportNumber}
                                                countryOfCitizenship={props.investorData['COUNTRY_CITIZEN']}
                                                errorConfirm={"Passport Numbers must match"}
                                                maxLength={props.isUSA ? 9 : 24}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                : ''
                            }
                        </Grid>
                    </Grid>

                    {/*Pasport 2*/}
                    {(props.investorData[passwordAttributeCode]
                        && ((props.investorData[passwordAttributeCode][props.investorData[passwordAttributeCode].length - 1]
                            && props.investorData[passwordAttributeCode][props.investorData[passwordAttributeCode].length - 1].chosenValue)
                            || (props.investorData[password2AttributeCode][props.investorData[password2AttributeCode].length - 1]
                                && props.investorData[password2AttributeCode][props.investorData[password2AttributeCode].length - 1].chosenValue))) ? (
                        <Grid item lg={6} xs={12}>
                            <Grid container spacing={2}>
                                <Grid item lg={12} xs={12}>
                                    <DropZoneComponent
                                        attributeCode={password2AttributeCode}
                                        innerText={'Please upload a picture or scan of your valid (Not Expired) passport.'}
                                        headText={'Passport 2 (if exists)'}
                                        currentValue={props.investorData[password2AttributeCode]}
                                        accept={'.jpg, .png, .pdf'}
                                        documentGroupCode={'passport'}
                                        onFileSelected={props.saveDocumentHandler}
                                        onDocumentDelete={props.deleteDocumentHandler}
                                        pushInvestorChange={props.changeInvestorHandler}
                                        className="passport"
                                        fileNumber="file-5"/>
                                </Grid>

                                {
                                    (props.investorData[password2AttributeCode]
                                        && (props.investorData[password2AttributeCode][props.investorData[password2AttributeCode].length - 1]
                                            ? props.investorData[password2AttributeCode][props.investorData[password2AttributeCode].length - 1].chosenValue
                                            : false))
                                        ? <Grid item lg={12} xs={12}>
                                            <Grid container spacing={3}>
                                                <Grid item lg={5} xs={12}>
                                                    <Typography gutterBottom>
                                                        Passport Number
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={12} xs={12}>
                                                    <HiddenInputDouble
                                                        defaultValue={props.investorData[password2NumberAttributeCode]}
                                                        attributeCode={password2NumberAttributeCode}
                                                        onBlurHandler={props.changeInvestorHandler}
                                                        placeholder={"confirm passport number"}
                                                        error={"min characters 6, max 9"}
                                                        hasError={!!props.errors.passport2Number}
                                                        errorMessage={props.errors.passport2Number}
                                                        countryOfCitizenship={props.investorData['COUNTRY_CITIZEN']}
                                                        errorConfirm={"Passport Numbers must match"}
                                                        maxLength={props.isUSA ? 9 : 24}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        : ''
                                }
                            </Grid>
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DocumentationArea;