import * as actionTypes from '../actions/actionTypes';

const initialState = {
    userInfo: null
};


const saveUser = (state, action) => {
    localStorage.setItem('userInfo', JSON.stringify(action.payload));
    return {
        ...state,
        userInfo: action.payload
    }
};

const fetchUserInfoSuccess = (state, action) => {
    localStorage.setItem('userInfo', JSON.stringify(action.payload.data));
    return {
        ...state,
        userInfo: action.payload.data
    }
};

const fetchUserInfoError = (state, action) => {
    return state;
};

const user = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_USER_INFO:
            return saveUser(state, action);
        case actionTypes.FETCH_USER_INFO_SUCCESS:
            return fetchUserInfoSuccess(state, action);
        case actionTypes.FETCH_USER_INFO_ERROR:
            return fetchUserInfoError(state, action);
        default:
            return state;
    }
};

export default user;