import {combineReducers} from "redux";
import {configureStore} from "@reduxjs/toolkit";
import {BrowserHistory} from "../components/BrowserHistory";
import {connectRouter, routerMiddleware} from 'connected-react-router';
import {logger} from "redux-logger";
import {currentUserSlice} from "../features/currentUser/CurrentUserSlice";
import {loaderSlice} from "../features/loader/LoaderSlice";
import {authSlice} from "../features/auth/AuthSlice";

import authReducer from "./reducers/auth";
import signUpReducer from "./reducers/signUp";
import activateReducer from "./reducers/activate";
import userReducer from "./reducers/user";
import initialDataReducer from "./reducers/initialData";
import documentReducer from "./reducers/document";
import registrationStepsReducer from "./reducers/registrationSteps";

const rootReducer = combineReducers({
    auth: authSlice.reducer,
    authReducer: authReducer,
    signUpReducer: signUpReducer,
    activateReducer: activateReducer,
    userReducer: userReducer,
    initialDataReducer: initialDataReducer,
    documentReducer: documentReducer,
    registrationStepsReducer: registrationStepsReducer,
    user: currentUserSlice.reducer,
    loader: loaderSlice.reducer,
    router: connectRouter(BrowserHistory),
});

export const store = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger).concat(routerMiddleware(BrowserHistory)),
        devTools: process.env.NODE_ENV === 'development'
    }
)
