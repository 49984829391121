import React from 'react';
import Iframe from 'react-iframe'
import {AUTH_TOKEN} from "../../../shared/utility";


const PdfPreviewModal = (props) => {

    return (
        <>
            <div id="composeMessageModal" className="modal modal-open" role="dialog" style={{
                transform: props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                display: props.show ? 'block' : 'none'
            }}>
                <div className="modal-dialog modal-lg">

                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">File Preview</h4>
                        </div>

                        <div className="container-fluid">
                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <Iframe
                                        url={process.env.REACT_APP_DMS_GATEWAY + '/v1/api/document/download/'
                                        + props.file + '?access_token=' + localStorage.getItem(AUTH_TOKEN) + '&embedded=true'}
                                        width="100%"
                                        height="450px"
                                        id="myId"
                                        className="myClassname"
                                        display="initial"
                                        position="relative"/>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer text-center">
                            <button type="button" className="btn btn-primary btn-xl" data-dismiss="modal"
                                    onClick={props.close}>close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PdfPreviewModal;