import axios from '../custom-axios/app';

const UserService = {

    fetchCurrentUser: () => {
        return axios.get('/users/current-user');
    },

    fetchUser: () => {
        return axios.get('/users/current-user');
    },

    setRegistrationStatus: (status) => {
        return axios.post('/users/registration-status', {status: status});
    },

    initializePasswordReset: (body) => {
        return axios.post('/users/reset-password', body);
    },

    saveInvestorRequest: (request) => {
        return axios.post('/users/nodlewaitlist', request)
    },

    assignOffer: (request) => {
        return axios.post('/users/offers', request);
    },

    saveOfferStatus: (request) => {
        return axios.post(`/users/steps`, request);
    },
}

export default UserService;