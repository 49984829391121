import React, {Component} from 'react';
import InputText from '../../components/input/InputText';
import Select from '../../components/input/Select1';
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

class ProfileCompanyPage extends Component {

    state = {
        updated: false,
        firstName: '',
        lastName: '',
        streetAddress: '',
        city: '',
        postalCode: '',
        mailStreetAddress: '',
        mailCity: '',
        mailPostalCode: '',
        stateId: '',
        countryId: '',
        mailStateId: '',
        mailCountryId: '',
        alternativeEmail: '',
        phoneNumber: '',
        alternativePhoneNumb: '',
        'HOME_STATE_PROVINCE': [],
        'COUNTRY': [],
        'PLEASE_CHOSE_2': []
    };

    componentDidMount() {
        this.init();
    }

    componentWillUpdate(nextProps, nextState) {
        if (!this.state.updated && nextProps.investor) {
            this.setState({
                updated: true
            }, () => this.setStateFields());
        }
    }

    init() {
        this.props.fetchAttributeOptions('HOME_STATE_PROVINCE').then((data) => {
            this.setState({
                ...this.state,
                'HOME_STATE_PROVINCE': data.data
            });
        });

        this.props.fetchAttributeOptions('COUNTRY').then((data) => {
            this.setState({
                ...this.state,
                'COUNTRY': data.data
            });
        });

        this.props.fetchAttributeOptions('PLEASE_CHOSE_2').then((data) => {
            this.setState({
                ...this.state,
                'PLEASE_CHOSE_2': data.data
            });
        });

    }

    onChangeHandler(stateField, value) {
        this.setState({
            [stateField]: value
        });
    }

    // submitChanges(e) {
    //     e.preventDefault();
    //     this.props.updateInvestorInfo({
    //         firstName: this.state.firstName,
    //         lastName: this.state.lastName,
    //         streetAddress: this.state.streetAddress,
    //         city: this.state.city,
    //         postalCode: this.state.postalCode,
    //         mailStreetAddress: this.state.mailStreetAddress,
    //         mailCity: this.state.mailCity,
    //         mailPostalCode: this.state.mailPostalCode,
    //         stateId: this.state.stateId,
    //         countryId: this.state.countryId,
    //         mailStateId: this.state.mailStateId,
    //         mailCountryId: this.state.mailCountryId,
    //         alternativeEmail: this.state.alternativeEmail,
    //         phoneNumber: this.state.phoneNumber,
    //         alternativePhoneNumb: this.state.alternativePhoneNumb
    //     });
    // }

    setStateFields() {
        this.setState({
            firstName: this.props.investor.user.firstName,
            lastName: this.props.investor.user.lastName,
            streetAddress: this.props.investor.streetAddress,
            city: this.props.investor.city,
            postalCode: this.props.investor.postalCode,
            mailStreetAddress: this.props.investor.mailStreetAddress,
            mailCity: this.props.investor.mailCity,
            mailPostalCode: this.props.investor.mailPostalCode,
            stateId: this.props.investor.state ? this.props.investor.state.id : '',
            countryId: this.props.investor.country ? this.props.investor.country.id : '',
            mailStateId: this.props.investor.mailState ? this.props.investor.mailState.id : '',
            mailCountryId: this.props.investor.mailCountry ? this.props.investor.mailCountry.id : '',
            alternativeEmail: this.props.investor.alternativeEmail,
            phoneNumber: this.props.investor.phoneNumber,
            alternativePhoneNumb: this.props.investor.alternativePhoneNumb,

        });
    }

    render() {
        return (
            <Grid container spacing={3} style={{paddingTop: 80}}>
                <Grid item lg={12} xs={12}>

                    <Box mb="'10px">
                        <Typography variant='h6'>
                            Profile
                        </Typography>
                    </Box>

                    <Divider/>
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                    <Card raised>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item lg={12} xs={12}>
                                    <form class="form-horizontal">
                                        <div class="form-group">
                                            <label class="col-xs-12 col-sm-4 control-label control-label-xl">Entity
                                                Name</label>
                                            <div class="col-sm-8">
                                                <InputText
                                                    defaultValue={this.props.investorData['ENTITY_NAME']}
                                                    attributeCode="ENTITY_NAME"
                                                    onBlurHandler={this.props.pushInvestorChange}
                                                    className="form-control input-xl"/>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="col-xs-12 col-sm-4 control-label control-label-xl">Street
                                                Address</label>
                                            <div class="col-sm-8">
                                                <InputText
                                                    defaultValue={this.props.investorData['HOME_STREET_ADDRESS']}
                                                    attributeCode="HOME_STREET_ADDRESS"
                                                    onBlurHandler={this.props.pushInvestorChange}
                                                    className="form-control input-xl"/>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label
                                                class="col-xs-12 col-sm-4 control-label control-label-xl">City</label>
                                            <div class="col-sm-8">
                                                <InputText
                                                    defaultValue={this.props.investorData['HOME_CITY']}
                                                    attributeCode="HOME_CITY"
                                                    onBlurHandler={this.props.pushInvestorChange}
                                                    className="form-control input-xl"/>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label className="col-xs-12 col-sm-4 control-label control-label-xl">
                                                State/Province (US only) *</label>
                                            <div className="col-sm-8">
                                                <Select
                                                    className="form-control input-lg"
                                                    dataLiveSearch="true"
                                                    dataStyle="form-control input-lg"
                                                    dataWidth="100%"
                                                    attributeCode="HOME_STATE_PROVINCE"
                                                    selectedValue={this.props.investorData['HOME_STATE_PROVINCE']}
                                                    onChange={this.props.pushInvestorChange}
                                                    options={this.props.investorData['COUNTRY'] &&
                                                    this.props.investorData['COUNTRY'].chosenValue === 'United States' ? this.state['HOME_STATE_PROVINCE'] : []}
                                                />
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="col-xs-12 col-sm-4 control-label control-label-xl">Postal
                                                Code</label>
                                            <div class="col-sm-4">
                                                <InputText
                                                    defaultValue={this.props.investorData['HOME_POSTAL_CODE']}
                                                    attributeCode="HOME_POSTAL_CODE"
                                                    onBlurHandler={this.props.pushInvestorChange}
                                                    className="form-control input-xl"/>
                                            </div>
                                        </div>


                                        <div class="form-group">
                                            <label
                                                class="col-xs-12 col-sm-4 control-label control-label-xl">Country</label>
                                            <div class="col-sm-8">
                                                <Select
                                                    className="form-control input-xl"
                                                    dataLiveSearch="true"
                                                    dataStyle="form-control input-xl"
                                                    dataWidth="100%"
                                                    attributeCode="COUNTRY"
                                                    selectedValue={this.props.investorData['COUNTRY']}
                                                    onChange={this.props.pushInvestorChange}
                                                    options={this.state['COUNTRY']}/>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="col-xs-12 col-sm-5 col-sm-offset-4">
                                                <h3 class="color-grey text-upper weight700 small-title margin-top-50">entity
                                                    type
                                                </h3>
                                                <div className="form-group">
                                                    <div className="col-sm-10">
                                                        <Select
                                                            className="form-control input-lg"
                                                            dataLiveSearch="true"
                                                            dataStyle="form-control input-lg"
                                                            dataWidth="100%"
                                                            attributeCode="PLEASE_CHOSE_2"
                                                            selectedValue={this.props.investorData['PLEASE_CHOSE_2']}
                                                            onChange={this.props.pushInvestorChange}
                                                            options={this.state['PLEASE_CHOSE_2']}/>
                                                    </div>
                                                </div>
                                            </div>

                                            {this.props.investorData['PLEASE_CHOSE_2'] && this.props.investorData['PLEASE_CHOSE_2'].chosenValue === 'Other' ?
                                                <div className="col-xs-12 col-sm-5 col-sm-offset-4">
                                                    <div className="form-group">
                                                        <div className="col-sm-10">
                                                            <InputText
                                                                defaultValue={this.props.investorData['OTHER_ENTITY_TYPE']}
                                                                attributeCode="OTHER_ENTITY_TYPE"
                                                                onBlurHandler={this.props.pushInvestorChange}
                                                                className="form-control input-lg"/>
                                                        </div>
                                                    </div>
                                                </div> : null}

                                        </div>

                                        <div class="form-group">
                                            <div class="col-xs-12 col-sm-5 col-sm-offset-4  col-md-6 margin-top-50">
                                                <button type="submit"
                                                        class="btn btn-orange btn-xl btn-block margin-bottom-40 text-capitalize"
                                                        style={{width: 'auto'}}>Submit
                                                    Changes
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

export default ProfileCompanyPage;