import React, {useEffect, useState} from "react";
import SignatureService from "../../repository/SignatureService";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import SigningModal from "../../components/signature/SigningModal";
import InvestmentService from "../../repository/InvestmentService";
import {toast} from "react-toastify";

const IndividualSignaturePage = (props) => {

    const [userInfo, setUserInfo] = useState([])
    const [signature, setSignature] = useState([])
    const [documents, setDocuments] = useState([])
    const [signDocumentUrl, setSignDocumentUrl] = useState('')
    const [showSigningModal, setShowSigningModal] = useState(false)

    useEffect(() => {
        setUserInfo(JSON.parse(localStorage.getItem('userInfo')))
        fetchSignatureDocuments()
    }, [])
    const fetchSignatureDocuments = () => {
        SignatureService.fetchSignatureDocuments(Number.parseInt(props.match.params.id))
            .then(response => {
                setSignature(response.data)
                setDocuments(response.data.documents)
            })
    }

    const openSigningModal = (document) => {
        const signDocumentUrl = document.signers.filter(signer => signer.email === userInfo.email)[0].signDocumentUrl

        setSignDocumentUrl(signDocumentUrl)
        setShowSigningModal(true)
    }

    const closeSignModal = () => {
        SignatureService.fetchSignatureStatus(signature.signatureRequestId)
            .then(response => {
                setSignature(response.data)
                setShowSigningModal(false)

                const signedDocuments = response.data.documents
                    .filter(document => document.signers.filter(signer => signer.email === userInfo.email)[0].signatureCompleted)

                if (signedDocuments.length === response.data.documents.length) {
                    InvestmentService.postNewInvestment({
                        dealId: Number.parseInt(props.match.params.id),
                        amount: 0,
                        signatureRequestId: response.data.signatureRequestId
                    })
                        .then(response => {
                            if (response.data.investmentStatus.id === 'MAKE_PAYMENT') {
                                props.history.push(`/investment/${response.data.id}/payment/`)
                            } else {
                                props.history.push('/my-investments')
                            }
                        })
                        .catch(error => {
                            toast.error(error)
                        })
                }
            })
            .catch(error => console.log(error))
    }


    return (
        <>
            <Grid container
                  spacing={2}
                  justify={"center"}
                  style={{paddingTop: 80}}
            >
                <Grid item lg={12} xs={12}>
                    <Card raised>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item lg={12} xs={12}>
                                    <Typography variant='h6'>
                                        You are required to sign the following documents:
                                    </Typography>
                                </Grid>

                                {documents.length === 0 ? (
                                    <Grid item lg={10} xs={12}>
                                        <Typography>
                                            No documents
                                        </Typography>
                                    </Grid>
                                ) : (
                                    documents.map(document => (
                                        <Grid key={document.id} item lg={10} xs={12}>
                                            <Grid container spacing={2}
                                                  alignItems={"center"}>
                                                <Grid item lg={5} xs={12}>
                                                    <Typography>
                                                        {document.title}
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={3} xs={12}
                                                      style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                    <Button style={{margin: 5}}
                                                            color="primary"
                                                            variant="contained"
                                                            onClick={() => openSigningModal(document)}>
                                                        Sign Agreement
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {document && (
                <SigningModal
                    documentUrl={signDocumentUrl}
                    open={showSigningModal}
                    close={closeSignModal}
                />
            )}
        </>
    )
}

export default IndividualSignaturePage