import React from 'react';

import {Route} from 'react-router-dom';
import ContactInfoPage from '../../pages/registration/ContactInfoPage';
import AccreditationPage from '../../pages/registration/AccreditationPage';
import SuitabilityPage from '../../pages/registration/SuitabilityPage';
import InvestorStatusPage from '../../pages/registration/InvestorStatusPage';
import FinalSubmissionPage from '../../pages/registration/FinalSubmissionPage';
import WaitingForApproval from '../../pages/registration/WaitingForApprovalPage';
import Aux from '../../hoc/AuxWrapper';
import DocumentationPage from "../../pages/registration/DocumentationPage";
import {Switch, useParams, useRouteMatch} from "react-router";
import HorizontalStepperWithError from "../../components/registration/HorizontalStepperWithError";
import NextStep from "../registration/NextStep";

const IndividualRegistration = (prop) => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let {path, url} = useRouteMatch()
    const {offerId, actionId} = useParams()

    const steps = [
        // {value: 'STEP_BASIC_INFO', label: 'Basic Info', path: '/basic-info', number: 1},
        {value: 'STEP_CONTACT_INFO', label: 'Contact Info & ID', path: '/contact-info', number: 1},
        {value: 'STEP_DOCUMENTATION', label: 'Documentation', path: '/documentation', number: 2},
        {value: 'STEP_SUITABILITY', label: 'Suitability', path: '/suitability', number: 3},
        {value: 'STEP_ACCREDITATION', label: 'Accreditation', path: '/accreditation', number: 4},
        {value: 'STEP_INVESTOR_STATUS', label: 'Investor Status', path: '/investor-status', number: 5},
        {value: 'STEP_FINAL_SUBMISSION', label: 'Disclosures & Final Submission', path: '/final-submission', number: 6},
        {value: 'STEP_APPROVAL', label: 'Waiting for approval', path: '/approval', number: 7}
    ];
    // const [steps1, setSteps1] = useState(null)
    // const history = useHistory()

    // useEffect(() => {
    //     if (prop.offer) {
    //         let st = steps.filter(value => prop.offer.steps.filter(step => (step.status !== 'APPROVED' && step.status !== 'PENDING_APPROVAL'))
    //             .map(step => step.step)
    //             .includes(value.value))
    //         setSteps1(st)
    //         // prop.redirect(st[0].path)
    //         // history.push(st[0].path)
    //     }
    // }, [prop.offer])

    return (
        <Aux>
            {actionId && (
                <HorizontalStepperWithError
                    steps={prop.offer?.steps}
                    currentStep={actionId}
                />
            )}

            {/*<RegistrationSteps*/}
            {/*    // steps={steps}*/}
            {/*    steps={steps.filter(value => prop.offer.individualSteps.includes(value.value))}*/}
            {/*    currentStep={userInfo.registrationStatus}*/}
            {/*    setRegistrationStatus={prop.setRegistrationStatus}/>*/}
            <Switch>
                <Route path={`${path}/contact-info`} exact
                       render={(props) =>
                           <ContactInfoPage
                               {...props}
                               pushInvestorChange={prop.pushInvestorChange}
                               investorData={prop.investorData}
                               deleteInvestorData={prop.deleteInvestorData}
                               onDocumentDelete={prop.onDocumentDelete}
                               fetchAttributeOptions={prop.fetchAttributeOptions}
                               fetchInvestorStatusByStep={(step) => prop.fetchInvestorStatusByStep(step)}
                               investorAttributeOptions={prop.investorAttributeOptions}
                               saveDocument={prop.saveDocument}
                               validate={prop.validate}
                               setRegistrationStatus={prop.setRegistrationStatus}
                               setCurrentRegistrationStep={(step) => prop.setCurrentRegistrationStep(step)}
                               currentStep={userInfo.registrationStatus}
                               steps={prop.offer}
                           />}
                />

                <Route path={`${path}/documentation`} exact
                       render={(props) =>
                           <DocumentationPage
                               {...props}
                               pushInvestorChange={prop.pushInvestorChange}
                               investorData={prop.investorData}
                               deleteInvestorData={prop.deleteInvestorData}
                               onDocumentDelete={prop.onDocumentDelete}
                               fetchAttributeOptions={prop.fetchAttributeOptions}
                               fetchInvestorStatusByStep={(step) => prop.fetchInvestorStatusByStep(step)}
                               investorAttributeOptions={prop.investorAttributeOptions}
                               saveDocument={prop.saveDocument}
                               validate={prop.validate}
                               setRegistrationStatus={prop.setRegistrationStatus}
                               setCurrentRegistrationStep={(step) => prop.setCurrentRegistrationStep(step)}
                               currentStep={userInfo.registrationStatus}
                               steps={prop.offer}
                           />
                       }
                />

                <Route path={`${path}/accreditation`} exact
                       render={(props) =>
                           <AccreditationPage
                               {...props}
                               pushInvestorChange={prop.pushInvestorChange}
                               investorData={prop.investorData}
                               onDocumentDelete={prop.onDocumentDelete}
                               fetchAttributeOptions={prop.fetchAttributeOptions}
                               fetchInvestorStatusByStep={(step) => prop.fetchInvestorStatusByStep(step)}
                               investorAttributeOptions={prop.investorAttributeOptions}
                               saveDocument={prop.saveDocument}
                               deleteInvestorData={prop.deleteInvestorData}
                               validate={prop.validate}
                               setRegistrationStatus={prop.setRegistrationStatus}
                               setCurrentRegistrationStep={(step) => prop.setCurrentRegistrationStep(step)}
                               currentStep={userInfo.registrationStatus}
                               steps={prop.offer}
                           />}/>

                <Route path={`${path}/suitability`} exact
                       render={(props) =>
                           <SuitabilityPage
                               {...props}
                               deleteInvestorData={prop.deleteInvestorData}
                               deleteMultiInvestorData={prop.deleteMultiInvestorData}
                               pushInvestorChange={prop.pushInvestorChange}
                               investorData={prop.investorData}
                               onDocumentDelete={prop.onDocumentDelete}
                               fetchAttributeOptions={prop.fetchAttributeOptions}
                               fetchInvestorStatusByStep={(step) => prop.fetchInvestorStatusByStep(step)}
                               investorAttributeOptions={prop.investorAttributeOptions}
                               saveDocument={prop.saveDocument}
                               validate={prop.validate}
                               setRegistrationStatus={prop.setRegistrationStatus}
                               setCurrentRegistrationStep={(step) => prop.setCurrentRegistrationStep(step)}
                               currentStep={userInfo.registrationStatus}
                               steps={prop.offer}
                           />}/>

                <Route path={`${path}/investor-status`} exact
                       render={(props) =>
                           <InvestorStatusPage
                               {...props}
                               pushInvestorChange={prop.pushInvestorChange}
                               investorData={prop.investorData}
                               onDocumentDelete={prop.onDocumentDelete}
                               fetchAttributeOptions={prop.fetchAttributeOptions}
                               fetchInvestorStatusByStep={(step) => prop.fetchInvestorStatusByStep(step)}
                               investorAttributeOptions={prop.investorAttributeOptions}
                               saveDocument={prop.saveDocument}
                               validate={prop.validate}
                               setRegistrationStatus={prop.setRegistrationStatus}
                               setCurrentRegistrationStep={(step) => prop.setCurrentRegistrationStep(step)}
                               currentStep={userInfo.registrationStatus}
                               steps={prop.offer}
                           />}/>

                <Route path={`${path}/final-submission`} exact
                       render={(props) =>
                           <FinalSubmissionPage
                               {...props}
                               pushInvestorChange={prop.pushInvestorChange}
                               onDocumentDelete={prop.onDocumentDelete}
                               investorData={prop.investorData}
                               fetchAttributeOptions={prop.fetchAttributeOptions}
                               fetchInvestorStatusByStep={(step) => prop.fetchInvestorStatusByStep(step)}
                               investorAttributeOptions={prop.investorAttributeOptions}
                               saveDocument={prop.saveDocument}
                               validate={prop.validate}
                               setRegistrationStatus={prop.setRegistrationStatus}
                               setCurrentRegistrationStep={(step) => prop.setCurrentRegistrationStep(step)}
                               currentStep={userInfo.registrationStatus}
                               steps={prop.offer}
                           />}/>


                <Route path={`${path}/approval`} exact component={WaitingForApproval}/>
                <Route path={path} exact
                       render={(props) =>
                           <NextStep
                               {...props}
                               nextStep={prop.nextStep}
                           />
                       }/>
                {/*<Redirect to="/next-step"/>*/}


                {/*{steps1 && steps1[0] && (*/}
                {/*    <Redirect to={steps1[0].path}/>*/}
                {/*)}*/}

                {/*{steps1.filter(s => s.value === userInfo.registrationStatus).length === 1*/}
                {/*    ? <Redirect to={steps1.filter(s => s.value === userInfo.registrationStatus)[0].path}/>*/}
                {/*    : <Redirect to={steps1[0].path}/>*/}
                {/*}*/}
            </Switch>
        </Aux>
    );
};

export default IndividualRegistration;