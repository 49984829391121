import axios from '../custom-axios/app';
import React from 'react';

const ConversationService = {
    fetchSubscriptions: () => {
        return axios.get("/rest/conversation/list");
    },
    fetchMessagesPerChannel: (channelId) => {
        return axios.get("/rest/conversation/" + channelId);
    },
    updateMessage: (id) => {
        return axios.post("/rest/conversation/update/" + id);
    },
    createZendeskTicket: () => {
        return axios.post("/rest/conversation/zendesk/add");
    },
    postMessage: (channelId, content, flag) => {
        return axios.post("/rest/conversation/add", {"channelId": channelId, "content": content, "flag": flag});
    },
    formatMessage: (message) => {
        return message.split('\n').map(part => (<> {part} <br/></>));
    },
    checkNewMessageFromZendesk: (channelId) => {
        return axios.get("/rest/conversation/checkzendesk/" + channelId);
    }

};

export default ConversationService;