import React, {useCallback} from 'react';

import {useDropzone} from 'react-dropzone';
import RootRef from "@material-ui/core/RootRef";
import {Typography} from "@material-ui/core";


const FileUploadField = ({title, inputCaption, multiple, accept, onChange}) => {

    const onDrop = useCallback(acceptedFiles => {
        onChange(acceptedFiles)
    }, [])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
    const {ref, ...rootProps} = getRootProps()


    return (
        <>
            <Typography>
                {title}
            </Typography>

            <RootRef rootRef={ref}>
                <div
                    {...rootProps}
                    className={'upload-zone'}
                >
                    <input
                        {...getInputProps()}
                        multiple={multiple}
                        accept={accept}
                        type={"file"}
                    />
                    <Typography>
                        {isDragActive ?
                            'Drop the files here ...' :
                            'Drag \'n\' drop some files here, or click to select files'
                        }
                    </Typography>

                    <Typography
                        variant={"caption"}
                        style={{marginTop: 10}}
                    >
                        {inputCaption}
                    </Typography>
                </div>
            </RootRef>
        </>
    )
}

export default FileUploadField