import React, {Component} from 'react';
import Aux from '../hoc/AuxWrapper';
import UserService from '../repository/UserService';
import {NavLink} from 'react-router-dom';
import styled from "styled-components";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";


const MainWrap = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  
  @media (max-width: 600px) {
    width: 100%;
    margin: 0 auto;
  }
`;

const SignText = styled.div`
  border-bottom: 1px solid #032F66;
  margin-left: 10px;
  color: #032F66;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  text-decoration: none;
`;

const TextLogin = styled.div`
  color: #607D8B;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
`;

const IconBlock = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  position: absolute;
  top: -25px;
  left: 30px;
  background: linear-gradient(0deg, rgba(102,187,106,1) 0%, rgba(67,160,71,1) 100%);;
`;

const WrapMainElement = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
   
  @media (max-width: 600px) {
    width: 90%;
   }
`;

const WrapBoxLogin = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0 75px 0;
`;

const CartComponent = styled.div`
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),
     0px 1px 1px 0px rgba(0,0,0,0.14),
      0px 1px 3px 0px rgba(0,0,0,0.12);
    padding: 60px 30px 35px 30px;
    position: relative;
    background: white;
    max-width: 480px;
    min-width: 480px;
    
    @media (max-width: 600px) {
        min-width: 100%;
        padding: 60px 15px 35px 15px;
  } 
`;

const HeaderTextLogin = styled.div`
  font-size: 24px;
  font-family: "Roboto";
  font-weight: 500;
`;

class changePassword extends Component {

  state = {
    email: ''
  };

  emailChangeHandler(e) {
    this.setState({
      email: e.target.value
    });
  }

  resetPasswordHandler(e) {
    e.preventDefault();
    UserService.initializePasswordReset({
      email: this.state.email
    }).then(res => {
      alert('Email for password reset was sent. Please check your inbox.')
    }).catch(err => {
      console.log(err);
    });
  }

  render() {
    return (
      <Aux>
        {/*<div className="signup-overlay-1"></div>*/}

        <MainWrap>


            <WrapMainElement>

              <WrapBoxLogin>
                <NavLink to="index.php">
                  <img
                    src={require('../assets/images/logo-blue.png')}
                    width="140"
                    height="51"
                    alt=""/>
                </NavLink>
              </WrapBoxLogin>

              <CartComponent>
                <IconBlock>
                  <PermIdentityOutlinedIcon style={{color: 'white', fontSize: '30px', margin: '0 auto'}}/>
                </IconBlock>

                <HeaderTextLogin>Reset Password</HeaderTextLogin>
                <TextLogin>Sign up on the internal platform</TextLogin>

                <hr/>

                <form className="" onSubmit={(e) => this.resetPasswordHandler(e)}>
                  <div className="form-group">
                    <TextField
                      type="email"
                      fullWidth
                      label="Email address"
                      variant="outlined"
                      placeholder="Email address"
                      onChange={(e) => this.emailChangeHandler(e)}
                      value={this.state.email}/>
                  </div>

                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    formAction=""
                    type="submit">Reset
                  </Button>
                </form>

                <hr/>

                <div className="form-group">
                  <Box display="flex">
                    <TextLogin>Remembered your password?</TextLogin>
                    <NavLink to="/login">
                      <SignText>Log In</SignText>
                    </NavLink>
                  </Box>
                </div>

              </CartComponent>

            </WrapMainElement>

        </MainWrap>

      </Aux>
    );
  }
}

export default changePassword;