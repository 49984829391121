import React, {Component} from 'react';
import RadioInput from '../../components/input/RadioInput';
import validationAlert from "../../components/ValidationAlert";
import {CardContent, Grid} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import InvestorService from "../../repository/InvestorService";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputNumber from "../../components/input/InputNumber";
import SelectCountry from "../../components/input/SelectCountry";
import UserService from "../../repository/UserService";
import Divider from "@material-ui/core/Divider";
import DobUS from "../../components/input/DobUsSelect";
import GooglePlaceAutocomplete from "../../components/input/new/GooglePlaceAutocomplete";
import InputText from "../../components/input/InputText";
import Select from "../../components/input/Select1";


class ContactInfoPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            'COUNTRY_CITIZEN': [],
            // 'HOME_ADDR_MATCH': [],
            'HAVE_PASSPORT': [],
            'HAVE_SSN': [],
            ssnError: false,
            nonMandatoryFields: ['SEC_MAIL', 'HOME_ADDR_MATCH', 'PASSPORT_NUMBER', 'ID_NUMBER', 'SSN', 'STATE_ID_4',
                'STATE_ID_BACK', 'PASSPORT', 'FINANCIAL_STATEMENT_', 'HAVE_PASSPORT', 'HAVE_SSN',
                'PASSPORT_2_NUMBER_INDIVIDUAL', 'PASSPORT_2_INDIVIDUAL', 'MAILING_STATE_PROVINCE_ID', 'STATE_PROVINCE_ID', 'SAME_MAILING_ADDRESS_ID'],
            months: [],
            years: [],
            openUpload: false,
            geolocationCitizenshipId: 0,
            errorEmailValid: false,
            email: '',
            firstEmailValue: ' ',
            user: null,
            errors: [],

            states: [],
            countries: [],
            'MAILING_COUNTRY_ID': [],
            'MAILING_STATE_PROVINCE_ID': [],
            'SAME_MAILING_ADDRESS_ID': [],
            //mandatoryFields: ['MAILING_STATE_PROVINCE_ID', 'STATE_PROVINCE_ID', 'SAME_MAILING_ADDRESS_ID'],
            // months: [],
            // years: [],
            geolocationCountryId: 0,
            geolocationStateId: 0,
            postalError: false,
            availableAddresses: [],
            apiKey: 'AIzaSyCaB_eJmQ8cOSg26Mx4u5MYEDHBzqtJ640',
        }
    }

    componentDidMount() {
        this.props.setCurrentRegistrationStep('Contact Info');
        this.init();
        window.scrollTo(0, 0)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.investorData !== undefined && nextProps.investorData['SEC_MAIL']
            && nextProps.investorData['SEC_MAIL'].chosenValue && nextProps.investorData['SEC_MAIL'].chosenValue !== prevState.firstEmailValue) {
            return {
                email: nextProps.investorData['SEC_MAIL'].chosenValue,
                firstEmailValue: nextProps.investorData['SEC_MAIL'].chosenValue
            }
        }
        return null
    }

    init() {
        UserService.fetchUser()
            .then(response => {
                this.setState({user: response.data})
            })
            .catch(error => console.log(error));

        this.props.fetchAttributeOptions('COUNTRY_CITIZEN')
            .then((data) => {
                // InvestorService.fetchByAttributeCode('COUNTRY_ID')
                //     .then(response => {
                //         for (let i = 0; i < data.data.length; i++) {
                //             if (data.data[i].value === response.data[0].chosenValue) {
                //                 this.setState({
                //                         geolocationCitizenshipId: data.data[i].id,
                //                     }, () => {
                //                         if ((this.props.investorData.COUNTRY_CITIZEN
                //                             && this.props.investorData.COUNTRY_CITIZEN.chosenOptionId === null)
                //                             || this.props.investorData.COUNTRY_CITIZEN === undefined) {
                //                             this.props.pushInvestorChange({
                //                                 attributeCode: "COUNTRY_CITIZEN",
                //                                 investorAttributeOptionId: this.state.geolocationCitizenshipId,
                //                                 value: data.data[i].value
                //                             })
                //                         }
                //                     }
                //                 );
                //                 break
                //             }
                //         }
                //     })

                this.setState({
                    ...this.state,
                    'COUNTRY_CITIZEN': data.data
                });
            });

        this.props.fetchAttributeOptions('HAVE_PASSPORT').then((data) => {
            this.setState({
                ...this.state,
                'HAVE_PASSPORT': data.data
            }, () => {
            })
        })

        this.props.fetchAttributeOptions('HAVE_SSN').then((data) => {
            this.setState({
                ...this.state,
                'HAVE_SSN': data.data
            }, () => {
            });
        });

        this.props.fetchAttributeOptions('STATE_PROVINCE_ID')
            .then((data) => {
                fetch("https://geolocation-db.com/json/7733a990-ebd4-11ea-b9a6-2955706ddbf3")
                    .then(response => response.json())
                    .then(response => {
                        for (let i = 0; i < data.data.length; i++) {
                            if (data.data[i].value === response.state) {
                                this.setState({
                                    geolocationStateId: data.data[i].id,
                                }, () => {
                                    if ((this.props.investorData['STATE_PROVINCE_ID']
                                        && this.props.investorData['STATE_PROVINCE_ID'].chosenOptionId === null)
                                        || this.props.investorData['STATE_PROVINCE_ID'] === undefined) {
                                        this.props.pushInvestorChange({
                                            attributeCode: "STATE_PROVINCE_ID",
                                            investorAttributeOptionId: data.data[i].id,
                                            value: data.data[i].value
                                        })
                                    }
                                });
                                break
                            }
                        }
                    });
                this.setState({
                    ...this.state,
                    states: data.data
                });
            });

        this.props.fetchAttributeOptions('COUNTRY_ID')
            .then((data) => {
                if ((this.props.investorData.COUNTRY_ID
                    && this.props.investorData.COUNTRY_ID.chosenOptionId === null)
                    || this.props.investorData.COUNTRY_ID === undefined) {
                    fetch("https://geolocation-db.com/json/7733a990-ebd4-11ea-b9a6-2955706ddbf3")
                        .then(response => response.json())
                        .then(response => {
                            for (let i = 0; i < data.data.length; i++) {
                                if (data.data[i].value === response.country_name) {
                                    this.setState({
                                        geolocationCountryId: data.data[i].id,
                                    }, () => {
                                        this.props.pushInvestorChange({
                                            attributeCode: "COUNTRY_ID",
                                            investorAttributeOptionId: data.data[i].id,
                                            value: data.data[i].value,
                                        })
                                    });
                                    break
                                }
                            }
                        });
                }

                this.setState({
                    ...this.state,
                    countries: data.data
                });
            });

        this.props.fetchAttributeOptions('MAILING_COUNTRY_ID').then((data) => {
            this.setState({
                ...this.state,
                'MAILING_COUNTRY_ID': data.data
            });
        });

        this.props.fetchAttributeOptions('MAILING_STATE_PROVINCE_ID').then((data) => {
            this.setState({
                ...this.state,
                'MAILING_STATE_PROVINCE_ID': data.data
            });
        });

        this.props.fetchAttributeOptions('SAME_MAILING_ADDRESS_ID').then((data) => {
            this.setState({
                ...this.state,
                'SAME_MAILING_ADDRESS_ID': data.data
            });
        });

        let allYears = [];

        for (let i = 1900; i <= new Date().getFullYear() - 18; i++) {
            allYears.unshift(i);
        }

        let allMonths = [];
        for (let i = 1; i <= 12; i++) {
            allMonths.push(i);
        }

        this.setState({
            months: allMonths,
            years: allYears
        })
    }

    handleOnChangeAddress = (address) => {
        this.props.pushInvestorChange({
            attributeCode: "STREET_ADDRESS_ID",
            value: address,
        })
    }

    handleSelectedAddress = (place) => {
        let state1 = ''
        let country1 = ''
        let city1 = ''
        let address1 = ''
        let postcode1 = ''

        for (const component of place.address_components) {
            const componentType = component.types[0];

            switch (componentType) {
                case "street_number": {
                    address1 = `${component.long_name} ${address1}`;
                    break;
                }
                case "route": {
                    address1 += component.long_name;
                    break;
                }
                case "postal_code": {
                    postcode1 = `${component.long_name}${postcode1}`;
                    break;
                }
                case "postal_code_suffix": {
                    postcode1 = `${postcode1}-${component.long_name}`;
                    break;
                }
                case "locality":
                    city1 = component.long_name;
                    break;

                case "administrative_area_level_1": {
                    state1 = component.long_name;
                    break;
                }
                case "country":
                    country1 = component.long_name;
                    break;
                default:
            }
        }

        let country = this.state.countries.filter(_country => _country.value === country1)[0]

        if (country) {
            this.props.pushInvestorChange({
                attributeCode: 'COUNTRY_ID',
                investorAttributeOptionId: country.id,
                value: country.value,
            })
        } else {
            this.props.deleteInvestorData({
                attributeCode: 'COUNTRY_ID'
            });
        }

        if (country.value === 'United States') {
            let state = this.state.states.filter(state => state.value === state1)[0]
            this.props.pushInvestorChange({
                attributeCode: 'STATE_PROVINCE_ID',
                investorAttributeOptionId: state.id,
                value: state.value,
            })
        } else {
            this.props.deleteInvestorData({
                attributeCode: 'STATE_PROVINCE_ID'
            });
        }

        this.props.pushInvestorChange({
            attributeCode: "CITY_ID",
            value: city1,
        })

        this.props.pushInvestorChange({
            attributeCode: "STREET_ADDRESS_ID",
            value: address1,
        })

        if (postcode1) {
            this.props.pushInvestorChange({
                attributeCode: "POSTAL_CODE_ID",
                value: postcode1
            })
        } else {
            this.props.deleteInvestorData({
                attributeCode: 'POSTAL_CODE_ID'
            });
        }
    }

    changeStep(e) {
        e.preventDefault();

        this.props.fetchInvestorStatusByStep('STEP_CONTACT_INFO')
            .then(response => {
                let newMandatoryFields = [...this.state.nonMandatoryFields]

                if (this.props.investorData['COUNTRY_ID'] && this.props.investorData['COUNTRY_ID'].chosenValue === 'United States') {
                    newMandatoryFields = newMandatoryFields.filter(f => f !== 'STATE_PROVINCE_ID');
                }

                if (this.props.investorData['MAILING_COUNTRY_ID'] && this.props.investorData['MAILING_COUNTRY_ID'].chosenValue === 'United States') {
                    newMandatoryFields = newMandatoryFields.filter(f => f !== 'MAILING_STATE_PROVINCE_ID');
                }

                if (this.props.investorData['COUNTRY_CITIZEN'].chosenValue === 'United States') {
                    newMandatoryFields = newMandatoryFields.filter(f => f !== 'SSN')

                    if ((this.props.investorData['STATE_ID_4']
                        && this.props.investorData['STATE_ID_4'][this.props.investorData['STATE_ID_4'].length - 1].chosenValue)
                        || (this.props.investorData['STATE_ID_BACK']
                            && this.props.investorData['STATE_ID_BACK'][this.props.investorData['STATE_ID_BACK'].length - 1].chosenValue)) {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'ID_NUMBER')
                    }

                    if (this.props.investorData['PASSPORT']
                        && this.props.investorData['PASSPORT'][this.props.investorData['PASSPORT'].length - 1].chosenValue) {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'PASSPORT_NUMBER');
                    }

                    if (this.props.investorData['ID_NUMBER']
                        && this.props.investorData['ID_NUMBER'].chosenValue
                        && this.props.investorData['ID_NUMBER'].chosenValue !== "") {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'STATE_ID_4'
                            && f !== 'STATE_ID_BACK');
                    }

                    if (this.props.investorData['PASSPORT_NUMBER']
                        && this.props.investorData['PASSPORT_NUMBER'].chosenValue
                        && this.props.investorData['PASSPORT_NUMBER'].chosenValue !== '') {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'PASSPORT');
                    }

                    if ((this.props.investorData['STATE_ID_4']
                        && this.props.investorData['STATE_ID_4'][this.props.investorData['STATE_ID_4'].length - 1].chosenValue)
                        || (this.props.investorData['STATE_ID_BACK']
                            && this.props.investorData['STATE_ID_BACK'][this.props.investorData['STATE_ID_BACK'].length - 1].chosenValue)
                        || (this.props.investorData['ID_NUMBER']
                            && this.props.investorData['ID_NUMBER'].chosenValue
                            && this.props.investorData['ID_NUMBER'].chosenValue !== '')) {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'STATE_ID_4'
                            && f !== 'STATE_ID_BACK');
                    } else {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'PASSPORT'
                            && f !== 'PASSPORT_NUMBER');
                    }

                    if ((this.props.investorData['PASSPORT_2_INDIVIDUAL']
                        && this.props.investorData['PASSPORT_2_INDIVIDUAL'][0].chosenValue)
                        && !(this.props.investorData['PASSPORT_2_NUMBER_INDIVIDUAL']
                            && this.props.investorData['PASSPORT_2_NUMBER_INDIVIDUAL'].chosenValue)) {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'PASSPORT_2_NUMBER_INDIVIDUAL');
                    }
                } else {
                    newMandatoryFields = newMandatoryFields.filter(f => f !== 'HAVE_SSN');
                    if (this.props.investorData['HAVE_PASSPORT']
                        && this.props.investorData['HAVE_PASSPORT'].chosenValue === 'yes') {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'PASSPORT'
                            && f !== 'HAVE_PASSPORT'
                            && f !== 'PASSPORT_NUMBER');
                    } else {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'STATE_ID_4'
                            && f !== 'STATE_ID_BACK'
                            && f !== 'HAVE_PASSPORT'
                            && f !== 'ID_NUMBER');
                    }

                    if ((this.props.investorData['PASSPORT_2_INDIVIDUAL']
                        && this.props.investorData['PASSPORT_2_INDIVIDUAL'][0].chosenValue)
                        && !(this.props.investorData['PASSPORT_2_NUMBER_INDIVIDUAL']
                            && this.props.investorData['PASSPORT_2_NUMBER_INDIVIDUAL'].chosenValue)) {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'PASSPORT_2_NUMBER_INDIVIDUAL');
                    }

                    if (this.props.investorData['HAVE_SSN']
                        && this.props.investorData['HAVE_SSN'].chosenValue === 'yes') {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'SSN');
                    }
                }

                if (this.props.investorData['FINANCIAL_STATEMENT_'] && this.props.investorData['FINANCIAL_STATEMENT_'].length > 0) {
                    newMandatoryFields = newMandatoryFields.filter(f => f !== 'FINANCIAL_STATEMENT_');
                }

                InvestorService.fetchInvestorStatus()
                    .then(newResponse => {
                        let valid = this.props.validate(newResponse.data, response.data.filter(item => item.code !== 'STREET_ADDRESS_ID_2'
                            && item.code !== 'MAILING_STREET_ADDRESSS_ID' && item.code !== 'MAILING_CITY_ID' && item.code !== 'MAILING_POSTAL_CODE_ID'
                            && item.code !== 'MAILING_COUNTRY_ID' && item.code !== 'MAILING_STATE_PROVINCE_ID'
                            && item.code !== 'FIRST_NAME_ON_ID' && item.code !== 'LAST_NAME_ON_ID'), newMandatoryFields);

                        if (valid.length === 0) {
                            if (this.props.investorData['SAME_MAILING_ADDRESS_ID'] && this.props.investorData['SAME_MAILING_ADDRESS_ID'].chosenValue === 'no') {
                                this.props.updateInvestorInfo({
                                    streetAddress: this.props.investorData['STREET_ADDRESS_ID'].chosenValue,
                                    city: this.props.investorData['CITY_ID'].chosenValue,
                                    postalCode: this.props.investorData['POSTAL_CODE_ID'].chosenValue,
                                    mailStreetAddress: this.props.investorData['MAILING_STREET_ADDRESSS_ID'].chosenValue,
                                    mailCity: this.props.investorData['MAILING_CITY_ID'].chosenValue,
                                    mailPostalCode: this.props.investorData['MAILING_POSTAL_CODE_ID'].chosenValue,
                                    stateId: 1,
                                    countryId: 1,
                                    mailStateId: 1,
                                    mailCountryId: 1,

                                });
                            }
                            if (this.props.investorData['SAME_MAILING_ADDRESS_ID']
                                && this.props.investorData['SAME_MAILING_ADDRESS_ID'].chosenValue === 'yes') {
                                this.props.updateInvestorInfo({
                                    streetAddress: this.props.investorData['STREET_ADDRESS_ID'].chosenValue,
                                    city: this.props.investorData['CITY_ID'].chosenValue,
                                    postalCode: this.props.investorData['POSTAL_CODE_ID'].chosenValue,
                                    mailStreetAddress: this.props.investorData['STREET_ADDRESS_ID'].chosenValue,
                                    mailCity: this.props.investorData['CITY_ID'].chosenValue,
                                    mailPostalCode: this.props.investorData['POSTAL_CODE_ID'].chosenValue,
                                    stateId: 1,
                                    countryId: 1,
                                    mailStateId: 1,
                                    mailCountryId: 1,
                                });
                            }
                        }

                        // let valid = this.props.validate(this.props.investorData, response.data, newMandatoryFields);

                        if (valid.length === 0) {
                            this.navigate(e);
                        } else {
                            this.handleValidation(valid)
                            validationAlert(valid)
                        }
                    })
                    .catch(e => console.log(e));
            }).catch(err => {
            console.log(err);
        });
    }

    handleValidation = () => {
        let errors = {}
        let valid = true

        if (!this.props.investorData['SSN'] || !this.props.investorData['SSN'].chosenValue) {
            valid = false
            errors.ssn = 'Cannot be empty'
        }

        if (!this.props.investorData['PASSPORT_NUMBER'] || !this.props.investorData['PASSPORT_NUMBER'].chosenValue) {
            valid = false
            errors.passportNumber = 'Cannot be empty'
        }

        if (!this.props.investorData['ID_NUMBER'] || !this.props.investorData['ID_NUMBER'].chosenValue) {
            valid = false
            errors.governmentId = 'Cannot be empty'
        }

        if (!this.props.investorData['CITY_ID'] || !this.props.investorData['CITY_ID'].chosenValue) {
            valid = false
            errors.city = 'Cannot be empty'
        }

        if (!this.props.investorData['STREET_ADDRESS_ID'] || !this.props.investorData['STREET_ADDRESS_ID'].chosenValue) {
            valid = false
            errors.streetAddress = 'Cannot be empty'
        }

        if (!this.props.investorData['POSTAL_CODE_ID'] || !this.props.investorData['POSTAL_CODE_ID'].chosenValue) {
            valid = false
            errors.postalCode = 'Cannot be empty'
        }

        if (!this.props.investorData['COUNTRY_ID'] || !this.props.investorData['COUNTRY_ID'].chosenValue) {
            valid = false
            errors.country = 'Cannot be empty'

            if ((this.props.investorData['COUNTRY_ID'].chosenValue === 'United States')
                && (!this.props.investorData['STATE_PROVINCE_ID'] || !this.props.investorData['STATE_PROVINCE_ID'].chosenValue)) {
                valid = false
                errors.state = 'Cannot be empty'
            }
        }

        this.setState({
            errors: errors
        })

        return valid
    }

    navigate(e) {
        e.preventDefault();

        this.props.setRegistrationStatus('STEP_CONTACT_INFO', true)
    }

    onChangeSecondaryEmail = (e) => {
        let email = e.target.value
        let error = !(this.validEmail(email) || email === '')

        this.setState({
            errorEmailValid: error,
            email: email
        })
    }

    validEmail = (email) => {
        let pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(email);
    }

    submitSecondaryEmail = (e) => {
        if (!this.state.errorEmailValid) {
            let request = {
                "attributeCode": "SEC_MAIL",
                "value": this.state.email
            };
            this.props.pushInvestorChange(request)
        }
    }

    saveProgress = () => {
        this.props.history.push("/logout")
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item md={12} lg={6} xs={12}>
                    <Card raised
                          style={{height: '100%'}}>
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item lg={12} xs={12}>
                                    <Typography variant="h6">
                                        Personal information
                                    </Typography>

                                    <Divider/>
                                </Grid>

                                <Grid item lg={6} md={12} xs={12}>
                                    <Box my="5px">
                                        <Typography>
                                            First Name
                                            <span className="required__circle"> *</span>
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom>
                                            Legal Name as it appears on your ID
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item md={12} lg={6} xs={12}>
                                    <Box>
                                        <TextField
                                            type="text"
                                            fullWidth
                                            size={"small"}
                                            variant="outlined"
                                            autoComplete="0"
                                            disabled={true}
                                            value={this.state.user ? this.state.user.firstName : ""}/>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Box mt="20px">
                                <Grid container spacing={1}>
                                    <Grid item lg={6} xs={12}>
                                        <Box my="5px">
                                            <Typography>
                                                Last Name
                                                <span className="required__circle"> *</span>
                                            </Typography>
                                        </Box>

                                        <Box mb="20px">
                                            <Typography
                                                variant="caption"
                                                display="block"
                                                gutterBottom>Legal Name as it appears on your
                                                ID
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            size={"small"}
                                            variant="outlined"
                                            autoComplete="0"
                                            disabled={true}
                                            value={this.state.user ? this.state.user.lastName : ""}/>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box my="20px">
                                <Typography>
                                    DOB (US Style)
                                    <span className="required__circle"> *</span>
                                </Typography>
                            </Box>

                            <DobUS
                                monthsClassName="select__custom"
                                yearsClassName="select__custom"
                                daysClassName="select__custom"
                                attributeCode="DOB_ID"
                                onChangeHandler={this.props.pushInvestorChange}
                                defaultValue={this.props.investorData['DOB_ID']}
                                months={this.state.months}
                                years={this.state.years}/>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item md={12} lg={6}>
                    <Card raised>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        Home Address
                                    </Typography>

                                    <Divider/>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} lg={6}>
                                            <Box mt="10px">
                                                <Typography>
                                                    Country
                                                    <span className="required__circle"> *</span>
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} lg={6}>
                                            <SelectCountry
                                                selectedValue={
                                                    this.props.investorData['COUNTRY_ID']
                                                    && this.props.investorData['COUNTRY_ID'].chosenOptionId
                                                        ? this.props.investorData['COUNTRY_ID'].chosenOptionId
                                                        : this.state.geolocationCitizenshipId
                                                        ? this.state.geolocationCitizenshipId
                                                        : ''
                                                }
                                                attributeCode="COUNTRY_ID"
                                                onChangeHandler={this.props.pushInvestorChange}
                                                deleteInvestorData={this.props.deleteInvestorData}
                                                values={this.state.countries}
                                                hasError={!!this.state.errors.country}
                                                errorMessage={this.state.errors.country}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} lg={6}>
                                            <Box mt="10px">
                                                <Typography>
                                                    Street Address 1
                                                    <span className="required__circle"> *</span>
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} lg={6}>
                                            <GooglePlaceAutocomplete
                                                apiKey={this.state.apiKey}
                                                defaultValue={this.props.investorData['STREET_ADDRESS_ID']}
                                                attributeCode="STREET_ADDRESS_ID"
                                                onChange={this.handleOnChangeAddress}
                                                fillInAddress={this.handleSelectedAddress}
                                                hasError={!!this.state.errors.streetAddress}
                                                errorMessage={this.state.errors.streetAddress}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} lg={6}>

                                            <Box mt="10px">
                                                <Typography>
                                                    Street Address 2
                                                </Typography>
                                            </Box>

                                        </Grid>

                                        <Grid item xs={12} lg={6}>
                                            <InputText
                                                defaultValue={this.props.investorData['STREET_ADDRESS_ID_2']}
                                                attributeCode="STREET_ADDRESS_ID_2"
                                                onBlurHandler={this.props.pushInvestorChange}
                                                onChangeHandler={this.props.pushInvestorChange}
                                                // className="form-control input-lg"
                                                className="input__custom"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {
                                    (this.props.investorData['COUNTRY_ID']
                                        && this.props.investorData['COUNTRY_ID'].chosenValue
                                        && this.props.investorData['COUNTRY_ID'].chosenValue === 'United States')
                                    || (this.props.investorData['COUNTRY_ID']
                                        && this.props.investorData['COUNTRY_ID'].chosenValue === null)
                                        ? <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} lg={6}>
                                                    <Box mt="10px">
                                                        <Typography>
                                                            State/Province (US only)

                                                            {
                                                                this.props.investorData['COUNTRY_ID']
                                                                && this.props.investorData['COUNTRY_ID'].chosenValue === 'United States'
                                                                    ? <span className="required__circle"> *</span>
                                                                    : ''
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <Select
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        // dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="STATE_PROVINCE_ID"
                                                        geolocation={this.state.geolocationStateId}
                                                        selectedValue={
                                                            this.props.investorData['STATE_PROVINCE_ID']
                                                            && this.props.investorData['STATE_PROVINCE_ID'].chosenOptionId
                                                                ? this.props.investorData['STATE_PROVINCE_ID']
                                                                : false
                                                        }
                                                        onChange={this.props.pushInvestorChange}
                                                        options={
                                                            this.props.investorData['COUNTRY_ID']
                                                            && this.props.investorData['COUNTRY_ID'].chosenValue
                                                                ? this.props.investorData['COUNTRY_ID'].chosenValue !== 'United States'
                                                                ? []
                                                                : this.state.states
                                                                : this.state.states
                                                        }
                                                        hasError={!!this.state.errors.state}
                                                        errorMessage={this.state.errors.state}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        : null
                                }

                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} lg={6}>
                                            <Box mt="10px">
                                                <Typography>
                                                    City
                                                    <span className="required__circle"> *</span>
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} lg={6}>
                                            {/*<InputField*/}
                                            {/*    title={"City"}*/}
                                            {/*    name={"CITY_ID"}*/}
                                            {/*    required*/}
                                            {/*    value={this.props.investorData['CITY_ID']}*/}
                                            {/*    onChange={this.props.pushInvestorChange}*/}
                                            {/*    hasError={!!this.state.errors.pocIssuerEmail}*/}
                                            {/*    errorMessage={this.state.errors.pocIssuerEmail}*/}
                                            {/*/>*/}

                                            <InputText
                                                defaultValue={this.props.investorData['CITY_ID']}
                                                attributeCode="CITY_ID"
                                                onBlurHandler={this.props.pushInvestorChange}
                                                onChangeHandler={this.props.pushInvestorChange}
                                                // geolocation={this.state.geolocationCity}
                                                className="input__custom"
                                                hasError={!!this.state.errors.city}
                                                errorMessage={this.state.errors.city}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Box mt="10px">
                                                <Typography>
                                                    Postal Code
                                                    <span className="required__circle"> *</span>
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} lg={6}>
                                            <InputText
                                                defaultValue={this.props.investorData['POSTAL_CODE_ID']}
                                                attributeCode="POSTAL_CODE_ID"
                                                onBlurHandler={this.props.pushInvestorChange}
                                                onChangeHandler={this.props.pushInvestorChange}
                                                setPostalError={(e) => this.setPostalError(e)}
                                                minLength={0}
                                                maxLength={
                                                    this.props.investorData['COUNTRY_ID']
                                                    && this.props.investorData['COUNTRY_ID'].chosenValue === 'United States'
                                                        ? 5 : 9
                                                }
                                                hasError={!!this.state.errors.postalCode}
                                                errorMessage={this.state.errors.postalCode}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                {(this.props.investorData['COUNTRY_ID']
                    && this.props.investorData['COUNTRY_ID'].chosenValue === 'United States'
                    && this.props.investorData['STATE_PROVINCE_ID']
                    && this.props.investorData['STATE_PROVINCE_ID'].chosenValue
                    && this.props.investorData['STREET_ADDRESS_ID']
                    && this.props.investorData['STREET_ADDRESS_ID'].chosenValue
                    && this.props.investorData['CITY_ID']
                    && this.props.investorData['CITY_ID'].chosenValue
                    && this.props.investorData['POSTAL_CODE_ID']
                    && this.props.investorData['POSTAL_CODE_ID'].chosenValue)
                || (this.props.investorData['COUNTRY_ID']
                    && this.props.investorData['COUNTRY_ID'].chosenValue !== 'United States'
                    && this.props.investorData['STREET_ADDRESS_ID']
                    && this.props.investorData['STREET_ADDRESS_ID'].chosenValue
                    && this.props.investorData['CITY_ID']
                    && this.props.investorData['CITY_ID'].chosenValue
                    && this.props.investorData['POSTAL_CODE_ID']
                    && this.props.investorData['POSTAL_CODE_ID'].chosenValue)
                    ? <Box>

                        {/*<Box pb="15px">*/}
                        {/*    <Typography*/}
                        {/*        variant="subtitle2">Is your Mailing Address the same as*/}
                        {/*        your*/}
                        {/*        Home Address?*/}
                        {/*        <span className="required__circle">*</span>*/}
                        {/*    </Typography>*/}
                        {/*</Box>*/}

                        <RadioInput
                            name="SAME_MAILING_ADDRESS_ID"
                            attributeCode="SAME_MAILING_ADDRESS_ID"
                            deleteInvestorData={this.props.deleteInvestorData}
                            pushInvestorChange={this.props.pushInvestorChange}
                            defaultValue={this.props.investorData['SAME_MAILING_ADDRESS_ID']}
                            options={this.state['SAME_MAILING_ADDRESS_ID']}
                            onChange={this.props.pushInvestorChange}
                            noDisplay={true}
                            alwaysChecked={true}
                            attributes={[
                                {
                                    code: 'MAILING_STREET_ADDRESSS_ID',
                                    data: this.props.investorData['STREET_ADDRESS_ID']
                                },
                                {
                                    code: 'MAILING_CITY_ID',
                                    data: this.props.investorData['CITY_ID']
                                },
                                {
                                    code: 'MAILING_POSTAL_CODE_ID',
                                    data: this.props.investorData['POSTAL_CODE_ID']
                                },
                                {
                                    code: 'MAILING_COUNTRY_ID',
                                    data: this.props.investorData['COUNTRY_ID'],
                                    chosenData: this.state['MAILING_COUNTRY_ID']
                                },
                                {
                                    code: 'MAILING_STATE_PROVINCE_ID',
                                    data: this.props.investorData['STATE_PROVINCE_ID'],
                                    chosenData: this.state['MAILING_STATE_PROVINCE_ID']
                                }
                            ]}/>

                    </Box>
                    : this.props.investorData['SAME_MAILING_ADDRESS_ID'] = ''
                }

                {this.props.investorData['SAME_MAILING_ADDRESS_ID']
                && this.props.investorData['SAME_MAILING_ADDRESS_ID'].chosenValue === 'no'
                    ? <Grid item xs={12} spacing={3}>
                        <Card raised>
                            <CardContent>
                                <Box pt="15px">
                                    <Typography variant="h6" gutterBottom>
                                        Mailing Address
                                    </Typography>

                                    <Box my="20px">
                                        <Grid spacing={1} container>
                                            <Grid item xs={4} spacing={3}>
                                                <Box mt="10px">
                                                    <Typography variant="subtitle2">
                                                        Country
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={4} spacing={3}>
                                                <Select
                                                    className="input__custom"
                                                    dataLiveSearch="true"
                                                    dataStyle="input__custom"
                                                    dataWidth="100%"
                                                    attributeCode="MAILING_COUNTRY_ID"
                                                    selectedValue={this.props.investorData['MAILING_COUNTRY_ID']}
                                                    onChange={this.props.pushInvestorChange}
                                                    options={this.state['MAILING_COUNTRY_ID']}/>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box my="20px">
                                        <Grid spacing={1} container>
                                            <Grid item xs={4} spacing={3}>
                                                <Box mt="10px">
                                                    <Typography variant="subtitle2">
                                                        Street Address
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={4} spacing={3}>
                                                <InputText
                                                    defaultValue={this.props.investorData['MAILING_STREET_ADDRESSS_ID']}
                                                    attributeCode="MAILING_STREET_ADDRESSS_ID"
                                                    onBlurHandler={this.props.pushInvestorChange}
                                                    className="form-control input-lg"/>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box my="20px">
                                        <Grid spacing={1} container>
                                            <Grid item xs={4} spacing={3}>
                                                <Box mt="10px">
                                                    <Typography variant="subtitle2">
                                                        City
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Box>

                                            </Grid>

                                            <Grid item xs={4} spacing={3}>
                                                <InputText
                                                    defaultValue={this.props.investorData['MAILING_CITY_ID']}
                                                    attributeCode="MAILING_CITY_ID"
                                                    onBlurHandler={this.props.pushInvestorChange}
                                                    className="form-control input-lg"/>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box mt="20px">
                                        <Grid spacing={1} container>
                                            <Grid item xs={4} spacing={3}>
                                                <Box mt="10px">
                                                    <Typography variant="subtitle2">
                                                        {this.props.investorData['MAILING_COUNTRY_ID'] &&
                                                        this.props.investorData['MAILING_COUNTRY_ID'].chosenValue === 'United States' ?
                                                            'State/Province (US only) *' : 'State/Province (US only)'}
                                                    </Typography>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={4} spacing={3}>
                                                <Select
                                                    className="select__custom"
                                                    dataLiveSearch="true"
                                                    dataStyle="select__custom"
                                                    dataWidth="100%"
                                                    attributeCode="MAILING_STATE_PROVINCE_ID"
                                                    selectedValue={this.props.investorData['MAILING_STATE_PROVINCE_ID']}
                                                    onChange={this.props.pushInvestorChange}
                                                    options={this.props.investorData['MAILING_COUNTRY_ID'] &&
                                                    this.props.investorData['MAILING_COUNTRY_ID'].chosenValue === 'United States' ?
                                                        this.state['MAILING_STATE_PROVINCE_ID'] : []}/>

                                            </Grid>
                                        </Grid>
                                    </Box>
                                    {/*: null }*/}

                                    <Box my="20px">
                                        <Grid spacing={1} container>
                                            <Grid item xs={4} spacing={3}>
                                                <Box mt="10px">
                                                    <Typography variant="subtitle2">
                                                        Postal Code
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={4} spacing={3}>
                                                <InputText
                                                    defaultValue={this.props.investorData['MAILING_POSTAL_CODE_ID']}
                                                    attributeCode="MAILING_POSTAL_CODE_ID"
                                                    onBlurHandler={this.props.pushInvestorChange}/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    : null}

                <Grid item md={12} lg={12}>
                    <Box mb='20px'>
                        <Card raised
                              style={{height: '100%'}}>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={4} className="registration__padding--bottom">
                                        <Box my="5px">
                                            <Typography>
                                                Secondary Email
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={4} xs={12} className="registration__padding--top">
                                        {/*<TextInput*/}
                                        {/*    defaultValue={this.props.investorData['SEC_MAIL']}*/}
                                        {/*    attributeCode="SEC_MAIL"*/}
                                        {/*    onBlurHandler={this.props.pushInvestorChange}*/}
                                        {/*    className="input__custom"/>*/}

                                        <FormControl variant="outlined"
                                                     error={this.state.errorEmailValid}
                                                     size={"small"}
                                                     fullWidth>
                                            <TextField variant='outlined'
                                                       size={"small"}
                                                       error={this.state.errorEmailValid}
                                                       value={this.state.email}
                                                       helperText={this.state.errorEmailValid ? 'Email is not valid' : ''}
                                                       onChange={(e) => this.onChangeSecondaryEmail(e)}
                                                       onBlur={(e) => this.submitSecondaryEmail(e)}>
                                            </TextField>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Box mt="20px">
                                    <Grid container spacing={3}>
                                        <Grid item md={8} xs={12} lg={4} className="registration__padding--bottom">
                                            <Box my="5px">
                                                <Typography>
                                                    Country of Citizenship
                                                    <span className="required__circle"> *</span>
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} lg={4} className="registration__padding--top">
                                            <SelectCountry
                                                selectedValue={
                                                    this.props.investorData['COUNTRY_CITIZEN']
                                                    && this.props.investorData['COUNTRY_CITIZEN'].chosenOptionId
                                                        ? this.props.investorData['COUNTRY_CITIZEN'].chosenOptionId
                                                        : this.state.geolocationCitizenshipId
                                                        ? this.state.geolocationCitizenshipId
                                                        : ''
                                                }
                                                attributeCode="COUNTRY_CITIZEN"
                                                onChangeHandler={this.props.pushInvestorChange}
                                                deleteInvestorData={this.props.deleteInvestorData}
                                                values={this.state['COUNTRY_CITIZEN']}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                {
                                    this.props.investorData['COUNTRY_CITIZEN']
                                    && this.props.investorData['COUNTRY_CITIZEN'].chosenValue !== 'United States'
                                        ? <Box mt="20px">
                                            <Grid container spacing={3}>
                                                <Grid item md={8} xs={12} lg={4} className="registration__padding--bottom">
                                                    <Box my="5px">
                                                        <Typography>
                                                            Do you have a US social security number
                                                            <span className="required__circle"> *</span>
                                                        </Typography>
                                                    </Box>
                                                </Grid>

                                                <Grid item md={8} xs={12} lg={4} className="registration__padding--top">
                                                    <RadioInput
                                                        name="HAVE_SSN"
                                                        fieldToClear="SSN"
                                                        attributeCode="HAVE_SSN"
                                                        defaultValue={this.props.investorData['HAVE_SSN']}
                                                        options={this.state['HAVE_SSN']}
                                                        onChange={this.props.pushInvestorChange}/>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        : null
                                }

                                {
                                    (this.props.investorData['COUNTRY_CITIZEN']
                                        && this.props.investorData['COUNTRY_CITIZEN'].chosenValue === null)
                                    || (this.props.investorData['COUNTRY_CITIZEN']
                                        && this.props.investorData['COUNTRY_CITIZEN'].chosenValue === 'United States')
                                    || (this.props.investorData['HAVE_SSN']
                                        && this.props.investorData['HAVE_SSN'].chosenValue === 'yes')
                                        ? <Box mt="20px">
                                            <Grid container spacing={3}>
                                                <Grid item md={8} lg={4} xs={12} className="registration__padding--bottom">
                                                    <Box my="5px">
                                                        <Typography>
                                                            US Social Security Number
                                                            <span className="required__circle"> *</span>
                                                        </Typography>
                                                        <Typography variant='caption'>
                                                            (only 9 numbers, no letters)
                                                        </Typography>
                                                    </Box>
                                                </Grid>

                                                <Grid item md={8} lg={4} xs={12} className="registration__padding--top">
                                                    <InputNumber
                                                        defaultValue={this.props.investorData['SSN']}
                                                        attributeCode="SSN"
                                                        onBlurHandler={this.props.pushInvestorChange}
                                                        minLength={9}
                                                        maxLength={9}
                                                        hidden={true}
                                                        hasError={!!this.state.errors.ssn}
                                                        errorMessage={this.state.errors.ssn}
                                                    />
                                                </Grid>

                                                {
                                                    this.state.ssnError === true
                                                        ? <p style={{color: 'red'}}>
                                                            asd
                                                        </p>
                                                        : null
                                                }
                                            </Grid>
                                        </Box> : null
                                }

                                {
                                    (this.props.investorData['COUNTRY_CITIZEN']
                                        && this.props.investorData['COUNTRY_CITIZEN'].chosenValue !== null)
                                    && (this.props.investorData['COUNTRY_CITIZEN']
                                        && this.props.investorData['COUNTRY_CITIZEN'].chosenValue !== 'United States')
                                        ? <Box mt="20px">
                                            <Grid container spacing={1}>
                                                <Grid item md={8} lg={4} xs={12}>
                                                    <Typography> Do you have a Passport
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={8} lg={4} xs={12}>
                                                    <RadioInput
                                                        name="HAVE_PASSPORT"
                                                        attributeCode="HAVE_PASSPORT"
                                                        attributeCodeStateIdFront={'STATE_ID_4'}
                                                        documentGroupCodeStateIdFront={'state-id-front'}
                                                        attributeCodeStateIdBack={'STATE_ID_BACK'}
                                                        documentGroupCodeStateIdBack={'state-id-back'}
                                                        attributeCodePassport={'PASSPORT'}
                                                        documentGroupCodePassport={'passport'}
                                                        onDocumentDelete={this.props.onDocumentDelete}
                                                        defaultValue={this.props.investorData['HAVE_PASSPORT']}
                                                        options={this.state['HAVE_PASSPORT']}
                                                        onChange={this.props.pushInvestorChange}/>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        : null
                                }

                                {
                                    (this.props.investorData['COUNTRY_CITIZEN']
                                        && this.props.investorData['COUNTRY_CITIZEN'].chosenValue !== 'United States')
                                    && (this.props.investorData.HAVE_PASSPORT
                                        && this.props.investorData.HAVE_PASSPORT.chosenValue === 'no')
                                        ? <Box mt="20px">
                                            <Grid container spacing={1} xs={12} justify={"center"}>
                                                <Grid item lg={6}>
                                                    <Typography
                                                        variant={'h6'}
                                                        color={"error"}
                                                        gutterBottom>
                                                        You can't continue, because you need to have a passport!
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        : ''
                                }
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>

                <Grid item lg={12} xs={12}
                      style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '20px'}}>
                    {/*<Button*/}
                    {/*    variant='outlined'*/}
                    {/*    style={{*/}
                    {/*        background: '#FAFAFA',*/}
                    {/*        marginRight: '5px'*/}
                    {/*    }}*/}
                    {/*    onClick={(e, step, path) => this.previousStep(e)}>*/}
                    {/*    Previous*/}
                    {/*</Button>*/}

                    <Button variant='outlined'
                            style={{marginRight: '5px'}}
                            color='primary'
                            onClick={() => this.saveProgress()}>
                        Save Progress & Come Back Later
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        disabled={
                            this.state.errorEmailValid
                            || ((this.props.investorData['COUNTRY_CITIZEN']
                                && this.props.investorData['COUNTRY_CITIZEN'].chosenValue !== 'United States')
                                && (this.props.investorData.HAVE_PASSPORT
                                    && (this.props.investorData.HAVE_PASSPORT.chosenValue === 'no'
                                        || this.props.investorData.HAVE_PASSPORT.chosenValue === null)))
                        }
                        onClick={(e) => this.changeStep(e)}>
                        Next
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

export default ContactInfoPage