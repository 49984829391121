import React, {Component} from 'react';
import IssuerDashboardRoutes from '../containers/routing/IssuerDashboard';
import Aux from '../hoc/AuxWrapper';
import IssuerService from "../repository/IssuerService";
import InvestorAttributeService from "../repository/InvestorAttributeService";
import DocumentService from "../repository/DocumentService";
import {toast} from "react-toastify";

class DashboardIssuer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            issuerData: {},
            issuerAttributeOptions: [],
            issuer: null,
            currentStep: ''
        };
    }

    componentDidMount() {
        document.body.setAttribute('class', 'dashboard-body');
        this.fetchIssuer();
    }

    pushInvestorChange = (request) => {
        return IssuerService.saveAttributeData(request).then((data) => {
            let investorData = this.state.investorData;
            investorData[data.data.attributeCode] = data.data;
            this.setState({
                ...this.state,
                investorData: investorData
            })
        })

    };

    fetchIssuer = () => {
        IssuerService.fetchIssuer()
            .then(response => {
                this.setState({
                    issuer: response.data
                });
            }).catch(error => {
            console.log(error);
        });
    };

    saveIssuerPOC = (request) => {
        IssuerService.saveProfile(request)
            .then(response => {
                toast.success('Saved!');
                this.fetchIssuer();
            }).catch(error => {
            toast.error(error);
        })
    };

    updateIssuerInfo = (request) => {
        IssuerService.updateIssuerInfo(request)
            .then(response => {
                this.setState({
                    investor: response.data
                });
                toast.success('Saved!')
            }).catch(error => {
            toast.error(error)
        });
    };

    deleteInvestorData = (request) => {
        IssuerService.deleteAttributeData(request)
            .then(response => {
                console.log('Deleted successfully');
                let investorData = this.state.investorData;
                investorData[request.attributeCode] = {};
                this.setState({
                    ...this.state,
                    investorData: investorData
                });
            }).catch(error => {
            console.log(error);
        });
    };

    onDocumentDelete = (groupId, attrCode) => {
        this.deleteInvestorData({
            attributeCode: attrCode
        });
    };

    saveDocument = (groupId, file, attrCode) => {
        DocumentService.saveDocument(groupId, file)
            .then(response => {
                this.pushInvestorChange({
                    value: response.data.filename,
                    attributeCode: attrCode
                });
            }).catch(error => {
            console.log(error);
        });
    };


    fetchAttributeOptions = (code) => {
        return InvestorAttributeService.getAttributeOptions(code);
    };

    render() {

        let userInfo = JSON.parse(localStorage.getItem('userInfo'));

        let routes = (
            <div/>
        );

        if (userInfo.userGroup.id === 'USER_ISSUER') {
            routes = (
                <IssuerDashboardRoutes
                    path={this.props.path}
                    issuer={this.state.issuer}
                    updateIssuerInfo={this.updateIssuerInfo}
                    saveIssuerPOC={this.saveIssuerPOC}
                    issuerData={this.state.issuerData}
                    onDocumentDelete={this.onDocumentDelete}
                    investorAttributeOptions={this.state.investorAttributeOptions}
                    fetchAttributeOptions={this.fetchAttributeOptions}
                    pushInvestorChange={this.pushInvestorChange}
                    deleteInvestorData={this.deleteInvestorData}
                    saveDocument={this.saveDocument}
                    validate={this.props.validate}/>
            )
        }

        return (
            <Aux>
                {routes}
            </Aux>
        );
    }
}

export default DashboardIssuer;