import React, {useEffect, useState} from 'react';

import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import FormHelperText from "@material-ui/core/FormHelperText";
import OutlinedInput from "@material-ui/core/OutlinedInput";

const InputTextH = (props) => {
    const [value, setValue] = useState('');
    const [hiddenValue, setHiddenValue] = useState('');
    const [minLength, setMinLength] = useState(0);
    const [maxLength, setMaxLength] = useState(15);
    const [showHidden, setShowHidden] = useState(true);
    const [error, setError] = useState(false)

    const searchValue = /./g;
    const replaceValue = "•";

    useEffect(() => {
        if (props.defaultValue && props.defaultValue.chosenValue) {
            if (props.hidden) {
                setValue(props.defaultValue.chosenValue)
                setHiddenValue(props.defaultValue.chosenValue.trim().replace(searchValue, replaceValue))
            } else {
                setValue(props.defaultValue.chosenValue)
                setHiddenValue(props.defaultValue.chosenValue)
            }
        } else if (props.defaultValue) {
            setValue(props.defaultValue)
        }
    }, [props.defaultValue])

    useEffect(() => {
        if (props.maxLength) {
            setMaxLength(props.maxLength)
        }

        if (props.minLength) {
            setMinLength(props.minLength)
        }
    }, [props.maxLength, props.minLength])

    const handleOnChange = (e) => {
        const _value = e.target.value

        if (props.hidden) {
            let offset = _value.length - value.length;

            if (offset > 0) {
                setValue(value + _value.substring(value.length, value.length + offset))
                setHiddenValue(hiddenValue + _value.substring(hiddenValue.length, hiddenValue.length + offset)
                    .trim().replace(searchValue, replaceValue))
            } else if (offset < 0) {
                setValue(value.substring(0, value.length + offset))
                setHiddenValue(hiddenValue.substring(0, hiddenValue.length + offset)
                    .trim().replace(searchValue, replaceValue))
            }
        } else {
            setValue(_value)
            setHiddenValue(_value)
        }

        if (_value.length < minLength || _value.length > maxLength) {
            setError(true)
        } else {
            setError(false)
        }
    }

    const handleOnBlur = () => {
        if (!error) {
            props.onBlurHandler({
                attributeCode: props.attributeCode,
                value: value
            });
        }
    };

    const handleClickShowPassword = () => {
        setShowHidden(!showHidden)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    return (
        <FormControl variant="outlined"
                     fullWidth
                     size={"small"}
                     error={(props.hasError && props.errorMessage) || error}
        >
            <OutlinedInput
                type={'text'}
                value={showHidden ? hiddenValue : value}
                autoComplete={'0'}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                error={props.hasError || error}
                endAdornment={
                    props.hidden && (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showHidden ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                        </InputAdornment>
                    )}
            />

            <FormHelperText>
                {(props.hasError && props.errorMessage) || (error ? 'Count of characters is not valid (New value not saved)' : '')}
            </FormHelperText>
        </FormControl>
    )
}

export default InputTextH;