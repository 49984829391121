import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
//import {setMessage} from "./message";
import AuthService from "../../repository/AuthService";
import {AUTH_TOKEN} from "../../shared/utility";

export const signUp = createAsyncThunk(
    "auth/signUp",
    async (request, thunkAPI) => {
        try {
            const response = await AuthService.register(request);
            // thunkAPI.dispatch(setMessage(response.data.message));

            return response.data;
        } catch (error) {
            //thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const signIn = createAsyncThunk(
    "auth/signIn",
    async (request, thunkAPI) => {
        try {
            const response = await AuthService.signIn(request)
            const data = response.data

            if (response.status === 200) {
                localStorage.setItem(AUTH_TOKEN, data.accessToken);

                return {...data};
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const logout = createAsyncThunk(
    "auth/logout",
    async () => {
        await AuthService.logout();
    })

const initialState = {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
}

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;

            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(signUp.fulfilled, (state, action) => {
                state.isLoggedIn = false;
            })
            .addCase(signUp.rejected, (state, action) => {
                state.isLoggedIn = false;
            })
            .addCase(signIn.pending, (state, action) => {
                state.isFetching = true
            })
            .addCase(signIn.fulfilled, (state, action) => {
                state.isFetching = false
                state.isSuccess = true
            })
            .addCase(signIn.rejected, (state, action) => {
                state.isFetching = false
                state.isError = true
                state.errorMessage = action.payload.message;
            })
    },
})

export const {clearState} = authSlice.actions;

export const authSelector = (state) => state.auth