import axios from '../custom-axios/app';

const InvestorService = {
    fetchInvestorBasicInfo: () => {
        return axios.get("/rest/investors/basic");
    },

    fetchInvestorStatus: () => {
        return axios.get('/rest/registration/status');
    },

    fetchInvestorStatusByStep: (step) => {
        return axios.get(`/rest/investor_attribute/registration_status/${step}`);
    },

    fetchInvestorAttributeOptions: () => {
        return axios.get(`/rest/investor_attribute_option`);
    },

    saveAttributeData: (request) => {
        return axios.post('/rest/investor-data', request);
    },

    deleteAttributeData: (request) => {
        return axios.delete('/rest/investor-data', {data: request});
    },

    deleteAttributeDataByDocument: (request) => {
        return axios.delete('/rest/investor-data/document', {data: request});
    },

    deleteMultiAttributeData: (request) => {
        return axios.delete('/rest/investor-data/multi', {data: request});
    },

    fetchInvestor: () => {
        return axios.get('/rest/investors');
    },

    updateInvestorInfo: (request) => {
        return axios.post('/rest/investors', request);
    },

    fetchByAttributeCode: (code) => {
        return axios.get('/rest/investor-data/attribute_code/' + code);
    },
}

export default InvestorService;