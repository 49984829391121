import React, {useEffect, useState} from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Moment from "react-moment";
import TaskService from "../../repository/TaskService";

const IndividualDashboardPage = (props) => {

    const [tasks, setTasks] = useState([])
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        fetchTasks()
    }, [])

    const fetchTasks = () => {
        setLoader(true)

        TaskService.fetchTasks()
            .then(response => {
                setTasks(response.data)
                setLoader(false)
            })
            .catch(error => console.log(error))
    }

    const handleOnClickItem = (task) => {
        switch (task.taskType) {
            case 'PAYMENT':
                props.history.push(`/investment/${task.value}/payment`, {taskId: task.id})
                break
            default:
        }
    }


    return (
        <Grid container spacing={2}
              style={{paddingTop: 80}}>
            <Grid item lg={12} xs={12}>
                <Typography variant='h6'>
                    Dashboard
                </Typography>

                <Divider/>
            </Grid>

            {loader ? (
                <Grid item lg={12} xs={12}
                      style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          minHeight: '50vh'
                      }}>
                    <CircularProgress/>
                </Grid>
            ) : (
                <Grid item lg={3} xs={12}>
                    <Card raised>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item lg={12} xs={12}>
                                    <Typography variant='h6'>
                                        Todo
                                    </Typography>

                                    <Divider/>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    {tasks.length === 0 ? (
                                        <Typography>
                                            No tasks
                                        </Typography>
                                    ) : (
                                        <List dense
                                              style={{overflow: 'auto', maxHeight: 400}}>
                                            {tasks.map((task) => {
                                                return (
                                                    <>
                                                        <ListItem
                                                            key={task.id}
                                                            button
                                                            onClick={() => handleOnClickItem(task)}>
                                                            <ListItemText
                                                                primary={task.title}
                                                                secondary={
                                                                    <Moment format={'LLL'}>
                                                                        {task.dateCreated}
                                                                    </Moment>
                                                                }/>
                                                        </ListItem>

                                                        <Divider variant="middle" component="li"/>
                                                    </>
                                                )
                                            })}
                                        </List>
                                    )}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            )}
        </Grid>
    )
}

export default IndividualDashboardPage