import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import axios from "../../custom-axios/dms-app";
import ImagePreviewModal from "../../pages/dashboard/modals/ImagePreviewModal";
import PdfPreviewModal from "../../pages/dashboard/modals/PdfPreviewModal";
import Button from "@material-ui/core/Button";
import PublishIcon from "@material-ui/icons/Publish";
import {Box} from "@material-ui/core";

class FileUploadComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPreview: false,
            showPdfPreview: false
        }
    }

    openFile = (e) => {
        e.stopPropagation();
        axios.get("/v1/api/document/download/" + this.props.currentValue.chosenValue, {responseType: 'blob'})
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', this.props.currentValue.chosenValue);
                document.body.appendChild(link);
                link.click();
            });
    }
    onDocumentDelete = (e, document) => {
        this.props.onDocumentDelete(this.props.documentGroupCode, this.props.attributeCode, document)
    }

    handleClose = (e) => {
        this.setState({
            showPreview: false
        })
    }

    onFilePreview = (e, item) => {
        /* this.setState({
             fileToPreview: item.chosenValue,
             showPreview: true
         });*/

        let fileToPreview = item.chosenValue.split('.');
        if (fileToPreview[fileToPreview.length - 1] === 'pdf') {
            this.setState({
                fileToPreview: item.chosenValue,
                showPdfPreview: true
            });
        } else {
            this.setState({
                fileToPreview: item.chosenValue,
                showPreview: true
            });
        }

    }

    handlePdfClose = (e) => {
        this.setState({
            showPdfPreview: false
        })
    }

    render() {
        return (
            <>
                {this.state.showPreview && (
                    <ImagePreviewModal
                        className="modal"
                        show={this.state.showPreview}
                        file={this.state.fileToPreview}
                        close={this.handleClose}
                    />
                )}

                {this.state.showPdfPreview && (
                    <PdfPreviewModal
                        className="modal"
                        show={this.state.showPdfPreview}
                        file={this.state.fileToPreview}
                        close={this.handlePdfClose}/>
                )}

                <Dropzone
                    onDrop={acceptedFiles => this.props.onFileSelected(this.props.documentGroupCode, acceptedFiles, this.props.attributeCode)}>
                    {({getRootProps, getInputProps}) => (

                        <div
                            className={this.props.wrapperClass}>

                            <input id={this.props.fileNumber}
                                   type={"file"}
                                   {...getInputProps()}
                                   className="custom-file-input"
                                   accept={this.props.accept}
                                   required>
                            </input>

                            {this.props.currentValue && this.props.currentValue.chosenValue ? (
                                <label htmlFor={this.props.fileNumber}>
                                    <a href={process.env.REACT_APP_DMS_GATEWAY + '/v1/api/document/download/' + this.props.currentValue.chosenValue}>
                                        {this.props.currentValue.chosenValue}
                                    </a>
                                </label>
                            ) : (
                                <label htmlFor={this.props.fileNumber}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        endIcon={<PublishIcon/>}
                                        component="span">
                                        Upload document
                                    </Button>
                                </label>
                            )}
                        </div>
                    )}
                </Dropzone>

                <Box>
                    {this.props.currentValue ?
                        this.props.currentValue.map(document => {
                                if (document.chosenValue) {
                                    return (
                                        <Box
                                            pt="20px"
                                            pb="5px"
                                            style={{borderColor: '#DFDFDF'}}
                                            borderBottom={1}
                                            display="flex"
                                            key={document.chosenValue}>

                                            <Box flexGrow={1}>
                                                {document.documentOriginalName}
                                            </Box>
                                            {/*<label*/}
                                            {/*    className="color-black"> {this.props.labelText ? this.props.headText + ' ' + this.props.labelText : this.props.headText} </label>*/}

                                            <Box>
                                                <Button
                                                    type={"button"}
                                                    onClick={(e) => this.onFilePreview(e, document)}>Preview
                                                </Button>

                                                <Button
                                                    style={{color: '#B71C1C'}}
                                                    type={"button"}
                                                    onClick={(e) => this.onDocumentDelete(e, document)}>Remove
                                                </Button>
                                            </Box>
                                        </Box>
                                    )
                                }

                                return null
                            }
                        ) : null}
                </Box>
            </>
        )
    }


}

export default FileUploadComponent;