import axios from 'axios';
import {AUTH_TOKEN} from "../shared/utility";

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_GATEWAY}`,
    headers: {
        'Access-Control-Allow-Origin': '*'
    },
});

function handle403() {
    this.props.history.push('/login');
}

instance.interceptors.request.use(
    config => {
        const token = localStorage.getItem(AUTH_TOKEN);

        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        } else {
            //this.props.history.push('/login')
        }
        return config;
    },
    error => {
        if (error.response.status === 403) {
            handle403()
        }

        return Promise.reject(error)
    }
);


export default instance;