import React from 'react';
import {Redirect} from 'react-router-dom';
import * as queryString from "query-string";

const Logout = (props) => {

    if (props.location) {
        const redirectTo = queryString.parse(props.location.search).redirect

        if (redirectTo) {
            window.location.href = process.env.REACT_APP_OFFERING_GATEWAY + redirectTo
        }
    }

    localStorage.clear();

    return (
        <Redirect to="/login"/>
    )
}

export default Logout;