import React, {useEffect, useState} from 'react';

import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';

const SelectState = (props) => {
    const [stateId, setStateId] = useState('')

    useEffect(() => {
        if (props.selectedValue && props.selectedValue.chosenValue) {
            setStateId(props.selectedValue.chosenOptionId)
        }
    }, [props.selectedValue])

    const handleNewValue = (_stateId, value) => {
        props.onChangeHandler({
            attributeCode: props.attributeCode,
            investorAttributeOptionId: _stateId,
            value: value
        });
    }

    const handleOnChange = (e) => {
        const options = e.target.options
        let value;

        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                value = options[i].innerHTML.trim();
                break;
            }
        }

        setStateId(e.target.value)
        handleNewValue(e.target.value, value)
    }

    return (
        <FormControl variant="outlined"
                     fullWidth
                     disabled={props.values.length === 0}>
            <Select
                native
                value={stateId}
                onChange={handleOnChange}>

                <option disabled defaultValue/>

                {props.values.map(value => {
                    return (
                        <option
                            key={value.id}
                            value={value.id}>

                            {value.value}
                        </option>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export default SelectState;