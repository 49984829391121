import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Logout from "../../components/Logout";
import Aux from '../../hoc/AuxWrapper';
import RegistrationSteps from '../../components/registration/RegistrationSteps';

import IssuerProfilePage from '../../pages/registration/issuers/IssuerProfilePage'
import IssuerDocumentationPage from '../../pages/registration/issuers/IssuerDocumentationPage'
import IssuerWaitingForApproval from "../../pages/registration/issuers/IssuerWaitingForApproval";


const IssuerRegistration = (prop) => {

    document.body.setAttribute('class', 'stReg');
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let steps = [
        {value: 'STEP_ISSUER_PROFILE', label: 'Profile', path: '/issuer-profile', number: 1},
        {value: 'STEP_ISSUER_DOCUMENTATION', label: 'Documentation', path: '/issuer-documentation', number: 2},
        {value: 'STEP_APPROVAL', label: 'Approval', path: '/issuer-approval', number: 3}
    ];
    /*const getCurrentStep = (val) => {
        if (val === "STEP_ISSUER_PROFILE") {
            return "/issuer-profile";
        } else if (val === "STEP_ISSUER_DOCUMENTATION") {
            return "/issuer-documentation";
        } else if (val === "STEP_APPROVAL") {
            return "/issuer-approval";
        }
    }*/
    return (
        <Aux>
            <RegistrationSteps
                steps={steps}
                userGroup={prop.userGroup}
                currentStep={userInfo.registrationStatus}/>

            <Switch>
                <Route path="/logout" reload={prop.reload} exact
                       render={(props) =>
                           <Logout
                               reload={prop.reload}/>}
                />

                <Route path="/issuer-profile" exact
                       render={(props) =>
                           <IssuerProfilePage
                               {...props}
                               reload={prop.reload}
                               setRegistrationStatus={prop.setRegistrationStatus}
                               setCurrentRegistrationStep={(step) => prop.setCurrentRegistrationStep(step)}
                           />
                       }
                />

                <Route path="/issuer-documentation" exact
                       render={(props) =>
                           <IssuerDocumentationPage
                               {...props}
                               fetchInvestorStatusByStep={(step) => prop.fetchInvestorStatusByStep(step)}
                               validate={prop.validate}
                               pushInvestorChange={prop.pushInvestorChange}
                               onDocumentDelete={prop.onDocumentDelete}
                               setRegistrationStatus={prop.setRegistrationStatus}
                               investorData={prop.investorData}
                               fetchAttributeOptions={prop.fetchAttributeOptions}
                               investorAttributeOptions={prop.investorAttributeOptions}
                               saveDocument={prop.saveDocument}
                               reload={prop.reload}
                               setCurrentRegistrationStep={(step) => prop.setCurrentRegistrationStep(step)}/>}/>


                <Route path="/issuer-approval" exact
                       render={(props) =>
                           <IssuerWaitingForApproval/>
                       }
                />

                <Route path="/logout" exact
                       render={(props) =>
                           <Logout
                               reload={props.reload}/>}
                />
                <Redirect to={steps.filter(s => s.value === userInfo.registrationStatus)[0].path}/>
            </Switch>
        </Aux>
    );
};

export default IssuerRegistration;