import axios from '../custom-axios/dms-app';

const DocumentService = {
    saveDocument: (groupId, file) => {
        let formData = new FormData();
        formData.append('file', file[0]);

        return axios.post('/v1/api/document/document_group/' + groupId + '/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })

        // return axios.post('/v1/api/document/document_group/' + groupId + '/upload', formData, {
        //     headers: {
        //         'Content-Type': 'multipart/form-data'
        //     }
        // })
    },

    saveDocumentMultiple: (groupId, files) => {
        const formData = new FormData();
        files.forEach((file) => {
            formData.append("files", file);
        })

        return axios.post('/v1/api/document/document_group/' + groupId + '/upload/multiple', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },

    saveDocumentMultipleByEmail: (groupId, files, email) => {
        const formData = new FormData();
        files.forEach((file) => {
            formData.append("files", file);
        })

        return axios.post('/v1/api/document/document_group/' + groupId + '/upload/multiple/' + email, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },

    // deleteFile: (uuid) => {
    //     return axios.delete(`/api/documents/${uuid}`)
    // }
}

export default DocumentService;