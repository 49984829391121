import React from 'react';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

const NextStep = (props) => {

    return (
        <Container maxWidth='lg'>
            <Grid
                container
                justify={"center"}
                alignItems={"center"}
                style={{height: '100vh'}}>

                <Grid item lg={6} xs={12}>
                    <Grid container spacing={3}>
                        <Grid item lg={12} xs={12}>
                            <Typography
                                variant="h6"
                                align={"center"}
                            >
                                You need to enter additional information to invest in this offering, would you like
                                to continue?
                            </Typography>
                        </Grid>

                        <Grid item lg={12} xs={6}
                              style={{display: 'flex', justifyContent: 'center'}}
                        >
                            <Button variant='outlined'
                                    style={{marginRight: '5px'}}
                                    color='primary'
                                    onClick={() => props.nextStep('continue')}>
                                Yes
                            </Button>

                            <Button variant='outlined'
                                    style={{marginRight: '5px'}}
                                    color='primary'
                                    onClick={() => props.nextStep('return')}>
                                Back to offers
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default NextStep
