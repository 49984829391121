import axios from '../custom-axios/app';

const OfferService = {

    fetchUserOfferById: (id) => {
        return axios.get(`users/offerings/${id}/steps`);
    },

     fetchOfferById: (id) => {
         return axios.get("/rest/deal/offers/" + id);
     },
    fetchActiveDeals: () => {
         return axios.get("/rest/deal/active");
     },
    fetchActiveInvDeals: () => {
        return axios.get("/rest/deal/investor");
    },
    fetchByDealCode: (code) => {
        return axios.get("/rest/deal/" + code);
    },
    save: (entity) => {
        return axios.post("/rest/deal", entity);
    }
}

export  default OfferService