import React, {useEffect, useState} from 'react';

import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";

const InputText = (props) => {
    const [value, setValue] = useState('')
    const [hiddenValue, setHiddenValue] = useState('')

    const searchValue = /./g
    const replaceValue = "•"

    useEffect(() => {
        if (props.defaultValue && props.defaultValue.chosenValue) {
            if (props.hidden) {
                setValue(props.defaultValue.chosenValue.trim().replace(searchValue, replaceValue))
                setHiddenValue(props.defaultValue.chosenValue)
            } else {
                setValue(props.defaultValue.chosenValue)
            }
        }
    }, [props.defaultValue])

    const handleOnChange = (e) => {
        const _value = e.target.value

        if (props.hidden) {
            let offset = _value.length - hiddenValue.length;

            if (offset > 0) {
                setHiddenValue(hiddenValue + _value.substring(hiddenValue.length, hiddenValue.length + offset))
            } else if (offset < 0) {
                setHiddenValue(hiddenValue.substring(0, hiddenValue.length + offset))
            }

            setValue(_value)
            clearTimeout();
            setTimeout(() => {
                setValue(_value.replace(searchValue, replaceValue))
            }, 200);
        } else {
            setValue(_value)
        }
    }

    const handleOnBlur = () => {
        props.onBlurHandler({
            attributeCode: props.attributeCode,
            value: props.hidden ? hiddenValue : value
        });
    }

    return (
        <FormControl variant="outlined"
                     fullWidth>
            <TextField variant='outlined'
                       value={value}
                       size={"small"}
                       type={props.type ? props.type : 'text'}
                       error={props.hasError}
                       helperText={props.hasError && props.errorMessage}
                       onBlur={handleOnBlur}
                       onChange={handleOnChange}
            />
        </FormControl>
    )
};

export default InputText;