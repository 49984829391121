import React, {Component} from 'react';
import FileUploadComponent from "../../components/input/FileUploadComponent";
import {CardContent, Grid} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import DocumentationArea from "../../components/DocumentationArea";
import UserService from "../../repository/UserService";
import Divider from "@material-ui/core/Divider";
import validationAlert from "../../components/ValidationAlert";

class DocumentationPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            'COUNTRY_CITIZEN': [],
            nonMandatoryFields: ['PASSPORT_NUMBER', 'ID_NUMBER', 'STATE_ID_4',
                'STATE_ID_BACK', 'PASSPORT', 'FINANCIAL_STATEMENT_',
                'PASSPORT_2_NUMBER_INDIVIDUAL', 'PASSPORT_2_INDIVIDUAL'],
            openUpload: false,
            user: null,
            errors: [],
        }
    }

    componentDidMount() {
        this.props.setCurrentRegistrationStep('Documentation');
        this.init();
    }

    init() {
        UserService.fetchUser()
            .then(response => {
                this.setState({user: response.data})
            })
            .catch(error => console.log(error));
    }

    changeStep(e) {
        e.preventDefault();

        this.props.fetchInvestorStatusByStep('STEP_DOCUMENTATION')
            .then(response => {
                let newMandatoryFields = [...this.state.nonMandatoryFields]

                if (this.props.investorData['COUNTRY_CITIZEN'].chosenValue === 'United States') {

                    if ((this.props.investorData['STATE_ID_4']
                        && this.props.investorData['STATE_ID_4'][this.props.investorData['STATE_ID_4'].length - 1].chosenValue)
                        || (this.props.investorData['STATE_ID_BACK']
                            && this.props.investorData['STATE_ID_BACK'][this.props.investorData['STATE_ID_BACK'].length - 1].chosenValue)) {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'ID_NUMBER')
                    }

                    if (this.props.investorData['PASSPORT']
                        && this.props.investorData['PASSPORT'][this.props.investorData['PASSPORT'].length - 1].chosenValue) {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'PASSPORT_NUMBER');
                    }

                    if (this.props.investorData['ID_NUMBER']
                        && this.props.investorData['ID_NUMBER'].chosenValue
                        && this.props.investorData['ID_NUMBER'].chosenValue !== "") {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'STATE_ID_4'
                            && f !== 'STATE_ID_BACK');
                    }

                    if (this.props.investorData['PASSPORT_NUMBER']
                        && this.props.investorData['PASSPORT_NUMBER'].chosenValue
                        && this.props.investorData['PASSPORT_NUMBER'].chosenValue !== '') {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'PASSPORT');
                    }

                    if ((this.props.investorData['STATE_ID_4']
                        && this.props.investorData['STATE_ID_4'][this.props.investorData['STATE_ID_4'].length - 1].chosenValue)
                        || (this.props.investorData['STATE_ID_BACK']
                            && this.props.investorData['STATE_ID_BACK'][this.props.investorData['STATE_ID_BACK'].length - 1].chosenValue)
                        || (this.props.investorData['ID_NUMBER']
                            && this.props.investorData['ID_NUMBER'].chosenValue
                            && this.props.investorData['ID_NUMBER'].chosenValue !== '')) {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'STATE_ID_4'
                            && f !== 'STATE_ID_BACK');
                    } else {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'PASSPORT'
                            && f !== 'PASSPORT_NUMBER');
                    }

                    if ((this.props.investorData['PASSPORT_2_INDIVIDUAL']
                        && this.props.investorData['PASSPORT_2_INDIVIDUAL'][0].chosenValue)
                        && !(this.props.investorData['PASSPORT_2_NUMBER_INDIVIDUAL']
                            && this.props.investorData['PASSPORT_2_NUMBER_INDIVIDUAL'].chosenValue)) {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'PASSPORT_2_NUMBER_INDIVIDUAL');
                    }
                } else {
                    if (this.props.investorData['HAVE_PASSPORT']
                        && this.props.investorData['HAVE_PASSPORT'].chosenValue === 'yes') {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'PASSPORT'
                            && f !== 'HAVE_PASSPORT'
                            && f !== 'PASSPORT_NUMBER');
                    } else {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'STATE_ID_4'
                            && f !== 'STATE_ID_BACK'
                            && f !== 'HAVE_PASSPORT'
                            && f !== 'ID_NUMBER');
                    }

                    if ((this.props.investorData['PASSPORT_2_INDIVIDUAL']
                        && this.props.investorData['PASSPORT_2_INDIVIDUAL'][0].chosenValue)
                        && !(this.props.investorData['PASSPORT_2_NUMBER_INDIVIDUAL']
                            && this.props.investorData['PASSPORT_2_NUMBER_INDIVIDUAL'].chosenValue)) {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'PASSPORT_2_NUMBER_INDIVIDUAL');
                    }
                }

                if (this.props.investorData['FINANCIAL_STATEMENT_'] && this.props.investorData['FINANCIAL_STATEMENT_'].length > 0) {
                    newMandatoryFields = newMandatoryFields.filter(f => f !== 'FINANCIAL_STATEMENT_');
                }

                let valid = this.props.validate(this.props.investorData, response.data, newMandatoryFields);

                if (valid.length === 0) {
                    this.navigate(e, true);
                } else {
                    this.handleValidation(valid)
                    validationAlert(valid)
                }
            }).catch(err => {
            console.log(err);
        });
    }

    handleValidation = () => {
        let errors = {}
        let valid = true

        if (!this.props.investorData['PASSPORT_NUMBER'] || !this.props.investorData['PASSPORT_NUMBER'].chosenValue) {
            valid = false
            errors.passportNumber = 'Cannot be empty'
        }

        if (!this.props.investorData['ID_NUMBER'] || !this.props.investorData['ID_NUMBER'].chosenValue) {
            valid = false
            errors.governmentId = 'Cannot be empty'
        }

        this.setState({
            errors: errors
        })

        return valid
    }

    previousStep(e) {
        e.preventDefault();

        this.navigate(e, false);
    }

    navigate(e, next) {
        e.preventDefault();

        this.props.setRegistrationStatus('STEP_DOCUMENTATION', next)
    }

    // openFileUpload = () => {
    //     const currentState = this.state.openUpload;
    //     this.setState({openUpload: !currentState});
    // }

    saveProgress = () => {
        this.props.history.push("/logout")
    }

    render() {
        return (
            <Grid container spacing={3}>
                {(this.props.investorData['COUNTRY_CITIZEN']
                    && this.props.investorData['COUNTRY_CITIZEN'].chosenValue === 'United States')
                || (this.props.investorData['HAVE_PASSPORT']
                    && this.props.investorData['HAVE_PASSPORT'].chosenValue === 'yes')
                    ? <>
                        <Grid item md={12} lg={12} xs={12}>
                            <Box mb="20px">
                                <Card raised>
                                    <CardContent>
                                        <DocumentationArea
                                            isUSA={this.props.investorData['COUNTRY_CITIZEN']
                                            && this.props.investorData['COUNTRY_CITIZEN'].chosenValue === 'United States'}
                                            userType={this.state.user ? this.state.user.userGroup.id : ''}
                                            investorData={this.props.investorData}
                                            saveDocumentHandler={this.props.saveDocument}
                                            deleteDocumentHandler={this.props.onDocumentDelete}
                                            changeInvestorHandler={this.props.pushInvestorChange}
                                            errors={this.state.errors}
                                        />
                                    </CardContent>
                                </Card>
                            </Box>
                        </Grid>

                        <Grid item md={12} lg={12} xs={12}>
                            <Box mb="20px">
                                <Card raised>
                                    <CardContent>
                                        <Grid container spacing={1}>
                                            <Grid item lg={12} xs={12}>
                                                <Typography variant="h6">
                                                    Proof of Address
                                                    <span className="required__circle"> *</span>
                                                </Typography>

                                                <Divider/>
                                            </Grid>

                                            <Grid item lg={8} xs={12}>
                                                <Box component="span">
                                                    <Typography gutterBottom>
                                                        Please provide a picture or scan of your Utility Bill,
                                                        Cable Bill, Credit Card Bill or Financial Statement that
                                                        matches the the address you provided:
                                                    </Typography>
                                                    <Typography variant={"subtitle2"}>
                                                        Only JPG, PNG or PDF files. File must be less than 20 Mb
                                                    </Typography>
                                                </Box>

                                                {this.props.investorData['FINANCIAL_STATEMENT_'] && (
                                                    <Box py="20px">
                                                        <FileUploadComponent
                                                            attributeCode={'FINANCIAL_STATEMENT_'}
                                                            currentValue={this.props.investorData['FINANCIAL_STATEMENT_']}
                                                            accept={'.jpg, .png, .pdf'}
                                                            documentGroupCode={'financial-statement'}
                                                            onFileSelected={this.props.saveDocument}
                                                            onDocumentDelete={this.props.onDocumentDelete}
                                                            className="state-id front"
                                                            fileNumber="file-4"
                                                            wrapperClass=""/>
                                                    </Box>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Box>
                        </Grid>
                    </>
                    : ''
                }

                <Grid item lg={12} xs={12}
                      style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '20px'}}>
                    <Button
                        variant='outlined'
                        style={{
                            background: '#FAFAFA',
                            marginRight: '5px'
                        }}
                        onClick={(e) => this.previousStep(e)}>
                        Previous
                    </Button>

                    <Button variant='outlined'
                            style={{marginRight: '5px'}}
                            color='primary'
                            onClick={() => this.saveProgress()}>
                        Save Progress & Come Back Later
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        disabled={
                            this.state.errorEmailValid
                            || ((this.props.investorData['COUNTRY_CITIZEN']
                                && this.props.investorData['COUNTRY_CITIZEN'].chosenValue !== 'United States')
                                && (this.props.investorData.HAVE_PASSPORT
                                    && (this.props.investorData.HAVE_PASSPORT.chosenValue === 'no'
                                        || this.props.investorData.HAVE_PASSPORT.chosenValue === null)))
                        }
                        onClick={(e) => this.changeStep(e)}>
                        Next
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

export default DocumentationPage