import React, {Component} from 'react';

import InputText from '../../components/input/InputText';
import validationAlert from "../../components/ValidationAlert";
import UserService from "../../repository/UserService";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import SelectCountry from "../../components/input/SelectCountry";
import SelectState from "../../components/input/SelectState";
import Divider from "@material-ui/core/Divider";
import InputNumber from "../../components/input/InputNumber";
import RadioInput from "../../components/input/RadioInput";
import SelectDoB from "../../components/input/SelectDoB";
import InputPhone from "../../components/input/InputPhone";
import InputEmail from "../../components/input/InputEmail";
import DocumentationArea from "../../components/DocumentationArea";
import Button from "@material-ui/core/Button";
import GooglePlaceAutocomplete from "../../components/input/new/GooglePlaceAutocomplete";

class InstitutionInfoPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            states: [],
            countries: [],
            'PLEASE_CHOSE_2': [],
            'CONTRACT_HOME_STATE': [],
            'CONTRACT_HOME_COUNTRY': [],
            'MATCH_CHECK': [],
            'HAVE_PASSPORT_COMPANY': [],
            'HAVE_SSN_COMPANY': [],
            'COUNTRY_CITIZEN_POINT_OF_CONTACT': [],
            years: [],
            investorData: null,
            user: null,
            errorWorkEmailValid: false,
            errors: [],
            postalError: false,
            workEmail: '',
            firstEmailValue: ' ',
            availableAddresses: new Map(),
            apiKey: 'AIzaSyCaB_eJmQ8cOSg26Mx4u5MYEDHBzqtJ640',
            nonMandatoryFields: ['HOME_STATE_PROVINCE', 'PASSPORT_NUMBER_COMPANY', 'ID_NUMBER_COMPANY', 'SSN_OR_ITIN',
                'STATE_ID_FRONT', 'STATE_ID_3', 'PASSPORT_2', 'OTHER_ENTITY_TYPE', 'HAVE_PASSPORT_COMPANY', 'HAVE_SSN_COMPANY',
                'PASSPORT_2_NUMBER_COMPANY', 'PASSPORT_2_COMPANY']
        };
    }

    componentDidMount() {
        this.props.setCurrentRegistrationStep('Institution Info');
        this.init();
        window.scrollTo(0, 0)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.investorData !== undefined && ((nextProps.investorData['WORK_EMAIL']
            && nextProps.investorData['WORK_EMAIL'].chosenValue
            && nextProps.investorData['WORK_EMAIL'].chosenValue !== prevState.firstWorkEmailValue)
            || (nextProps.investorData['WORK_PHONE_NUMBER']
                && nextProps.investorData['WORK_PHONE_NUMBER'].chosenValue
                && nextProps.investorData['WORK_PHONE_NUMBER'].chosenValue !== prevState.workPhone))) {
            return {
                workEmail: nextProps.investorData['WORK_EMAIL'] ? nextProps.investorData['WORK_EMAIL'].chosenValue : '',
                firstWorkEmailValue: nextProps.investorData['WORK_EMAIL'] ? nextProps.investorData['WORK_EMAIL'].chosenValue : '',
                workPhone: nextProps.investorData['WORK_PHONE_NUMBER'] ? nextProps.investorData['WORK_PHONE_NUMBER'].chosenValue : ''
            }
        }
    }

    init() {
        let allYears = [];
        let maxYear = new Date().getFullYear() - 18
        let minYear = new Date().getFullYear() - 105

        for (let i = minYear; i <= maxYear; i++) {
            allYears.unshift(i);
        }

        this.setState({
            years: allYears
        })

        UserService.fetchUser()
            .then(response => {
                this.setState({user: response.data})
            })
            .catch(error => console.log(error));

        this.props.fetchAttributeOptions('MATCH_CHECK').then((data) => {
            this.setState({
                ...this.state,
                'MATCH_CHECK': data.data
            });
        });

        this.props.fetchAttributeOptions('HAVE_PASSPORT_COMPANY').then((data) => {
            this.setState({
                ...this.state,
                'HAVE_PASSPORT_COMPANY': data.data
            })
        });

        this.props.fetchAttributeOptions('HAVE_SSN_COMPANY').then((data) => {
            this.setState({
                ...this.state,
                'HAVE_SSN_COMPANY': data.data
            })
        });

        this.props.fetchAttributeOptions('CONTRACT_HOME_COUNTRY').then((data) => {
            this.setState({
                ...this.state,
                'CONTRACT_HOME_COUNTRY': data.data
            });
        });

        this.props.fetchAttributeOptions('CONTRACT_HOME_STATE').then((data) => {
            this.setState({
                ...this.state,
                'CONTRACT_HOME_STATE': data.data
            });
        });

        this.props.fetchAttributeOptions('PLEASE_CHOSE_2').then((data) => {
            this.setState({
                ...this.state,
                'PLEASE_CHOSE_2': data.data
            });
        });

        this.props.fetchAttributeOptions('HOME_STATE_PROVINCE').then((data) => {
            this.setState({
                ...this.state,
                states: data.data
            });
        });

        this.props.fetchAttributeOptions('COUNTRY').then((data) => {
            this.setState({
                ...this.state,
                countries: data.data
            });
        });

        this.props.fetchAttributeOptions('COUNTRY_CITIZEN_POINT_OF_CONTACT').then((data) => {
            this.setState({
                ...this.state,
                'COUNTRY_CITIZEN_POINT_OF_CONTACT': data.data
            });
        });
    }

    handleAddressValue = (value) => {
        if (value) {
            fetch('https://app.geocodeapi.io/api/v1/autocomplete?apikey=0ca00de0-804a-11eb-9b23-7f709f164b07' +
                '&text=' + value + '&size=15&layers=address')
                .then(response => response.json())
                .then(response => {
                    let _availableAddresses = new Map()
                    response.features.forEach(feature => {
                        _availableAddresses.set(feature.properties.label, feature.properties)
                    })

                    this.setState({availableAddresses: _availableAddresses})
                })
        }
    }

    handleSelectedAddress = (place) => {
        let state1 = ''
        let country1 = ''
        let city1 = ''
        let address1 = ''
        let postcode1 = ''

        for (const component of place.address_components) {
            const componentType = component.types[0];

            switch (componentType) {
                case "street_number": {
                    address1 = `${component.long_name} ${address1}`;
                    break;
                }
                case "route": {
                    address1 += component.long_name;
                    break;
                }
                case "postal_code": {
                    postcode1 = `${component.long_name}${postcode1}`;
                    break;
                }
                case "postal_code_suffix": {
                    postcode1 = `${postcode1}-${component.long_name}`;
                    break;
                }
                case "locality":
                    city1 = component.long_name;
                    break;

                case "administrative_area_level_1": {
                    state1 = component.long_name;
                    break;
                }
                case "country":
                    country1 = component.long_name;
                    break;
                default:
            }
        }

        let country = this.state.countries.filter(_country => _country.value === country1)[0]

        if (country) {
            this.props.pushInvestorChange({
                attributeCode: 'COUNTRY',
                investorAttributeOptionId: country.id,
                value: country.value,
            })
        } else {
            this.props.deleteInvestorData({
                attributeCode: 'COUNTRY'
            });
        }

        if (country.value === 'United States') {
            let state = this.state.states.filter(state => state.value === state1)[0]
            this.props.pushInvestorChange({
                attributeCode: 'HOME_STATE_PROVINCE',
                investorAttributeOptionId: state.id,
                value: state.value,
            })
        } else {
            this.props.deleteInvestorData({
                attributeCode: 'HOME_STATE_PROVINCE'
            });
        }

        this.props.pushInvestorChange({
            attributeCode: "HOME_CITY",
            value: city1,
        })

        this.props.pushInvestorChange({
            attributeCode: "HOME_STREET_ADDRESS",
            value: address1,
        })

        if (postcode1) {
            this.props.pushInvestorChange({
                attributeCode: "HOME_POSTAL_CODE",
                value: postcode1
            })
        } else {
            this.props.deleteInvestorData({
                attributeCode: 'HOME_POSTAL_CODE'
            });
        }
    }

    handleOnChangeAddress = (address) => {
        this.props.pushInvestorChange({
            attributeCode: "HOME_STREET_ADDRESS",
            value: address,
        })
    }

    checkData(data) {
        if (this.props.investorData[data]
            && !this.props.investorData[data].chosenOptionId) {
            fetch("https://geolocation-db.com/json/7733a990-ebd4-11ea-b9a6-2955706ddbf3")
                .then(response => response.json())
                .then(response => {
                    for (let i = 0; i < this.state.countries.length; i++) {
                        if (this.state.countries[i].value === response.country_name) {
                            this.props.pushInvestorChange({
                                attributeCode: data,
                                investorAttributeOptionId: this.state.countries[i].id,
                                value: this.state.countries[i].value,
                            })
                            break
                        }
                    }
                });
        }
    }

    navigate(e, next) {
        e.preventDefault();
        this.props.setRegistrationStatus('STEP_INSTITUTION_INFO', next)
        //     .then(res => {
        //         localStorage.setItem('userInfo', JSON.stringify(res.data));
        //         this.props.history.push('/background-info');
        //     }).catch(err => {
        // });
    }

    changeStep(e) {
        e.preventDefault();
        this.props.fetchInvestorStatusByStep('STEP_INSTITUTION_INFO')
            .then(response => {
                let newMandatoryFields = [...this.state.nonMandatoryFields];

                if (this.props.investorData['COUNTRY_CITIZEN_POINT_OF_CONTACT'].chosenValue === 'United States') {
                    newMandatoryFields = newMandatoryFields.filter(f => f !== 'SSN_OR_ITIN');

                    // if ((this.props.investorData['STATE_ID_3']
                    //     && this.props.investorData['STATE_ID_3'][this.props.investorData['STATE_ID_3'].length - 1].chosenValue)
                    //     || (this.props.investorData['STATE_ID_FRONT']
                    //         && this.props.investorData['STATE_ID_FRONT'][this.props.investorData['STATE_ID_FRONT'].length - 1].chosenValue)) {
                    //     newMandatoryFields = newMandatoryFields.filter(f => f !== 'ID_NUMBER_COMPANY');
                    // } else if (this.props.investorData['PASSPORT_2']
                    //     && this.props.investorData['PASSPORT_2'][this.props.investorData['PASSPORT_2'].length - 1].chosenValue) {
                    //     newMandatoryFields = newMandatoryFields.filter(f => f !== 'PASSPORT_NUMBER_COMPANY');
                    // }

                    // if (this.props.investorData['PASSPORT_2']
                    //     && this.props.investorData['PASSPORT_2'][this.props.investorData['PASSPORT_2'].length - 1].chosenValue) {
                    //     newMandatoryFields = newMandatoryFields.filter(f => f !== 'PASSPORT_NUMBER_COMPANY');
                    // }

                    // if (this.props.investorData['ID_NUMBER_COMPANY']
                    //     && this.props.investorData['ID_NUMBER_COMPANY'].chosenValue) {
                    //     newMandatoryFields = newMandatoryFields.filter(f => f !== 'STATE_ID_3' && f !== 'STATE_ID_FRONT');
                    // }

                    // if (this.props.investorData['PASSPORT_NUMBER_COMPANY']
                    //     && this.props.investorData['PASSPORT_NUMBER_COMPANY'].chosenValue) {
                    //     newMandatoryFields = newMandatoryFields.filter(f => f !== 'PASSPORT_2');
                    // }

                    if ((this.props.investorData['STATE_ID_3']
                        && this.props.investorData['STATE_ID_3'][this.props.investorData['STATE_ID_3'].length - 1].chosenValue)
                        && (this.props.investorData['STATE_ID_FRONT']
                            && this.props.investorData['STATE_ID_FRONT'][this.props.investorData['STATE_ID_FRONT'].length - 1].chosenValue)
                        && (this.props.investorData['ID_NUMBER_COMPANY']
                            && this.props.investorData['ID_NUMBER_COMPANY'].chosenValue
                            && this.props.investorData['ID_NUMBER_COMPANY'].chosenValue !== '')) {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'STATE_ID_3' && f !== 'STATE_ID_FRONT' && f !== 'ID_NUMBER_COMPANY');
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'PASSPORT_2' && f !== 'PASSPORT_NUMBER_COMPANY');
                    } else {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'PASSPORT_2' && f !== 'PASSPORT_NUMBER_COMPANY');
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'STATE_ID_3' && f !== 'STATE_ID_FRONT' && f !== 'ID_NUMBER_COMPANY');
                    }

                    if ((this.props.investorData['PASSPORT_2_COMPANY']
                        && this.props.investorData['PASSPORT_2_COMPANY'][0].chosenValue)
                        && !(this.props.investorData['PASSPORT_2_NUMBER_COMPANY']
                            && this.props.investorData['PASSPORT_2_NUMBER_COMPANY'].chosenValue)) {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'PASSPORT_2_NUMBER_COMPANY');
                    }
                } else {
                    newMandatoryFields = newMandatoryFields.filter(f => f !== 'HAVE_SSN_COMPANY');

                    if (this.props.investorData['HAVE_PASSPORT_COMPANY'] && this.props.investorData['HAVE_PASSPORT_COMPANY'].chosenValue === 'yes') {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'PASSPORT_2'
                            && f !== 'HAVE_PASSPORT_COMPANY'
                            && f !== 'PASSPORT_NUMBER_COMPANY');
                    } else {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'STATE_ID_3'
                            && f !== 'STATE_ID_FRONT' && f !== 'HAVE_PASSPORT_COMPANY' && f !== 'ID_NUMBER_COMPANY');
                    }

                    if (this.props.investorData['HAVE_SSN_COMPANY'] && this.props.investorData['HAVE_SSN_COMPANY'].chosenValue === 'yes') {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'SSN_OR_ITIN');
                    }

                    if ((this.props.investorData['PASSPORT_2_COMPANY']
                        && this.props.investorData['PASSPORT_2_COMPANY'][0].chosenValue)
                        && !(this.props.investorData['PASSPORT_2_NUMBER_COMPANY']
                            && this.props.investorData['PASSPORT_2_NUMBER_COMPANY'].chosenValue)) {
                        newMandatoryFields = newMandatoryFields.filter(f => f !== 'PASSPORT_2_NUMBER_COMPANY');
                    }
                }

                if (this.props.investorData['PLEASE_CHOSE_2'] && this.props.investorData['PLEASE_CHOSE_2'].chosenValue === 'Other') {
                    newMandatoryFields = newMandatoryFields.filter(f => f !== 'OTHER_ENTITY_TYPE')
                }

                let valid = this.props.validate(this.props.investorData, response.data, newMandatoryFields);

                if (valid.length === 0) {
                    this.navigate(e, true);
                } else if (valid.length !== 0
                    && ((valid.includes('Government Issued ID (Back)')
                        && valid.includes('Government Issued ID (Front)'))
                        || valid.includes('Government ID Number'))
                    && (!valid.includes('Passport')
                        && !valid.includes('Passport Number'))) {
                    let index1 = valid.indexOf('Government Issued ID (Back)');
                    if (index1 !== -1) {
                        valid.splice(index1, 1);
                    }

                    let index2 = valid.indexOf('Government Issued ID (Front)');
                    if (index2 !== -1) {
                        valid.splice(index2, 1);
                    }

                    let index3 = valid.indexOf('Government ID Number');
                    if (index3 !== -1) {
                        valid.splice(index3, 1);
                    }

                    if (valid.length !== 0) {
                        validationAlert(valid);
                    } else {
                        this.navigate(e, true);
                    }
                } else if (valid.length !== 0
                    && (!valid.includes('Government Issued ID (Back)')
                        && !valid.includes('Government Issued ID (Front)')
                        && !valid.includes('Government ID Number'))
                    && (valid.includes('Passport')
                        || valid.includes('Passport Number'))
                    && this.props.investorData['COUNTRY_CITIZEN_POINT_OF_CONTACT'].chosenValue === 'United States') {
                    let index1 = valid.indexOf('Passport');
                    if (index1 !== -1) {
                        valid.splice(index1, 1);
                    }

                    let index2 = valid.indexOf('Passport Number');
                    if (index2 !== -1) {
                        valid.splice(index2, 1);
                    }

                    if (valid.length !== 0) {
                        // this.handleValidation(valid)
                        validationAlert(valid);
                    } else {
                        this.navigate(e, true);
                    }
                } else {
                    validationAlert(valid);
                }
            }).catch(err => {
            console.log(err);
        })
    }

    handleValidation = () => {
        let errors = {}
        let valid = true

        if (!this.props.investorData['SSN'] || !this.props.investorData['SSN'].chosenValue) {
            valid = false
            errors.ssn = 'Cannot be empty'
        }

        if (!this.props.investorData['PASSPORT_2_NUMBER'] || !this.props.investorData['PASSPORT_2_NUMBER'].chosenValue) {
            valid = false
            errors.passportNumber = 'Cannot be empty'
        }

        this.setState({
            errors: errors
        })

        return valid
    }

    // navigate(e, step, path) {
    //     e.preventDefault();
    //
    //     // this.props.setRegistrationStatus(step)
    //     //     .then(res => {
    //     //         localStorage.setItem('userInfo', JSON.stringify(res.data));
    //     //         this.props.history.push(path);
    //     //     }).catch(err => {
    //     // });
    // }

    saveProgress = () => {
        this.props.history.push("/logout")
    }


    render() {
        return (
            <Grid container item spacing={3}>
                <Grid item lg={6} md={6} xs={12}>
                    <Card raised>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item lg={12} xs={12}>
                                    <Typography variant="h6">
                                        Entity
                                    </Typography>

                                    <Divider/>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <Grid container spacing={3}>

                                        <Grid item lg={5} xs={12} md={4}
                                              className='app_form--group-label registration__padding--bottom'>
                                            <Typography>Entity name
                                                <span className="required__circle"> *</span>
                                            </Typography>
                                        </Grid>

                                        <Grid item lg={7} md={8} xs={12} className="registration__padding--top">
                                            <InputText
                                                defaultValue={this.props.investorData['ENTITY_NAME']}
                                                attributeCode="ENTITY_NAME"
                                                onBlurHandler={this.props.pushInvestorChange}/>
                                        </Grid>

                                        <Grid item lg={5} md={4} xs={12}
                                              className='app_form--group-label registration__padding--bottom'>
                                            <Typography>
                                                Country
                                                <span className="required__circle"> *</span>
                                            </Typography>
                                        </Grid>

                                        <Grid item lg={7} md={8} xs={12} className="registration__padding--top">
                                            <SelectCountry
                                                selectedValue={
                                                    this.props.investorData['COUNTRY']
                                                    && this.props.investorData['COUNTRY'].chosenOptionId
                                                        ? this.props.investorData['COUNTRY'].chosenOptionId
                                                        : this.checkData('COUNTRY')
                                                }
                                                attributeCode="COUNTRY"
                                                onChangeHandler={this.props.pushInvestorChange}
                                                values={this.state.countries}/>
                                        </Grid>

                                        <Grid item lg={5} md={4} xs={12}
                                              className='app_form--group-label registration__padding--bottom'>
                                            <Typography>
                                                Street address
                                                <span className="required__circle"> *</span>
                                            </Typography>
                                        </Grid>

                                        <Grid item lg={7} md={8} xs={12} className="registration__padding--top">
                                            <GooglePlaceAutocomplete
                                                apiKey={this.state.apiKey}
                                                defaultValue={this.props.investorData['HOME_STREET_ADDRESS']}
                                                attributeCode="HOME_STREET_ADDRESS"
                                                onChange={this.handleOnChangeAddress}
                                                fillInAddress={this.handleSelectedAddress}
                                            />
                                        </Grid>

                                        <Grid item lg={5} md={4} xs={12}
                                              className='app_form--group-label registration__padding--bottom'>
                                            <Typography>
                                                City
                                                <span className="required__circle"> *</span>
                                            </Typography>
                                        </Grid>

                                        <Grid item lg={7} md={8} xs={12} className="registration__padding--top">
                                            <InputText
                                                defaultValue={this.props.investorData['HOME_CITY']}
                                                attributeCode="HOME_CITY"
                                                onBlurHandler={this.props.pushInvestorChange}/>
                                        </Grid>

                                        {
                                            !this.props.investorData['COUNTRY']
                                            || (this.props.investorData['COUNTRY']
                                                && this.props.investorData['COUNTRY'].chosenValue === 'United States')
                                                ? <>
                                                    <Grid item md={4} lg={5} xs={12}
                                                          className='app_form--group-label registration__padding--bottom'>
                                                        <Typography>
                                                            State/Province (US only)
                                                            <span className="required__circle"> *</span>
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item lg={7} md={8} xs={12} className="registration__padding--top">
                                                        <SelectState
                                                            attributeCode="HOME_STATE_PROVINCE"
                                                            selectedValue={this.props.investorData['HOME_STATE_PROVINCE']}
                                                            onChangeHandler={this.props.pushInvestorChange}
                                                            values={this.state.states}
                                                        />
                                                    </Grid>
                                                </>
                                                : null
                                        }

                                        <Grid item lg={5} md={4} xs={12} className='registration__padding--bottom'>
                                            <Typography>
                                                Postal Code
                                                <span className="required__circle"> *</span>
                                            </Typography>
                                            {/*<Typography variant='caption'>*/}
                                            {/*    (only numbers, no letters)*/}
                                            {/*</Typography>*/}
                                        </Grid>

                                        <Grid item lg={7} md={8} xs={12} className="registration__padding--top">
                                            <InputText
                                                defaultValue={this.props.investorData['HOME_POSTAL_CODE']}
                                                attributeCode="HOME_POSTAL_CODE"
                                                onBlurHandler={this.props.pushInvestorChange}
                                                minLength={
                                                    this.props.investorData['COUNTRY']
                                                    && this.props.investorData['COUNTRY'].chosenValue === 'United States'
                                                        ? 5
                                                        : 1
                                                }
                                                maxLength={
                                                    this.props.investorData['COUNTRY']
                                                    && this.props.investorData['COUNTRY'].chosenValue === 'United States'
                                                        ? 5
                                                        : 9
                                                }
                                            />
                                        </Grid>

                                        <Grid item lg={5} md={4} xs={12} className="registration__padding--bottom">
                                            <Typography>
                                                EIN or Foreign Corporation #
                                                <span className="required__circle"> *</span>
                                            </Typography>
                                            <Typography variant='caption'>
                                                (only 9 numbers, no letters)
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={7} md={8} xs={12}>
                                            <InputNumber
                                                defaultValue={this.props.investorData['EIN_FOREIGN_CORPORATION_#']}
                                                attributeCode="EIN_FOREIGN_CORPORATION_#"
                                                onBlurHandler={this.props.pushInvestorChange}
                                                minLength={9}
                                                maxLength={
                                                    this.props.investorData['COUNTRY'] &&
                                                    this.props.investorData['COUNTRY'].chosenValue === 'United States'
                                                        ? 9
                                                        : 9
                                                }
                                            />
                                        </Grid>

                                        <Grid item lg={12} xs={12} style={{paddingTop: 48}}>
                                            <Typography
                                                variant="h6">
                                                Entity type
                                            </Typography>

                                            <Divider/>
                                        </Grid>

                                        <Grid item lg={5} md={4} xs={12}
                                              className='app_form--group-label registration__padding--bottom'>
                                            <Typography>
                                                Please choose entity type
                                                <span className="required__circle"> *</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={7} md={8} xs={12}>
                                            <SelectState
                                                attributeCode="PLEASE_CHOSE_2"
                                                selectedValue={this.props.investorData['PLEASE_CHOSE_2']}
                                                onChangeHandler={this.props.pushInvestorChange}
                                                values={this.state['PLEASE_CHOSE_2']}/>
                                        </Grid>

                                        {
                                            this.props.investorData['PLEASE_CHOSE_2']
                                            && this.props.investorData['PLEASE_CHOSE_2'].chosenValue === 'Other'
                                                ? <>
                                                    <Grid item lg={5} md={4} xs={12}>
                                                        <Typography>
                                                            Other entity type
                                                            <span className="required__circle"> *</span>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item lg={6} xs={12}>
                                                        <InputText
                                                            defaultValue={this.props.investorData['OTHER_ENTITY_TYPE']}
                                                            attributeCode="OTHER_ENTITY_TYPE"
                                                            onBlurHandler={this.props.pushInvestorChange}/>
                                                    </Grid>
                                                </>
                                                : null
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={6} xs={12} md={6}>
                    <Card raised
                          style={{overflow: 'unset'}}>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item lg={12} xs={12}>
                                    <Typography variant="h6">
                                        Point of contact
                                    </Typography>

                                    <Divider/>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <Grid container spacing={3}>

                                        <Grid item lg={5} md={4} xs={12}
                                              className='app_form--group-label registration__padding--bottom'>
                                            <Typography>
                                                First Name
                                                <span className="required__circle"> *</span>
                                            </Typography>
                                        </Grid>

                                        <Grid item lg={7} md={8} xs={12} className='registration__padding--top'>
                                            <InputText
                                                defaultValue={this.props.investorData['NAME']}
                                                attributeCode="NAME"
                                                onBlurHandler={this.props.pushInvestorChange}/>
                                        </Grid>

                                        <Grid item lg={5} md={4} xs={12}
                                              className='app_form--group-label registration__padding--bottom'>
                                            <Typography>
                                                Last Name
                                                <span className="required__circle"> *</span></Typography>
                                        </Grid>

                                        <Grid item lg={7} md={8} xs={12} className='registration__padding--top'>
                                            <InputText
                                                defaultValue={this.props.investorData['LAST_NAME_COMPANY_POINT_OF_CONTACT']}
                                                attributeCode="LAST_NAME_COMPANY_POINT_OF_CONTACT"
                                                onBlurHandler={this.props.pushInvestorChange}/>
                                        </Grid>

                                        <Grid item lg={5} md={4} xs={12}
                                              className='app_form--group-label registration__padding--bottom'>
                                            <Typography>
                                                Title
                                                <span className="required__circle"> *</span>
                                            </Typography>
                                        </Grid>

                                        <Grid item lg={7} md={8} xs={12} className='registration__padding--top'>
                                            <InputText
                                                defaultValue={this.props.investorData['TITLE']}
                                                attributeCode="TITLE"
                                                onBlurHandler={this.props.pushInvestorChange}/>
                                        </Grid>

                                        <Grid item lg={5} md={4} xs={12}
                                              className='app_form--group-label registration__padding--bottom'>
                                            <Typography>
                                                Country of citizenship
                                                <span className="required__circle"> *</span>
                                            </Typography>
                                        </Grid>

                                        <Grid item lg={7} md={8} xs={12} className='registration__padding--top'>
                                            <SelectCountry
                                                selectedValue={
                                                    this.props.investorData['COUNTRY_CITIZEN_POINT_OF_CONTACT']
                                                    && this.props.investorData['COUNTRY_CITIZEN_POINT_OF_CONTACT'].chosenOptionId
                                                        ? this.props.investorData['COUNTRY_CITIZEN_POINT_OF_CONTACT'].chosenOptionId
                                                        : this.checkData('COUNTRY_CITIZEN_POINT_OF_CONTACT')
                                                }
                                                deleteInvestorData={this.props.deleteInvestorData}
                                                attributeCode="COUNTRY_CITIZEN_POINT_OF_CONTACT"
                                                onChangeHandler={this.props.pushInvestorChange}
                                                values={this.state['COUNTRY_CITIZEN_POINT_OF_CONTACT']}/>
                                        </Grid>

                                        <Grid item lg={5} md={4} xs={12}
                                              className='app_form--group-label registration__padding--bottom'>
                                            <Typography>
                                                DOB (US Style)
                                                <span className="required__circle"> *</span></Typography>
                                        </Grid>
                                        <Grid item lg={7} md={8} xs={12} className='registration__padding--top'>
                                            <SelectDoB
                                                attributeCode="DOB"
                                                selectedValue={this.props.investorData['DOB']}
                                                onChangeHandler={this.props.pushInvestorChange}
                                                years={this.state.years}/>
                                        </Grid>

                                        {
                                            this.props.investorData['COUNTRY_CITIZEN_POINT_OF_CONTACT']
                                            && this.props.investorData['COUNTRY_CITIZEN_POINT_OF_CONTACT'].chosenValue !== 'United States'
                                                ? <>
                                                    <Grid item lg={5} md={4} xs={12}
                                                          className='app_form--group-label registration__padding--bottom'>
                                                        <Typography>
                                                            Do you have a social security number?
                                                            <span className="required__circle"> *</span>
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item lg={7} md={8} xs={12} className='registration__padding--top'>
                                                        <RadioInput
                                                            name="HAVE_SSN_COMPANY"
                                                            fieldToClear="SSN_OR_ITIN"
                                                            attributeCode="HAVE_SSN_COMPANY"
                                                            defaultValue={this.props.investorData['HAVE_SSN_COMPANY']}
                                                            options={this.state['HAVE_SSN_COMPANY']}
                                                            onChange={this.props.pushInvestorChange}/>
                                                    </Grid>
                                                </>
                                                : null
                                        }

                                        {(this.props.investorData['COUNTRY_CITIZEN_POINT_OF_CONTACT']
                                            && this.props.investorData['COUNTRY_CITIZEN_POINT_OF_CONTACT'].chosenValue === 'United States')
                                        || (this.props.investorData['HAVE_SSN_COMPANY']
                                            && this.props.investorData['HAVE_SSN_COMPANY'].chosenValue === 'yes')
                                            ? <>
                                                <Grid item lg={5} md={4} xs={12}
                                                      className='registration__padding--bottom'>
                                                    <Typography>
                                                        US Social Security Number
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                    <Typography variant='caption'>
                                                        (only 9 numbers, no letters)
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={7} md={8} xs={12} className='registration__padding--top'>
                                                    <InputNumber
                                                        defaultValue={this.props.investorData['SSN_OR_ITIN']}
                                                        attributeCode="SSN_OR_ITIN"
                                                        onBlurHandler={this.props.pushInvestorChange}
                                                        minLength={9}
                                                        maxLength={9}
                                                        hidden={true}
                                                    />
                                                </Grid>
                                            </>
                                            : null}

                                        {this.props.investorData['COUNTRY_CITIZEN_POINT_OF_CONTACT']
                                        && this.props.investorData['COUNTRY_CITIZEN_POINT_OF_CONTACT'].chosenValue !== 'United States'
                                            ? <>
                                                <Grid item lg={5} md={4} xs={12}
                                                      className='app_form--group-label registration__padding--bottom'>
                                                    <Typography>
                                                        Do you have a Passport?
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={7} md={8} xs={12} className='registration__padding--top'>
                                                    <RadioInput
                                                        name="HAVE_PASSPORT_COMPANY"
                                                        attributeCode="HAVE_PASSPORT_COMPANY"
                                                        attributeCodeStateIdFront={'STATE_ID_FRONT'}
                                                        documentGroupCodeStateIdFront={'state-id-front'}
                                                        attributeCodeStateIdBack={'STATE_ID_3'}
                                                        documentGroupCodeStateIdBack={'state-id-back'}
                                                        attributeCodePassport={'PASSPORT_2'}
                                                        documentGroupCodePassport={'passport'}
                                                        defaultValue={this.props.investorData['HAVE_PASSPORT_COMPANY']}
                                                        options={this.state['HAVE_PASSPORT_COMPANY']}
                                                        onDocumentDelete={this.props.onDocumentDelete}
                                                        onChange={this.props.pushInvestorChange}/>
                                                </Grid>
                                            </>
                                            : null
                                        }

                                        <Grid item lg={5} md={4} xs={12}
                                              className='app_form--group-label registration__padding--bottom'>
                                            <Typography>
                                                Work phone number
                                                <span className="required__circle"> *</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={7} md={8} xs={12} className='registration__padding--top'>
                                            <InputPhone
                                                defaultValue={this.props.investorData['WORK_PHONE_NUMBER']}
                                                attributeCode="WORK_PHONE_NUMBER"
                                                onCnangeHandler={this.props.pushInvestorChange}
                                            />
                                        </Grid>

                                        <Grid item lg={5} md={4} xs={12}
                                              className='app_form--group-label registration__padding--bottom'>
                                            <Typography>
                                                Work email
                                                <span className="required__circle"> *</span>
                                            </Typography>
                                            <Typography variant='caption'>
                                                (example@mail.com)
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={7} md={8} xs={12} className='registration__padding--top'>
                                            <InputEmail
                                                defaultValue={this.props.investorData['WORK_EMAIL']}
                                                attributeCode="WORK_EMAIL"
                                                onBlurHandler={this.props.pushInvestorChange}/>
                                        </Grid>

                                        {
                                            (this.props.investorData['COUNTRY_CITIZEN_POINT_OF_CONTACT']
                                                && this.props.investorData['COUNTRY_CITIZEN_POINT_OF_CONTACT'].chosenValue !== 'United States')
                                            && (this.props.investorData.HAVE_PASSPORT_COMPANY
                                                && this.props.investorData.HAVE_PASSPORT_COMPANY.chosenValue === 'no')
                                                ? <Grid item xs={12}>
                                                    <Grid container spacing={1} justify={"center"}>
                                                        <Grid item lg={11}>
                                                            <Typography
                                                                variant={'h6'}
                                                                color={"error"}
                                                                gutterBottom>
                                                                You can't continue, because you need to have a passport!
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                : ''
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                {(this.props.investorData['HAVE_PASSPORT_COMPANY']
                    && this.props.investorData['HAVE_PASSPORT_COMPANY'].chosenValue === 'yes')
                || (this.props.investorData['COUNTRY_CITIZEN_POINT_OF_CONTACT']
                    && this.props.investorData['COUNTRY_CITIZEN_POINT_OF_CONTACT'].chosenValue === 'United States')
                    ? <Grid item lg={12} xs={12}>
                        <Card raised>
                            <CardContent>
                                <DocumentationArea
                                    isUSA={this.props.investorData.COUNTRY_CITIZEN_POINT_OF_CONTACT
                                    && this.props.investorData.COUNTRY_CITIZEN_POINT_OF_CONTACT.chosenValue === 'United States'}
                                    userType={this.state.user ? this.state.user.userGroup.id : ''}
                                    investorData={this.props.investorData}
                                    saveDocumentHandler={this.props.saveDocument}
                                    deleteDocumentHandler={this.props.onDocumentDelete}
                                    changeInvestorHandler={this.props.pushInvestorChange}
                                    errors={this.state.errors}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    : ''
                }

                <Grid item lg={12} xs={12}
                      style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '20px'}}>
                    <Button variant='outlined'
                            style={{marginRight: '5px'}}
                            color='primary'
                            onClick={() => this.saveProgress()}>
                        Save Progress & Come Back Later
                    </Button>

                    <Button variant='contained'
                            disabled={
                                this.state.postalError
                                || ((this.props.investorData['COUNTRY_CITIZEN_POINT_OF_CONTACT']
                                    && this.props.investorData['COUNTRY_CITIZEN_POINT_OF_CONTACT'].chosenValue !== 'United States')
                                    && (this.props.investorData.HAVE_PASSPORT_COMPANY
                                        && this.props.investorData.HAVE_PASSPORT_COMPANY.chosenValue === 'no'))}
                            color='primary'
                            onClick={(e) => this.changeStep(e)}>
                        Next
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

export default InstitutionInfoPage;