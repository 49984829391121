import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import Aux from '../../hoc/AuxWrapper'
import axios from '../../custom-axios/app'
import {AUTH_TOKEN} from "../../shared/utility";
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import Box from '@material-ui/core/Box';
import styled from "styled-components";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Dialog from "@material-ui/core/Dialog";
import {toast} from "react-toastify";
import InputTextH from "../../components/input/new/InputTextH";


const IconBlock = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  position: absolute;
  top: -25px;
  left: 30px;
  background: linear-gradient(0deg, rgba(102,187,106,1) 0%, rgba(67,160,71,1) 100%);;
`;

const HeaderTextLogin = styled.div`
  font-size: 24px;
  font-family: "Roboto";
  font-weight: 500;
`;

const CartComponent = styled.div`
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),
     0px 1px 1px 0px rgba(0,0,0,0.14),
      0px 1px 3px 0px rgba(0,0,0,0.12);
    padding: 60px 30px 35px 30px;
    position: relative;
    background: white;
    max-width: 480px;
    min-width: 480px;
    
    @media (max-width: 600px) {
        min-width: 100%;
        padding: 60px 15px 35px 15px;
  } 
`;

const TextLogin = styled.div`
  color: #607D8B;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
`;

const MainWrap = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  
    @media (max-width: 600px) {
    width: 100%;
    margin: 0 auto;
  }
`;

const WrapBoxLogin = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0 75px 0;
`;

const WrapMainElement = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  
  @media (max-width: 600px) {
    width: 90%;
  }
`;

const SignText = styled.div`
  border-bottom: 1px solid #032F66;
  margin-left: 10px;
  color: #032F66;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  text-decoration: none;
`;

const TextErrorMessage = styled.div`
  color: #B71C1C;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  text-decoration: none;
`;

const PopupCard = styled.div`
  min-width: 480px;
  padding: 30px;
  max-width: 480px;
`;


class Login extends Component {

    constructor(props) {
        super(props);

        this.handler = (ev) => {
            ev.preventDefault();
            localStorage.clear()
            // return ev.returnValue = 'Changes are not saved, leave the page now?';
        }
    }

    state = {
        username: '',
        password: '',
        passwordRegex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,30}$/,
        loginError: null,
        redirectTo: '',
        company: '',
        phoneNumber: '',
        showModal: false,
        authCode: '',
        confError: '',
        sendCodeTo: 'phone',
        confirmed: false,
        timer: 0,
    };

    componentDidMount() {
        console.log(this.props.currentUser)
        // const parsed = queryString.parse(this.props.location.search)
        //
        // if (parsed.redirect) {
        //     this.setState({
        //         redirectTo: parsed.redirect
        //     });
        // }

        document.getElementsByTagName('body')[0].setAttribute('class', '');
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        window.removeEventListener('beforeunload', this.handler);
        if (localStorage.getItem('confirmed') !== 'true') {
            window.addEventListener("beforeunload", this.handler);
        } else window.removeEventListener('beforeunload', this.handler);
    }

    componentWillUnmount() {
        if (localStorage.getItem('confirmed') === 'true') {
            window.removeEventListener('beforeunload', this.handler);
        }
    }

    checkPasswordValidity() {
        return this.state.passwordRegex.test(this.state.password);
    }

    loginHandler(e) {
        e.preventDefault();

        axios.post('/login', {
            email: this.state.username,
            password: this.state.password
        }).then(response => {
            if (this.checkPasswordValidity()) {
                localStorage.setItem(AUTH_TOKEN, response.data.accessToken);
                this.sendCode();
            } else {
                alert('Your password is too simple. Please change it.')
                this.props.history.push('/reset-password');
            }
        }).catch(error => {
            this.setErrorMessage(error.response);
        });
    }

    startTimer() {
        this.setState({
            timer: 59
        });

        let time = setInterval(() => {
            this.setState({
                timer: this.state.timer - 1,
            });
            // Check if we're at zero.
            if (this.state.timer <= 0) {
                clearInterval(time)
            }
        }, 1000);

    }

    usernameChangeHandler(e) {
        this.setState({
            ...this.state,
            username: e.target.value
        });
    }

    passwordChangeHandler(e) {
        this.setState({
            ...this.state,
            password: e.value
        });
    }

    clearErrorMessage() {
        this.setState({
            loginError: null
        });
    }

    setErrorMessage(response) {
        if (response.status === 403) {
            this.setState({
                loginError: 'Email or password are incorrect'
            });
        } else {
            this.setState({
                loginError: 'Server temporarily unavailable'
            });
        }
    }

    close = (e) => {
        this.setState({
            showModal: false,
            authCode: '',
            confError: '',
        })
        localStorage.clear()
    }

    onCodeChange(e) {
        this.setState({
            authCode: e.target.value
        })
    }

    confirmCode = () => {
        if (this.state.sendCodeTo === 'phone') {
            axios.post("rest/auth/sms/" + this.state.phoneNumber, {code: this.state.authCode})
                .then(res => {
                    if (res.data) {
                        localStorage.setItem('confirmed', 'true');

                        if ((this.state.userGroup && this.state.userGroup.id === 'USER_INVESTOR_INDIVIDUAL' && this.state.registrationStatus === 'STEP_FINISHED')
                            || (this.state.userGroup && this.state.userGroup.id === 'USER_INVESTOR_COMPANY' && this.state.registrationStatus === 'STEP_FINISHED')) {
                            this.props.history.push('/choose-next-step');
                        } else
                            this.props.history.push('/');
                    } else {
                        this.setState({
                            confError: "Wrong confirmation code"
                        })
                    }
                })
                .catch(err => {
                    this.setState({
                        confirmError: "Server Error"
                    })
                })
        } else if (this.state.sendCodeTo === 'email') {
            axios.post("rest/auth/email/" + this.state.username, {code: this.state.authCode})
                .then(res => {
                    if (res.data) {
                        localStorage.setItem('confirmed', 'true');

                        if ((this.state.userGroup && this.state.userGroup.id === 'USER_INVESTOR_INDIVIDUAL' && this.state.registrationStatus === 'STEP_FINISHED')
                            || (this.state.userGroup && this.state.userGroup.id === 'USER_INVESTOR_COMPANY' && this.state.registrationStatus === 'STEP_FINISHED')) {
                            this.props.history.push('/choose-next-step');
                        } else this.props.history.push('/');
                    } else {
                        this.setState({
                            confError: "Wrong confirmation code"
                        })
                    }
                })
                .catch(err => {
                    this.setState({
                        confirmError: "Server Error"
                    })
                })
        }
    };

    sendCode = () => {
        this.clearErrorMessage()

        axios.get('/users/current-user')
            .then(res => {
                localStorage.setItem('userInfo', JSON.stringify(res.data));

                if (res.data.twoFactorAuthentication) {
                    this.setState({
                        phoneNumber: "+1 609-334-8980",
                        // phoneNumber: res.data.phoneNumber,
                        showModal: true,
                        registrationStatus: res.data.registrationStatus,
                        userGroup: res.data.userGroup.id
                    }, () => {
                        if (this.state.sendCodeTo === 'phone') {
                            axios.get("rest/auth/sms/" + this.state.phoneNumber)
                        } else {
                            axios.get("rest/auth/email/" + this.state.username)
                        }
                        this.startTimer()
                    });
                } else if (res.data.accountStatus === 'DELETED') {
                    toast.error('Your account has been deleted!')
                } else {
                    this.setState({
                        registrationStatus: res.data.registrationStatus,
                        userGroup: res.data.userGroup
                    }, () => {
                        localStorage.setItem('confirmed', 'true');

                        if (this.state.redirectTo) {
                            window.location.href = process.env.REACT_APP_OFFERING_GATEWAY + this.state.redirectTo
                                + '/' + this.state.userGroup.id.toLowerCase()
                        } else if (((this.state.userGroup && this.state.userGroup.id === 'USER_INVESTOR_INDIVIDUAL')
                            || (this.state.userGroup && this.state.userGroup.id === 'USER_INVESTOR_COMPANY'))
                            && this.state.registrationStatus === 'STEP_FINISHED') {
                            this.props.history.push('/choose-action');
                        } else if (this.state.userGroup && this.state.userGroup.id === 'USER_ADMIN') {
                            window.location.href = process.env.REACT_APP_BACKOFFICE_GATEWAY + '/'
                        } else {
                            this.props.history.push('/');
                        }
                    })
                }
            }).catch(err => {
            console.log(err)
        });
    };

    render() {
        return (
            <Aux>
                <div className="signup-overlay-1"></div>

                <MainWrap>


                    <WrapMainElement>
                        <WrapBoxLogin>
                            <NavLink to="index.php">
                                <img
                                    src={require('../../assets/images/logo-blue.png')}
                                    width="140"
                                    height="51"
                                    alt=""/>
                            </NavLink>
                        </WrapBoxLogin>

                        <CartComponent>
                            <IconBlock>
                                <PermIdentityOutlinedIcon
                                    style={{color: 'white', fontSize: '30px', margin: '0 auto'}}/>
                            </IconBlock>

                            <form onSubmit={(e) => this.loginHandler(e)}>

                                <HeaderTextLogin>Login</HeaderTextLogin>

                                <TextLogin>Sign up on the internal platform</TextLogin>
                                <hr/>

                                <div className="form-group">
                                    <Typography>
                                        Email
                                        <span className="required__circle"> *</span>
                                    </Typography>
                                    <TextField
                                        type="text"
                                        autoFocus={true}
                                        size={"small"}
                                        fullWidth
                                        variant="outlined"
                                        onChange={(e) => this.usernameChangeHandler(e)}
                                        value={this.state.username}/>
                                </div>

                                <div className="form-group">
                                    <Typography>
                                        Password
                                        <span className="required__circle"> *</span>
                                    </Typography>
                                    <InputTextH
                                        defaultValue={this.state.password}
                                        onBlurHandler={(e) => this.passwordChangeHandler(e)}
                                        hidden={true}
                                    />

                                    {/*<TextField*/}
                                    {/*    fullWidth*/}
                                    {/*    type="password"*/}
                                    {/*    variant="outlined"*/}
                                    {/*    placeholder="Password"*/}
                                    {/*    label="Password"*/}
                                    {/*    onChange={(e) => this.passwordChangeHandler(e)}*/}
                                    {/*    value={this.state.password}/>*/}
                                </div>

                                <div className="form-group">
                                    <TextErrorMessage>{this.state.loginError}</TextErrorMessage>
                                </div>

                                <div className="form-group">
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        formAction=""
                                        size="large"
                                        type="submit">
                                        Submit
                                    </Button>
                                </div>

                                <hr/>

                                <div className="form-group">

                                    <Box display="flex" justifyContent="space-between">
                                        <Box display="flex">
                                            <TextLogin>Not a member?</TextLogin>
                                            <NavLink to="/sign-up">
                                                <SignText>Sign Up</SignText>
                                            </NavLink>
                                        </Box>

                                        <NavLink
                                            to="/reset-password">
                                            <TextLogin>Forgot password?</TextLogin>

                                        </NavLink>
                                    </Box>

                                </div>
                            </form>
                        </CartComponent>

                    </WrapMainElement>

                    <Dialog
                        open={this.state.showModal}
                        aria-labelledby="alert-dialog-title"
                        style={{minWidth: '480px'}}
                        aria-describedby="alert-dialog-description">

                        <PopupCard>
                            <Typography variant="subtitle2">Enter Verification Code</Typography>

                            <Box pt="5px">
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    onChange={(e) => this.onCodeChange(e)}
                                    value={this.state.authCode}/>
                            </Box>

                            <Box py="15px">
                                <Typography variant="caption">Confirmation code valid for 5 minutes</Typography>
                            </Box>

                            <Box display="flex" pt="10px">
                                <Box flexGrow={1}>
                                    <Button
                                        type="button"
                                        data-dismiss="modal"
                                        onClick={this.close}>Close
                                    </Button>

                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="button"
                                        data-dismiss="modal"
                                        onClick={this.confirmCode}>Confirm
                                    </Button>
                                </Box>

                                <Button
                                    type="button"
                                    data-dismiss="modal"
                                    disabled={this.state.timer !== 0}
                                    onClick={this.sendCode}>Resend {this.state.timer !== 0 ? this.state.timer : null}
                                </Button>
                            </Box>

                            <TextErrorMessage> {this.state.confError}</TextErrorMessage>
                        </PopupCard>
                    </Dialog>
                </MainWrap>
            </Aux>
        );
    }
}

export default Login;