import React, {useState} from 'react';

import Aux from '../../hoc/AuxWrapper';
import axios from '../../custom-axios/app';
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import {NavLink} from "react-router-dom";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const IconBlock = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  position: absolute;
  top: -25px;
  left: 30px;
  background: linear-gradient(0deg, rgba(102,187,106,1) 0%, rgba(67,160,71,1) 100%);;
`;


// const HeaderTextLogin = styled.div`
//   font-size: 24px;
//   font-family: "Roboto";
//   font-weight: 500;
// `;

const CartComponent = styled.div`
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),
     0px 1px 1px 0px rgba(0,0,0,0.14),
      0px 1px 3px 0px rgba(0,0,0,0.12);
    padding: 60px 30px 0 30px;
    position: relative;
    background: white;
    max-width: 480px;
    min-width: 480px;
`;

// const TextLogin = styled.div`
//   color: #607D8B;
//   font-size: 14px;
//   font-family: "Roboto";
//   font-weight: 400;
// `;

const MainWrap = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

// const LabelLogin = styled.label`
//   display: flex;
//   margin-bottom: 0;
//   alignItems: 'center';
//   font-size: 14px;
//   font-family: "Roboto";
//   font-weight: 400;
//   cursor: pointer;
//   color: #607D8B;
// `;

const WrapBoxLogin = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0 75px 0;
`;

const WrapMainElement = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 75px 0;
`;

// const SignText = styled.div`
//   border-bottom: 1px solid #032F66;
//   margin-left: 10px;
//   color: #032F66;
//   font-size: 14px;
//   font-family: "Roboto";
//   font-weight: 400;
//   text-decoration: none;
// `;
//
// const TextErrorMessage = styled.div`
//   color: #B71C1C;
//   font-size: 14px;
//   font-family: "Roboto";
//   font-weight: 400;
//   text-decoration: none;
// `;


const Verification = (props) => {

    const [expirationMessage, setExpirationMessage] = useState('The verification link will expire in 24 hours');

    const resendEmail = () => {
        const signupInfo = JSON.parse(localStorage.getItem('signupInfo'));

        if (signupInfo !== null) {
            axios.post('/users/resend-activation-email', {
                firstName: signupInfo.firstName,
                lastName: signupInfo.lastName,
                email: signupInfo.email,
                phoneNumber: signupInfo.phoneNumber
            })
                .then(response => {
                    setExpirationMessage('We have re-sent your verification link. It will expire in 24 hours');
                })
                .catch(error => {
                });
        } else alert('not signed up');
    }


    return (
        <Aux>
            <MainWrap>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={3}>

                    <WrapMainElement>

                        <WrapBoxLogin>
                            <NavLink to="index.php">
                                <img
                                    src={require('../../assets/images/logo-blue.png')}
                                    width="140"
                                    height="51"
                                    alt=""/>
                            </NavLink>
                        </WrapBoxLogin>

                        <CartComponent>
                            <IconBlock>
                                <PermIdentityOutlinedIcon
                                    style={{
                                        color: 'white',
                                        fontSize: '30px',
                                        margin: '0 auto'
                                    }}/>
                            </IconBlock>

                            <Box py="20px">
                                <Typography variant="subtitle2">
                                    Please help keep your account secure by clicking the
                                    verification link in your email to complete your profile.
                                </Typography>
                            </Box>

                            <Box pb="5px" pt="20px">
                                <Typography
                                    variant="subtitle2">
                                    Did not receive a verification e-mail?
                                </Typography>
                            </Box>

                            <Box pb="20px">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    onClick={() => resendEmail()}> Click here to resend
                                </Button>
                            </Box>

                            <Box pb="25px">
                                <Typography variant="caption">
                                    {expirationMessage}
                                </Typography>
                            </Box>

                        </CartComponent>
                    </WrapMainElement>
                </Grid>
            </MainWrap>
        </Aux>
    )
};

export default Verification;