import React, {Component} from 'react';

import {NavLink} from 'react-router-dom';
import Aux from '../../hoc/AuxWrapper'
import SignUpService from '../../repository/SignUpService';
import PhoneInput from 'react-phone-input-2'
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import Box from "@material-ui/core/Box";

import 'react-phone-input-2/lib/style.css'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import queryString from "query-string";

const IconBlock = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  position: absolute;
  top: -25px;
  left: 30px;
  background: linear-gradient(0deg, rgba(102,187,106,1) 0%, rgba(67,160,71,1) 100%);;
`;


const HeaderTextLogin = styled.div`
  font-size: 24px;
  font-family: "Roboto";
  font-weight: 500;
  

`;

const CartComponent = styled.div`
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),
     0px 1px 1px 0px rgba(0,0,0,0.14),
      0px 1px 3px 0px rgba(0,0,0,0.12);
    padding: 60px 30px 35px 30px;
    position: relative;
    background: white;
    max-width: 480px;
    min-width: 480px;
    
   @media (max-width: 600px) {
        min-width: 100%;
        padding: 60px 15px 35px 15px;
  } 
    
`;

const TextLogin = styled.div`
  color: #607D8B;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
`;

const MainWrap = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  
  @media (max-width: 600px) {
    width: 100%;
    margin: 0 auto;
  }
  
`;

const WrapBoxLogin = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0 75px 0;
  
   @media (max-width: 600px) {
        min-width: 100%;
        padding-top: 30px;
  }
  
`;

const WrapMainElement = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  
   @media (max-width: 600px) {
    width: 90%;
   }
   
`;

const SignText = styled.div`
  border-bottom: 1px solid #032F66;
  margin-left: 10px;
  color: #032F66;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  text-decoration: none;
`;

const TextErrorMessage = styled.div`
  color: #B71C1C;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  text-decoration: none;
`;

class SignUp extends Component {

    state = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        termsConditions: false,
        receiveMessages: false,
        errorMessage: null,
        allowsubmit: false,

    };

    componentDidMount() {
        const allowsubmit = queryString.parse(this.props.location.search).allowsubmit;
        this.setState({
            allowsubmit: allowsubmit === 'true'
        })
    }

    signUpHandler(e) {
        e.preventDefault();

        if (this.state.firstName.length > 0 && this.state.lastName.length > 0 && this.state.email.length > 0
            && this.state.phoneNumber.length > 0 && this.fieldsRegexValidation()
            && this.state.termsConditions && this.state.receiveMessages) {
            localStorage.setItem('signupInfo', JSON.stringify({
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                phoneNumber: this.state.phoneNumber
            }));

            SignUpService.signUp({
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email.toLowerCase(),
                phoneNumber: this.state.phoneNumber
            }).then(response => {
                if (response.status === 226) {
                    this.setState({
                        errorMessage: response.data
                    });
                } else {
                    this.props.history.push('/verification');
                }
            }).catch(error => {
                this.setState({
                    errorMessage: 'There was a problem with the sign up process'
                });
            });
        } else this.setErrorMessage()
    }

    firstNameChangeHandler(e) {
        this.setState({
            firstName: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
        });
    }

    lastNameChangeHandler(e) {
        this.setState({
            lastName: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
        });
    }

    emailChangeHandler(e) {
        this.setState({
            email: e.target.value
        });
    }

    phoneNumberChangeHandler(isValid, newNumber, countryData, fullNumber, isExtension) {
        const re = /^[0-9\b]+$/;

        if (newNumber === '' || re.test(newNumber)) {
            this.setState({
                phoneNumber: newNumber
            });

            return fullNumber.replace(/(\s|-)/g, '');
        } else {
            return fullNumber.replace(/(\s|-)/g, '');
        }
    }

    fieldsRegexValidation() {
        return this.state.emailRegex.test(this.state.email);
    }

    setErrorMessage() {
        this.setState({
            errorMessage: 'All fields are required.'
        })
    }


    render() {
        return (
            <Aux>
                {/*<div className="signup-overlay-1"></div>*/}

                <MainWrap>

                    <WrapMainElement>
                        <WrapBoxLogin>
                            <NavLink to="index.php">
                                <img
                                    src={require('../../assets/images/logo-blue.png')}
                                    width="140"
                                    height="51"
                                    alt=""/>
                            </NavLink>
                        </WrapBoxLogin>

                        <CartComponent>
                            <IconBlock>
                                <PermIdentityOutlinedIcon
                                    style={{color: 'white', fontSize: '30px', margin: '0 auto'}}/>
                            </IconBlock>

                            <form onSubmit={(e) => this.signUpHandler(e)}>

                                <HeaderTextLogin>Sign Up</HeaderTextLogin>
                                <TextLogin>Sign up on the internal platform</TextLogin>

                                <hr/>

                                <div className="form-group">
                                    <TextField
                                        type="text"
                                        fullWidth
                                        size={"small"}
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 100
                                        }}
                                        label="First Name *"
                                        onChange={(e) => this.firstNameChangeHandler(e)}
                                        value={this.state.firstName}/>
                                </div>

                                <div className="form-group">
                                    <TextField
                                        fullWidth
                                        size={"small"}
                                        type="text"
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 100
                                        }}
                                        label="Last Name *"
                                        onChange={(e) => this.lastNameChangeHandler(e)}
                                        value={this.state.lastName}/>
                                </div>

                                <div className="form-group">
                                    <TextField
                                        fullWidth
                                        size={"small"}
                                        type="email"
                                        variant="outlined"
                                        label="E-mail *"
                                        inputProps={{
                                            maxLength: 100
                                        }}
                                        onChange={(e) => this.emailChangeHandler(e)}
                                        value={this.state.email}/>
                                </div>

                                <div className="form-group">
                                    <PhoneInput id='phone'
                                                value={this.state.phoneNumber}
                                                country={'us'}
                                                inputClass='tel-input'
                                                containerClass="tel-input-container"
                                                onChange={phone => this.setState({phoneNumber: phone})}/>
                                </div>

                                <div className="form-group">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.termsConditions}
                                                onChange={(event) => this.setState({termsConditions: event.target.checked})}
                                                name="termsConditions"
                                                color="primary"
                                            />
                                        }
                                        label={
                                            <Typography>
                                                I have read and agree to the site's <a
                                                href="https://www.amerx.com/terms-conditions">Terms and
                                                Conditions</a> and <a href="https://www.amerx.com/privacy-policy">Privacy
                                                Policy</a>
                                            </Typography>
                                        }
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.receiveMessages}
                                                onChange={(event) => this.setState({receiveMessages: event.target.checked})}
                                                name="receiveMessages"
                                                color="primary"
                                            />
                                        }
                                        label="I agree to receive electronic communications such as updates and new opportunities"
                                    />
                                </div>

                                <div className="form-group">
                                    <TextErrorMessage> {this.state.errorMessage} </TextErrorMessage>
                                </div>

                                <div className="form-group">
                                    <Button
                                        fullWidth
                                        size="large"
                                        disabled={!this.state.allowsubmit}
                                        color="primary"
                                        variant="contained"
                                        formAction="signup-2-verification.php"
                                        type="submit">
                                        Submit
                                    </Button>
                                </div>

                                <hr/>

                                <div className="form-group">
                                    <Box display="flex" justifyContent="space-between">
                                        <Box display="flex">
                                            <TextLogin>Already a member?</TextLogin>
                                            <NavLink to="/login">
                                                <SignText>Log In</SignText>
                                            </NavLink>
                                        </Box>

                                        <NavLink
                                            to="/reset-password">
                                            <TextLogin>Forgot password?</TextLogin>

                                        </NavLink>
                                    </Box>
                                </div>
                            </form>

                        </CartComponent>
                    </WrapMainElement>

                </MainWrap>

            </Aux>
        );
    }
}

export default SignUp;