import axios from '../custom-axios/app';

const MiscService = {
    fetchCountries: () => {
        return axios.get('/rest/misc/countries');
    },

    fetchStates: () => {
        return axios.get('/rest/misc/states')
    }
}

export default MiscService;