import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

const SelectDoB = (props) => {
    const [date, setDate] = useState(null)
    const [days, setDays] = useState([])
    const [years, setYears] = useState([])
    const [day, setDay] = useState(0)
    const [month, setMonth] = useState(0)
    const [year, setYear] = useState(0)
    const [errorMonth, setErrorMonth] = useState(false)
    const [errorDay, setErrorDay] = useState(false)

    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

    useEffect(() => {
        setYears(props.years)

        if (props.selectedValue && props.selectedValue.chosenValue) {
            initDoB(props.selectedValue.chosenValue);
        }
    }, [props.selectedValue])

    useEffect(() => {
        updateDate()
    }, [month, day, year])

    useEffect(() => {
        handleNewDate()
    }, [date])

    const initDoB = (dob) => {
        const _date = new Date(dob);
        const _month = _date.getMonth() + 1
        const _day = _date.getDate()
        const _year = _date.getFullYear()

        setMonth(_month)
        setDay(_day)
        setYear(_year)

        updateDays(_month, _year);
    }

    const handleMonthOnChange = (e) => {
        const _month = e.target.value

        if (month !== _month) {
            setMonth(parseInt(_month))
            updateDays(_month, year)
        }
    }

    const handleDayOnChange = (e) => {
        const _day = e.target.value

        if (day !== _day) {
            setDay(parseInt(_day))
        }
    }

    const handleYearOnChange = (e) => {
        const _year = e.target.value

        if (year !== _year) {
            setYear(parseInt(_year))
            updateDays(month, _year)
        }
    }

    const updateDays = (month, year) => {
        let newDays = [];
        const _daysInMonth = new Date(year, month, 0).getDate()

        for (let i = 1; i <= _daysInMonth; i++) {
            newDays.push(i);
        }

        setDays(newDays);
    }

    const updateDate = () => {
        if (!checkError()) {
            const dob = new Date(year, month - 1, day, 0, 0, 0, 0)
            setDate(dob)
        }
    }

    const handleNewDate = () => {
        if (date !== null) {
            props.onChangeHandler({
                attributeCode: props.attributeCode,
                value: date
            });
        }
    }

    const checkError = () => {
        if (year !== 0) {
            let _errorMonth = false;
            let _errorDay = false;

            if (month === 0) {
                _errorMonth = true
            } else if (day === 0) {
                _errorDay = true
            }

            setErrorDay(_errorDay)
            setErrorMonth(_errorMonth)

            return (_errorDay || _errorMonth);
        } else {
            return true
        }
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={4} spacing={3}>
                <FormControl variant="outlined"
                             fullWidth
                             disabled={year === 0}
                             error={errorMonth}>
                    <InputLabel htmlFor="outlined-age-native-simple">
                        Month
                    </InputLabel>
                    <Select native
                            value={month !== 0 ? month : ''}
                            onChange={handleMonthOnChange}
                            label="Month"
                            inputProps={{
                                id: 'outlined-age-native-simple'
                            }}>

                        <option value="" disabled selected={month === 0}/>
                        {months.map(_month => {
                            return (
                                <option key={_month}
                                        value={_month}
                                        selected={_month === month}>
                                    {_month}
                                </option>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={4} spacing={3}>
                <FormControl variant="outlined"
                             fullWidth
                             disabled={month === 0}
                             error={errorDay}>
                    <InputLabel htmlFor="outlined-age-native-simple">
                        Day
                    </InputLabel>
                    <Select native
                            value={day !== 0 ? day : ''}
                            onChange={handleDayOnChange}
                            label="Day"
                            inputProps={{
                                id: 'outlined-age-native-simple',
                            }}>

                        <option value="" disabled selected={day === 0}/>
                        {days.map(_day => {
                            return (
                                <option key={_day}
                                        value={_day}
                                        selected={_day === day}>
                                    {_day}
                                </option>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={4} spacing={3}>
                <FormControl variant="outlined"
                             fullWidth>
                    <InputLabel htmlFor="outlined-age-native-simple">
                        Year
                    </InputLabel>
                    <Select native
                            value={year !== 0 ? year : ''}
                            onChange={handleYearOnChange}
                            label="Year"
                            inputProps={{
                                id: 'outlined-age-native-simple',
                            }}>

                        <option value="" disabled selected={year === 0}/>
                        {props.years.map(_year => {
                            return (
                                <option key={_year}
                                        value={_year}
                                        selected={_year === year}>
                                    {_year}
                                </option>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}

export default SelectDoB;