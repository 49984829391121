import React, {Component} from 'react';
import InputText from '../../components/input/InputText';
import PasswordInput from '../../components/input/PasswordInput';
import Select from '../../components/input/Select1';
import EmailInput from '../../components/input/Email';
import DobUS from "../../components/input/DobUsSelect";
import IntlTelInput from "react-intl-tel-input";

class PointOfContactCompanyPage extends Component {

    state = {
        updated: false,
        firstName: '',
        lastName: '',
        streetAddress: '',
        city: '',
        postalCode: '',
        mailStreetAddress: '',
        mailCity: '',
        mailPostalCode: '',
        stateId: '',
        countryId: '',
        mailStateId: '',
        mailCountryId: '',
        alternativeEmail: '',
        phoneNumber: '',
        alternativePhoneNumb: '',
        'STATE_PROVINCE_ID': [],
        'COUNTRY_ID': [],
        'MAILING_COUNTRY_ID': [],
        'MAILING_STATE_PROVINCE_ID': [],
        'HOME_STATE_PROVINCE': [],
        'CONTRACT_HOME_STATE': [],
        'COUNTRY_CITIZEN_POINT_OF_CONTACT': [],
        months: [],
        years: [],
        ssnHidden: true
    };

    componentDidMount() {
        this.init();
    }

    componentWillUpdate(nextProps, nextState) {
        if (!this.state.updated && nextProps.investor) {
            this.setState({
                updated: true
            }, () => this.setStateFields());
        }
    }

    init() {
        this.props.fetchAttributeOptions('COUNTRY_CITIZEN_POINT_OF_CONTACT').then((data) => {
            this.setState({
                ...this.state,
                'COUNTRY_CITIZEN_POINT_OF_CONTACT': data.data
            });
        });

        this.props.fetchAttributeOptions('CONTRACT_HOME_STATE').then((data) => {
            this.setState({
                ...this.state,
                'CONTRACT_HOME_STATE': data.data
            });
        });

        this.props.fetchAttributeOptions('PLEASE_CHOSE_2').then((data) => {
            this.setState({
                ...this.state,
                'PLEASE_CHOSE_2': data.data
            });
        });

        this.props.fetchAttributeOptions('HOME_STATE_PROVINCE').then((data) => {
            this.setState({
                ...this.state,
                'HOME_STATE_PROVINCE': data.data
            });
        });

        this.props.fetchAttributeOptions('COUNTRY').then((data) => {
            this.setState({
                ...this.state,
                'COUNTRY': data.data
            });
        });

        let allYears = [];
        let years = new Date().getFullYear() - 18;
        for (let i = 1900; i <= years; i++) {
            allYears.unshift(i);
        }

        let allMonths = [];
        for (let i = 1; i <= 12; i++) {
            allMonths.push(i);
        }

        this.setState({
            months: allMonths,
            years: allYears
        })

    }

    onChangeHandler(stateField, value) {
        this.setState({
            [stateField]: value
        });
    }

    submitChanges(e) {
        e.preventDefault();
        this.props.updateInvestorInfo({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            streetAddress: this.state.streetAddress,
            city: this.state.city,
            postalCode: this.state.postalCode,
            mailStreetAddress: this.state.mailStreetAddress,
            mailCity: this.state.mailCity,
            mailPostalCode: this.state.mailPostalCode,
            stateId: this.state.stateId,
            countryId: this.state.countryId,
            mailStateId: this.state.mailStateId,
            mailCountryId: this.state.mailCountryId,
            alternativeEmail: this.state.alternativeEmail,
            phoneNumber: this.state.phoneNumber,
            alternativePhoneNumb: this.state.alternativePhoneNumb
        });
    }

    setStateFields() {

        this.setState({
            firstName: this.props.investor.user.firstName,
            lastName: this.props.investor.user.lastName,
            streetAddress: this.props.investor.streetAddress,
            city: this.props.investor.city,
            postalCode: this.props.investor.postalCode,
            mailStreetAddress: this.props.investor.mailStreetAddress,
            mailCity: this.props.investor.mailCity,
            mailPostalCode: this.props.investor.mailPostalCode,
            stateId: this.props.investor.state.id,
            countryId: this.props.investor.country.id,
            mailStateId: this.props.investor.mailState.id,
            mailCountryId: this.props.investor.mailCountry.id,
            alternativeEmail: this.props.investor.alternativeEmail,
            phoneNumber: this.props.investor.phoneNumber,
            alternativePhoneNumb: this.props.investor.alternativePhoneNumb,

        });
    }

    toggleSsnHidden() {
        this.setState((prevState, props) => ({
            ssnHidden: !prevState.ssnHidden
        }));
    }

    phoneNumberChangeHandler(e) {
        let request = {
            "attributeCode": "WORK_PHONE_NUMBER",
            "value": e
        };
        this.props.pushInvestorChange(request);
    }

    render() {
        return (

            <section className="dashboard-content margin-top-50">
                <div className="container-fluid">
                    <div class="row">

                        <div className="col-xs-12">
                            <div class="info-wrapper padding-bottom-40">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-lg-offset-2">
                                        <form class="form-horizontal">

                                            <div className="form-group">
                                                <label
                                                    className="col-xs-12 col-sm-5 control-label control-label-xl">First
                                                    Name *</label>
                                                <div className="col-sm-7">
                                                    <input
                                                        defaultValue={this.props.investorData['NAME'] ? this.props.investorData['NAME'].chosenValue : ''}
                                                        className="form-control input-lg text-capitalize"
                                                        disabled/>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label
                                                    className="col-xs-12 col-sm-5 control-label control-label-xl">Last
                                                    Name *</label>
                                                <div className="col-sm-7">
                                                    <input
                                                        defaultValue={this.props.investorData['LAST_NAME_COMPANY_POINT_OF_CONTACT'] ? this.props.investorData['LAST_NAME_COMPANY_POINT_OF_CONTACT'].chosenValue : ''}
                                                        className="form-control input-lg text-capitalize"
                                                        disabled/>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label
                                                    className="col-xs-12 col-sm-5 control-label control-label-xl">Title
                                                    *</label>
                                                <div className="col-sm-7">
                                                    <InputText
                                                        defaultValue={this.props.investorData['TITLE']}
                                                        attributeCode="TITLE"
                                                        onBlurHandler={this.props.pushInvestorChange}
                                                        className="form-control input-lg"/>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label
                                                    className="col-xs-12 col-sm-5 control-label control-label-xl">Country
                                                    of Citizenship *</label>
                                                <div className="col-sm-7">
                                                    <Select
                                                        className="form-control input-lg"
                                                        dataLiveSearch="true"
                                                        dataStyle="form-control input-lg"
                                                        dataWidth="100%"
                                                        deleteInvestorData={this.props.deleteInvestorData}
                                                        attributeCode="COUNTRY_CITIZEN_POINT_OF_CONTACT"
                                                        selectedValue={this.props.investorData['COUNTRY_CITIZEN_POINT_OF_CONTACT']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['COUNTRY_CITIZEN_POINT_OF_CONTACT']}/>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="col-xs-12 col-sm-5 control-label control-label-xl">DOB
                                                    (US Style) *</label>
                                                <div className="col-sm-7">

                                                    <DobUS monthsClassName="form-control input-lg"
                                                           yearsClassName="form-control input-lg"
                                                           daysClassName="form-control input-lg"
                                                           attributeCode="DOB"
                                                           onChangeHandler={this.props.pushInvestorChange}
                                                           defaultValue={this.props.investorData['DOB']}
                                                           months={this.state.months}
                                                           years={this.state.years}/>
                                                </div>
                                            </div>

                                            {this.props.investorData['COUNTRY_CITIZEN_POINT_OF_CONTACT'] && this.props.investorData['COUNTRY_CITIZEN_POINT_OF_CONTACT'].chosenValue === 'United States' ?
                                                <div className="form-group">
                                                    <label
                                                        className="col-xs-12 col-sm-5 control-label control-label-xl">
                                                        SSN *
                                                    </label>
                                                    <div className="col-sm-7">
                                                        <div className="row">
                                                            <div className="col-sm-9">
                                                                {this.state.ssnHidden ?
                                                                    <PasswordInput
                                                                        defaultValue={this.props.investorData['SSN_OR_ITIN']}
                                                                        attributeCode="SSN_OR_ITIN"
                                                                        onBlurHandler={this.props.pushInvestorChange}
                                                                        className="form-control input-lg"/>
                                                                    :
                                                                    <InputText
                                                                        defaultValue={this.props.investorData['SSN_OR_ITIN']}
                                                                        attributeCode="SSN_OR_ITIN"
                                                                        onBlurHandler={this.props.pushInvestorChange}
                                                                        className="form-control input-lg"/>
                                                                }
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <button className="btn btn-orange btn-block btn-lg"
                                                                        type="button"
                                                                        onClick={() => this.toggleSsnHidden()}> Show
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : null}

                                            <div className="form-group">
                                                <label className="col-xs-12 col-sm-5 control-label control-label-xl">Work
                                                    phone number *</label>
                                                <div className="col-sm-7">
                                                    <IntlTelInput
                                                        style={{"display": "block"}}
                                                        defaultValue={this.props.investorData['WORK_PHONE_NUMBER'] ? this.props.investorData['WORK_PHONE_NUMBER'].chosenValue : ''}
                                                        format="true"
                                                        autoHideDialCode="true"
                                                        containerClassName="intl-tel-input"
                                                        inputClassName="form-control input-xl"
                                                        onPhoneNumberChange={(valid, value, obj, clearval) => this.phoneNumberChangeHandler(clearval)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="col-xs-12 col-sm-5 control-label control-label-xl">Work
                                                    Email *</label>
                                                <div className="col-sm-7">
                                                    <EmailInput
                                                        defaultValue={this.props.investorData['WORK_EMAIL']}
                                                        attributeCode="WORK_EMAIL"
                                                        onBlurHandler={this.props.pushInvestorChange}
                                                        className="form-control input-lg"/>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="col-xs-12 col-sm-5 control-label control-label-xl">Primary
                                                    Email *</label>
                                                <div className="col-sm-7">
                                                    <input type="text"
                                                           className="form-control input-lg"
                                                           defaultValue={this.props.investor ? this.props.investor.user.email : ''}
                                                           disabled
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

export default PointOfContactCompanyPage;