import React, {Component} from 'react';
import Aux from "../../../hoc/AuxWrapper";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

class IssuerWaitingForApproval extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <Aux>
                <Container maxWidth='lg' style={{margin: '48px auto', minHeight: '65vh'}}>
                    <Grid container spacing={3}>
                        <Grid item lg={12} xs={12} style={{paddingTop: 320, display: 'flex', justifyContent: 'center'}}>
                            <Typography variant='h6'>Thank you for completing the application process. You will receive
                                an email once your account is approved.</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Aux>
        )
    }
}

export default IssuerWaitingForApproval;