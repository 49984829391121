import {createMuiTheme} from "@material-ui/core/styles";

import '../assets/css/bootstrap.css';
import '../index.css';
import '../assets/css/style-new.css';
import '../assets/fontawesome/css/fontawesome-all.css';
import '../assets/css/owl/owl.carousel.min.css';
import '../assets/css/owl/owl.theme.default.min.css';
import '../assets/css/dropzone.css';
import '../assets/css/custom-radio-checkbox.css';
import '../assets/css/bootstrap-select.min.css';
import '../assets/css/bootstrap-datetimepicker.css';
// new global
import '../css/global.css';

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#032F66',
        },
        secondary: {
            main: '#4CAF50',
        },
        default: {
            main: '#FAFAFA'
        }
    },
})